import { useContext, useEffect, useState } from "react";
import "./DomainsAllowed.css";
import { ReactComponent as CrossIcon } from "../../../media/crossPink.svg";
import { ChatbotFormContext } from "../../ChatbotForm";
import { InfoDescription } from "../../InfoDescription/InfoDescription";
import { ReactComponent as ChatIcon } from "../../../media/chatIcon.svg";

export default function DomainsAllowed({
  customContextValues,
  returnJustInput,
  inputPlaceholder = "Insert allowed domains here",
  inputRef = null,
  breakOnSpace = true,
}) {
  // Custom context is for when we want to use this component with data that is not domainsAllowed from chatbotformcontext
  const formContext = useContext(ChatbotFormContext);
  const { domainsAllowed, setDomainsAllowed } = customContextValues
    ? customContextValues
    : formContext;

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => setInputValue(event.target.value);

  useEffect(() => {
    if (inputValue === "") return;
    if ((breakOnSpace ? inputValue === " " : false) || inputValue === "\n") {
      setInputValue("");
      return;
    }

    const textItems = inputValue.split(" ");

    const notSpace = textItems[textItems.length - 1] !== "";
    const notNewLine =
      textItems[textItems.length - 1].charAt(textItems[textItems.length - 1].length - 1) !== "\n";

    if (breakOnSpace ? notSpace : true && notNewLine) return;

    let newItem = null;
    if (textItems && !["", " "].includes(textItems[0])) newItem = textItems[0];

    if (newItem) {
      setDomainsAllowed((prevItems) => {
        return [...prevItems, newItem];
      });
    }
    setInputValue("");
  }, [inputValue]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (inputValue) {
        setDomainsAllowed((prevItems) => {
          return [...prevItems, inputValue];
        });
        setInputValue("");
      }
    }
  };

  const domainsAllowedJsx = (
    <div className="items-input-items-container">
      {domainsAllowed.map((item, index) =>
        item ? <Item item={item} key={index} index={index} setItems={setDomainsAllowed} /> : ""
      )}

      <input
        className="items-input"
        onChange={handleInputChange}
        value={inputValue}
        placeholder={domainsAllowed?.length == 0 && inputPlaceholder}
        onKeyPress={handleKeyPress}
        ref={inputRef}
      />
    </div>
  );

  if (returnJustInput) return domainsAllowedJsx;

  return (
    <div className="form-item-column">
      <div className="chatbot-settings-title row-align-center">
        Allowed Domains
        <InfoDescription customDistance="30px">
          Optional. If you only use specific domains, we recommend to enter them here. When this is
          filled, your chatbot will not work from any other domain or personal test page except the
          ones that you listed here. Leave this empty if you wish to allow any domain to host your
          chatbot. Use a space or enter key to enter multiple domains.
        </InfoDescription>
      </div>
      <br />

      {domainsAllowedJsx}
      <br />
    </div>
  );
}

export const Item = ({ item, index, setItems, hasIndexText, hasChatIcon = false }) => {
  const removeFromItems = () => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];

      const filteredItems = updatedItems.filter((item, currentIndex) => index !== currentIndex);

      return filteredItems;
    });
  };

  return (
    <span className={`items-input-item ${hasIndexText ? "with-index" : ""}`}>
      {hasIndexText && (
        <span className="items-input-item-text" style={{ marginRight: "9px" }}>
          {index + 1}.{" "}
        </span>
      )}

      <span className={`items-input-item-text ${hasIndexText ? "with-index" : ""}`}>
        {item?.question ? item?.question : item}
      </span>

      <div className="flex row align-center" style={{ gap: "2px", marginLeft: "10px" }}>
        {hasChatIcon && <ChatIcon height={15} width={15} />}
        <CrossIcon
          className={`cross-pink-icon ${hasIndexText ? "with-index" : ""}`}
          onClick={removeFromItems}
        />
      </div>
    </span>
  );
};
