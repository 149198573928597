import { ErrorFormat } from "../../../Tools/Errors/ErrorFormatting";
import { API_URL } from "../../../config";

export async function getChatbotForms(chatbotId, token) {
  try {
    const endpoint = `${API_URL}/chat_bots/${chatbotId}/forms`;
    const headers = { Authorization: token };

    const response = await fetch(endpoint, { headers });
    if (!response.ok)
      throw new ErrorFormat("Failed to fetch forms.", response.status);

    const forms = await response.json();
    return forms;
  } catch (error) {
    return null;
  }
}
