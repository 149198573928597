import { useContext, useState } from "react";
import { WrappedComponentContext } from "../../../WrappedComponent";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../config";
import { formatFormFieldsForSaving } from "./CreateForm";
import { ErrorFormat } from "../../../Tools/Errors/ErrorFormatting";
import FormSettings from "./FormSettings";

const EditForm = ({ formData }) => {
  const [form, setForm] = useState(formData);
  const [isSaving, setIsSaving] = useState(false);

  const { token, logout, setBannerMessage } = useContext(WrappedComponentContext);
  const { chatbotId, tabItemId } = useParams();

  const saveForm = async () => {
    try {
      if (isSaving) return;
      setIsSaving(true);

      const endpoint = `${API_URL}/chat_bots/${chatbotId}/forms/${tabItemId}`;
      const method = "PUT";
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      const body = JSON.stringify({
        form: {
          ...form,
          form_fields_attributes: formatFormFieldsForSaving(form),
        },
      });

      const response = await fetch(endpoint, { method, headers, body });
      if (!response.ok) throw new ErrorFormat("Failed to save form", response.status, logout);

      window.location.href = `/chatbot/settings/${chatbotId}/forms/${form.id}`;
    } catch (error) {
      setBannerMessage({ type: "error", title: "Failed to save form." });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <FormSettings
      form={form}
      setForm={setForm}
      saveFunction={saveForm}
      cancelLink={`/chatbot/settings/${chatbotId}/forms/${formData?.id}`}
      isSaving={isSaving}
    />
  );
};

export default EditForm;
