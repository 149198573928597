import { useContext, useEffect, useState } from "react";
import { PricingContext } from "./Pricing";
import { API_URL } from "../config";
import { useNavigate } from "react-router-dom";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import CircularIndeterminate from "../Tools/Loading/CircularIndeterminate";
import usePageWidth from "../Tools/usePageWidth";
import { WrappedComponentContext } from "../WrappedComponent";
import { createNewChatbot } from "../SubscriptionStates/Checkout/createNewChatbot";
import { findBestPlan } from "../Tools/findBestPlan";

export const PricingPlan = ({ plan }) => {
  const [planLink, setPlanLink] = useState({
    monthly: `/signup`,
    annually: `/signup`,
  });
  const [prices, setPrices] = useState();
  const [isSelectedPlan, setIsSelectedPlan] = useState(false);
  const [loading, setLoading] = useState(false);

  const { monthly, token, isUpgrade, userInfo } = useContext(PricingContext);
  const { logout, setBannerMessage, checkForEventTriggers } = useContext(
    WrappedComponentContext
  );

  const navigate = useNavigate();
  const width = usePageWidth();

  useEffect(() => {
    const action = hasPaidPlan() ? "update_subscription" : "checkout_sessions";

    if (!(plan?.stripePlan?.stripe_prices?.length > 0)) return;
    const activePrices = plan.stripePlan.stripe_prices.filter(
      (stripePrice) => stripePrice?.active
    );

    if (activePrices.length > 1) {
      const planLinkObj = { ...planLink };
      const pricesObj = {};

      for (let i = 0; i < activePrices.length; i++) {
        if (activePrices[i]?.interval === "month") {
          if (isUpgrade)
            planLinkObj.monthly = `${API_URL}/stripe/${action}?price_id=${activePrices[i]?.id}`;
          pricesObj.monthly = activePrices[i]?.price_cents / 100;
        } else if (activePrices[i]?.interval === "year") {
          if (isUpgrade)
            planLinkObj.annually = `${API_URL}/stripe/${action}?price_id=${activePrices[i]?.id}`;
          pricesObj.annually = activePrices[i]?.price_cents / 100;
        }
      }

      setPlanLink(planLinkObj);
      setPrices(pricesObj);
    } else {
      if (isUpgrade) {
        setPlanLink({
          monthly: `${API_URL}/stripe/${action}?price_id=${activePrices[0]?.id}`,
          annually: `${API_URL}/stripe/${action}?price_id=${activePrices[0]?.id}`,
        });
      }
      setPrices({ monthly: 0, annually: 0 });
    }
  }, [plan, userInfo]);

  useEffect(() => {
    if (userInfo) setIsSelectedPlan((prev) => checkIfSelectedPlan());
  }, [userInfo, monthly]);

  if (!plan) return "";
  const { name } = plan;

  const checkIfSelectedPlan = () => {
    const bestPlan = findBestPlan(userInfo?.subscriptions);

    const intervalsEqual =
      (bestPlan?.interval === "month" && monthly) ||
      (bestPlan?.interval === "year" && !monthly) ||
      !bestPlan?.interval;

    const tiersEqual = plan?.stripePlan?.tier === bestPlan?.tier;

    return intervalsEqual && tiersEqual;
  };

  const hasPaidPlan = () => {
    try {
      const bestPlan = findBestPlan(userInfo?.subscriptions);

      return bestPlan?.tier !== "free";
    } catch (error) {
      return false;
    }
  };

  const selectFreePlan = async () => {
    try {
      if (!token) return;

      const endpoint = `${API_URL}/accounts/plan_selection_made`;
      const headers = { Authorization: token };
      const method = "POST";

      const response = await fetch(endpoint, { method, headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Free plan selection failed.",
          response.status,
          logout
        );

      const data = await response.json();
      if (data?.success) {
        // If chatbot was made before checkout it is created here
        await createNewChatbot({ token, checkForEventTriggers });

        window.location.replace("/chatbots");
      } else setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setBannerMessage({ type: "error", title: "Free plan selection failed." });
    }
  };

  const handlePlanButtonClick = async () => {
    try {
      setLoading(true);

      if (!userInfo?.plan_selection_made && name.toLowerCase() === "free")
        selectFreePlan();

      let endpoint = monthly ? planLink.monthly : planLink.annually;

      // If not logged in reroutes to signup with selected plan data
      if (endpoint.startsWith("/signup")) {
        window.sessionStorage.removeItem("plan");
        window.sessionStorage.removeItem("interval");

        window.sessionStorage.setItem("plan", name);
        window.sessionStorage.setItem("interval", monthly ? "month" : "year");
        setLoading(false);
        navigate(endpoint);
        return;
      }

      if (isSelectedPlan) return;

      if (name === "Free") endpoint = `${API_URL}/stripe/cancel_subscription`;

      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      const body = JSON.stringify({ product_id: plan?.id });
      const method = "POST";

      const response = await fetch(endpoint, { method, headers, body });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to fetch slack plan link.",
          response.status,
          logout
        );

      const data = await response.json();
      const stripeUrl = hasPaidPlan()
        ? data?.stripe_portal_url
        : data?.stripe_checkout_session_url;

      if (stripeUrl) window.location.replace(stripeUrl);
      else setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err?.errorMessage ?? err);
      setBannerMessage({
        type: "error",
        title: "Failed to select plan",
        message: "Could not fetch slack plan link.",
      });
    }
  };

  let buttonText;
  if (isUpgrade) {
    if (userInfo?.plan_selection_made) {
      isSelectedPlan ? (buttonText = "Current") : (buttonText = "Change");
      if (width >= 340) buttonText += " Plan";
    } else {
      buttonText = "Choose Plan";
    }
  } else {
    buttonText = "Start Now";
  }

  return (
    <div
      className="pricing-title-container"
      style={
        isSelectedPlan && userInfo?.plan_selection_made && isUpgrade
          ? { position: "relative", zIndex: -1 }
          : {}
      }
    >
      <h3>{name}</h3>

      {((isUpgrade && userInfo) || !isUpgrade) && (
        <button
          onClick={handlePlanButtonClick}
          className={`purple-button ${
            isSelectedPlan &&
            userInfo?.plan_selection_made &&
            isUpgrade &&
            "muted"
          }`}
        >
          {loading ? <CircularIndeterminate color="white" /> : buttonText}
        </button>
      )}

      {prices && (
        <h5>
          <span className="purple">
            ${monthly ? `${prices?.monthly}` : `${prices?.annually / 12}`}
          </span>
          /month
          {!monthly && (
            <span>
              <br />${prices?.annually} billed per year
            </span>
          )}
        </h5>
      )}
    </div>
  );
};
