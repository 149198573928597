import { createContext, useContext, useEffect, useState } from "react";
import { ViewWebsiteSourcePreview } from "../ViewSourcePreviews/ViewWebsiteSourcePreview/ViewWebsiteSourcePreview";
import { ViewTextSourcePreview } from "../ViewSourcePreviews/ViewTextSourcePreview";
import Modal from "../../../Modal/Modal";
import { Actions } from "../Actions/Actions";
import formatDate from "../../../Tools/formatDate";
import { KnowledgeBaseContext } from "../KnowledgeBase";
import "./KnowledgeBaseTableRow.css";
import StatusIcon from "./StatusIcon";
import WaitingWithText from "../../../Tools/ChatWaiting/WaitingWithText";
import LoadingStatusView from "./LoadingStatusView/LoadingStatusView";

export const KnowledgeBaseTableRowContext = createContext();

export const KnowledgeBaseTableRow = ({ source }) => {
  const [viewOpen, setViewOpen] = useState(false);
  const [fetchingDeleteResponse, setFetchingDeleteResponse] = useState(false);
  const [removeModalCloseButton, setRemoveModalCloseButton] = useState(false);

  const { sourceType, planCharLimitExceeded } =
    useContext(KnowledgeBaseContext);

  const viewComponent =
    sourceType === "Web" ? (
      <ViewWebsiteSourcePreview
        source={source}
        setRemoveModalCloseButton={setRemoveModalCloseButton}
      />
    ) : sourceType === "Text" ? (
      <ViewTextSourcePreview source={source} />
    ) : (
      ""
    );

  const typeText = getTypeText(sourceType, source);

  const openViewSourceModal = () => {
    if (
      sourceType !== "Pdf" &&
      !fetchingDeleteResponse &&
      !source?.pendingDeletion
    ) {
      setViewOpen(true);
    }
  };

  const value = {
    sourceType,
    source,
  };

  return (
    <KnowledgeBaseTableRowContext.Provider value={value}>
      <tr className="tr-style kb-tr">
        <td className="kb-side-table-item no-padding-vertical">
          <div
            className="row-align-center first-column-styles"
            style={{ position: "relative" }}
          >
            {/* Modal for viewing source */}
            {viewOpen && (
              <Modal
                setOpen={setViewOpen}
                component={viewComponent}
                extraClassName="view-source-modal"
                closeOnOutsideClick={false}
                removeCloseButton={removeModalCloseButton}
              />
            )}

            <div
              className="loading-status-view-container"
              style={
                source?.state === "gathering_data" ? { cursor: "pointer" } : {}
              }
            >
              <StatusIcon
                source={source}
                isDelete={fetchingDeleteResponse || source?.pendingDeletion}
              />

              <LoadingStatusView source={source} />
            </div>

            <span
              className="table-icon-next-to-text kb-title"
              onClick={openViewSourceModal}
            >
              <div
                className={`kb-title-text-container ${
                  sourceType === "Pdf" ||
                  fetchingDeleteResponse ||
                  source?.pendingDeletion
                    ? "document-kb-title"
                    : ""
                }`}
              >
                <span className="kb-title-text">{source?.title}</span>
              </div>
            </span>
          </div>
        </td>

        <td className={`kb-table-item type`}>
          <div className="type-pink">{typeText}</div>
        </td>

        <td className="kb-table-item date">
          {fetchingDeleteResponse || source?.pendingDeletion ? (
            <WaitingWithText optionalClassName="table-icon-next-to-text">
              Deleting
            </WaitingWithText>
          ) : (
            formatDate(source?.date)
          )}
        </td>

        <td
          className={`kb-table-item actions ${
            ["Pdf", "Text"].includes(sourceType) && "small"
          }`}
        >
          <Actions
            source={source}
            setModalOpen={setViewOpen}
            setFetchingDeleteResponse={setFetchingDeleteResponse}
            deleteFunctionDisabled={!source["can_destroy?"]}
            viewActionDisabled={
              fetchingDeleteResponse || source?.pendingDeletion
            }
            reindexActionDisabled={
              fetchingDeleteResponse || source?.pendingDeletion
            }
          />
        </td>
      </tr>
    </KnowledgeBaseTableRowContext.Provider>
  );
};

const getTypeText = (sourceType, source) => {
  const type = source?.type ? source?.type : source?.content_item_type;

  if (sourceType === "Web") {
    if (source?.subtype) return source?.subtype;
    if (type === "ContentItems::CrawledWebsiteContentItem") return "Website";
    else if (type === "ContentItems::SinglePageUrlContentItem")
      return "Single Page";
  }

  if (sourceType === "Pdf") {
    let documentType = "Document";
    if (type === "ContentItems::Documents::RawTextDocumentContentItem") {
      documentType = "TXT";
    } else if (type === "ContentItems::Documents::PdfDocumentContentItem") {
      documentType = "PDF";
    } else if (type === "ContentItems::Documents::MsWordDocumentContentItem") {
      documentType = "Word";
    }
    return documentType;
  }

  return sourceType;
};
