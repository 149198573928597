import { Link, useParams } from "react-router-dom";
import { ReactComponent as CloseAction } from "../../../media/closeAction.svg";
import { ReactComponent as SettingsAction } from "../../../media/settingsAction.svg";
import { useContext, useState } from "react";
import { useConfirm } from "../../../Tools/Confirm/Confirm";
import { API_URL } from "../../../config";
import { WrappedComponentContext } from "../../../WrappedComponent";
import { ErrorFormat } from "../../../Tools/Errors/ErrorFormatting";

const ActionsTableActions = ({ action, setActions }) => {
  const [deleteActionDisabled, setDeleteActionDisabled] = useState(false);

  const { chatbotId } = useParams();
  const { token, logout } = useContext(WrappedComponentContext);

  const confirm = useConfirm();

  const deleteAction = async () => {
    try {
      if (deleteActionDisabled) return;

      const confirmed = await confirm({ title: "Are you sure you want to delete this action?" });
      if (!confirmed) return;

      setDeleteActionDisabled(true);

      const endpoint = `${API_URL}/chat_bots/${chatbotId}/bot_actions/${action?.id}`;
      const headers = { Authorization: token };
      const method = "DELETE";
      const res = await fetch(endpoint, { method, headers });
      if (!res.ok) throw new ErrorFormat("Failed to delete action", res.status, logout);

      setActions((actions) => actions.filter((currAction) => currAction?.id !== action?.id));
    } catch (error) {
      setDeleteActionDisabled(false);
    }
  };

  return (
    <div className="flex align-center justify-center" style={{ gap: "5px" }}>
      <div className="action-icon-container">
        <Link to={`./${action?.id}`} style={{ display: "flex" }}>
          <SettingsAction />
        </Link>
        <span
          className="action-icon-text"
          style={{ width: "89px", right: "0px", fontSize: "12px" }}
        >
          Action Settings
        </span>
      </div>

      <div className={`action-icon-container ${deleteActionDisabled ? "disabled" : ""}`}>
        <CloseAction onClick={deleteAction} />
        <span
          className="action-icon-text"
          style={{ width: "79px", right: "0px", fontSize: "12px" }}
        >
          Delete Action
        </span>
      </div>
    </div>
  );
};

export default ActionsTableActions;
