import "./styles/App.css";
import "./styles/buttonStyles.css";
import "./styles/iconStyles.css";
import "./styles/pageCardLayout.css";
import { createContext, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Home from "./Home/Home";
import Chatbots from "./Chatbots/Chatbots";
import NewChatbot from "./ChatbotForm/NewChatbot";
import ManageChatbot from "./ManageChatbot/ManageChatbot";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";
import { API_URL } from "./config";
import Pricing from "./Pricing/Pricing";
import ContactUs from "./ContactUs/ContactUs";
import ResetPassword from "./Auth/ResetPassword/ResetPassword";
import ConfirmEmail from "./Auth/ConfirmEmail";
import EditChatbot from "./ChatbotForm/EditChatbot";
import NoMatch from "./NoMatch/NoMatch";
import ResetPasswordPage from "./Auth/ResetPasswordPage/ResetPasswordPage";
import ChangeEmail from "./Auth/ChangeEmail/ChangeEmail";
import SlackIntegration from "./SlackIntegration/SlackIntegration";
import SlackSuccess from "./SlackIntegration/SlackSuccess";
import Support from "./Support/Support";
import { ErrorFormat } from "./Tools/Errors/ErrorFormatting";
import Account from "./Account/Account";
import SlackFailed from "./SlackIntegration/SlackFailed";
import { GoogleLoginSuccess } from "./Auth/SignInWithGoogle/SignInWithGoogle";
import { WrappedComponent } from "./WrappedComponent";
import { GoogleSignInFailed } from "./Auth/SignInWithGoogle/GoogleSignInFailed";
import { CheckoutSuccess } from "./SubscriptionStates/Checkout/CheckoutSuccess";
import SubscriptionUpdated from "./SubscriptionStates/SubscriptionUpdated";
import SubscriptionCancelled from "./SubscriptionStates/SubscriptionCancelled";
import SubscriptionSuccessPage from "./SubscriptionStates/SubscriptionSuccessPage";
import SubscriptionErrorPage from "./SubscriptionStates/SubscriptionErrorPage";
import { ResetPasswordSuccess } from "./Auth/ResetPassword/ResetPasswordSuccess";
import { ResetPasswordLinkSent } from "./Auth/ResetPasswordPage/ResetPasswordLinkSent";
import HostedChatbot from "./HostedChatbot/HostedChatbot";
import { ConfirmDialogProvider } from "./Tools/Confirm/Confirm";
import { Helmet } from "react-helmet";
// import Intents from "./Intents/Intents";
// import IntentSettingsPage from "./Intents/IntentSettingsPage";
import MiniHostedChatbot from "./HostedChatbot/MiniHostedChatbot";
import ConfirmedEmails from "./ConfirmedEmails/ConfirmedEmails";
import TrustedEmailConfirmedPage from "./ConfirmedEmails/TrustedEmailConfirmedPage";

export const AppContext = createContext();
function App() {
  const [token, setToken] = useState(localStorage.getItem("accessKey"));
  const [userInfo, setUserInfo] = useState();
  const [globalSettings, setGlobalSettings] = useState();

  // Get global settings on page load
  useEffect(() => {
    fetchGlobalSettings();
  }, []);
  const fetchGlobalSettings = async () => {
    try {
      const response = await fetch(`${API_URL}/global_settings`);
      if (!response.ok) throw new Error("Failed to fetch global settings");

      const data = await response.json();
      setGlobalSettings(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchUserInfo = async (logout = () => {}) => {
    if (!token) return;

    try {
      const endpoint = `${API_URL}/accounts/current`;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { headers });
      if (!response.ok)
        throw new ErrorFormat("Failed to fetch user info.", response.status, logout);

      const data = await response.json();
      setUserInfo(data);
    } catch (error) {
      console.error(error?.errorMessage ?? error);
    }
  };

  const logout = (navigate = null) => {
    localStorage.removeItem("accessKey");
    if (navigate) navigate("/login");
    setToken(null);
    setUserInfo(null);

    fetch(API_URL + "/users/sign_out", {
      method: "DELETE",
      headers: { Authorization: token },
    });
  };

  const contextValue = {
    logout,
    token,
    setToken,
    userInfo,
    setUserInfo,
    fetchUserInfo,
    globalSettings,
  };

  const router = createBrowserRouter([
    {
      path: "/login",
      element: (
        <WrappedComponent startingTransparent={true}>
          <Login token={token} setToken={setToken} />
        </WrappedComponent>
      ),
    },
    {
      path: "/signup",
      element: (
        <WrappedComponent startingTransparent={true}>
          <Signup token={token} setToken={setToken} />
        </WrappedComponent>
      ),
    },

    {
      path: "/google/login_success",
      element: (
        <WrappedComponent>
          <GoogleLoginSuccess />
        </WrappedComponent>
      ),
    },
    {
      path: "/google/login_failed",
      element: (
        <WrappedComponent>
          <GoogleSignInFailed />
        </WrappedComponent>
      ),
    },

    {
      path: "/",
      element: (
        <WrappedComponent startingTransparent={true}>
          <Home />
        </WrappedComponent>
      ),
    },
    {
      path: "/ai_customer_service",
      element: (
        <WrappedComponent startingTransparent={true}>
          <Home version="ai_customer_service" />
        </WrappedComponent>
      ),
    },
    {
      path: "/ai_chatbots",
      element: (
        <WrappedComponent startingTransparent={true}>
          <Home version="ai_chatbots" />
        </WrappedComponent>
      ),
    },

    {
      path: "/chatbots",
      element: (
        <WrappedComponent>
          <Chatbots token={token} />
        </WrappedComponent>
      ),
    },

    {
      path: "/new-chatbot",
      element: (
        <WrappedComponent>
          <Helmet>
            <title>Create New Chatbot | Majic Chatbot</title>
            <meta property="og:title" content="Create New Chatbot | Majic Chatbot" />
            <meta name="twitter:title" content="Create New Chatbot | Majic Chatbot" />
            <meta name="description" content="Create a new chatbot powered by Majic Chatbot" />
            <meta
              property="og:description"
              content="Create a new chatbot powered by Majic Chatbot"
            />
            <meta
              name="twitter:description"
              content="Create a new chatbot powered by Majic Chatbot"
            />
            <meta property="og:url" content="https://majic.ai/new-chatbot" />
            <meta property="twitter:url" content="https://majic.ai/new-chatbot" />
          </Helmet>

          <NewChatbot />
        </WrappedComponent>
      ),
    },

    {
      path: "/emails",
      element: (
        <WrappedComponent>
          <ConfirmedEmails />
        </WrappedComponent>
      ),
    },
    {
      path: "/trusted-email/verified",
      element: (
        <WrappedComponent>
          <TrustedEmailConfirmedPage />
        </WrappedComponent>
      ),
    },

    {
      path: "/chatbot/settings/:chatbotId",
      element: (
        <WrappedComponent>
          <EditChatbot />
        </WrappedComponent>
      ),
    },
    {
      path: "/chatbot/settings/:chatbotId/:tab",
      element: (
        <WrappedComponent>
          <EditChatbot />
        </WrappedComponent>
      ),
    },
    {
      path: "/chatbot/settings/:chatbotId/:tab/:tabItemId",
      element: (
        <WrappedComponent>
          <EditChatbot />
        </WrappedComponent>
      ),
    },

    {
      path: "/chatbot/:chatbotId",
      element: (
        <WrappedComponent>
          <ManageChatbot />
        </WrappedComponent>
      ),
    },
    // {
    //   path: "/chatbot/:chatbotId/intents",
    //   element: (
    //     <WrappedComponent>
    //       <Intents />
    //     </WrappedComponent>
    //   ),
    // },
    // {
    //   path: "/chatbot/:chatbotId/intents/:intentId",
    //   element: (
    //     <WrappedComponent>
    //       <IntentSettingsPage />
    //     </WrappedComponent>
    //   ),
    // },
    {
      path: "/chatbot/:chatbotId/:tabParam",
      element: (
        <WrappedComponent>
          <ManageChatbot />
        </WrappedComponent>
      ),
    },
    {
      path: "/chatbot/:chatbotId/:tabParam/:tabItemId",
      element: (
        <WrappedComponent>
          <ManageChatbot />
        </WrappedComponent>
      ),
    },

    {
      path: "/pricing",
      element: (
        <WrappedComponent>
          <Pricing />
        </WrappedComponent>
      ),
    },

    {
      path: "/plan",
      element: (
        <WrappedComponent>
          <Pricing isUpgrade={true} />
        </WrappedComponent>
      ),
    },

    {
      path: "/contact",
      element: (
        <WrappedComponent startingTransparent={true}>
          <ContactUs />
        </WrappedComponent>
      ),
    },

    {
      path: "/reset-password",
      element: (
        <WrappedComponent>
          <ResetPassword />
        </WrappedComponent>
      ),
    },
    {
      path: "/password/reset/success",
      element: (
        <WrappedComponent>
          <ResetPasswordSuccess />
        </WrappedComponent>
      ),
    },

    {
      path: "/send-reset-password",
      element: (
        <WrappedComponent>
          <ResetPasswordPage />
        </WrappedComponent>
      ),
    },
    {
      path: "/password/reset/sent",
      element: (
        <WrappedComponent>
          <ResetPasswordLinkSent />
        </WrappedComponent>
      ),
    },

    {
      path: "/change-email",
      element: (
        <WrappedComponent>
          <ChangeEmail />
        </WrappedComponent>
      ),
    },

    {
      path: "/confirm-email",
      element: (
        <WrappedComponent>
          <ConfirmEmail />
        </WrappedComponent>
      ),
    },

    {
      path: "/account",
      element: (
        <WrappedComponent>
          <Account />
        </WrappedComponent>
      ),
    },

    {
      path: "/slack-integration",
      element: (
        <WrappedComponent>
          <SlackIntegration />
        </WrappedComponent>
      ),
    },

    {
      path: "/chatbots/slack/success",
      element: (
        <WrappedComponent>
          <SlackSuccess />
        </WrappedComponent>
      ),
    },
    {
      path: "/chatbots/slack/failed",
      element: (
        <WrappedComponent>
          <SlackFailed />
        </WrappedComponent>
      ),
    },

    {
      path: "/support",
      element: (
        <WrappedComponent>
          <Support />
        </WrappedComponent>
      ),
    },

    {
      path: "/subscription/checkout_success",
      element: (
        <WrappedComponent>
          <CheckoutSuccess />
        </WrappedComponent>
      ),
    },
    {
      path: "/subscription/checkout_cancelled",
      element: (
        <WrappedComponent>
          <SubscriptionErrorPage
            title="Checkout Cancelled"
            description="Checkout has been cancelled."
            to="/plan"
            urlText={"Your Plan"}
          />
        </WrappedComponent>
      ),
    },
    {
      path: "/subscription/updated",
      element: (
        <WrappedComponent>
          <SubscriptionUpdated />
        </WrappedComponent>
      ),
    },
    {
      path: "/subscription/updated_back",
      element: (
        <WrappedComponent>
          <SubscriptionErrorPage
            title="Subscription not updated"
            description="Your subscription has not been updated."
            to="/plan"
            urlText={"Your Plan"}
          />
        </WrappedComponent>
      ),
    },
    {
      path: "/subscription/cancelled",
      element: (
        <WrappedComponent>
          <SubscriptionCancelled />
        </WrappedComponent>
      ),
    },
    {
      path: "/subscription/cancelled_back",
      element: (
        <WrappedComponent>
          <SubscriptionErrorPage
            title="Subscription not cancelled"
            description="Your subscription has not been cancelled."
            to="/plan"
            urlText={"Your Plan"}
          />
        </WrappedComponent>
      ),
    },
    {
      path: "/subscription/payment_method_updated",
      element: (
        <WrappedComponent>
          <SubscriptionSuccessPage
            title="Payment Method Updated"
            description="Your payment method has been updated."
            to="/account"
          />
        </WrappedComponent>
      ),
    },
    {
      path: "/subscription/payment_method_updated_back",
      element: (
        <WrappedComponent>
          <SubscriptionErrorPage
            title="Did Not Update Payment Method"
            description="Your payment method has not been updated."
            to="/account"
          />
        </WrappedComponent>
      ),
    },

    {
      path: "/needs-plan",
      element: (
        <WrappedComponent>
          <SubscriptionErrorPage
            title="Please Choose a Plan"
            description="Please choose a plan to proceed. You can make updates to your plan at any time."
            to="/plan"
            urlText="Choose Plan"
            removeIcon={true}
          />
        </WrappedComponent>
      ),
    },

    {
      path: "/404.html",
      element: (
        <WrappedComponent>
          <SubscriptionErrorPage title="Page not found" to="/" urlText="OK" />
        </WrappedComponent>
      ),
    },
    {
      path: "/200.html",
      element: (
        <WrappedComponent>
          <SubscriptionErrorPage title="Page not found" to="/" urlText="OK" />
        </WrappedComponent>
      ),
    },

    {
      path: "/:chatbotAlias",
      element: <HostedChatbot />,
    },
    {
      path: "/:chatbotAlias/mini",
      element: (
        <WrappedComponent noFooter={true}>
          <MiniHostedChatbot />
        </WrappedComponent>
      ),
    },

    {
      path: "*",
      element: (
        <WrappedComponent>
          <NoMatch />
        </WrappedComponent>
      ),
    },
  ]);

  return (
    <AppContext.Provider value={contextValue}>
      <HelmetProvider>
        <ConfirmDialogProvider>
          <RouterProvider router={router} />
        </ConfirmDialogProvider>
      </HelmetProvider>
    </AppContext.Provider>
  );
}

export default App;
