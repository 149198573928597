import { useContext, useEffect, useState } from "react";
import Toggle from "../Toggle/Toggle";
import DocumentTitle from "../Tools/DocumentTitle/DocumentTitle";
import "./SlackIntegration.css";
import { API_URL } from "../config";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import { ReactComponent as SlackIcon } from "../media/slack.svg";
import StatusCard from "../Tools/StatusCard/StatusCard";
import SelectSlackBot from "./SelectSlackBot/SelectSlackBot";
import debounce from "../Tools/debounce";
import WaitingWithText from "../Tools/ChatWaiting/WaitingWithText";
import { WrappedComponentContext } from "../WrappedComponent";
import { useConfirm } from "../Tools/Confirm/Confirm";
import { useNavigate } from "react-router-dom";

export default function SlackIntegration() {
  const [privateSlackResponses, setPrivateSlackResponses] = useState(true);
  const [isSavingPSR, setIsSavingPSR] = useState(false);

  const [slackButtonUrl, setSlackButtonUrl] = useState();
  const [chatbots, setChatbots] = useState();
  const [currentSlackBot, setCurrentSlackBot] = useState();
  const [newSelectedChatbot, setNewSelectedChatbot] = useState();
  const [slackStillLinked, setSlackStillLinked] = useState(null);

  const { logout, token, userInfo, fetchUserInfo } = useContext(
    WrappedComponentContext
  );
  const confirm = useConfirm();
  const navigate = useNavigate();

  const planSupportsSlack =
    userInfo?.plan_information?.plan_supported_features?.feature_limits?.slack;

  useEffect(() => {
    if (!token) navigate("/login");
    if (!userInfo && token) fetchUserInfo();
    if (token) fetchSlackUserInfo();

    if (token) document.title = "Slack Integration | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  useEffect(() => {
    if (userInfo && slackStillLinked === false && planSupportsSlack) {
      getSlackButtonUrl();
    }
  }, [userInfo, slackStillLinked]);

  useEffect(() => {
    if (token) fetchChatbots();
  }, [token]);

  const fetchSlackUserInfo = async () => {
    try {
      const endpoint = `${API_URL}/slack/user_info`;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { headers });

      if (!response.ok)
        throw new ErrorFormat(
          "Failed to fetch slack user info",
          response.status,
          logout
        );

      const data = await response.json();
      setSlackStillLinked(data?.slack_still_linked);
    } catch (error) {}
  };

  const getSlackButtonUrl = async () => {
    try {
      const endpoint = `${API_URL}/slack/slack_button_url`;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to fetch slack button.",
          response.status,
          logout
        );

      const data = await response.json();
      if (!data?.url)
        throw new ErrorFormat("Failed to fetch slack button.", 401, logout);

      setSlackButtonUrl(data?.url);
    } catch (err) {
      console.error(err?.errorMessage ?? err);
    }
  };

  const fetchChatbots = async () => {
    try {
      const endpoint = `${API_URL}/chat_bots`;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Could not fetch chatbots.",
          response.status,
          logout
        );

      const data = await response.json();
      setChatbots(data);

      if (data) findExistingChatbot(data);
    } catch (error) {
      console.error(error);
    }
  };

  const findExistingChatbot = (chatbots) => {
    for (let i = 0; i < chatbots.length; i++) {
      if (chatbots[i]?.is_slack_chat_bot) {
        console.log(chatbots[i]);
        setCurrentSlackBot(chatbots[i]);
        setNewSelectedChatbot(null);
        setPrivateSlackResponses(chatbots[i].private_slack_responses);
        return;
      }
    }
  };

  const removeSlackIntegration = async () => {
    try {
      const confirmed = await confirm({
        title: "Remove slack integration",
        description:
          "Are you sure you want to remove slack integration from your account?",
      });
      if (!confirmed) return;

      const endpoint = `${API_URL}/accounts/current/uninstall_slack`;
      const method = "DELETE";
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { method, headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to fetch user info.",
          response.status,
          logout
        );

      fetchUserInfo();
    } catch (error) {
      console.error(error?.errorMessage ?? error);
    }
  };

  const saveSlackBot = async () => {
    try {
      if (!newSelectedChatbot) return;

      const endpoint = `${API_URL}/chat_bots/${newSelectedChatbot?.id}`;
      const method = "PUT";
      const body = new FormData();
      body.append("chat_bot[is_slack_chat_bot]", true);
      body.append("chat_bot[private_slack_reponses]", privateSlackResponses);
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { method, headers, body });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to set slack bot",
          response.status,
          logout
        );

      fetchChatbots();
    } catch (error) {
      console.error(error);
    }
  };

  const savePrivateSlackResponses = async (value) => {
    const prevValue = privateSlackResponses;
    try {
      setIsSavingPSR(true);
      setPrivateSlackResponses(value);

      const endpoint = `${API_URL}/chat_bots/${currentSlackBot?.id}`;
      const method = "PUT";
      const body = new FormData();
      body.append("chat_bot[private_slack_responses]", value);
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { method, headers, body });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to set slack bot",
          response.status,
          logout
        );

      setIsSavingPSR(false);
    } catch (error) {
      console.error(error);
      setPrivateSlackResponses(prevValue);
      setIsSavingPSR(false);
    }
  };

  const handlePrivateSlackResponsesClick = debounce((value) =>
    savePrivateSlackResponses(value)
  );

  return planSupportsSlack ? (
    <div className="page-card-container">
      <div className="page-card small min-height-mid">
        <DocumentTitle>Slack Integration</DocumentTitle>

        <div className="slack-integration-container">
          <p>
            MajicAI Slack app lets your team converse with your chatbot right
            inside your Slack workspace.
          </p>

          <p>
            You can connect one chatbot to the Slack app at any given time. You
            can change which chatbot at any time.
          </p>

          {slackButtonUrl && (
            <a href={slackButtonUrl} className="add-to-slack-link">
              <img
                alt="Add to Slack"
                src="https://platform.slack-edge.com/img/add_to_slack.png"
              />
            </a>
          )}

          {slackStillLinked && (
            <>
              <div className="flex row align-center" style={{ gap: "5px" }}>
                <SlackIcon style={{ height: "20px", width: "20px" }} />

                <span>
                  <b>
                    Your slack is already linked. Remove it in order to add a
                    different slack.
                  </b>
                </span>
              </div>

              <button
                className="purple-button delete-button"
                style={{ marginTop: "8px" }}
                onClick={removeSlackIntegration}
              >
                Remove Slack
              </button>
            </>
          )}

          {/* set slack bot */}
          {slackStillLinked && (
            <div style={{ padding: "20px 0px" }}>
              <SelectSlackBot
                chatbots={chatbots}
                currentSlackBot={
                  newSelectedChatbot ? newSelectedChatbot : currentSlackBot
                }
                setSelectedChatbot={setNewSelectedChatbot}
              />

              {currentSlackBot?.id !== newSelectedChatbot?.id &&
                newSelectedChatbot?.id && (
                  <div>
                    <button
                      onClick={saveSlackBot}
                      className="purple-button small-text"
                      style={{ marginTop: "10px", padding: "9px 15px" }}
                    >
                      Save
                    </button>
                  </div>
                )}
            </div>
          )}

          <h4>Integration Settings</h4>

          {!currentSlackBot && (
            <span>
              Integration settings will be here after adding to slack.
            </span>
          )}

          {currentSlackBot && (
            <div
              className="flex row align-center"
              style={{ gap: "20px", flexWrap: "wrap" }}
            >
              <span>
                Make <span style={{ fontWeight: "500" }}>/majicbot</span>{" "}
                command messages visible only to you:
              </span>

              <Toggle
                open={privateSlackResponses}
                setOpen={handlePrivateSlackResponsesClick}
              />

              {isSavingPSR && <WaitingWithText>Saving</WaitingWithText>}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="status-card-page-container">
      <StatusCard
        loading={!userInfo}
        success={false}
        title="Please Upgrade to Enable Slack Integration"
        description="You are currently on a free plan. Please upgrade to a paid plan to get Slack bot integration."
        to="/plan"
        customUrlText="Upgrade Now"
      />
    </div>
  );
}
