import { useContext, useState } from "react";
import { ChatbotFormContext } from "../ChatbotForm";
import { useNavigate } from "react-router-dom";
import { InfoDescription } from "../InfoDescription/InfoDescription";

const ChatbotFormTabs = () => {
  const { tab, setTab, isEdit, requiredItemsAreFilled, existingData } =
    useContext(ChatbotFormContext);
  const navigate = useNavigate();

  const changeTab = (newTab) => {
    const tabValues = {
      "embed-chatbot": "embed",
      "main-settings": "main",
      "look-&-feel": "look",
      forms: "forms",
      actions: "actions",
    };

    if (isEdit) {
      setTab(newTab);
      navigate(`/chatbot/settings/${existingData.id}/${tabValues[newTab]}`);
    } else {
      setTab(newTab);
    }
  };

  return (
    <div className="new-chatbot-tabs-container">
      <span
        className={`new-chatbot-tab-title ${tab === "main-settings" ? "selected" : ""}`}
        onClick={() => changeTab("main-settings")}
      >
        <span className="manage-chatbot-menu-item-text">MAIN SETTINGS</span>
      </span>

      <ConditionalTab
        tabName="look-&-feel"
        notMutedCondition={isEdit || requiredItemsAreFilled}
        changeTab={changeTab}
      />

      <ConditionalTab tabName="embed-chatbot" notMutedCondition={isEdit} changeTab={changeTab} />

      <ConditionalTab tabName="actions" notMutedCondition={isEdit} changeTab={changeTab} />

      <ConditionalTab tabName="forms" notMutedCondition={isEdit} changeTab={changeTab} />
    </div>
  );
};

// This is a tab which can only sometimes be clicked
// When it can't be clicked it should have a description on hover
const ConditionalTab = ({ tabName, notMutedCondition, changeTab }) => {
  const { tab } = useContext(ChatbotFormContext);

  const { title, description } = interchangableTabData[tabName];

  const tabJsx = (
    <span
      className={`
        new-chatbot-tab-title
        ${notMutedCondition ? "" : "new-chatbot-tab-title-no-hover"}
        ${tab === tabName ? "selected" : ""}
        chatbot-form-tab-popup-container
      `}
      onClick={
        notMutedCondition
          ? () => {
              changeTab(tabName);
            }
          : () => {}
      }
      style={{ position: "relative" }}
    >
      <span
        className="manage-chatbot-menu-item-text"
        style={{ opacity: notMutedCondition ? 1 : 0.3 }}
      >
        {title}
      </span>
    </span>
  );

  return notMutedCondition ? (
    tabJsx
  ) : (
    <InfoDescription customComponent={tabJsx}>{description}</InfoDescription>
  );
};

const interchangableTabData = {
  "embed-chatbot": {
    title: "EMBED CHATBOT",
    description: "Embed settings will be available once chatbot has been created.",
  },
  "look-&-feel": {
    title: "LOOK & FEEL",
    description: "Fill out mandatory fields before going to look & feel.",
  },
  forms: {
    title: "FORMS",
    description: "Forms will be available once chatbot has been created.",
  },
  actions: {
    title: "ACTIONS",
    description: "Actions will be available once chatbot has been created.",
  },
};

export default ChatbotFormTabs;
