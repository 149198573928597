import { useContext, useEffect } from "react";
import "./Stats.css";
import { WrappedComponentContext } from "../../WrappedComponent";
import { APP_URL } from "../../config";

export default function Stats({ chatbot }) {
  const { userInfo, fetchUserInfo } = useContext(WrappedComponentContext);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <div className="stats">
      <table className="stats-table">
        <tr className="stats-tr">
          <th className="not-middle-th">
            <div className="row-align-center first-column-styles">
              <span></span>
            </div>
          </th>
          <th>Unique Visitors</th>
          <th>Responses</th>
          <th>Responses / visitor</th>
        </tr>

        {chatbot?.visitor_stats && (
          <>
            {/* only show Today, Yesterday and 30 Days */}
            <StatRow stat={chatbot?.visitor_stats[0]} />
            <StatRow stat={chatbot?.visitor_stats[1]} />
            <StatRow stat={chatbot?.visitor_stats[2]} />
          </>
        )}
      </table>

      <br />
      <br />

      <div className="stats-bottom-data-container">
        {/* Num content pages */}
        <span>
          Content pages:{" "}
          {Math.round((chatbot?.used_character_index_limit / 2000) * 10) / 10}{" "}
          (of{" "}
          {userInfo?.plan_information?.plan_supported_features?.usage
            ?.character_index_limit / 2000}
          )
        </span>

        {/* Chatbot Alias */}
        {chatbot?.alias && chatbot?.public_chat_bot_enabled && (
          <div style={{ textAlign: "left" }}>
            <span>Public chatbot page is live: </span>
            <a
              href={`${APP_URL}/${chatbot?.alias}`}
              style={{ color: "#A24DFF", fontWeight: "500" }}
              target="_blank"
              rel="noreferrer"
            >
              {`${APP_URL}/${chatbot?.alias}`}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

const StatRow = ({ stat }) => {
  return (
    <tr className="tr-style stats-tr">
      <td
        style={{ paddingLeft: "0px", paddingRight: "50px" }}
        className="first-td"
      >
        <div className="row-align-center first-column-styles">
          <span className="table-icon-next-to-text">
            {stat?.date_range_label}
          </span>
        </div>
      </td>

      <td>{stat?.num_unique_visitors}</td>

      <td className="td-side-padding">{stat?.num_responses}</td>

      <td className="td-less-side-padding">{stat?.responses_per_visitor}</td>
    </tr>
  );
};
