import { useContext, useEffect } from "react";
import { AddPdfSourceContext } from "./AddPdfSource";
import { KnowledgeBaseContext } from "../../KnowledgeBase";
import { API_URL } from "../../../../config";
import { ErrorFormat } from "../../../../Tools/Errors/ErrorFormatting";
import { waitForIndexingToFinish } from "../../indexing";
import { ReactComponent as DocumentIcon } from "../../../../media/documentIcon.svg";
import { WrappedComponentContext } from "../../../../WrappedComponent";

export const ChangePdfNames = () => {
  const {
    setModalOpen,
    changedNames,
    changeNamesRef,
    setSuccessfullyChangedNames,
    setSuccessMessage,
    setChangedNames,
    setUploadedPdfs,
    setIsChangingNames,
    successfulyChangedNames,
    uploadedPdfs,
  } = useContext(AddPdfSourceContext);

  const { chatbotId, token, setPdfSources, setOpenIntervals, fetchUserInfo } =
    useContext(KnowledgeBaseContext);
  const { setBannerMessage } = useContext(WrappedComponentContext);

  useEffect(() => {
    if (changedNames.length > 0) changeNames();
  }, [changedNames]);

  const changeNames = async () => {
    try {
      if (changedNames.length === 0) return;

      for (let i = 0; i < changedNames.length; i++) {
        if (changeNamesRef.current) throw "PDF title change aborted";

        const body = new FormData();
        body.append("content_item[type]", "pdf_document");
        body.append("content_item[title]", changedNames[i]?.newTitle);
        const endpoint = `${API_URL}/chat_bots/${chatbotId}/content_items/${changedNames[i]?.id}`;
        const headers = { Authorization: token };
        const method = "PUT";
        const response = await fetch(endpoint, { body, method, headers });
        if (!response.ok)
          throw new ErrorFormat("Failed to update pdf title", response.status);
        const updatedPdf = await response.json();

        // Updated pdf sources list with new values
        setPdfSources((prevPdfSources) => {
          const updatedSources = prevPdfSources.map((pdf) => {
            if (pdf.id === updatedPdf?.id) return { ...updatedPdf };
            return pdf;
          });
          return updatedSources;
        });
        // for indicating how many titles were successfully changed
        setSuccessfullyChangedNames((prev) => [...prev, updatedPdf]);

        // Wait for indexing to finish if needed
        waitForIndexingToFinish({
          chatbotId,
          token,
          contentItemId: updatedPdf.id,
          type: "pdf",
          setOpenIntervals,
          setSources: setPdfSources,
          fetchUserInfo,
        });
      }

      setSuccessMessage({
        title: "Success!",
        message: "PDF titles have been updated.",
      });
      setChangedNames([]);
      setUploadedPdfs([]);
      setIsChangingNames(false);
      setModalOpen(false);
    } catch (error) {
      setBannerMessage({
        type: "error",
        title: "Failed to update pdf titles.",
      });
      setChangedNames([]);
      setUploadedPdfs([]);
      setIsChangingNames(false);
      setModalOpen(false);
    }
  };

  if (changedNames.length > 0) {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <section
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <span
            style={{
              color: "#5F5968",
              fontFamily: "Work Sans",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Update titles now
          </span>

          <div className="uploaded-pdf" style={{ position: "relative" }}>
            <span>
              {successfulyChangedNames.length}/{changedNames.length} files
            </span>

            <div
              style={{
                position: "absolute",
                height: "4px",
                background: "#FF5E8E",
                width: `${
                  (successfulyChangedNames.length / changedNames.length) * 100
                }%`,
                bottom: "0px",
                left: "0px",
                borderRadius: "0px 0px 0px 4px",
              }}
            />
          </div>
        </section>

        <button
          className="text-button"
          style={{ alignSelf: "flex-start" }}
          onClick={() => (changeNamesRef.current = true)}
        >
          Cancel Update
        </button>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <span>Rename Files (optional)</span>

      {uploadedPdfs.map((pdf) => (
        <div style={{ borderTop: "1px solid #F6F7F9", paddingTop: "10px" }}>
          <div className="flex align-center" style={{ gap: "4px" }}>
            <DocumentIcon />
            <span
              style={{
                maxWidth: "calc(100% - 30px)",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "text",
              }}
            >
              {pdf?.title}
            </span>
          </div>

          <input
            placeholder="Enter Document Title"
            className="upload-pdf-change-name-input"
            onChange={(e) => {
              pdf.newTitle = e.target.value;
            }}
          />
        </div>
      ))}

      <button
        className="purple-button"
        style={{ alignSelf: "flex-start" }}
        onClick={() => {
          const filteredPdfs = uploadedPdfs.filter((pdf) => pdf?.newTitle);
          if (filteredPdfs.length === 0) setModalOpen(false);
          else setChangedNames(filteredPdfs);
        }}
      >
        Save & Close
      </button>
    </div>
  );
};

export default ChangePdfNames;
