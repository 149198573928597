export function formatSuggestedQuestionForSaving(
  question,
  customAnswer = null
) {
  question = question.replace(/\n/g, " ");
  question = question.replace(/%ANS%/g, "");

  if (customAnswer) {
    customAnswer = customAnswer.replace(/%ANS%/g, "");
  }

  return [question, customAnswer];
}
