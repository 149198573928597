import StatusCard from "../Tools/StatusCard/StatusCard";

const TrustedEmailConfirmedPage = () => {
  return (
    <div className="status-card-page-container">
      <StatusCard
        success={true}
        title="Email Confirmed"
        description="Your email has been confirmed."
        to={"/"}
      />
    </div>
  );
};

export default TrustedEmailConfirmedPage;
