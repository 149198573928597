import { useContext } from "react";
import { PricingContext } from "./Pricing";

export const PricingToggle = () => {
  const { monthly, setMonthly, isNotPaid } = useContext(PricingContext);

  return (
    <div
      className={`pricing-toggle-container ${
        isNotPaid ? "has-guarantee-line" : ""
      }`}
    >
      <div className={`pricing-type`} onClick={() => setMonthly(true)}>
        Monthly
      </div>

      <div className={`pricing-type`} onClick={() => setMonthly(false)}>
        Annual
        <div className="pricing-sale">40% OFF</div>
      </div>

      <div
        className={`pricing-type whitebackground ${monthly ? "" : "annual"}`}
      />
    </div>
  );
};
