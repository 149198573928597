import { createContext, useCallback, useContext, useRef, useState } from "react";
import Modal from "../../Modal/Modal";

const ConfirmDialog = createContext();

export function ConfirmDialogProvider({ children }) {
  const [state, setState] = useState({ isOpen: false });
  const fn = useRef();

  const confirm = useCallback(
    (data) => {
      return new Promise((resolve) => {
        setState({ ...data, isOpen: true });
        fn.current = (choice) => {
          resolve(choice);
          setState({ isOpen: false });
        };
      });
    },
    [setState]
  );

  const setOpen = (value) => {
    fn.current(false);
  };

  const component = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        fontWeight: "400",
        fontSize: "14px",
      }}
    >
      <h3 style={{ margin: 0, fontWeight: "500", fontSize: "16px" }}>{state?.title}</h3>
      <div
        style={{
          borderBottom: "1px solid #E1E1E1",
          width: "calc(100% - 20px)",
        }}
      />
      <span>{state?.description}</span>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "10px",
          gap: "8px",
        }}
      >
        <button className="purple-button" onClick={() => fn.current(true)}>
          Yes
        </button>
        <button className="outline-button" onClick={() => fn.current(false)}>
          No
        </button>
      </div>
    </div>
  );

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      {state?.isOpen && (
        <Modal
          component={component}
          setOpen={setOpen}
          closeOnOutsideClick={false}
          extraClassName="confirm"
        />
      )}
    </ConfirmDialog.Provider>
  );
}

export function useConfirm() {
  return useContext(ConfirmDialog);
}
