import { formatSourceStateString } from "../../KnowledgeBase";
import "./LoadingStatusView.css";

const errorStates = [
  "indexing_error",
  "gathering_data_error",
  "no_content_to_index",
  "plan_character_limit_exceeded",
];

const LoadingStatusView = ({ source }) => {
  if (!source || !["gathering_data", ...errorStates].includes(source?.state))
    return;

  const text = source?.processing_status_text
    ? source?.processing_status_text
    : source?.state;

  return (
    <div className={`loading-status ${text?.length > 58 ? "wrapped" : ""}`}>
      <span>{formatSourceStateString(text)}</span>
    </div>
  );
};

export default LoadingStatusView;
