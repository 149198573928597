import { useState } from "react";
import CircularIndeterminate from "../../Tools/Loading/CircularIndeterminate";

const DeleteChatbotButton = ({ deleteFunc = () => {}, loading }) => {
  const [loadingColor, setLoadingColor] = useState("#cf212e");

  return (
    <>
      <br />
      <br />
      <br />
      <h1 className="title-20">Danger Zone</h1>
      <div className="new-chatbot-divider" style={{ marginBottom: "0px" }} />

      <div className="form-item-row">
        <span className="chatbot-settings-title">Delete Chatbot</span>
        <button
          className="delete-button"
          onClick={deleteFunc}
          onMouseEnter={() => setLoadingColor("#f6f8fa")}
          onMouseLeave={() => setLoadingColor("#cf212e")}
        >
          {loading && <CircularIndeterminate color={loadingColor} />}
          Delete this chatbot
        </button>
      </div>
    </>
  );
};

export default DeleteChatbotButton;
