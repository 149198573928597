import { useContext } from "react";
import { InfoDescription } from "../InfoDescription/InfoDescription";
import { ChatbotFormContext } from "../ChatbotForm";
import ChatbotFormInlineToggle from "./ChatbotFormInlineToggle";

const ChatbotFormShowSources = () => {
  const { showSources, setShowSources, maxSources, setMaxSources } =
    useContext(ChatbotFormContext);

  const handleMaxSourcesChange = (e) => {
    setMaxSources(e.target.value);
  };

  return (
    <div className="form-item-column without-margin">
      <ChatbotFormInlineToggle
        open={showSources}
        setOpen={setShowSources}
        hasToggleContainer={false}
        extraClass="noborder"
      >
        Show website sources
        <InfoDescription customDistance="55px">
          If your knowledgebase contains any website crawls or single URLs,
          turning this option to "Yes" will make your chatbot list the
          referenced URLs, if applicable. This is useful if you'd like your
          users to easily navigate to the right page for more information on the
          topic. This setting will be ignored when not applicable.
        </InfoDescription>
      </ChatbotFormInlineToggle>

      {showSources && (
        <div
          className="form-item-row"
          style={{ minHeight: "auto", paddingBottom: "20px", gap: "10px" }}
        >
          <span className="chatbot-settings-secondary-title">
            Maximum sources to show
          </span>

          <select
            onChange={handleMaxSourcesChange}
            value={maxSources ? maxSources : 2}
            style={{ width: "65px" }}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </select>
        </div>
      )}
    </div>
  );
};

export default ChatbotFormShowSources;
