import { Link, useParams } from "react-router-dom";
import addPurple from "../../../media/addPurple.svg";
import { useContext, useEffect, useState } from "react";
import { WrappedComponentContext } from "../../../WrappedComponent";
import { getChatbotForms } from "./getChatbotForms";

const ShowForms = () => {
  const [forms, setForms] = useState([]);

  const { token } = useContext(WrappedComponentContext);
  const { chatbotId } = useParams();

  useEffect(() => {
    getChatbotForms(chatbotId, token).then((forms) => {
      if (forms) setForms(forms);
    });
  }, []);

  return (
    <div>
      <Link to="./new-form" style={{ textDecoration: "none" }}>
        <button className="add-source" style={{ margin: "23px 0px" }}>
          <img src={addPurple} alt="Add Source" />
          <span>New Form</span>
        </button>
      </Link>

      <table className="scrollable-table" style={{ width: "100%", maxWidth: "100%" }}>
        <tr>
          <th className="first-td" style={{ width: "225px", minWidth: "185px" }}>
            Your Forms
          </th>
          <th style={{ width: "110px" }}>Submissions</th>
          <th className="last-td" style={{ width: "110px" }}></th>
        </tr>

        {forms.map((form, index) => (
          <tr key={index}>
            <td
              className="first-td"
              style={{
                width: "225px",
                minWidth: "185px",
                maxWidth: "225px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {form?.name}
            </td>
            <td style={{ width: "110px" }}>{Number(form?.filled_forms_count)}</td>
            <td className="last-td" style={{ width: "110px" }}>
              <Link to={`./${form?.id}`} className="link link-on-hover">
                Manage
              </Link>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default ShowForms;
