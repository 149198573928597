import { useContext, useEffect, useState } from "react";
import { AddLinkSource } from "./AddLinkSource";
import { AddTextSource } from "./AddTextSource";
import AddPdfSource from "./AddPdfSources/AddPdfSource";
import Modal from "../../../Modal/Modal";
import addPurple from "../../../media/addPurple.svg";
import "./AddSource.css";
import { WrappedComponentContext } from "../../../WrappedComponent";
import StatusCard from "../../../Tools/StatusCard/StatusCard";
import { KnowledgeBaseContext } from "../KnowledgeBase";

export const AddSourceButton = ({ text, type, subType }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isFreePlan, setIsFreePlan] = useState(false);

  const { userInfo, fetchUserInfo, setBannerMessage } = useContext(WrappedComponentContext);
  const { currentSources, planCharLimitExceeded } = useContext(KnowledgeBaseContext);

  useEffect(() => {
    if (!userInfo) {
      fetchUserInfo();
    }

    if (userInfo?.stripe_subscriptions?.length === 0) {
      setIsFreePlan(true);
    }
  }, [userInfo]);

  let component;
  if (type === "web" || subType === "link")
    component = <AddLinkSource subType={subType} type={type} setModalOpen={setModalOpen} />;
  else if (type === "text") component = <AddTextSource setModalOpen={setModalOpen} />;
  else component = <AddPdfSource setModalOpen={setModalOpen} />;

  const getPlanSourceCountAllowed = () => {
    const planSourceCounts =
      userInfo?.plan_information?.plan_supported_features?.feature_limits
        ?.document_count_plan_limits;
    if (!planSourceCounts) return 100000000000000;

    switch (type) {
      case "web":
        return planSourceCounts[
          subType === "Single Page"
            ? "ContentItems::SinglePageUrlContentItem"
            : "ContentItems::CrawledWebsiteContentItem"
        ];
      case "pdf":
        return planSourceCounts["ContentItems::Documents::DocumentContentItem"];
      case "text":
        return planSourceCounts["ContentItems::RawTextContentItem"];
      default:
        return 100000000000000;
    }
  };

  const planSourceCountAllowed = getPlanSourceCountAllowed();
  const freeHasReachedMaxSources = isFreePlan && currentSources?.length >= planSourceCountAllowed;

  if (freeHasReachedMaxSources || planCharLimitExceeded || !planSourceCountAllowed) {
    component = (
      <StatusCard
        success={false}
        title="This action requires an upgrade to a paid plan."
        description="All our plans come with a 30-day money-back guarantee. Please upgrade here:"
        to="/plan"
        customUrlText="Upgrade Here"
        justContentWithoutBackground={true}
      />
    );
  }

  // Throws error if user has reached character limit
  const handleButtonClick = () => {
    try {
      if (userInfo && !userInfo?.email_confirmed) {
        setBannerMessage({
          type: "error",
          title: "Email not confirmed.",
          message: "Please confirm your email before continuing.",
        });
      } else {
        setModalOpen(true);

        if (planCharLimitExceeded) {
          setBannerMessage({
            type: "error",
            title: "Plan character limit reached.",
            message: "This action requires an upgraded plan.",
          });
        }
      }
    } catch (error) {}
  };

  return (
    <div style={{ display: "inline-block" }}>
      <button className="add-source" onClick={handleButtonClick}>
        <img src={addPurple} alt="Add Source" />
        <span>{text}</span>
      </button>

      {modalOpen ? (
        <Modal
          setOpen={setModalOpen}
          component={component}
          // Type of text puts the modal higher up so if we are displaying error card we want that as well
          type={freeHasReachedMaxSources || planCharLimitExceeded ? "text" : type}
          // Makes modal take shape of the status card
          takeChildrensShape={
            freeHasReachedMaxSources || planCharLimitExceeded || !planSourceCountAllowed
          }
          subtype={subType}
          closeOnOutsideClick={false}
          extraClassName={type === "web" && subType !== "Single Page" && "overflow-visible"}
        />
      ) : (
        ""
      )}
    </div>
  );
};
