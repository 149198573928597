import { useEffect } from "react";
import StatusCard from "../Tools/StatusCard/StatusCard";

const SubscriptionErrorPage = ({
  title,
  description,
  to,
  urlText,
  removeIcon = false,
}) => {
  useEffect(() => {
    document.title = `${title} | Majic AI`;
    return () => (document.title = "Majic AI");
  }, []);

  return (
    <div className="status-card-page-container">
      <StatusCard
        success={false}
        title={title}
        description={description}
        to={to}
        customUrlText={urlText}
        removeIcon={removeIcon}
      />
    </div>
  );
};

export default SubscriptionErrorPage;
