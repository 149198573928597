import { ReactComponent as DisabledIcon } from "../../media/disabledIcon.svg";
import "./DisabledIndication.css";

const DisabledIndication = ({ overflowRisk }) => {
  return (
    <div className="disabled-icon-container">
      <DisabledIcon className="disabled-icon" />

      <div className={`disabled-icon-message ${overflowRisk && overflowRisk}`}>
        This chatbot is currently disabled.
      </div>
    </div>
  );
};

export default DisabledIndication;
