import { useContext, useEffect } from "react";
import ActionsNotificationsSettings from "./ActionsNotificationsSettings";
import ActionsFormSettings from "./ActionsFormSettings";
import ActionTitleSettings from "./ActionTitleSettings";
import { useParams } from "react-router-dom";
import { ChatbotFormContext } from "../../../ChatbotForm";

const ActionSettings = ({ action, setAction, type, scenarioType }) => {
  const { setBackLink, manageChatbotLink, setHideTabsMenu } = useContext(ChatbotFormContext);
  const { chatbotId } = useParams();

  useEffect(() => {
    setBackLink({ link: `/chatbot/settings/${chatbotId}/actions`, text: "<  Back to Actions" });
    setHideTabsMenu(true);

    return () => {
      setBackLink({ link: manageChatbotLink, text: "<  Back to Chatbot" });
      setHideTabsMenu(false);
    };
  }, []);

  return (
    <div>
      <ActionTitleSettings
        action={action}
        setAction={setAction}
        type={type}
        scenarioType={scenarioType}
      />

      <div className="action-tab-settings-divider" />

      {type === "notification" ? (
        <ActionsNotificationsSettings action={action} setAction={setAction} />
      ) : (
        <ActionsFormSettings action={action} setAction={setAction} />
      )}
    </div>
  );
};

export default ActionSettings;
