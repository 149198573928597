import React, { useContext } from "react";
import { ChatbotFormContext } from "../ChatbotForm";
import { InfoDescription } from "../InfoDescription/InfoDescription";

const ChatbotFormBasePrompt = () => {
  const { basePrompt, setBasePrompt } = useContext(ChatbotFormContext);

  const handleChange = (e) => setBasePrompt(e.target.value);

  return (
    <div className="form-item-column">
      <span className="chatbot-settings-title row-align-center">
        Supplemental prompt
        <InfoDescription customDistance="30px">
          Optional. You can provide additional commands here to tweak your
          chatbot's experience. This prompt will be used together with Majic
          AI's internal base prompt. Combined, these prompts form the basis of
          how your chatbot interacts with your users.
        </InfoDescription>
      </span>
      <br />

      <textarea
        placeholder="Base prompt to use"
        value={basePrompt}
        onChange={handleChange}
        style={{
          minHeight: "32px",
          height: "32px",
          width: "calc(100% - 28px)",
        }}
      />

      <br />
    </div>
  );
};

export default ChatbotFormBasePrompt;
