import { useDropzone } from "react-dropzone";
import { ReactComponent as PdfUploadIcon } from "../../../../media/pdfUploadIcon.svg";

export const PdfFileUpload = ({ setPdfFiles }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Filter out non-(pdf, word, txt) files
      const acceptedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/plain",
      ];
      const validFiles = acceptedFiles.filter((file) =>
        acceptedTypes.includes(file.type)
      );

      setPdfFiles((prevFiles) => [...prevFiles, ...validFiles]);
    },
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
    },
  });

  return (
    <section {...getRootProps()} className="file-upload-container">
      <input {...getInputProps({ accept: "application/pdf" })} multiple />

      <PdfUploadIcon />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <span
          style={{
            textAlign: "center",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "Work Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          Drag & drop files
        </span>

        <span
          style={{
            color: "#616161",
            textAlign: "center",
            leadingTrim: "both",
            textEdge: "cap",
            fontFamily: "Work Sans",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          or
        </span>

        <button className="purple-button">Browse Files</button>
      </div>
    </section>
  );
};
