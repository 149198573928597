import Chat from "../ManageChatbot/Chat/Chat";
import "./HostedChatbot.css";
import chatbotIcon from "../media/chatbotPurple.svg";
import { useEffect, useState } from "react";
import { API_URL, APP_URL } from "../config";
import WaitingWithText from "../Tools/ChatWaiting/WaitingWithText";
import StatusCard from "../Tools/StatusCard/StatusCard";
import { useDarkModeDetector } from "../Tools/useDarkModeDetector";
import { ReactComponent as ChatbotClear } from "../media/chatbot_logo_clear.svg";
import { useParams } from "react-router-dom";

export default function HostedChatbot({
  fullspace = false,
  chatbotParam,
  isAdmin = false,
}) {
  const { chatbotAlias } = useParams();

  const [chatbot, setChatbot] = useState();
  const [error, setError] = useState(false);

  const isDarkMode = useDarkModeDetector();
  let isUsingDarkMode = chatbot?.design_colors?.theme === "dark";
  let colors = chatbot?.design_colors
    ? chatbot?.design_colors[chatbot?.design_colors?.theme]
    : "";
  if (chatbot?.design_colors?.theme === "system") {
    isUsingDarkMode = isDarkMode;

    colors = isDarkMode
      ? chatbot?.design_colors?.dark
      : chatbot?.design_colors?.light;
  }

  useEffect(() => {
    document.body.dataset.page = "/hosted-chatbot";

    fetchChatbotData();

    // Change the theme color
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", colors?.headerPlusStartToggle);

    return () => {
      document.body.dataset.page = "";
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", "#A24DFF");
    };
  }, []);

  useEffect(() => {
    if (chatbotParam && !chatbot) {
      setChatbot(chatbotParam);
    }
  }, [chatbotParam]);

  useEffect(() => {
    if (chatbot?.avatar_url && !fullspace) changeFavicon(chatbot?.avatar_url);

    return () => {
      if (chatbot?.avatar_url) changeFavicon();
    };
  }, [chatbot]);

  const changeFavicon = (url = "https://majic.ai/favicon.ico") => {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = url;
  };

  const fetchChatbotData = async () => {
    try {
      if (fullspace) {
        return;
      }

      const endpoint = `${API_URL}/chat_bots/public/alias/${chatbotAlias}`;

      const response = await fetch(endpoint);

      const data = await response.json();
      if (!response.ok) throw "Error";

      if (!data?.public_chat_bot_enabled) throw "Public chatbot disabled.";

      setChatbot(data);
    } catch (error) {
      setError({
        title: "Invalid chatbot alias",
        message: `Could not find chatbot with the alias of "${chatbotAlias}".`,
      });
      console.error(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
      }}
    >
      {chatbot ? (
        <div
          className={`hosted-chatbot-container ${fullspace && "fullspace"}`}
          style={{
            // background: colors?.botBubble,
            background: isUsingDarkMode ? "#444444" : "#f7f7f7",
            boxShadow: fullspace
              ? "1px 1px 6px 0px rgba(0, 0, 0, 0.10)"
              : "none",
          }}
        >
          <div
            className="page-card hosted-chatbot"
            style={{ background: colors?.mainBackground }}
          >
            <div
              className="page-card-title-container"
              style={{ background: colors?.headerPlusStartToggle }}
            >
              <div className="manage-chatbot-title">
                <img
                  src={chatbot.avatar_url ?? chatbotIcon}
                  height="100%"
                  style={{
                    border: `2px solid ${colors?.headerTextPlusStartToggleIconColor}`,
                  }}
                  alt="Chatbot Avatar"
                />
                <h1
                  className="title-20"
                  style={{ color: colors?.headerTextPlusStartToggleIconColor }}
                >
                  {chatbot?.name ?? chatbotAlias}
                </h1>
              </div>
            </div>

            <div className="hosted-chatbot-chat-container">
              <Chat chatbot={chatbot} colors={colors} isAdmin={isAdmin} />
            </div>

            {!chatbot?.remove_branding && (
              <a
                className="powered-by-link flex row align-center"
                style={{ margin: "0 auto" }}
                href={APP_URL}
                target="__blank"
              >
                <div
                  className="powered-by-container"
                  style={{ color: colors?.mainBackgroundText }}
                >
                  <span>Powered by&nbsp;</span>
                  <ChatbotClear style={{ fill: colors?.mainBackgroundText }} />
                  <span>&nbsp;Majic Chatbot</span>
                </div>
              </a>
            )}
          </div>
        </div>
      ) : (
        <div
          className="status-card-page-container"
          style={{
            height: fullspace ? "calc(100vh - 400px)" : "calc(100vh - 50px)",
            minHeight: fullspace ? "550px" : "auto",
            padding: "0px",
            paddingBottom: "50px",
            borderRadius: fullspace ? "20px" : "0px",
          }}
        >
          <StatusCard
            success={!error}
            loading={!error}
            loadingChildren={<WaitingWithText>Loading</WaitingWithText>}
            title={error?.title}
            description={`Could not find chatbot with the alias of "${chatbotAlias}".`}
            customUrl={APP_URL}
            customUrlText="OK"
          />
        </div>
      )}
    </div>
  );
}
