import React, { useContext } from "react";
import { ChatbotFormContext } from "../ChatbotForm";

const ChatbotFormTitle = () => {
  const handleNameChange = (e) => setName(e.target.value);

  const { error, name, setName } = useContext(ChatbotFormContext);

  return (
    <div
      className="form-item-row"
      style={{
        justifyContent: "flex-start",
      }}
    >
      <span className="chatbot-settings-title">Chatbot Title</span>

      <div className="inline-input-container">
        <input
          id="chatbot-title"
          className={`inline-input has-container ${
            error?.item === "name" ? "error" : ""
          }`}
          onChange={handleNameChange}
          value={name}
        />

        {error?.item === "name" && (
          <label className="inline-input-error" for="chatbot-title">
            {error?.message}
          </label>
        )}
      </div>
    </div>
  );
};

export default ChatbotFormTitle;
