import React, { useState } from "react";
import addPurple from "../media/addPurple.svg";
import Modal from "../Modal/Modal";
import { AddConfirmedEmailComponent } from "./AddConfirmedEmailComponent";

const AddConfirmedEmailButton = ({ setEmailList }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleButtonClick = () => setModalOpen((prev) => !prev);

  return (
    <div style={{ display: "inline-block" }}>
      <button className="add-source" onClick={handleButtonClick}>
        <img src={addPurple} alt="Add Source" />
        <span>Add Email</span>
      </button>

      {modalOpen ? (
        <Modal
          setOpen={setModalOpen}
          component={
            <AddConfirmedEmailComponent
              setModalOpen={setModalOpen}
              setEmailList={setEmailList}
            />
          }
          closeOnOutsideClick={false}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default AddConfirmedEmailButton;
