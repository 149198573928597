import { createContext, useContext, useEffect, useRef, useState } from "react";
import "./Conversations.css";
import { API_URL } from "../../config";
import searchIcon from "../../media/searchDark.svg";
import { ReactComponent as CloseIcon } from "../../media/closeLight.svg";
// import archived from "../../media/archived.svg";
// import starred from "../../media/starred.svg";
import WaitingWithText from "../../Tools/ChatWaiting/WaitingWithText";
import ChatSessionPrev from "./ChatSessionPrev";
import Conversation from "./Conversation";
import { ErrorFormat } from "../../Tools/Errors/ErrorFormatting";
import { WrappedComponentContext } from "../../WrappedComponent";
import { useParams } from "react-router-dom";

export const ConversationsContext = createContext();

const CHAT_SESSIONS_PER_PAGE = 10;

export default function Conversations({ chatbotId, chatbot }) {
  const { tabItemId } = useParams();

  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState();
  const [searchQuery, setSearchQuery] = useState(tabItemId ? `id: ${tabItemId}` : "");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(100000000000);
  const [isLoading, setIsLoading] = useState(false);

  // This is for not running handleSearch twice on load when tabItemId exists
  const queryIdSearchedRef = useRef(false);
  const queryIdChatSessionHasBeenClickedRef = useRef(false);

  const { token, setBannerMessage } = useContext(WrappedComponentContext);

  // Debounce for search query
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);

    return () => clearTimeout(delayInputTimeoutId);
  }, [searchQuery]);

  useEffect(() => {
    if (tabItemId && !queryIdSearchedRef.current) {
      queryIdSearchedRef.current = true;
    } else {
      handleSearch();
    }
  }, [debouncedSearchQuery]);

  // Select given id chat session
  useEffect(() => {
    if (conversations?.length > 0 && !queryIdChatSessionHasBeenClickedRef.current && tabItemId) {
      setSelectedConversation(conversations[0]);
      queryIdChatSessionHasBeenClickedRef.current = true;
    }
  }, [conversations]);

  const handleSearchInputChange = (e) => setSearchQuery(e.target.value);

  const chatSessionsContainerRef = useRef(null);

  const fetchChatSessions = async (
    searchQuery = null,
    currentPage = page,
    replaceContent = false
  ) => {
    try {
      if (isLoading || (currentPage > totalPages && !replaceContent)) return;

      setIsLoading(true);

      // Get chat sessions without search query
      let endpoint = `${API_URL}/chat_bots/${chatbotId}/chat_sessions?page=${currentPage}&per_page=${CHAT_SESSIONS_PER_PAGE}`;

      if (searchQuery) {
        // get chat sessions by search query
        endpoint = `${API_URL}/chat_bots/${chatbotId}/chat_sessions/search?search=${searchQuery}&page=${currentPage}&per_page=${CHAT_SESSIONS_PER_PAGE}`;

        // get specific chat session by id
        if (searchQuery.startsWith("id:") && searchQuery.split(":")?.length > 1) {
          const chatSessionId = searchQuery.split(":")[1].trim();
          if (chatSessionId) {
            endpoint = `${API_URL}/chat_bots/${chatbotId}/chat_sessions/${chatSessionId}`;
          }
        }
      }

      const headers = { Authorization: token };
      const response = await fetch(endpoint, { headers });
      if (!response.ok) throw new ErrorFormat("Failed to fetch conversations.", response.status);

      const data = await response.json();
      const chatSessions = Array.isArray(data?.chat_sessions)
        ? data?.chat_sessions
        : [{ ...data, isGivenParamId: true }];
      const metaData = data?.meta;

      setConversations((prevItems) =>
        replaceContent ? chatSessions : [...prevItems, ...chatSessions]
      );
      setTotalPages(metaData?.total_pages);
      setPage(currentPage + 1);
    } catch (error) {
      console.error(error);
      setBannerMessage({
        type: "error",
        title:
          error?.httpStatusCode === 404
            ? "No conversations found."
            : "Failed to fetch conversations.",
        message: "",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    try {
      let searchQuery = null;
      if (debouncedSearchQuery?.length >= 3) searchQuery = debouncedSearchQuery;

      fetchChatSessions(searchQuery, 1, true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleScroll = () => {
    const scrollContainer = chatSessionsContainerRef.current;

    // Check if the user has scrolled to the bottom
    if (
      scrollContainer?.scrollTop + scrollContainer?.clientHeight >=
        scrollContainer?.scrollHeight - 1 &&
      !isLoading &&
      totalPages >= page
    ) {
      let searchQuery = null;
      if (debouncedSearchQuery?.length >= 3) searchQuery = debouncedSearchQuery;
      fetchChatSessions(searchQuery);
    }
  };

  const value = {
    selectedConversation,
    setSelectedConversation,
    setConversations,
    token,
    chatbot,
  };

  return (
    <ConversationsContext.Provider value={value}>
      <div className="conversations-container">
        <div className="convos-plus-search-container">
          <div className="convo-search">
            <img src={searchIcon} alt="Search" />

            <input placeholder="Search" value={searchQuery} onChange={handleSearchInputChange} />

            <CloseIcon onClick={() => setSearchQuery("")} />
          </div>

          {conversations?.length === 0 && (
            <div className="no-convos">
              <h3>No conversations found</h3>
            </div>
          )}

          <div className="conversations" ref={chatSessionsContainerRef} onScroll={handleScroll}>
            {conversations?.length > 0 &&
              conversations.map((chatSession, index) => (
                <ChatSessionPrev chatSession={chatSession} key={chatSession?.id} />
              ))}

            {isLoading && <WaitingWithText>Loading Conversations</WaitingWithText>}
          </div>
        </div>

        <div className={`conversation-container ${selectedConversation ? "selected" : ""}`}>
          {selectedConversation ? <Conversation key={selectedConversation?.id} /> : ""}
        </div>
      </div>
    </ConversationsContext.Provider>
  );
}
