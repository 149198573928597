import { useContext, useState } from "react";
import { waitForIndexingToFinish } from "../indexing";
import { addSource } from "./addSource";
import { KnowledgeBaseContext } from "../KnowledgeBase";
import "./AddSource.css";
import { API_URL } from "../../../config";
import { WrappedComponentContext } from "../../../WrappedComponent";

export const AddTextSource = ({
  setModalOpen,

  // For editing text source
  isEdit,
  originalTitle,
  originalText,
  sourceId,
  setSourceData,
}) => {
  const [title, setTitle] = useState(originalTitle ?? "");
  const [text, setText] = useState(originalText ?? "");

  const { chatbotId, token, setTextSources, setOpenIntervals } =
    useContext(KnowledgeBaseContext);
  const { setBannerMessage, fetchUserInfo } = useContext(
    WrappedComponentContext
  );

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleTextChange = (e) => setText(e.target.value);

  const addTextSource = async () => {
    try {
      const requestBody = {
        content_item: {
          type: "raw_text",
          title: title,
          trigger_data: { raw_text: text },
          optional_auto_index: true,
        },
      };

      const newContentItem = await addSource({
        requestBody: requestBody,
        type: "text",
        chatbotId,
        token,
      });
      if (!newContentItem) throw { title: "Failed to create content item!" };
      if (newContentItem?.isError) throw newContentItem;

      const date = new Date();
      setTextSources((prevTextSources) => {
        return [{ ...newContentItem, title, text, date }, ...prevTextSources];
      });

      waitForIndexingToFinish({
        chatbotId,
        token,
        contentItemId: newContentItem.id,
        source: { title, text },
        type: "text",
        setOpenIntervals,
        setSources: setTextSources,
        fetchUserInfo,
      });
    } catch (error) {
      console.error(error);
      setBannerMessage({ ...error, type: "error" });
    }
  };

  const updateTextSource = async () => {
    try {
      const endpoint = `${API_URL}/chat_bots/${chatbotId}/content_items/${sourceId}`;
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      const requestBody = {
        content_item: {
          type: "raw_text",
          title: title,
          trigger_data: { raw_text: text },
          optional_auto_index: true,
        },
      };

      const response = await fetch(endpoint, {
        headers,
        method: "PUT",
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) throw { title: "Could not update text source." };

      const updatedContentItem = await response.json();
      if (!updatedContentItem) throw { title: "Failed to update content item" };

      // Set data on the card for viewing text data
      if (setSourceData) setSourceData({ ...updatedContentItem, text: text });

      waitForIndexingToFinish({
        chatbotId,
        token,
        contentItemId: updatedContentItem.id,
        source: { title, text },
        type: "text",
        setOpenIntervals,
        setSources: setTextSources,
        fetchUserInfo,
      });
    } catch (err) {
      console.error(err);
      setBannerMessage({ ...err, type: "error" });
    }
  };

  const save = () => {
    if (!readyToSend) return;

    isEdit ? updateTextSource() : addTextSource();
    setModalOpen(false);
  };

  const readyToSend = title && text;

  return (
    <div className="add-text-source-container">
      <div className="add-text-title">Enter title or question to answer:</div>
      <input
        className="inline-input full add-text-source-input"
        value={title}
        onChange={handleTitleChange}
        required
        placeholder="text title or question"
      />
      <br />
      <br />

      <div className="add-text-title">Full text or answer:</div>
      <textarea
        className="upload-textarea"
        value={text}
        onChange={handleTextChange}
        required
        style={{ minHeight: "calc(75vh - 185px)" }}
      />

      <div style={{ marginTop: "25px" }}>
        <button
          className={`purple-button ${!readyToSend && "muted"}`}
          onClick={save}
        >
          Save
        </button>

        {isEdit && (
          <button
            className="outline-button"
            onClick={() => {
              setModalOpen(false);
            }}
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};
