import { createContext, useEffect, useRef } from "react";
import "./Dropdown.css";
import { useState } from "react";
import { useContext } from "react";
import { ReactComponent as DropdownArrow } from "../../media/dropdown_arrow.svg";
import { Link } from "react-router-dom";

const DropdownContext = createContext();

export default function Dropdown({
  items,
  selectedItem,
  setSelectedItem,
  placeholderText = "Select Item",

  customWidth,
  isSmall,

  linkOnBottom,
}) {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Closes dropdown when clicking outside of it
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const selectItem = (item) => {
    setSelectedItem(item);
    setOpen(false);
  };

  const value = {
    selectedItem,
    selectItem,
    items,
    isSmall,
    linkOnBottom,
  };

  return (
    <DropdownContext.Provider value={value}>
      <div className="dropdown-container" style={{ maxWidth: "100%" }}>
        <div
          className={`dropdown ${open && "open"}`}
          ref={wrapperRef}
          style={{ width: customWidth ? customWidth : "auto", maxWidth: "100%" }}
        >
          <button
            className={`dropdown-item main ${open && "open"} ${isSmall && "small"}`}
            onClick={() => setOpen((prev) => !prev)}
          >
            {/* display selected item */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "3px",
                justifyContent: "center",
                textAlign: "left",
              }}
            >
              <span
                className={`dropdown-item-title ${!selectedItem?.title ? "placeholder-text" : ""}`}
              >
                {selectedItem?.title ?? placeholderText}
              </span>

              {selectedItem?.description && (
                <span className="dropdown-item-description">{selectedItem?.description}</span>
              )}
            </div>

            <DropdownArrow />
          </button>

          <div className="dropdown-bottom">
            <div className={`dropdown-items ${open && "open"}`}>
              {items?.length > 0 &&
                items.map((item, index) => <DropdownItem item={item} key={index} />)}

              {linkOnBottom && <DropdownItem item={{ title: linkOnBottom?.text }} isLink={true} />}
            </div>
          </div>
        </div>
      </div>
    </DropdownContext.Provider>
  );
}

function DropdownItem({ item, isLink }) {
  const { selectedItem, selectItem, items, isSmall, linkOnBottom } = useContext(DropdownContext);

  const isLast = linkOnBottom ? isLink : items[items.length - 1].value === item.value;

  const handleItemClick = () => {
    if (isLink) {
      window.scrollTo(0, 0);
      return;
    }

    selectItem(item);
  };

  const ItemButton = isLink ? Link : "button";

  return (
    <ItemButton
      className={`
        dropdown-item regular
        ${item?.value === selectedItem?.value && "selected"}
        ${isLast && "last"}
        ${isSmall && "small"}
        ${isLink && "dropdown-item-link"}
      `}
      onClick={handleItemClick}
      to={linkOnBottom?.href}
    >
      <span className="dropdown-item-title">{item?.title}</span>

      {item?.description && <span className="dropdown-item-description">{item?.description}</span>}
    </ItemButton>
  );
}
