import "./UseCases.css";
import customerSupport from "../media/customerSupport.png";
import brands from "../media/brands.png";
import influencers from "../media/influencers.png";
import educational from "../media/educational.png";
import { useEffect, useState } from "react";
import usePageWidth from "../Tools/usePageWidth";

export default function UseCases() {
  return (
    <div className="use-cases-container">
      <h2>Use Cases</h2>

      <div className="use-cases">
        {useCases &&
          useCases.map((useCase, index) => {
            return (
              <UseCase
                useCase={useCase.useCase}
                description={useCase.description}
                img={useCase.img}
                key={index}
                isEven={(index + 1) % 2 === 0}
              />
            );
          })}
      </div>
    </div>
  );
}

const UseCase = ({ useCase, description, img, isEven }) => {
  return (
    <div className={`use-case ${isEven ? "even" : ""}`}>
      <img src={img} alt={useCase} className="use-case-img" />

      <div className="use-case-text-container">
        <span className={`use-case-title ${isEven ? "even" : ""}`}>
          {useCase}
        </span>

        <p className="use-case-description">{description}</p>
      </div>
    </div>
  );
};

const useCases = [
  {
    useCase: "Customer Support",
    description:
      "Offer your customers instant and accurate answers to their queries, improving satisfaction and reducing wait times.",
    img: customerSupport,
  },
  {
    useCase: "Brands",
    description:
      "Engage your users in interactive conversations, strengthening brand presence and customer relationships.",
    img: brands,
  },
  {
    useCase: "Influencers",
    description:
      "Facilitate new interactions with followers, enhancing personal connection and fan loyalty. Let your fans easily find your specific content.",
    img: influencers,
  },
  {
    useCase: "Lead Generation",
    description:
      "Address potential clients' queries instantly, building trust and capturing key data for lead nurturing and conversion.",
    img: educational,
  },
];
