import { createContext, useContext, useEffect, useState } from "react";
import { API_URL } from "../../../../config";
import {
  KnowledgeBaseContext,
  formatSourceStateString,
} from "../../KnowledgeBase";
import "../ViewSourcePreview.css";
import { AiOutlineDown as DownIcon } from "react-icons/ai";
import { ErrorFormat } from "../../../../Tools/Errors/ErrorFormatting";
import { WrappedComponentContext } from "../../../../WrappedComponent";
import { ListUrls } from "./ListUrls";
import { ExcludedButtons } from "./ExcludedButtons";

export const ViewWebsiteSourcePreviewContext = createContext();

export const ViewWebsiteSourcePreview = ({
  source,
  setRemoveModalCloseButton = () => {},
}) => {
  const { token, logout } = useContext(WrappedComponentContext);
  const { webSources, setWebSources, setPingWebSourcesLoaded } =
    useContext(KnowledgeBaseContext);

  const [indexedLinks, setIndexedLinks] = useState([]);
  const [notIndexedLinks, setNotIndexedLinks] = useState([]);

  const [manageExclusions, setManageExclusions] = useState(false);
  const [excluded, setExcluded] = useState([]);
  const [savingExcluded, setSavingExcluded] = useState(false);
  const [excludedHadChange, setExcludedHadChange] = useState(false);
  const [excludedHasBeenSet, setExcludedHasBeenSet] = useState(false);

  useEffect(() => {
    sortDiscoveredLinks();
  }, [webSources]);

  const sortDiscoveredLinks = () => {
    let indexed = [];
    let notIndexed = [];
    let blacklistedItems = [];

    if (source?.content?.discovered_url_infos) {
      for (let i = 0; i < source.content.discovered_url_infos?.length; i++) {
        const discoveredUrl = source.content.discovered_url_infos[i];

        if (discoveredUrl?.needs_index) {
          notIndexed.push(discoveredUrl);
        } else {
          indexed.push(discoveredUrl);
        }

        if (discoveredUrl?.blacklisted) {
          blacklistedItems.push(discoveredUrl);
        }
      }
    }

    setIndexedLinks(indexed);
    setNotIndexedLinks(notIndexed);

    if (!excludedHasBeenSet) {
      blacklistedItems = blacklistedItems.map((item) => item?.url);
      setExcluded(blacklistedItems);
      setExcludedHasBeenSet(true);
    }
  };

  const saveExcluded = async () => {
    if (!excludedHadChange || savingExcluded) return;
    setSavingExcluded(true);
    setRemoveModalCloseButton(true);

    try {
      let newDiscoveredUrls = [...source?.content?.discovered_url_infos];
      const discoveredUrls = source?.content?.discovered_url_infos;
      for (let i = 0; i < discoveredUrls?.length; i++) {
        const itemInBlacklist = excluded.find(
          (item) => item === discoveredUrls[i]?.url
        );

        // If item was in blacklist onload
        if (discoveredUrls[i]?.blacklisted) {
          // If item removed from blacklist give it blacklist false
          if (!itemInBlacklist) {
            // find url in discovered urls and turn blacklisted value to false
            for (let j = 0; j < newDiscoveredUrls.length; j++) {
              if (newDiscoveredUrls[j]?.url === discoveredUrls[i]?.url) {
                const isSaved = await saveBlacklistItem(
                  discoveredUrls[i]?.url,
                  false
                );
                if (isSaved) newDiscoveredUrls[j].blacklisted = false;
                break;
              }
            }
          }
        }
        // If item was not in blacklist onload
        else {
          // If item was added to blacklist
          if (itemInBlacklist) {
            // find url in discovered urls and turn blacklisted value to true
            for (let j = 0; j < newDiscoveredUrls.length; j++) {
              if (newDiscoveredUrls[j]?.url === discoveredUrls[i]?.url) {
                const isSaved = await saveBlacklistItem(
                  discoveredUrls[i]?.url,
                  true
                );
                if (isSaved) newDiscoveredUrls[j].blacklisted = true;
                break;
              }
            }
          }
        }
      }

      performReindex();

      setRemoveModalCloseButton(false);
      setSavingExcluded(false);
      setExcludedHadChange(false);
      setManageExclusions(false);
    } catch (error) {
      setRemoveModalCloseButton(false);
      setSavingExcluded(false);
    }
  };

  const performReindex = async () => {
    try {
      const endpoint = `${API_URL}/chat_bots/${source?.chat_bot_id}/content_items/${source?.id}/perform_index`;
      const method = "PUT";
      const headers = { Authorization: token };
      const response = await fetch(endpoint, { headers, method });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to reindex source.",
          response.status,
          logout
        );
      const data = await response.json();

      setWebSources((prevSources) => {
        const updatedSources = prevSources.map((currentSource) => {
          let updatedSource = currentSource;
          if (data?.id === currentSource?.id) {
            updatedSource = {
              ...updatedSource,
              ...data,
              url: updatedSource?.url,
            };
          }
          return updatedSource;
        });

        return updatedSources;
      });
      setPingWebSourcesLoaded(true);
    } catch (error) {
      console.error(error?.message);
    }
  };

  const saveBlacklistItem = async (url, blacklist = false) => {
    const errorMessage = `Failed to ${
      !blacklist ? "un" : ""
    }blacklist url of ${url}`;
    try {
      const endpoint = `${API_URL}/chat_bots/${source?.chat_bot_id}/content_items/${source?.id}/blacklist_url?blacklist=${blacklist}&url=${url}`;
      const method = "PUT";
      const headers = { Authorization: token };
      const response = await fetch(endpoint, { method, headers });
      const data = await response.json();

      if (!response.ok)
        throw new ErrorFormat(errorMessage, response.status, logout);
      if (data?.error && !data?.success) throw new Error(errorMessage);

      return url;
    } catch (error) {
      return null;
    }
  };

  const closeExcluded = () => {
    setManageExclusions(false);

    let blacklistedItems = indexedLinks.filter((item) => item?.blacklisted);
    blacklistedItems = blacklistedItems.map((item) => item?.url);
    setExcluded(blacklistedItems);
  };

  const value = {
    manageExclusions,
    setManageExclusions,
    excluded,
    setExcluded,
    excludedHadChange,
    setExcludedHadChange,
    saveExcluded,
    savingExcluded,
    closeExcluded,
  };

  return (
    <ViewWebsiteSourcePreviewContext.Provider value={value}>
      <div style={{ overflowWrap: "break-word", textAlign: "left" }}>
        <h3>
          Crawled {source?.subtype === "Website" ? "Website" : "URL"} -{" "}
          <a href={source?.url} target="_blank" className="link">
            {source?.url}
          </a>
        </h3>

        {source?.state !== "indexed" && (
          <>
            <span>
              <b>State:</b> {formatSourceStateString(source?.state)}
            </span>
            <div style={{ height: "8px" }} />
          </>
        )}
        {/* <br /> */}
        {/* <span>{source?.indexing_status_text}</span> */}
        {/* <br /> */}

        {manageExclusions && (
          <div
            style={{
              paddingTop: "5px",
              paddingBottom: "15px",
              color: "#1e1e1e",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Excluded URLs - select each for exclusion:
          </div>
        )}

        {manageExclusions && (
          <>
            <ExcludedButtons />
            <div style={{ height: "15px" }} />
          </>
        )}

        {indexedLinks.length > 0 && (
          <ListUrls
            urlObjs={indexedLinks}
            title="Processed URLs:"
            isIndexed={true}
            hasManageExclusionsButton={source?.subtype === "Website"}
          />
        )}

        {notIndexedLinks.length > 0 && (
          <>
            <div style={{ height: "7px" }} />
            <ListUrls
              urlObjs={notIndexedLinks}
              title="Processing URLs:"
              isIndexed={false}
              hasManageExclusionsButton={
                indexedLinks?.length === 0 && source?.subtype === "Website"
              }
            />
          </>
        )}

        {manageExclusions && (
          <>
            <div style={{ height: "15px" }} />
            <ExcludedButtons />
          </>
        )}
      </div>
    </ViewWebsiteSourcePreviewContext.Provider>
  );
};
