import { useState } from "react";
import { FiEdit as EditIcon } from "react-icons/fi";
import { AddTextSource } from "../AddSources/AddTextSource";
import Modal from "../../../Modal/Modal";

export const ViewTextSourcePreview = ({ source, closeView }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [sourceData, setSourceData] = useState(source);

  if (isEdit) {
    return (
      <div style={{ textAlign: "left" }}>
        <Modal
          setOpen={setIsEdit}
          component={
            <AddTextSource
              setModalOpen={setIsEdit}
              isEdit={true}
              originalTitle={sourceData?.title}
              originalText={sourceData?.content?.raw_text}
              sourceId={sourceData?.id}
              setSourceData={setSourceData}
            />
          }
          extraClassName="view-source-modal"
          closeOnOutsideClick={false}
          withoutBackgroundBlur={true}
        />
      </div>
    );
  }

  return (
    <div
      className="view-text-container"
      style={{ textAlign: "left", position: "relative" }}
    >
      <button className="edit-button-container" onClick={() => setIsEdit(true)}>
        <EditIcon />
      </button>

      <h2 className="view-text-title">{sourceData?.title}</h2>

      <span>
        <b>State:</b> {sourceData?.state}
      </span>
      <br />
      <span>{sourceData?.indexing_status_text}</span>

      <pre className="view-text-text">{sourceData?.content?.raw_text}</pre>
    </div>
  );
};
