import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./App";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ConfirmEmailBanner from "./Tools/ConfirmEmail/ConfirmEmailBanner";
import BannerMessage from "./Tools/BannerMessage/BannerMessage";
import { Helmet } from "react-helmet";
import { IS_STAGING } from "./config";
import EventTriggers from "./Tools/EventTriggers/EventTriggers";

export const WrappedComponentContext = createContext();

export const WrappedComponent = ({ startingTransparent, noFooter = false, children }) => {
  const [bannerMessage, setBannerMessage] = useState();

  const [emailResent, setEmailResent] = useState(false); // This is for knowing if the user whos email is not confirmed has clicked on resend email button
  const [hideConfirmEmailBanner, setHideConfirmEmailBanner] = useState(false); // Hides the confirm email banner in certain situations
  const [hideMenu, setHideMenu] = useState(false);

  const [recheckEventTriggers, setRecheckEventTriggers] = useState(false); // Setting this to true will rerun getEventTriggers - is needed when there is no hard reload after adding trigger
  const checkForEventTriggers = () => {
    setRecheckEventTriggers(true);
  };

  const navigate = useNavigate();

  const { token, setToken, logout, userInfo, fetchUserInfo, setUserInfo } = useContext(AppContext);

  const value = {
    token: token,
    setToken: setToken,
    userInfo: userInfo,
    setUserInfo: setUserInfo,
    logout: () => {
      logout(navigate);
    },
    fetchUserInfo: () => {
      fetchUserInfo(() => logout(navigate));
    },
    bannerMessage,
    setBannerMessage,
    emailResent,
    setEmailResent,
    setHideConfirmEmailBanner,
    setHideMenu,
    checkForEventTriggers,
  };

  return (
    <WrappedComponentContext.Provider value={value}>
      {/*
       This will add the noindex meta tag for staging.
       This is needed for the pages that will be prerendered - other pages will get this meta tag from Cloudfront function
      */}
      <Helmet>{IS_STAGING && <meta name="robots" content="noindex" />}</Helmet>

      <EventTriggers
        recheckEventTriggers={recheckEventTriggers}
        setRecheckEventTriggers={setRecheckEventTriggers}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <Header
          token={token}
          logout={() => logout(navigate)}
          startingTransparent={startingTransparent}
          hideMenu={hideMenu}
        />

        <div
          style={{
            display: "flex",
            flex: 1,
            width: "100%",
          }}
        >
          {userInfo && !userInfo?.email_confirmed && !hideConfirmEmailBanner && (
            <ConfirmEmailBanner />
          )}
          {children}
        </div>

        {!noFooter && <Footer />}

        <BannerMessage message={bannerMessage} setMessage={setBannerMessage} />
      </div>
    </WrappedComponentContext.Provider>
  );
};
