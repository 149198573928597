import { useRef, useState } from "react";
import CircularIndeterminate from "../../Tools/Loading/CircularIndeterminate";
import { API_URL } from "../../config";
import { useMemo } from "react";

const ChatMessageForm = ({ action, colors, chatSessionToken }) => {
  const formFields = useMemo(
    () => action.bot_action.form.form_fields.filter((formField) => formField?.enabled),
    [action]
  );
  const [formFieldValues, setFormFieldValues] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFieldChange = (e) => {
    setFormFieldValues((prevValues) => ({ ...prevValues, [e.target.name]: e.target.value }));
  };

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault();
      if (isSubmitted || isSubmitting) return;
      setIsSubmitting(true);

      // Format form fields in form submission format
      // formFieldValues state is in this format - { 1: "value1", 2: "value2" } - the key being the form field id and the value being the form field value
      // we turn this format into this - [{ form_field_id: 1, value: "value1" }, { form_field_id: 2, value: "value2" }]
      const formattedFormFields = Object.entries(formFieldValues).map(([form_field_id, value]) => ({
        form_field_id,
        value,
      }));

      const endpoint = `${API_URL}/filled_forms?token=${chatSessionToken}`;
      const method = "POST";
      const headers = { "Content-Type": "application/json" };
      const body = JSON.stringify({
        filled_form: {
          triggered_action_id: action?.id,
          filled_form_fields_attributes: formattedFormFields,
        },
      });

      const res = await fetch(endpoint, { headers, method, body });
      if (!res.ok) throw new Error("Failed to submit form");

      setIsSubmitted(true);
    } catch (error) {
      console.error(error?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const FormComponent = isSubmitted ? "div" : "form";

  return (
    <div style={{ paddingBottom: "20px" }}>
      <FormComponent
        className="chatbot-demo-form"
        style={{
          background: colors?.mainBackground,
          color: colors?.mainBackgroundText,
          border: `1px solid ${colors?.messageBarBorder}`,
          boxShadow: "1px 1px 6px 0px rgba(0, 0, 0, 0.20)",
        }}
        onSubmit={handleFormSubmit}
      >
        {isSubmitted ? (
          <span style={{ fontSize: "15px", fontWeight: "400", paddingBottom: "4px" }}>
            {action?.bot_action?.success_text}
          </span>
        ) : (
          <>
            <span style={{ fontSize: "15px", fontWeight: "400" }}>
              {action?.bot_action?.intro_text}
            </span>

            <style>
              {`
                .chatbot-demo-form-input::-webkit-input-placeholder{
                  color: ${colors?.messageBarText};
                  font-weight: 400;
                  font-style: italic;
                }
                .chatbot-demo-form-input:focus {
                  outline: 2px solid ${colors?.userBubble}
                }
              `}
            </style>

            {/* Form fields */}
            {formFields.map((formField, index) => (
              <ChatMessageFormField
                formField={formField}
                colors={colors}
                formFieldValues={formFieldValues}
                handleFieldChange={handleFieldChange}
                key={index}
              />
            ))}

            <button
              className={`chatbot-demo-form-button ${isSubmitting ? "muted" : ""}`}
              style={{
                background: colors?.userBubble,
                color: colors?.userText,
              }}
              type="submit"
            >
              {isSubmitting ? (
                <>
                  Submitting
                  <CircularIndeterminate color={colors?.userText} />
                </>
              ) : (
                "Submit"
              )}
            </button>
          </>
        )}
      </FormComponent>
    </div>
  );
};

const ChatMessageFormField = ({ formField, colors, formFieldValues, handleFieldChange }) => {
  const InputType = formField?.type === "TextAreaFormField" ? "textarea" : "input";

  return (
    <div className={`chatbot-demo-form-input-container ${formField?.required ? "required" : ""}`}>
      <span style={{ color: colors?.mainBackgroundText }}>
        {formField?.label}
        {formField?.is_required ? "*" : ""}
      </span>

      <InputType
        name={formField.id}
        value={formFieldValues[formField.id] ?? ""}
        onChange={handleFieldChange}
        required={formField?.is_required}
        type={inputType[formField?.type] ?? "text"}
        style={{
          background: colors?.messageBarBackground,
          border: `1px solid ${colors?.messageBarBorder}`,
          color: colors?.messageBarText,
        }}
        className={`inline-input chatbot-demo-form-input ${
          formField?.type === "TextAreaFormField" ? "is-textarea" : ""
        }`}
      />
    </div>
  );
};

const inputType = {
  TextFormField: "text",
  EmailFormField: "email",
  PhoneNumberFormField: "tel",
};

export default ChatMessageForm;
