import { useEffect, useState } from "react";
import { API_URL } from "../config";

const usePricingConfig = () => {
  const [stripeInfo, setStripeInfo] = useState();

  // Get stripe pricing info
  useEffect(() => {
    if (!stripeInfo) fetchStripePlansInfo();
  }, []);

  const fetchStripePlansInfo = async () => {
    try {
      const endpoint = `${API_URL}/stripe_products`;

      const response = await fetch(endpoint);
      if (!response.ok) throw "Could not fetch stripe info";

      let stripePlans = await response.json();
      stripePlans = stripePlans.filter((stripePlan) => stripePlan?.active);

      let stripeInfo = {};
      for (let i = 0; i < stripePlans?.length; i++) {
        stripeInfo[stripePlans[i]?.tier] = stripePlans[i];
      }

      setStripeInfo(stripeInfo);
    } catch (error) {
      console.error(error);
    }
  };

  const getValue = (value, afterValue = "") => {
    if (value === 0) {
      return false;
    } else if (value === null || value === undefined) {
      return " ";
    } else if (value >= 100000000000000) {
      return true;
    } else {
      let newValue = value;
      if (afterValue) newValue = newValue + afterValue;
      if (typeof value == "number") return newValue.toLocaleString("en-US");
      return value;
    }
  };

  const pricingConfig = {
    plans: [
      { name: "Free", stripePlan: stripeInfo?.free },
      { name: "Pro", stripePlan: stripeInfo?.pro },
      { name: "Business", stripePlan: stripeInfo?.business },
      { name: "Elite", stripePlan: stripeInfo?.elite },
    ],

    sections: [
      {
        title: "Usage",
        rows: [
          // Amount chatbots
          [
            {
              text: "Chatbots",
            },
            getValue(stripeInfo?.free?.plan_limits?.usage?.chat_bot_limit, " Chatbots"),
            getValue(stripeInfo?.pro?.plan_limits?.usage?.chat_bot_limit, " Chatbots"),
            getValue(stripeInfo?.business?.plan_limits?.usage?.chat_bot_limit, " Chatbots"),
            {
              text: getValue(stripeInfo?.elite?.plan_limits?.usage?.chat_bot_limit, "+ Chatbots"),
              description:
                "Accounts which are not agencies can request additional free chatbots over this limit at any time.",
            },
          ],
          [
            {
              text: "Response credits per month",
              description:
                "Total number of responses across all chatbots in a plan. One response is what a chatbot replies to any question that it is asked by a user, regardless of the length of the response.",
            },
            getValue(stripeInfo?.free?.plan_limits?.usage?.message_monthly_limit),
            getValue(stripeInfo?.pro?.plan_limits?.usage?.message_monthly_limit),
            getValue(stripeInfo?.business?.plan_limits?.usage?.message_monthly_limit),
            {
              text: getValue(
                stripeInfo?.elite?.plan_limits?.usage?.message_monthly_limit.toLocaleString(
                  "en-US"
                ),
                "+"
              ),
              description: "Additional credits are available for purchase.",
            },
          ],
          [
            {
              text: "Content base size",
              description:
                "How much information can be absorbed and trained across your chatbots in total. We consider 2,000 characters to be 1 page. Example: 1,000 pages is 2 million characters.",
            },
            getNumPages(stripeInfo?.free),
            getNumPages(stripeInfo?.pro),
            getNumPages(stripeInfo?.business),
            getNumPages(stripeInfo?.elite),
          ],
        ],
      },

      {
        title: "Features",
        rows: [
          [
            { text: "Index single page URLs" },
            getValue(
              stripeInfo?.free?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::SinglePageUrlContentItem"
              ],
              " max"
            ),
            getValue(
              stripeInfo?.pro?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::SinglePageUrlContentItem"
              ]
            ),
            getValue(
              stripeInfo?.business?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::SinglePageUrlContentItem"
              ]
            ),
            getValue(
              stripeInfo?.elite?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::SinglePageUrlContentItem"
              ]
            ),
          ],
          [
            { text: "Index documents" },
            getValue(
              stripeInfo?.free?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::Documents::DocumentContentItem"
              ],
              " max"
            ),
            getValue(
              stripeInfo?.pro?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::Documents::DocumentContentItem"
              ]
            ),
            getValue(
              stripeInfo?.business?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::Documents::DocumentContentItem"
              ]
            ),
            getValue(
              stripeInfo?.elite?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::Documents::DocumentContentItem"
              ]
            ),
          ],
          [
            { text: "Index custom text blocks" },
            getValue(
              stripeInfo?.free?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::RawTextContentItem"
              ],
              " max"
            ),
            getValue(
              stripeInfo?.pro?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::RawTextContentItem"
              ]
            ),
            getValue(
              stripeInfo?.business?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::RawTextContentItem"
              ]
            ),
            getValue(
              stripeInfo?.elite?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::RawTextContentItem"
              ]
            ),
          ],
          [
            { text: "Index whole sites with URL crawling" },
            getValue(
              stripeInfo?.free?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::CrawledWebsiteContentItem"
              ]
            ),
            getValue(
              stripeInfo?.pro?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::CrawledWebsiteContentItem"
              ]
            ),
            getValue(
              stripeInfo?.business?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::CrawledWebsiteContentItem"
              ]
            ),
            getValue(
              stripeInfo?.elite?.plan_limits?.feature_limits?.document_count_plan_limits[
                "ContentItems::CrawledWebsiteContentItem"
              ]
            ),
          ],
          [
            { text: "Optional hosting on majic.ai/your_alias" },
            customChatbotAlias(stripeInfo?.free),
            customChatbotAlias(stripeInfo?.pro),
            customChatbotAlias(stripeInfo?.business),
            customChatbotAlias(stripeInfo?.elite),
          ],
          [
            {
              text: "Option to use GPT-4 Turbo",
              description: "Each GPT-4 message credit costs 15 regular message credits.",
            },
            stripeInfo?.free?.plan_limits?.feature_limits?.gpt4_usage,
            stripeInfo?.pro?.plan_limits?.feature_limits?.gpt4_usage,
            stripeInfo?.business?.plan_limits?.feature_limits?.gpt4_usage,
            stripeInfo?.elite?.plan_limits?.feature_limits?.gpt4_usage,
          ],
          [
            { text: "Auto-sync data sources" },
            getAutoSyncText(stripeInfo?.free),
            getAutoSyncText(stripeInfo?.pro),
            getAutoSyncText(stripeInfo?.business),
            getAutoSyncText(stripeInfo?.elite),
          ],
          [
            {
              text: "Chatbot message history",
              description:
                "You will be able to view any and all conversations conducted by your users with your chatbots.",
            },
            getChatbotMessageHistory(stripeInfo?.free),
            getChatbotMessageHistory(stripeInfo?.pro),
            getChatbotMessageHistory(stripeInfo?.business),
            getChatbotMessageHistory(stripeInfo?.elite),
          ],
          [
            { text: "Slack integration" },
            stripeInfo?.free?.plan_limits?.feature_limits?.slack,
            stripeInfo?.pro?.plan_limits?.feature_limits?.slack,
            stripeInfo?.business?.plan_limits?.feature_limits?.slack,
            stripeInfo?.elite?.plan_limits?.feature_limits?.slack,
          ],
          [
            { text: "Remove Majic.AI branding" },
            stripeInfo?.free?.plan_limits?.feature_limits?.whitelabel,
            stripeInfo?.pro?.plan_limits?.feature_limits?.whitelabel,
            stripeInfo?.business?.plan_limits?.feature_limits?.whitelabel,
            stripeInfo?.elite?.plan_limits?.feature_limits?.whitelabel,
          ],
          [
            { text: "Trigger custom forms to collect data" },
            "1 form max",
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.user_data_collection,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.user_data_collection,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.user_data_collection,
          ],
          [
            { text: "Escalate questions to human support" },
            true,
            true,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.human_support_escalation,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.human_support_escalation,
          ],
          [
            { text: "Custom Actions & Triggers" },
            "1 max",
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.sequences_custom_actions,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.sequences_custom_actions,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.sequences_custom_actions,
          ],
        ],
      },

      {
        title: "Service & Support",
        rows: [
          [
            { text: "Customer support" },
            stripeInfo?.free?.plan_limits?.service_support_limits?.customer_support,
            stripeInfo?.pro?.plan_limits?.service_support_limits?.customer_support,
            stripeInfo?.business?.plan_limits?.service_support_limits?.customer_support,
            stripeInfo?.elite?.plan_limits?.service_support_limits?.customer_support,
          ],
          [
            { text: "Dedicated account manager" },
            stripeInfo?.free?.plan_limits?.service_support_limits?.account_manager,
            stripeInfo?.pro?.plan_limits?.service_support_limits?.account_manager,
            stripeInfo?.business?.plan_limits?.service_support_limits?.account_manager,
            stripeInfo?.elite?.plan_limits?.service_support_limits?.account_manager,
          ],
          [
            {
              text: "Assisted chatbot creation",
              description:
                "Majic Chatbots are very quick to create. But there are times when you are too busy and want someone to do it all for you. We are happy to assist and do all the work for you for no extra charge! Just contact us and we’ll hop on a call to make it happen in no time.",
            },
            stripeInfo?.free?.plan_limits?.service_support_limits?.assisted_chatbot_creation,
            stripeInfo?.pro?.plan_limits?.service_support_limits?.assisted_chatbot_creation,
            stripeInfo?.business?.plan_limits?.service_support_limits?.assisted_chatbot_creation,
            stripeInfo?.elite?.plan_limits?.service_support_limits?.assisted_chatbot_creation,
          ],
        ],
      },

      {
        title: "Compliance & security",
        rows: [
          [
            { text: "Rate limiting features" },
            stripeInfo?.free?.plan_limits?.compliance_limits?.rate_limiting,
            stripeInfo?.pro?.plan_limits?.compliance_limits?.rate_limiting,
            stripeInfo?.business?.plan_limits?.compliance_limits?.rate_limiting,
            stripeInfo?.elite?.plan_limits?.compliance_limits?.rate_limiting,
          ],
          [
            { text: "DDOS protections" },
            stripeInfo?.free?.plan_limits?.compliance_limits?.ddos_protection,
            stripeInfo?.pro?.plan_limits?.compliance_limits?.ddos_protection,
            stripeInfo?.business?.plan_limits?.compliance_limits?.ddos_protection,
            stripeInfo?.elite?.plan_limits?.compliance_limits?.ddos_protection,
          ],
          [
            { text: "GDPR compliance" },
            stripeInfo?.free?.plan_limits?.compliance_limits?.gdpr_compliance,
            stripeInfo?.pro?.plan_limits?.compliance_limits?.gdpr_compliance,
            stripeInfo?.business?.plan_limits?.compliance_limits?.gdpr_compliance,
            stripeInfo?.elite?.plan_limits?.compliance_limits?.gdpr_compliance,
          ],
        ],
      },

      {
        title: "Upcoming Features",
        rows: [
          [
            { text: "Index Notion pages" },
            stripeInfo?.free?.plan_limits?.upcoming_limits?.notion_integration,
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.notion_integration,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.notion_integration,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.notion_integration,
          ],
          [
            { text: "MS Team integration" },
            stripeInfo?.free?.plan_limits?.upcoming_limits?.ms_teams_integration,
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.ms_teams_integration,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.ms_teams_integration,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.ms_teams_integration,
          ],
          [
            { text: "Zapier integration" },
            stripeInfo?.free?.plan_limits?.upcoming_limits?.zapier_integration,
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.zapier_integration,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.zapier_integration,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.zapier_integration,
          ],
          [
            { text: "Facebook Messenger integration" },
            stripeInfo?.free?.plan_limits?.upcoming_limits?.fb_messenger_integration,
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.fb_messenger_integration,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.fb_messenger_integration,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.fb_messenger_integration,
          ],
          [
            { text: "WhatsApp Business integration" },
            stripeInfo?.free?.plan_limits?.upcoming_limits?.whatsapp_integration,
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.whatsapp_integration,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.whatsapp_integration,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.whatsapp_integration,
          ],
          [
            { text: "Confluence integration" },
            stripeInfo?.free?.plan_limits?.upcoming_limits?.confluence_integration,
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.confluence_integration,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.confluence_integration,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.confluence_integration,
          ],
          [
            { text: "Auto-Transcribe Videos to knowledgebase" },
            stripeInfo?.free?.plan_limits?.upcoming_limits?.auto_transcribe_video,
            stripeInfo?.pro?.plan_limits?.upcoming_limits?.auto_transcribe_video,
            stripeInfo?.business?.plan_limits?.upcoming_limits?.auto_transcribe_video,
            stripeInfo?.elite?.plan_limits?.upcoming_limits?.auto_transcribe_video,
          ],
        ],
      },
    ],
  };

  return pricingConfig;
};

const getAutoSyncText = (stripePlan) => {
  if (stripePlan?.plan_limits?.feature_limits?.autosync_daily) {
    return "Daily";
  } else if (stripePlan?.plan_limits?.feature_limits?.autosync_weekly) {
    return "Weekly";
  } else if (stripePlan?.plan_limits?.feature_limits?.autosync_monthly) {
    return "Monthly";
  }
  return false;
};

const getChatbotMessageHistory = (stripePlan) => {
  const numDays = stripePlan?.plan_limits?.feature_limits?.message_history_num_days;

  if (!numDays) return " ";

  if (numDays >= 100000000000000) return "Unlimited";
  else if (numDays >= 365) {
    return `${Math.round(numDays / 365)} Year`;
  } else if (numDays >= 30) {
    return `${Math.round(numDays / 30)} Months`;
  } else {
    return `${numDays} Days`;
  }
};

const getNumPages = (stripePlan) => {
  const charIndexLimit = stripePlan?.plan_limits?.usage?.character_index_limit;

  if (charIndexLimit) return charIndexLimit / 2000 + " pages";
  return " ";
};

const customChatbotAlias = (stripePlan) => {
  if (!stripePlan) return " ";

  return stripePlan?.plan_limits?.feature_limits?.custom_chat_bot_alias ? true : "random ID";
};

export default usePricingConfig;
