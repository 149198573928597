import React, { useContext, useEffect, useRef, useState } from "react";
import Dropdown from "../../../../Tools/Dropdown/Dropdown";
import { InfoDescription } from "../../../InfoDescription/InfoDescription";
import { ReactComponent as DropdownArrow } from "../../../../media/dropdown_arrow.svg";
import Toggle from "../../../../Toggle/Toggle";
import SaveAction from "./SaveAction";
import { useParams } from "react-router-dom";
import { WrappedComponentContext } from "../../../../WrappedComponent";
import { prepopulateActionFormSettings } from "./preopulateActionSettings";
import { ChatbotDemo } from "../../Look&Feel/ChatbotPreview/ChatbotDemo/ChatbotDemo";
import DomainsAllowed from "../../../ChatbotFormItems/DomainsAllowed/DomainsAllowed";

const ActionsFormSettings = ({ action, setAction, type, scenarioType }) => {
  const [state, setState] = useState({
    previewFormOpen: false,
    forms: [],
    selectedForm: null,

    // userIntentTrigger: userIntentTriggers[0],
    // triggerOnUserIntent: true,

    triggerOnNoAnswer: true,
    triggerOnChatSessionStart: false,

    triggerAfterEveryNumQuestions: false,
    numQuestionsToTriggerAfter: numQuestionsOptions[2],

    triggerOnKeywords: true,
    keywordTriggers: [],

    forwardSubmissionsViaEmail: true,
    emails: [],
    selectedEmail: null,
  });
  const keywordsInputRef = useRef(null);

  const { chatbotId } = useParams();
  const { token } = useContext(WrappedComponentContext);

  useEffect(() => {
    // prepopulate data
    prepopulateActionFormSettings({ chatbotId, token, action, setState });
  }, []);

  const toggleOpen = (name) => {
    try {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    } catch (error) {}
  };

  const setStateValue = (key) => {
    return (value) => {
      setState((prev) => ({ ...prev, [key]: value }));
    };
  };

  const setActionValue = (key, value) => {
    setAction((prev) => ({ ...prev, [key]: value }));
  };

  const handleNumQuestionsChange = (newValue) => {
    setState((prev) => ({
      ...prev,
      numQuestionsToTriggerAfter: newValue,
    }));
  };

  const setKeywordTriggers = (updater) => {
    setState((prevState) => ({
      ...prevState,
      keywordTriggers: typeof updater === "function" ? updater(prevState.keywordTriggers) : updater,
    }));
  };

  return (
    <>
      <section className="actions-settings-section-container">
        <div className="flex align-center" style={{ gap: "12px", flexWrap: "wrap" }}>
          <h4 className="actions-tab-settings-title">Show this form:</h4>

          <Dropdown
            isSmall={true}
            selectedItem={state.selectedForm}
            setSelectedItem={(() => setStateValue("selectedForm"))()}
            items={state.forms}
            customWidth="253px"
            placeholderText="Select form..."
            linkOnBottom={{
              href: `/chatbot/settings/${chatbotId}/forms/new-form`,
              text: "Create a new form",
            }}
          />
        </div>

        <div>
          <div
            className="flex align-center actions-tab-settings-title subtitle"
            style={{ position: "relative", paddingBottom: "12px" }}
          >
            Intro text:{" "}
            <InfoDescription customDistance="30px">
              This is the text that appears above the form fields and informs the user what this
              form is trying to collect or why you'd like to collect it.{" "}
            </InfoDescription>
          </div>

          <input
            className="inline-input"
            value={action?.intro_text ?? ""}
            onChange={(e) => setActionValue("intro_text", e.target.value)}
          />
        </div>

        <div>
          <div
            className="flex align-center actions-tab-settings-title subtitle"
            style={{ position: "relative", paddingBottom: "12px" }}
          >
            Success text:{" "}
            <InfoDescription customDistance="30px">
              This text is displayed to a user once the form is submitted.
            </InfoDescription>
          </div>

          <input
            className="inline-input"
            value={action?.success_text ?? ""}
            onChange={(e) => setActionValue("success_text", e.target.value)}
          />
        </div>

        {state?.selectedForm && (
          <div>
            <button
              className="flex align-center purple-text"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                padding: "0px",
                border: "none",
                background: "transparent",
                fontFamily: "Work Sans",
              }}
              onClick={() => toggleOpen("previewFormOpen")}
            >
              Preview Form
              <DropdownArrow
                className={`advanced-dropdown-icon ${state.previewFormOpen ? "open" : ""}`}
                style={{
                  width: "22px",
                  marginBottom: "0px",
                  fill: "#a24dff",
                }}
              />
            </button>

            <div
              style={{
                textAlign: "left",
                width: "418px",
                maxWidth: "100%",
                display: state?.previewFormOpen ? "block" : "none",
              }}
            >
              <ChatbotDemo
                isForm={true}
                formIntroText={action?.intro_text}
                formFields={state.selectedForm?.form_fields.filter((field) => field?.enabled)}
              />
            </div>
          </div>
        )}
      </section>

      <div className="action-tab-settings-divider" />

      <section className="actions-settings-section-container">
        <h4 className="actions-tab-settings-title">When to trigger this form:</h4>

        {/* Trigger on user intent */}
        {/* <div>
          <div className="flex align-center" style={{ gap: "8px", flexWrap: "wrap" }}>
            <div style={{ marginRight: "16px" }}>
              <Toggle
                open={state.triggerOnUserIntent}
                setOpen={() => toggleOpen("triggerOnUserIntent")}
                small={true}
              />
            </div>

            <span className="actions-tab-settings-title subtitle">
              When user intent is detected
            </span>
          </div>

          {state.triggerOnUserIntent && (
            <div className="actions-tab-toggle-indented">
              <Dropdown
                items={userIntentTriggers}
                selectedItem={state.userIntentTrigger}
                setSelectedItem={(() => setStateValue("userIntentTrigger"))()}
                isSmall={true}
                customWidth="312px"
              />
            </div>
          )}
        </div> */}

        {/* <div className="action-tab-settings-divider small" /> */}

        {/* Trigger on no answer */}
        <div className="flex align-center" style={{ gap: "8px", flexWrap: "wrap" }}>
          <div style={{ marginRight: "16px" }}>
            <Toggle
              open={state.triggerOnNoAnswer}
              setOpen={() => toggleOpen("triggerOnNoAnswer")}
              small={true}
            />
          </div>

          <span className="actions-tab-settings-title subtitle">
            When chatbot cannot find an answer
          </span>
        </div>

        <div className="action-tab-settings-divider small" />

        {/* Trigger on start of chat session */}
        <div className="flex align-center" style={{ gap: "8px", flexWrap: "wrap" }}>
          <div style={{ marginRight: "16px" }}>
            <Toggle
              open={state.triggerOnChatSessionStart}
              setOpen={() => toggleOpen("triggerOnChatSessionStart")}
              small={true}
            />
          </div>

          <span className="actions-tab-settings-title subtitle">
            At start of every chat session
          </span>
        </div>

        <div className="action-tab-settings-divider small" />

        {/* Trigger on start of chat session */}
        <div className="flex align-center" style={{ gap: "8px", flexWrap: "wrap" }}>
          <div style={{ marginRight: "16px" }}>
            <Toggle
              open={state.triggerAfterEveryNumQuestions}
              setOpen={() => toggleOpen("triggerAfterEveryNumQuestions")}
              small={true}
            />
          </div>

          <span
            className="actions-tab-settings-title subtitle flex align-center"
            style={{ gap: "8px", flexWrap: "wrap" }}
          >
            After every
            <Dropdown
              isSmall={true}
              items={numQuestionsOptions}
              selectedItem={state.numQuestionsToTriggerAfter}
              setSelectedItem={handleNumQuestionsChange}
              customWidth="62px"
            />
            questions
          </span>
        </div>

        <div className="action-tab-settings-divider small" />

        {/* Trigger on keyword appearance */}
        <div>
          <div
            className="flex align-center"
            style={{ gap: "8px", flexWrap: "wrap", position: "relative" }}
          >
            <div style={{ marginRight: "16px" }}>
              <Toggle
                open={state.triggerOnKeywords}
                setOpen={() => toggleOpen("triggerOnKeywords")}
                small={true}
              />
            </div>

            <div className="actions-tab-settings-title subtitle flex align-center">
              When keywords appear in chat session
            </div>
          </div>

          {state.triggerOnKeywords && (
            <div className="actions-tab-toggle-indented">
              <DomainsAllowed
                returnJustInput={true}
                customContextValues={{
                  domainsAllowed: state.keywordTriggers,
                  setDomainsAllowed: setKeywordTriggers,
                }}
                inputPlaceholder="Insert keywords here"
                inputRef={keywordsInputRef}
                breakOnSpace={false}
              />
            </div>
          )}
        </div>
      </section>

      <div className="action-tab-settings-divider" />

      <section className="actions-settings-section-container">
        <h4 className="actions-tab-settings-title">Notify of form submissions</h4>

        {/* Forward submissions via email */}
        <div style={{ position: "relative" }}>
          <div className="flex align-center" style={{ gap: "8px", flexWrap: "wrap" }}>
            <div style={{ marginRight: "16px" }}>
              <Toggle
                open={state.forwardSubmissionsViaEmail}
                setOpen={() => toggleOpen("forwardSubmissionsViaEmail")}
                small={true}
              />
            </div>

            <div className="actions-tab-settings-title subtitle flex align-center">
              Forward submissions via email
              <InfoDescription>
                Send an email notification as soon as a new submission is received.
              </InfoDescription>
            </div>
          </div>

          {state.forwardSubmissionsViaEmail && (
            <div className="actions-tab-toggle-indented">
              <Dropdown
                items={state.emails}
                selectedItem={state.selectedEmail}
                setSelectedItem={(() => setStateValue("selectedEmail"))()}
                isSmall={true}
                customWidth="312px"
                placeholderText="Select emails..."
                linkOnBottom={{ href: "/emails", text: "Add another email address" }}
              />
            </div>
          )}
        </div>
      </section>

      <SaveAction
        action={action}
        setAction={setAction}
        settingsState={state}
        keywordsInputRef={keywordsInputRef}
        type="form"
      />
    </>
  );
};

// const userIntentTriggers = [{ title: "User wants support", value: "User wants support" }];
const numQuestionsOptions = [
  { title: "1", value: 1 },
  { title: "2", value: 2 },
  { title: "3", value: 3 },
  { title: "4", value: 4 },
  { title: "5", value: 5 },
];

export default ActionsFormSettings;
