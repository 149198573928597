import { useContext, useEffect, useRef, useState } from "react";
import { ConversationsContext } from "./Conversations";
import { getTimeSinceString } from "./getTimeSinceString";
import { API_URL } from "../../config";
import { ErrorFormat } from "../../Tools/Errors/ErrorFormatting";
import { WrappedComponentContext } from "../../WrappedComponent";
import ConversationChatMessage from "./ConversationChatMessage";

const Conversation = () => {
  const { selectedConversation, setSelectedConversation, token, chatbot, setConversations } =
    useContext(ConversationsContext);
  const { setBannerMessage } = useContext(WrappedComponentContext);

  const date = selectedConversation?.last_interaction_timestamp
    ? selectedConversation?.last_interaction_timestamp
    : selectedConversation?.created_at;

  const customerInfo = parseCustomerInfo(selectedConversation);
  const conversationTopRef = useRef(null);

  const dayString = getTimeSinceString(date);

  const chatMessages = selectedConversation?.chatMessages;

  useEffect(() => {
    if (!chatMessages) {
      fetchChatMessages();
    }
  }, [selectedConversation]);

  const fetchChatMessages = async () => {
    if (!selectedConversation) return;
    try {
      const endpoint = `${API_URL}/chat_messages.json?timestamp=${selectedConversation.created_at}&chat_session_id=${selectedConversation.id}`;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { headers });
      if (!response.ok) throw new ErrorFormat("Failed to fetch chat messages.", response.status);

      const data = await response.json();
      setSelectedConversation({ ...selectedConversation, chatMessages: data });
      setConversations((prev) =>
        prev.map((prevConvo) =>
          prevConvo?.id === selectedConversation?.id
            ? { ...prevConvo, chatMessages: data }
            : prevConvo
        )
      );
    } catch (error) {
      console.error(error);
      setBannerMessage({
        type: "error",
        title: "Failed to fetch chat messages.",
      });
    }
  };

  return (
    <div className="conversation">
      <span
        className="manage-chatbot-back-to-chatbots back-to-conversations"
        onClick={() => {
          setSelectedConversation(null);
        }}
      >
        {"< Back to Conversations"}
      </span>

      <div className="conversation-top" ref={conversationTopRef}>
        <span className="conversation-top-time">{dayString}</span>

        {customerInfo && (
          <ExpandedText
            classNames="conversation-top-time"
            styles={{ fontWeight: "400", fontSize: "14px" }}
            text={`Customer Info: ${customerInfo}`}
            container={conversationTopRef.current}
          />
        )}
      </div>

      <div
        className="conversation-body"
        style={!chatbot?.show_chatbot_avatar_in_responses ? { marginTop: "0px" } : {}}
      >
        {chatMessages?.chat_messages && (
          <>
            <br />

            {chatMessages.chat_messages.map((chatMessage, index) => (
              <ConversationChatMessage chatMessage={chatMessage} chatbot={chatbot} key={index} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const parseCustomerInfo = (selectedConversation) => {
  if (selectedConversation?.customer_info_parsed) {
    return Object.keys(selectedConversation?.customer_info_parsed)
      .map((key) => key + ": " + selectedConversation?.customer_info_parsed[key])
      .join(", ");
  }
  return "";
};

// This is for showing customer info in a way that can be exapnded to see the full text
const ExpandedText = ({ text = "", classNames = "", styles = {}, container }) => {
  const [expanded, setExpanded] = useState(false);
  const textRef = useRef(null);
  const [overflowing, setOverflowing] = useState(false);
  const moreRef = useRef(null);

  useEffect(() => {
    if (container) {
      updateOverflowing();
      window.addEventListener("resize", updateOverflowing);
    }

    return () => {
      window.removeEventListener("resize", updateOverflowing);
    };
  }, [container]);

  const updateOverflowing = () => {
    if (textRef.current.offsetWidth >= container.offsetWidth) setOverflowing(true);
  };

  return (
    <div
      className="flex"
      style={{
        flexDirection: expanded ? "column" : "row",
        justifyContent: "space-between",
      }}
    >
      <div
        ref={textRef}
        className={classNames}
        style={
          expanded
            ? { ...styles, maxWidth: "100%", whiteSpace: "normal" }
            : {
                ...styles,
                maxWidth: `calc(100% - ${moreRef.current?.offsetWidth}px)`,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }
        }
      >
        {text}
      </div>
      {overflowing && (
        <a
          onClick={() => setExpanded((prev) => !prev)}
          href="#"
          className={classNames}
          style={{
            ...styles,
          }}
          ref={moreRef}
        >
          {expanded ? "Less" : "More"}
        </a>
      )}
    </div>
  );
};

export default Conversation;
