import { useContext, useState } from "react";
import { AiOutlineMail as MailIcon } from "react-icons/ai";
import { API_URL } from "../config";
import StatusCard from "../Tools/StatusCard/StatusCard";
import Modal from "../Modal/Modal";
import { WrappedComponentContext } from "../WrappedComponent";

const ForgotPasswordPopup = ({ setIsResetPassword, isResetPassword }) => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  const { setBannerMessage } = useContext(WrappedComponentContext);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const sendResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (!email) return;

      const endpoint = API_URL + "/users/password";
      const method = "POST";
      const headers = { "Content-Type": "application/json" };
      const body = JSON.stringify({ user: { email } });
      const response = await fetch(endpoint, { method, body, headers });
      const data = await response.json();
      if (!response.ok) throw data;

      setSent(true);
    } catch (error) {
      let errorMessage = "";
      for (const [key, value] of Object.entries(error?.errors)) {
        errorMessage += `${key} ${value}. `;
      }

      setBannerMessage({
        type: "error",
        title: "Failed to reset password",
        message: errorMessage,
      });
    }
  };

  let component = (
    <form
      onSubmit={sendResetPassword}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        fontWeight: "400",
        fontSize: "14px",
        // textAlign: "center",

        margin: "25px",
      }}
    >
      <span style={{ margin: 0 }}>
        Please enter the email address on your account, we’ll send a reset link
        straight away:
      </span>

      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <div className="login-input-container">
          <MailIcon className="login-icon" size={18} />
          <input
            value={email}
            placeholder="Email"
            type="email"
            required
            onChange={handleEmailChange}
          />
        </div>

        <button className={`purple-button ${!email && "muted"}`} type="submit">
          Send Reset Email
        </button>
      </div>
    </form>
  );

  if (sent) {
    component = (
      <StatusCard
        success={true}
        title="Email Sent"
        description="Check your email for a link to reset your password."
        to="/"
        justContentWithoutBackground={true}
      />
    );
  }

  if (!isResetPassword) {
    return;
  }

  return (
    <Modal
      setOpen={setIsResetPassword}
      extraClassName="confirm"
      component={component}
      closeOnOutsideClick={false}
      takeChildrensShape={sent}
    />
  );
};

export default ForgotPasswordPopup;
