import { useContext, useEffect, useRef, useState } from "react";
import Dropdown from "../../../../Tools/Dropdown/Dropdown";
import { InfoDescription } from "../../../InfoDescription/InfoDescription";
import Toggle from "../../../../Toggle/Toggle";
import DomainsAllowed from "../../../ChatbotFormItems/DomainsAllowed/DomainsAllowed";
import SaveAction from "./SaveAction";
import { WrappedComponentContext } from "../../../../WrappedComponent";
import { prepopulateActionNotificationsSettings } from "./preopulateActionSettings";

// const userIntentTriggers = [{ title: "User wants support", value: "User wants support" }];

const ActionsNotificationsSettings = ({ action, setAction }) => {
  const [state, setState] = useState({
    previewFormOpen: false,
    forms: [],
    selectedForm: null,

    triggerOnNoAnswer: true,
    triggerOnChatSessionStart: false,

    triggerOnKeywords: true,
    keywordTriggers: [],

    // userIntentTrigger: userIntentTriggers[0],
    // triggerOnUserIntent: true,

    forwardSubmissionsViaEmail: true,
    emails: [],
    selectedEmail: null,

    // sendNotificationViaSlack: true,
    // slackChannels: [],
    // selectedSlackChannel: null,
  });
  const keywordsInputRef = useRef(null);

  const { token } = useContext(WrappedComponentContext);

  useEffect(() => {
    // prepopulate data
    prepopulateActionNotificationsSettings({ token, action, setState });
  }, []);

  const toggleOpen = (name) => {
    try {
      setState((prev) => ({ ...prev, [name]: !prev[name] }));
    } catch (error) {}
  };

  const setValue = (key) => {
    return (value) => {
      setState((prev) => ({ ...prev, [key]: value }));
    };
  };

  const setKeywordTriggers = (updater) => {
    setState((prevState) => ({
      ...prevState,
      keywordTriggers: typeof updater === "function" ? updater(prevState.keywordTriggers) : updater,
    }));
  };

  return (
    <>
      <section className="actions-settings-section-container">
        <h4 className="actions-tab-settings-title">When to trigger this notification:</h4>

        {/* Trigger on no answer */}
        <div className="flex align-center" style={{ gap: "8px", flexWrap: "wrap" }}>
          <div style={{ marginRight: "16px" }}>
            <Toggle
              open={state.triggerOnNoAnswer}
              setOpen={() => toggleOpen("triggerOnNoAnswer")}
              small={true}
            />
          </div>

          <span className="actions-tab-settings-title subtitle">
            When chatbot cannot find an answer
          </span>
        </div>

        <div className="action-tab-settings-divider small" />

        {/* Trigger on start of chat session */}
        <div className="flex align-center" style={{ gap: "8px", flexWrap: "wrap" }}>
          <div style={{ marginRight: "16px" }}>
            <Toggle
              open={state.triggerOnChatSessionStart}
              setOpen={() => toggleOpen("triggerOnChatSessionStart")}
              small={true}
            />
          </div>

          <span className="actions-tab-settings-title subtitle">
            At start of every chat session
          </span>
        </div>

        <div className="action-tab-settings-divider small" />

        {/* Trigger on keyword appearance */}
        <div>
          <div
            className="flex align-center"
            style={{ gap: "8px", flexWrap: "wrap", position: "relative" }}
          >
            <div style={{ marginRight: "16px" }}>
              <Toggle
                open={state.triggerOnKeywords}
                setOpen={() => toggleOpen("triggerOnKeywords")}
                small={true}
              />
            </div>

            <div className="actions-tab-settings-title subtitle flex align-center">
              When keywords appear in chat session
            </div>
          </div>

          {state.triggerOnKeywords && (
            <div className="actions-tab-toggle-indented">
              <DomainsAllowed
                returnJustInput={true}
                customContextValues={{
                  domainsAllowed: state.keywordTriggers,
                  setDomainsAllowed: setKeywordTriggers,
                }}
                inputPlaceholder="Insert keywords here"
                keywordsInputRef={keywordsInputRef}
                breakOnSpace={false}
              />
            </div>
          )}
        </div>

        {/* <div className="action-tab-settings-divider small" /> */}

        {/* Trigger on user intent */}
        {/* <div>
          <div className="flex align-center" style={{ gap: "8px", flexWrap: "wrap" }}>
            <div style={{ marginRight: "16px" }}>
              <Toggle
                open={state.triggerOnUserIntent}
                setOpen={() => toggleOpen("triggerOnUserIntent")}
                small={true}
              />
            </div>

            <span className="actions-tab-settings-title subtitle">
              When user intent is detected
            </span>
          </div>

          {state.triggerOnUserIntent && (
            <div className="actions-tab-toggle-indented">
              <Dropdown
                items={userIntentTriggers}
                selectedItem={state.userIntentTrigger}
                setSelectedItem={(() => setValue("userIntentTrigger"))()}
                isSmall={true}
                customWidth="312px"
              />
            </div>
          )}
        </div> */}
      </section>

      <div className="action-tab-settings-divider" />

      <section className="actions-settings-section-container">
        <h4 className="actions-tab-settings-title">Where to send notification:</h4>

        {/* send notification by email */}
        <div>
          <div
            className="flex align-center"
            style={{ gap: "8px", flexWrap: "wrap", position: "relative" }}
          >
            <div style={{ marginRight: "16px" }}>
              <Toggle
                open={state.forwardSubmissionsViaEmail}
                setOpen={() => toggleOpen("forwardSubmissionsViaEmail")}
                small={true}
              />
            </div>

            <div className="actions-tab-settings-title subtitle flex align-center">
              Send notification via email
              <InfoDescription>
                Send an email notification as soon as this event occurs.
              </InfoDescription>
            </div>
          </div>

          {state.forwardSubmissionsViaEmail && (
            <div className="actions-tab-toggle-indented">
              <Dropdown
                items={state.emails}
                selectedItem={state.selectedEmail}
                setSelectedItem={(() => setValue("selectedEmail"))()}
                isSmall={true}
                customWidth="312px"
                placeholderText="Select emails..."
                linkOnBottom={{ href: "/emails", text: "Add another email address" }}
              />
            </div>
          )}
        </div>

        {/* send notification by slack */}
        {/* <div>
          <div
            className="flex align-center"
            style={{ gap: "8px", flexWrap: "wrap", position: "relative" }}
          >
            <div style={{ marginRight: "16px" }}>
              <Toggle
                open={state.sendNotificationViaSlack}
                setOpen={() => toggleOpen("sendNotificationViaSlack")}
                small={true}
              />
            </div>

            <div className="actions-tab-settings-title subtitle flex align-center">
              Send notification to a Slack channel
              <InfoDescription customDistance="30px">
                Forw ard submi ssions via emai l Fo rward submiss ions via email For ward submis
                sions via email For ward submis sions via email
              </InfoDescription>
            </div>
          </div>

          {state.sendNotificationViaSlack && (
            <div className="actions-tab-toggle-indented">
              <Dropdown
                items={state.slackChannels}
                selectedItem={state.selectedSlackChannel}
                setSelectedItem={(() => setValue("selectedSlackChannel"))()}
                isSmall={true}
                customWidth="312px"
                placeholderText="Select Slack channel..."
              />
            </div>
          )}
        </div> */}
      </section>

      <SaveAction
        action={action}
        setAction={setAction}
        keywordsInputRef={keywordsInputRef}
        settingsState={state}
        type="notification"
      />
    </>
  );
};

export default ActionsNotificationsSettings;
