import { useEffect } from "react";
import DocumentTitle from "../Tools/DocumentTitle/DocumentTitle";
import { Link } from "react-router-dom";

export default function Support() {
  useEffect(() => {
    document.title = "Support | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  return (
    <div className="page-card-container">
      <div className="page-card min-height-mid">
        <DocumentTitle>Support</DocumentTitle>

        <div
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#1E1E1E",
            fontFamily: "Work Sans",
            marginTop: "16px",
          }}
        >
          <span>
            Please send support questions to{" "}
            <a href="mailto: support@majic.ai" className="purple-link">
              support@majic.ai
            </a>
          </span>
          <br />
          <br />
          <br />

          <span>
            Serving Majic.AI customers is our top priority, so we usually
            respond pretty quickly.
          </span>
          <br />
          <br />
          <br />

          <span>
            If you prefer to get in touch with the founder, please see this{" "}
            <Link to="/contact" className="purple-link">
              contact page
            </Link>{" "}
            for additional info.
          </span>
        </div>
      </div>
    </div>
  );
}
