import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularIndeterminate({ color="#A24DFF", size="20px" }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress size={size} style={{ color }} />
    </Box>
  );
}
