import { Link, useNavigate, useParams } from "react-router-dom";
import addPurple from "../../../media/addPurple.svg";
import Dropdown from "../../../Tools/Dropdown/Dropdown";
import { useContext, useEffect, useRef, useState } from "react";
import Toggle from "../../../Toggle/Toggle";
import ActionsTableActions from "./ActionsTableActions";
import { WrappedComponentContext } from "../../../WrappedComponent";
import { API_URL } from "../../../config";
import { ErrorFormat } from "../../../Tools/Errors/ErrorFormatting";

const Actions = () => {
  const [scenario, setScenario] = useState(null);
  const [actions, setActions] = useState([]);

  const { token, logout } = useContext(WrappedComponentContext);
  const { chatbotId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getActions() {
      try {
        const endpoint = `${API_URL}/chat_bots/${chatbotId}/bot_actions`;
        const headers = { Authorization: token };

        const res = await fetch(endpoint, { headers });
        if (!res.ok) throw new ErrorFormat("", res.status, logout);

        const data = await res.json();
        setActions(data);
      } catch (error) {}
    }
    getActions();
  }, []);

  const selectScenario = (selectedScenario) => {
    setScenario(selectedScenario);
    navigate(`./scenario?type=${selectedScenario?.queryParamValue}`);
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <div className="actions-tab-standard-text">
        Create actions that get triggered inside chat sessions.
      </div>

      {/* Event notification and collect form buttons */}
      <div className="flex align-center" style={{ flexWrap: "wrap" }}>
        <Link
          to="./form"
          className="add-source"
          style={{ textDecoration: "none", margin: "5px 18px 5px 0px" }}
        >
          <img src={addPurple} alt="Collect Form" />
          <span>Collect Form</span>
        </Link>

        <Link
          to="./notification"
          className="add-source"
          style={{ textDecoration: "none", margin: "5px 0px" }}
        >
          <img src={addPurple} alt="Event Notification" />
          <span>Event Notification</span>
        </Link>
      </div>

      {/* scenario select */}
      <div
        className="flex align-center"
        style={{ width: "100%", position: "relative", marginTop: "17px" }}
      >
        <div className="actions-tab-standard-text">Or choose from a pre-made scenario:</div>
      </div>
      <Dropdown
        items={scenarios}
        selectedItem={scenario}
        setSelectedItem={selectScenario}
        placeholderText="Choose a scenario"
        customWidth="337px"
        isSmall={true}
      />

      {/* Actions table */}
      {actions?.length > 0 && (
        <table className="scrollable-table actions-tab-table" style={{ marginTop: "40px" }}>
          <tr>
            <th className="first-td" style={{ width: "281px", minWidth: "180px" }}>
              Current Actions
            </th>
            <th>Type</th>
            <th>Enabled</th>
            <th style={{ width: "100px" }} className="last-td">
              Actions
            </th>
          </tr>

          {actions.map((action, index) => (
            <ActionTableRow action={action} setActions={setActions} key={action?.id} />
          ))}
        </table>
      )}
    </div>
  );
};

const ActionTableRow = ({ action, setActions }) => {
  const [disabled, setDisabled] = useState(action?.disabled);

  const isDisablingRef = useRef(false);
  const disabledValueToReturnOnErrorRef = useRef(null);

  const { chatbotId } = useParams();
  const { token, logout, setBannerMessage } = useContext(WrappedComponentContext);

  const toggleDisabled = async (enabledValue) => {
    try {
      if (isDisablingRef.current) return;

      isDisablingRef.current = true;
      const newDisabledValue = !enabledValue;

      setDisabled((prev) => {
        disabledValueToReturnOnErrorRef.current = prev;
        return newDisabledValue;
      });

      const endpoint = `${API_URL}/chat_bots/${chatbotId}/bot_actions/${action.id}`;
      const method = "PUT";
      const headers = { Authorization: token, "Content-Type": "application/json" };
      const body = JSON.stringify({ disabled: newDisabledValue });

      const res = await fetch(endpoint, { headers, method, body });
      if (!res.ok) throw new ErrorFormat("", res.status, logout);

      setActions((prevActions) =>
        prevActions.map((currAction) =>
          currAction?.id === action?.id ? { ...currAction, disabled: newDisabledValue } : currAction
        )
      );
    } catch (error) {
      setBannerMessage({ type: "error", title: "Failed to toggle enabled on action" });
      setDisabled(disabledValueToReturnOnErrorRef.current);
    } finally {
      isDisablingRef.current = false;
      disabledValueToReturnOnErrorRef.current = null;
    }
  };

  return (
    <tr>
      <td className="first-td" style={{ width: "281px", minWidth: "180px" }}>
        {action?.title}
      </td>
      <td>
        <div className="type-pink">{getActionTypeStr(action?.type)}</div>
      </td>
      <td>
        <div className="flex justify-center">
          <Toggle small={true} open={!disabled} setOpen={toggleDisabled} />
        </div>
      </td>
      <td style={{ width: "100px" }} className="last-td">
        <ActionsTableActions action={action} setActions={setActions} />
      </td>
    </tr>
  );
};

const getActionTypeStr = (actionType) => {
  if (actionType === "FormCollectionBotAction") return "Form Collection";
  if (actionType === "EventNotificationBotAction") return "Notification";
  return actionType;
};

export const scenarios = [
  {
    title: "Offer support hand-off",
    value: "Offer support hand-off",
    queryParamValue: "offer-support-hand-off",
    defaultValues: {
      introText: "Would you like for a support agent to get back to you?",
      successText: "Thanks! We’ll review this as soon as possible.",
    },
  },
  {
    title: "Collect sales leads with form",
    value: "Collect sales leads with form",
    queryParamValue: "collect-sales",
    defaultValues: {
      introText: "Please provide your details and our team will be in touch shortly:",
      successText: "Thanks! We’ll review your information as soon as possible.",
    },
  },
  {
    title: "Collect name & email with form",
    queryParamValue: "collect-name",
    value: "Collect name & email with form",
    defaultValues: {
      introText: "To better assist you, kindly provide your name and email:",
      successText: "Thank You!",
    },
  },
];

export default Actions;
