import { API_URL } from "../../../config";

export const addSource = async ({ requestBody, type, chatbotId, token }) => {
  // Sends request to add new content item
  // Returns new content item id
  try {
    const endpoint = `${API_URL}/chat_bots/${chatbotId}/content_items`;
    const headers =
      type === "document"
        ? { Authorization: token }
        : { Authorization: token, "Content-Type": "application/json" };
    const body =
      type === "document" ? requestBody : JSON.stringify(requestBody);
    const method = "POST";

    const response = await fetch(endpoint, { method, headers, body });

    const newContentItem = await response.json();

    if (!response.ok) throw newContentItem ?? "Failed to create content item.";

    return newContentItem;
  } catch (error) {
    const baseError = error?.base ? error?.base[0] : null;
    console.log(baseError ?? error);
    return {
      title: "Failed to create content item.",
      message: baseError ? baseError : "",
      isError: true,
    };
  }
};
