import { useState } from "react";
import { InfoDescription } from "../../InfoDescription/InfoDescription";
import AdvancedDropdown from "../../../ManageChatbot/KnowledgeBase/AddSources/AdvancedDropdown/AdvancedDropdown";

const MakeSuggestedQuestion = ({
  question = "",
  customAnswer = "",
  id = undefined,
  submit = () => {},
}) => {
  const [state, setState] = useState({
    question: question,
    customAnswer: customAnswer,
  });

  const handleInputChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const canSubmit = state.question.trim();

  const handleSubmit = () => {
    if (!canSubmit) return;

    submit({
      question: state.question.trim(),
      customAnswer: state.customAnswer.trim(),
      id: id,
    });
  };

  return (
    <div
      className="flex col"
      style={{
        marginTop: "18px",
        gap: "8px",
        overflow: "hidden",
      }}
    >
      <div style={{ margin: "2px" }}>
        <input
          className="inline-input full"
          placeholder="Enter suggested starter here"
          value={state.question}
          name="question"
          onChange={handleInputChange}
          style={{ boxSizing: "border-box", height: "39px" }}
        />
      </div>

      <AdvancedDropdown
        openedHeight="auto"
        dropdownText={<span style={{ marginLeft: "2px" }}>Exact Reply</span>}
        initialOpenState={state.customAnswer}
        arrowAfterText={true}
        indented={false}
      >
        <div style={{ margin: "2px" }}>
          <div
            className="flex row align-center"
            style={{
              marginBottom: "6px",
              position: "relative",
              fontSize: "12px",
            }}
          >
            The text below will be used to reply instantly.
            <InfoDescription customDistance="30px" left="0px">
              When filled, your chatbot will bypass AI, and instantly provide
              this exact response. This consumes zero response credits. When
              left blank, the default AI behavior will be used to respond with
              knowledge based on your sources.
            </InfoDescription>
          </div>

          <textarea
            className="upload-textarea"
            value={state.customAnswer}
            name="customAnswer"
            onChange={handleInputChange}
            style={{ height: "160px" }}
            placeholder="Enter exact reply here"
          />
        </div>
      </AdvancedDropdown>

      <button
        className={`purple-button ${!canSubmit && "muted"}`}
        style={{ alignSelf: "flex-start", margin: "2px" }}
        onClick={handleSubmit}
      >
        Save
      </button>
    </div>
  );
};

export default MakeSuggestedQuestion;
