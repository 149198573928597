export function saveFileToSessionStorage(file, key) {
  const reader = new FileReader();
  reader.onload = function (event) {
    const dataURL = event.target.result;
    const base64String = dataURL.split(",")[1]; // Remove data URL prefix like: "data:image/jpeg;base64,"

    const fileName = file.name; // Get the file name from the File object

    const dataToStore = {
      base64: base64String,
      fileName: fileName,
    };
    window.sessionStorage.setItem(key, JSON.stringify(dataToStore));
  };

  reader.readAsDataURL(file);
}
