import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import { API_URL } from "../config";

export async function getTrustedEmails(token) {
  try {
    const endpoint = `${API_URL}/trusted_email_addresses`;
    const headers = { Authorization: token };

    const res = await fetch(endpoint, { headers });
    if (!res.ok) throw new ErrorFormat("", res.status);

    const trustedEmails = await res.json();
    return trustedEmails;
  } catch (error) {
    return null;
  }
}
