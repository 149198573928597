import { API_URL } from "../config";
import useQuery from "../Tools/useQuery";
import { useEffect, useState } from "react";
import StatusCard from "../Tools/StatusCard/StatusCard";
import WaitingWithText from "../Tools/ChatWaiting/WaitingWithText";

export default function ConfirmEmail() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const query = useQuery();
  const confirmationToken = query.get("confirmation_token");

  useEffect(() => {
    confirmEmail();
  }, []);

  const confirmEmail = async () => {
    try {
      if (!confirmationToken) throw "Can't confirm email";

      const response = await fetch(
        API_URL + "/users/confirmation?confirmation_token=" + confirmationToken
      );
      if (response.ok) {
        setLoading(false);
      } else {
        const data = await response.json();

        if (
          data?.email &&
          data?.email[0] === "was already confirmed, please try signing in"
        ) {
          setLoading(false);
        } else {
          throw "Failed to confirm email.";
        }
      }
    } catch (error) {
      console.error(error);
      setError({ title: "Failed to confirm email." });
      setLoading(false);
    }
  };

  return (
    <div className="status-card-page-container">
      <StatusCard
        loading={loading}
        loadingChildren={<WaitingWithText>Confirming Email</WaitingWithText>}
        success={!error}
        title={error ? "Failed to confirm email" : "Email Confirmed"}
        to="/"
        customUrlText="Continue"
      />
    </div>
  );
}
