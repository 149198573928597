export const findBestPlan = (subscriptions) => {
  try {
    subscriptions = subscriptions.filter(
      (subscription) => subscription?.status === "active"
    );

    let bestPlan = null;

    for (let i = 0; i < subscriptions?.length; i++) {
      if (!bestPlan || bestPlan?.tier === "free") bestPlan = subscriptions[i];
      else if (subscriptions[i]?.tier === "elite") return subscriptions[i];
      else if (subscriptions[i]?.tier === "business")
        bestPlan = subscriptions[i];
      else if (
        subscriptions[i]?.tier === "pro" &&
        bestPlan?.tier !== "business"
      )
        bestPlan = subscriptions[i];
    }
    return bestPlan;
  } catch (error) {
    return null;
  }
};
