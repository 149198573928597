import { useContext, useEffect, useState } from "react";
import ActionSettings from "./ActionSettings";
import { API_URL } from "../../../../config";
import { useParams } from "react-router-dom";
import { WrappedComponentContext } from "../../../../WrappedComponent";
import { ErrorFormat } from "../../../../Tools/Errors/ErrorFormatting";
import WaitingWithText from "../../../../Tools/ChatWaiting/WaitingWithText";

const EditAction = () => {
  const [action, setAction] = useState(null);

  const { chatbotId, tabItemId } = useParams();
  const { token, logout } = useContext(WrappedComponentContext);

  useEffect(() => {
    async function getAction() {
      try {
        const endpoint = `${API_URL}/chat_bots/${chatbotId}/bot_actions/${tabItemId}`;
        const headers = { Authorization: token };

        const res = await fetch(endpoint, { headers });
        if (!res.ok) throw new ErrorFormat("", res.status, logout);

        const data = await res.json();
        setAction(data);
      } catch (error) {
        console.error(error);
      }
    }
    getAction();
  }, []);

  if (!action)
    return (
      <div style={{ marginTop: "10px" }}>
        <WaitingWithText>Loading</WaitingWithText>
      </div>
    );

  return (
    <ActionSettings
      action={action}
      setAction={setAction}
      type={action?.type === "FormCollectionBotAction" ? "form" : "notification"}
    />
  );
};

export default EditAction;
