import React, { useContext } from "react";
import ChatbotFormAvatar from "../../ChatbotFormItems/ChatbotFormAvatar";
import ChatbotFormInlineToggle from "../../ChatbotFormItems/ChatbotFormInlineToggle";
import ChatbotFormWelcomeMessage from "../../ChatbotFormItems/ChatbotFormWelcomeMessage";
import ChatbotFormWelcomeBubble from "../../ChatbotFormItems/ChatbotFormWelcomeBubble";
import { SuggestedQuestionsSelect } from "../../ChatbotFormItems/ChatbotFormSelectedQuestions/SelectedQuestionsSelect";
import EditThemes from "./EditThemes/EditThemes";
import { ChatbotFormContext } from "../../ChatbotForm";
import ChatbotFormShowSources from "../../ChatbotFormItems/ChatbotFormShowSources";

const ChatbotFormLookFeelSettings = () => {
  const {
    showImageInReplies,
    setShowImageInReplies,
    setShowTimeStamps,
    showTimeStamps,
  } = useContext(ChatbotFormContext);

  return (
    <div className="chatbot-details-container">
      <ChatbotFormWelcomeMessage />
      <div className="form-item-column">
        <span className="chatbot-settings-title">Chatbot image</span>
        <br />
        <ChatbotFormAvatar />
      </div>
      <div className="standard-divider" style={{ marginTop: "20px" }} />

      <ChatbotFormInlineToggle
        open={showImageInReplies}
        setOpen={setShowImageInReplies}
        hasToggleContainer={false}
        extraClass="noborder"
      >
        Show image in replies?
      </ChatbotFormInlineToggle>
      <div className="standard-divider" style={{ marginTop: "0px" }} />

      <ChatbotFormInlineToggle
        open={showTimeStamps}
        setOpen={setShowTimeStamps}
        hasToggleContainer={false}
      >
        Show timestamps?
      </ChatbotFormInlineToggle>

      <ChatbotFormShowSources />

      <ChatbotFormWelcomeBubble />

      <SuggestedQuestionsSelect />

      <EditThemes />
    </div>
  );
};

export default ChatbotFormLookFeelSettings;
