import { useContext } from "react";
import { ChatbotFormContext } from "../ChatbotForm";

export default function ChatbotFormButtons({ page }) {
  const contextValue = useContext(ChatbotFormContext);
  const {
    setTab,
    isEdit,
    canGoToNext = () => {
      return true;
    },
    loading,
    saveChatbotForm,
    saveFunc,
  } = contextValue;

  const transparentButtonAction = () => {
    setTab(page === 1 ? "look-&-feel" : "main-settings");
  };

  const handlePrimaryButtonClick = () => {
    const canProceed = isEdit || page === 2 ? true : canGoToNext(true);
    if (!canProceed) return;

    if (isEdit) {
      saveChatbotForm(saveFunc, contextValue);
    } else {
      page === 1
        ? transparentButtonAction()
        : saveChatbotForm(saveFunc, contextValue);
    }
  };

  return (
    <div
      className="new-chatbot-button-container"
      style={
        page === 1
          ? { flexDirection: "row" }
          : { display: "inline-flex", flexDirection: "row-reverse" }
      }
    >
      <button className="purple-button" onClick={handlePrimaryButtonClick}>
        {isEdit ? "Save" : page === 1 ? "Next" : "Save"}
      </button>

      {!isEdit && page !== 1 && (
        <button
          className="white-next-button"
          onClick={transparentButtonAction}
          style={{ marginRight: "14px" }}
        >
          <>
            Previous:&nbsp;
            <span style={{ fontWeight: "600" }}>Main Settings</span>
          </>
        </button>
      )}
    </div>
  );
}
