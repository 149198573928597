export class ErrorFormat {
  constructor(errorMessage, httpStatusCode, logout) {
    this.errorMessage = errorMessage;
    this.httpStatusCode = httpStatusCode;

    if (httpStatusCode === 401) {
      /*
        Some pages upon having an error occur they will show an error page,
        but when redirecting to login we don't want to show these error pages even for a split second.
        In these cases we should use the provided logout function that should eliminate these states.
      */
      if (logout) {
        // alert("using given logout funciton");
        logout();
      } else {
        localStorage.removeItem("accessKey");
        window.location.href = "/login";
      }
    }
  }
}
