export function addEventTrigger(triggerType) {
  try {
    let triggers = localStorage.getItem("event-triggers");
    triggers = triggers ? JSON.parse(triggers) : [];

    if (triggers.includes(triggerType)) {
      return;
    }

    triggers.push(triggerType);

    localStorage.setItem("event-triggers", JSON.stringify(triggers));
  } catch (error) {
    console.error(`Failed to add ${triggerType} trigger`);
  }
}

export function removeEventTrigger(triggerType) {
  try {
    let triggers = localStorage.getItem("event-triggers");
    triggers = triggers ? JSON.parse(triggers) : [];

    if (!triggers.includes(triggerType)) {
      return;
    }

    triggers = triggers.filter((trigger) => trigger !== triggerType);

    localStorage.setItem("event-triggers", JSON.stringify(triggers));
  } catch (error) {}
}
