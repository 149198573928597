// This function saves the chatbot form data and
// calls the correct save function with the form data as a param.
export const saveChatbotForm = (saveFunc, contextValue) => {
  try {
    const {
      disabled,
      name,
      temperature,
      defaultModel,
      basePrompt,
      image,
      enablePublicChatbot,
      botAlias,
      userInfo,
      suggestedQuestions,
      domainsAllowed,
      theme,
      lightColors,
      darkColors,
      showTimeStamps,
      showImageInReplies,
      rateLimiting,
      rateLimitingDetails,
      welcomeMessage,
      displayWelcomeBubble,
      welcomeBubbleText,
      welcomeBubbleHangTime,
      limitMessage,
      removeBranding,
      showSources,
      maxSources,
      notifyFinishedSessions,
      noAnswerMessage,
    } = contextValue;

    const formData = new FormData();
    formData.append("chat_bot[user_disabled]", disabled);
    formData.append("chat_bot[name]", name);
    formData.append("chat_bot[temperature]", temperature);

    if (defaultModel?.value) {
      formData.append("chat_bot[open_ai_model]", defaultModel.value);
    }

    if (basePrompt) formData.append("chat_bot[botadmin_base_prompt]", basePrompt);

    if (image) formData.append("chat_bot[avatar]", image);

    formData.append("chat_bot[public_chat_bot_enabled]", enablePublicChatbot);
    if (botAlias) formData.append("chat_bot[alias]", botAlias);

    if (suggestedQuestions) {
      let onlyQuestions = suggestedQuestions.map((question) => {
        return `${question?.question}${
          question?.customAnswer ? `%ANS%${question?.customAnswer.replace(/\n/g, "%NL")}` : ""
        }`;
      });
      onlyQuestions = onlyQuestions.filter((question) => !["", " "].includes(question));
      const suggestedQuestionsString = onlyQuestions.join("\n");
      formData.append("chat_bot[suggested_questions]", suggestedQuestionsString);
    }

    if (domainsAllowed) formData.append("chat_bot[domains]", domainsAllowed.join(","));

    if (rateLimitingDetails?.requests)
      formData.append("chat_bot[message_rate_limit_count]", rateLimitingDetails.requests);
    if (rateLimitingDetails?.perAmountSeconds)
      formData.append(
        "chat_bot[message_rate_limit_window_seconds]",
        rateLimitingDetails.perAmountSeconds
      );

    formData.append("chat_bot[welcome_message]", welcomeMessage);
    formData.append("chat_bot[display_welcome_bubble]", displayWelcomeBubble);
    formData.append("chat_bot[welcome_bubble_text_content]", welcomeBubbleText ?? "");
    formData.append("chat_bot[welcome_bubble_hangtime_seconds]", Number(welcomeBubbleHangTime));
    formData.append("chat_bot[show_timestamps_in_responses]", showTimeStamps);
    formData.append("chat_bot[show_chatbot_avatar_in_responses]", showImageInReplies);
    formData.append("chat_bot[message_limit_reached_message]", limitMessage);
    formData.append("chat_bot[remove_branding]", removeBranding);
    formData.append("chat_bot[show_sources]", showSources);
    formData.append("chat_bot[show_sources_max]", maxSources);
    formData.append("chat_bot[notify_finished_sessions]", notifyFinishedSessions);
    formData.append("chat_bot[no_answer_response]", noAnswerMessage);

    if (theme && lightColors && darkColors) {
      const designColors = {
        theme: theme,
        light: lightColors,
        dark: darkColors,
      };
      formData.append("chat_bot[design_colors]", JSON.stringify(designColors));
    }

    saveFunc(formData);
  } catch (error) {
    console.error(error);
  }
};
