import { useContext, useState } from "react";
import { ReactComponent as DownloadAction } from "../../media/downloadAction.svg";
import debounce from "../../Tools/debounce";
import { downloadContentItemDocument } from "../KnowledgeBase/Actions/downloadContentItemDocument";
import { WrappedComponentContext } from "../../WrappedComponent";
import { InfoDescription } from "../../ChatbotForm/InfoDescription/InfoDescription";

const ChatMessageDocs = ({ chatMessage, showAvatar }) => {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <div style={{ marginTop: "4px", position: "relative" }}>
      <div onClick={() => setInfoOpen((prev) => !prev)} style={{ display: "inline-flex" }}>
        <InfoDescription customTooltipContainerStyle={{ marginLeft: showAvatar ? "4px" : "10px" }}>
          Click to view raw details for all sources used.
        </InfoDescription>
      </div>

      <div
        style={{
          display: infoOpen ? "block" : "none",

          marginTop: "0px",
          overflowWrap: "break-word",
          paddingRight: "20px",

          color: "#3d3d3d",
          fontFamily: "Work Sans",
          fontSize: "14px",
          fontWeight: "400",
        }}
      >
        <span style={{ fontWeight: "600" }}>
          Top sources found potentially related to the question:
        </span>
        <ul
          style={{
            margin: "0",
            marginTop: "8px",
            paddingLeft: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {chatMessage?.utilized_docs?.docs.map((doc, index) => (
            <ChatMessageDoc doc={doc} key={index} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const ChatMessageDoc = ({ doc }) => {
  const { token } = useContext(WrappedComponentContext);

  const isDocumentType = doc?.source_type && doc.source_type.includes("document");

  const handleDownloadClick = debounce(() => {
    if (!isDocumentType) return;

    downloadContentItemDocument(doc?.source_url, doc?.source_title, token);
  }, 300);

  return (
    <li>
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        {doc?.source_title && (
          <section>
            <b>Title:</b> <span>{doc?.source_title}</span>
            <br />
          </section>
        )}

        {doc?.source_url && (
          <section
            className="flex align-center"
            onClick={handleDownloadClick}
            style={
              isDocumentType
                ? { gap: "4px", cursor: "pointer" }
                : {
                    gap: "4px",
                    maxWidth: "100%",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }
            }
          >
            {isDocumentType ? (
              <>
                <b className="link-on-hover">Download document</b>
                <div className="action-icon-container" style={{ height: "20px", width: "20px" }}>
                  <DownloadAction
                    style={{
                      height: "15px",
                      width: "15px",
                      transform: "scale(1)",
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <b>URL:</b>
                <a href={doc?.source_url} target="__blank">
                  {doc?.source_url}
                </a>
              </>
            )}
            <br />
          </section>
        )}

        {doc?.score && (
          <section>
            <b>Score:</b> {doc?.score}
            <br />
          </section>
        )}

        <section>
          <b>Text:</b> {doc?.text}
        </section>
      </div>
    </li>
  );
};

export default ChatMessageDocs;
