import { useParams } from "react-router-dom";
import Actions from "./Actions";
import "./actions-tab.css";
import NewAction from "./ActionsSettings/NewAction";
import EditAction from "./ActionsSettings/EditAction";

const ActionsTab = () => {
  const { tabItemId } = useParams();

  let componentToRender = <Actions />;
  if (tabItemId === "form" || tabItemId === "scenario") {
    componentToRender = <NewAction type="form" />;
  } else if (tabItemId === "notification") {
    componentToRender = <NewAction type="notification" />;
  } else if (tabItemId) {
    componentToRender = <EditAction />;
  }

  return componentToRender;
};

export default ActionsTab;
