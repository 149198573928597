import { useContext, useEffect, useState } from "react";
import { API_URL } from "../config";
import ChatbotForm from "./ChatbotForm";
import { useNavigate, useParams } from "react-router-dom";
import NoMatch from "../NoMatch/NoMatch";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import { WrappedComponentContext } from "../WrappedComponent";
import { useConfirm } from "../Tools/Confirm/Confirm";

const allowedTabs = ["embed", "main", "look", "forms", "actions"];
const tabValues = {
  embed: "embed-chatbot",
  main: "main-settings",
  look: "look-&-feel",
  forms: "forms",
  actions: "actions",
};

export default function EditChatbot() {
  const navigate = useNavigate();
  const { chatbotId, tab, formId } = useParams();

  const [chatbot, setChatbot] = useState();
  const [errorFindingChatbot, setErrorFindingChatbot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState();

  const { logout, token, setBannerMessage } = useContext(
    WrappedComponentContext
  );
  const confirm = useConfirm();

  useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  useEffect(() => {
    if (!allowedTabs.includes(tab)) {
      navigate(`/chatbot/settings/${chatbotId}/main`);
    } else if (formId && tab !== "forms") {
      navigate(`/chatbot/settings/${chatbotId}/${tab}`);
    }
    fetchChatbot();

    document.title = "Chatbot Settings | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  const fetchChatbot = async () => {
    try {
      if (!token) return;

      const endpoint = API_URL + "/chat_bots/" + chatbotId;
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      const response = await fetch(endpoint, { headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to fetch chatbot.",
          response.status,
          logout
        );

      const data = await response.json();
      setChatbot(data);
    } catch (error) {
      setErrorFindingChatbot(true);
      console.error(error?.errorMessage ?? error);
    }
  };

  const validateData = (requestData) => {
    const name = requestData.get("chat_bot[name]");
    const enablePublicChatbot = requestData.get(
      "chat_bot[public_chat_bot_enabled]"
    );
    const alias = requestData.get("chat_bot[alias]");
    const model = requestData.get("chat_bot[open_ai_model]");

    if (!name) {
      setError({ item: "name", message: "Chatbot name can't be blank" });
      return false;
    } else if (enablePublicChatbot === "true" && !alias) {
      setError({
        item: "alias",
        message: "Public chatbot must have a bot alias.",
      });
      return false;
    } else if (model === "undefined") {
      setError({ item: "open_ai_model", message: "Model must not be blank" });
    }
    setError(null);
    return true;
  };

  const filterData = (requestData) => {
    const avatar = requestData.get("chat_bot[avatar]");
    if (typeof avatar === "string" && avatar.startsWith("https")) {
      requestData.delete("chat_bot[avatar]");
    }

    return requestData;
  };

  const updateChatbot = async (requestData) => {
    try {
      const dataValidated = validateData(requestData);
      if (!dataValidated) return;
      requestData = filterData(requestData);
      setLoading(true);

      const endpoint = `${API_URL}/chat_bots/${chatbot?.id}`;
      const method = "PUT";
      const body = requestData;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { method, body, headers });
      const updatedChatbot = await response.json();

      if (!response.ok)
        throw updatedChatbot
          ? updatedChatbot
          : { httpStatusCode: response.status };

      setLoading(false);
      navigate(`/chatbot/${chatbot.id}/knowledgebase`);
    } catch (err) {
      setLoading(false);

      if (err?.name) setError({ item: "name", message: err?.name[0] });
      else if (err?.alias) setError({ item: "alias", message: err?.alias[0] });
      else if (err?.base) setError({ item: "base", message: err?.base[0] });
      else if (err?.welcome_bubble_text_content)
        setError({
          item: "welcome bubble text content",
          message: `welcome bubble text ${err?.welcome_bubble_text_content[0]}`,
        });
      else if (err?.open_ai_model)
        setError({
          item: "open_ai_model",
          message: `open ai model ${err?.open_ai_model[0]}`,
        });
      else
        setError({
          title: "Failed to save chatbot.",
          message: `Server responded with ${err?.httpStatusCode}`,
        });
    }
  };

  const deleteChatbot = async () => {
    try {
      const confirmed = await confirm({
        title: "Delete chatbot",
        description: "Are you sure you want to delete this chatbot?",
      });
      if (!confirmed) return;

      setIsDeleting(true);

      const endpoint = `${API_URL}/chat_bots/${chatbot.id}`;
      const method = "DELETE";
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { method, headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Could not delete chatbot",
          response.status,
          logout
        );

      setBannerMessage({ type: "success", title: "Chatbot Deleted" });
      navigate("/chatbots");
    } catch (error) {
      console.error(error);
      setIsDeleting(false);
      setBannerMessage({ type: "error", title: "Failed to delete chatbot" });
    }
  };

  if (errorFindingChatbot) return <NoMatch />;

  return (
    <ChatbotForm
      isEdit={true}
      saveFunc={updateChatbot}
      deleteFunc={deleteChatbot}
      existingData={chatbot}
      manageChatbotLink={`/chatbot/${chatbotId}/knowledgebase`}
      defaultTab={tabValues[tab]}
      error={error}
      setError={setError}
      loading={loading}
      isDeleting={isDeleting}
    />
  );
}
