let staging = false;

if (process.env.REACT_APP_STAGING === "true" || process.env.NODE_ENV !== "production") {
  staging = true;
}

export const IS_STAGING = staging;

export const DEPLOYED_APP_URL = staging ? "https://staging.majic.ai" : "https://majic.ai";

export const API_URL = staging ? "https://staging-api.majic.ai" : "https://api.majic.ai";

export const APP_URL =
  process.env.NODE_ENV === "development" ? `http://localhost:3000` : DEPLOYED_APP_URL;
