import "./SignInWithGoogle.css";
import { FcGoogle as GoogleIcon } from "react-icons/fc";
import { useNavigate, useSearchParams } from "react-router-dom";
import StatusCard from "../../Tools/StatusCard/StatusCard";
import WaitingWithText from "../../Tools/ChatWaiting/WaitingWithText";
import { API_URL } from "../../config";
import { useContext, useEffect, useState } from "react";
import { WrappedComponentContext } from "../../WrappedComponent";
import getStripeUrl from "../getStripeUrl";
import { createNewChatbot } from "../../SubscriptionStates/Checkout/createNewChatbot";
import { addEventTrigger } from "../../Tools/EventTriggers/addOrRemoveEventTrigger";

export const SignInWithGoogleButton = ({ page = "login" }) => {
  return (
    <a
      href={`${API_URL}/users/auth/google_oauth2`}
      className="sign-in-with-google-button"
      onClick={() => sessionStorage.setItem("entranceFrom", page)}
    >
      <GoogleIcon size={25} />

      <span>Continue with Google</span>
    </a>
  );
};

export const GoogleLoginSuccess = () => {
  const [signInFailed, setSignInFailed] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const loginToken = searchParams.get("login_token");
  const isNewGoogleUser = searchParams.get("is_new_google_user");

  const navigate = useNavigate();
  const { setBannerMessage, setToken, checkForEventTriggers } = useContext(
    WrappedComponentContext
  );

  useEffect(() => login(), []);

  const login = async () => {
    try {
      const endpoint = `${API_URL}/users/google_sign_in`;
      const method = "POST";
      const body = new FormData();
      body.append("login_token", loginToken);

      const response = await fetch(endpoint, { method, body });
      if (!response.ok) throw new Error({ message: "Could not sign in" });

      const accessKey = response.headers.get("Authorization");
      if (!accessKey) throw new Error({ errorMessage: "No Access Key" });

      localStorage.setItem("accessKey", accessKey);

      const plan = window.sessionStorage.getItem("plan");
      const interval = window.sessionStorage.getItem("interval");
      window.sessionStorage.removeItem("plan");
      window.sessionStorage.removeItem("interval");

      const data = await response.json();

      if (isNewGoogleUser === "true") {
        // add gtag event trigger for registration
        addEventTrigger("registration");
      }

      if (data?.plan_selection_made) {
        // If chatbot was selected before checkout it is created here
        await createNewChatbot({ token: accessKey, checkForEventTriggers });

        setToken(accessKey);
        navigate("/chatbots");
        window.location.reload();
        return;
      }

      // If plan was selected prior to signing up we redirect them to checkout here
      if (plan) {
        const stripeURL = await getStripeUrl({
          accessKey,
          plan,
          interval,
          setBannerMessage,
          checkForEventTriggers,
        });
        window.location.replace(stripeURL ? stripeURL : "/plan");
      } else {
        window.location.replace("/plan");
      }

      // Redirect to select plan if no plan has been selected
      setToken(accessKey);
      navigate("/plan");
    } catch (error) {
      console.error(error?.errorMessage ?? error);
      setSignInFailed(true);
    }
  };

  return (
    <div className="status-card-page-container">
      <StatusCard
        loading={loginToken && !signInFailed}
        loadingChildren={
          <>
            <WaitingWithText>Logging in</WaitingWithText>
            <span>You will be redirected</span>
          </>
        }
        success={false}
        title={"Sign In Failed"}
        description={"Failed to sign in."}
        to="/login"
      />
    </div>
  );
};
