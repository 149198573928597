export default function ChatbotDemoSuggestedQuestions({
  suggestedQuestions,
  colors,
}) {
  if (!suggestedQuestions?.length > 0) return "";

  return (
    <div
      className={suggestedQuestions && "suggested-questions"}
      style={{
        margin: "18px",
        marginTop: "20px",
        marginBottom: "20px",
        border: "none",
        width: "calc(100% - 50px)",
        maxHeight: "none",
        overflowY: "visible",
      }}
    >
      <span
        style={{
          color: colors?.mainBackgroundText
            ? colors?.mainBackgroundText
            : "#5F5968",
          fontFamily: "Work Sans",
          fontSize: "14px",
        }}
      >
        Here are some suggestions:
      </span>

      {suggestedQuestions.map((suggestedQuestion, index) => (
        <ChatbotDemoSuggestedQuestion
          suggestedQuestion={suggestedQuestion?.question}
          key={suggestedQuestion?.id ?? index}
          colors={colors}
        />
      ))}
    </div>
  );
}

const ChatbotDemoSuggestedQuestion = ({ suggestedQuestion, colors }) => {
  if (!suggestedQuestion) return "";

  return (
    <button className="suggested-question chat-demo-sq">
      <style>{`
        .suggested-question.chat-demo-sq {
          color: ${colors.botText};
          background: ${colors.botBubble};
        }
        .suggested-question.chat-demo-sq:hover {
          color: ${colors?.userText};
          background: ${colors?.userBubble};
        }
      `}</style>
      <span>{suggestedQuestion}</span>
    </button>
  );
};
