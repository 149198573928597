import "./ContactUs.css";
import ariShohat from "../media/arishohat.png";
import LinkedIn from "../media/in.svg";
import Twitter from "../media/tw.png";
import Facebook from "../media/fb.png";
import chatbotLogoPurple from "../media/chatbot_logo_purple.svg";
import { Helmet } from "react-helmet";

export default function ContactUs() {
  return (
    <div className="contact-us">
      <Helmet>
        <title>Contact | Majic Chatbot</title>
        <meta property="og:title" content="Contact | Majic Chatbot" />
        <meta name="twitter:title" content="Contact | Majic Chatbot" />
        <meta name="description" content="Contact page for Majic Chatbot." />
        <meta
          property="og:description"
          content="Contact page for Majic Chatbot."
        />
        <meta
          name="twitter:description"
          content="Contact page for Majic Chatbot."
        />
        <meta property="og:url" content="https://majic.ai/contact" />
        <meta property="twitter:url" content="https://majic.ai/contact" />
      </Helmet>
      <div className="contact-us-support-container">
        <h1>Contact Us</h1>

        <div
          style={{
            width: "251px",
            height: "2px",
            backgroundColor: "#E9DDF5",
            margin: "30px 0px",
          }}
        />

        <span>
          To get in touch about opportunities or partnerships, feel free to
          reach out on LinkedIn.
        </span>
        <span style={{ margin: "30px 0px" }}>
          For support or sales questions, please email us at{" "}
          <a href="mailto: support@majic.ai">support@majic.ai</a>
        </span>
      </div>
      <div className="contact-us-people-container">
        <div className="contact-person-card">
          <div className="contact-person-img-container">
            <img
              style={{
                objectFit: "contain",
              }}
              src={chatbotLogoPurple}
              alt="Majic AI Chatbot"
            />
          </div>

          <div className="contact-us-person-info">
            <h3>Majic.AI</h3>
            <span>Denver, CO USA</span>

            <div className="flex align-center" style={{ gap: "20px" }}>
              <a
                target="__blank"
                href="https://www.linkedin.com/company/majic-ai/"
              >
                <img src={LinkedIn} alt="LinkedIn" />
              </a>
              <a target="__blank" href="https://twitter.com/majic_chatbot">
                <img src={Twitter} alt="Twitter" />
              </a>
              <a target="__blank" href="https://www.facebook.com/majic.chatbot">
                <img src={Facebook} alt="Facebook" />
              </a>
            </div>
          </div>
        </div>

        <div className="contact-person-card">
          <div className="contact-person-img-container">
            <img src={ariShohat} alt="Ari Shohat" />
          </div>

          <div className="contact-us-person-info">
            <h3>Ari Shohat</h3>
            <span>Founder</span>

            <div className="flex align-center" style={{ gap: "20px" }}>
              <a target="__blank" href="https://www.linkedin.com/in/ashohat/">
                <img src={LinkedIn} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
