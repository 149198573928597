export const defaultLightColors = {
  headerPlusStartToggle: "#A24DFF",
  headerTextPlusStartToggleIconColor: "#FFFFFF", // non-customizable

  mainBackground: "#FFFFFF",
  mainBackgroundText: "#1e1e1e",

  botBubble: "#EDEDEF",
  botText: "#1e1e1e",
  botUrlColor: "#1957d0",
  userBubble: "#A24DFF",
  userText: "#FFFFFF",

  messageBarBackground: "#F6F7F9",
  messageBarText: "#1e1e1e",
  messageBarBorder: "#E8E8E8", // non-customizable

  sendIconColor: "#A24DFF",
  welcomeCloseIconColor: "#B3ABC2", // non-customizable

  chatScrollBarBackground: "#EDEDEF", // non-customizable
  chatScrollBarThumb: "#A599BA", // non-customizable

  isDark: false,
};

export const defaultDarkColors = {
  headerPlusStartToggle: "#A24DFF",
  headerTextPlusStartToggleIconColor: "#FFFFFF", // non-customizable

  mainBackground: "#272727",
  mainBackgroundText: "#F4F4F4",

  botBubble: "#454545",
  botText: "#F4F4F4",
  botUrlColor: "#288cfa",
  userBubble: "#A24DFF",
  userText: "#FFFFFF",

  messageBarBackground: "#454545",
  messageBarText: "#F4F4F4",
  messageBarBorder: "#636363", // non-customizable

  sendIconColor: "#FFFFFF",
  welcomeCloseIconColor: "#8F8F8F", // non-customizable

  chatScrollBarBackground: "#4F4F4F", // non-customizable
  chatScrollBarThumb: "#8A8592", // non-customizable

  isDark: true,
};
