import "./Toggle.css";

export default function Toggle({
  open,
  setOpen,
  isThemeToggle = false,
  small = false,
}) {
  const toggleOpen = () => (open ? setOpen(false) : setOpen(true));

  return (
    <button
      className={`toggle ${open ? "open" : ""} ${
        isThemeToggle ? "is-theme-toggle" : ""
      } ${small ? "small" : ""}`}
      onClick={toggleOpen}
    >
      {open && !small && (
        <span
          className="buttontext-off"
          style={{ position: "absolute", left: 0, color: "white" }}
        >
          {!isThemeToggle ? "Yes" : "Light"}
        </span>
      )}
      {!open && !small && (
        <span
          className="buttontext-off"
          style={{
            position: "absolute",
            right: 0,
            color: !isThemeToggle ? "#616161" : "white",
          }}
        >
          {!isThemeToggle ? "No" : "Dark"}
        </span>
      )}

      <div
        className="buttontoggle"
        // style={
        //   open
        //     ? {
        //         transform: "translateX(50px)",
        //         backgroundColor: "white",
        //         transition: "transform .5s, background-color .5s",
        //       }
        //     : { transition: "transform .5s, background-color .5s" }
        // }
      ></div>
    </button>
  );
}
