export const getTimeSinceString = (startDateString) => {
  const startDate = new Date(startDateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName = monthNames[startDate.getMonth()];
  const dayOfMonth = startDate.getDate();

  const now = new Date();
  const difference = now - startDate;

  // Calculate years, months, days, hours, and minutes
  const millisecondsPerMinute = 1000 * 60;
  const millisecondsPerHour = millisecondsPerMinute * 60;
  const millisecondsPerDay = millisecondsPerHour * 24;
  const millisecondsPerMonth = millisecondsPerDay * 30.44; // Average month length
  const years = Math.floor(difference / (millisecondsPerDay * 365));
  const months = Math.floor(
    (difference % (millisecondsPerDay * 365)) / millisecondsPerMonth
  );
  const days = Math.floor(
    (difference % millisecondsPerMonth) / millisecondsPerDay
  );
  const hours = Math.floor(
    (difference % millisecondsPerDay) / millisecondsPerHour
  );
  const minutes = Math.floor(
    (difference % millisecondsPerHour) / millisecondsPerMinute
  );

  let timeString = "";
  if (years) timeString += years > 1 ? `${years} years ` : `${years} year `;
  if (months)
    timeString += months > 1 ? `${months} months ` : `${months} month `;
  if (days) timeString += days > 1 ? `${days} days ` : `${days} day `;
  if (hours) timeString += hours > 1 ? `${hours} hours ` : `${hours} hour `;
  if (minutes)
    timeString += minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;

  if (!timeString || timeString.trim().startsWith("-"))
    timeString = "0 minutes";

  const dayString = `${timeString} - ${monthName} ${dayOfMonth}`;
  return dayString;
};
