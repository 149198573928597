export function throttleWithoutWaitingArgs(cb, delay = 1000) {
  // // throttle function that does not rerun the cb if there are "waiting args" that are added on waiting time after the timeout ends

  let shouldWait = false;

  return (...args) => {
    if (shouldWait) return;

    cb(...args);
    shouldWait = true;

    setTimeout(() => {
      shouldWait = false;
    }, delay);
  };
}
