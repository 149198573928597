import { createContext, useContext, useEffect, useState } from "react";
import "./Pricing.css";
import guarantee from "../media/guarantee.svg";
import FrequentQuestions from "../FrequentQuestions/FrequentQuestions";
import { PricingToggle } from "./PricingToggle";
import { PricingRow } from "./PricingRow";
import { PricingPlan } from "./PricingPlan";
import NoMatch from "../NoMatch/NoMatch";
import { WrappedComponentContext } from "../WrappedComponent";
import { findBestPlan } from "../Tools/findBestPlan";
import { Helmet } from "react-helmet";
import usePricingConfig from "./pricingConfig";
import { EventTrigger } from "../Tools/EventTriggers/EventTriggers";

export const PricingContext = createContext();

export default function Pricing({ isUpgrade }) {
  const [monthly, setMonthly] = useState(true);
  const pricingConfig = usePricingConfig();

  const { token, userInfo, fetchUserInfo, setBannerMessage, setHideMenu } =
    useContext(WrappedComponentContext);

  const isNotPaid =
    (userInfo &&
      isUpgrade &&
      (!userInfo?.plan_selection_made ||
        findBestPlan(userInfo?.subscriptions)?.tier === "free")) ||
    !isUpgrade;

  useEffect(() => {
    if (!userInfo) fetchUserInfo();

    if (userInfo && isUpgrade) {
      const bestPlan = findBestPlan(userInfo?.subscriptions);
      if (bestPlan?.interval === "year") setMonthly(false);
      else setMonthly(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && !userInfo?.plan_selection_made && isUpgrade) {
      setHideMenu(true);
    }

    return () => {
      setHideMenu(false);
    };
  }, [userInfo, isUpgrade]);

  const value = {
    monthly,
    setMonthly,
    token,
    userInfo,
    isUpgrade,
    setBannerMessage,
    isNotPaid,
  };

  if (!token && isUpgrade) {
    return <NoMatch linkTo="/login" linkText="Go to Log In" />;
  }

  return (
    <PricingContext.Provider value={value}>
      {!isUpgrade && (
        <Helmet>
          <title>Pricing | Majic Chatbot</title>
          <meta property="og:title" content="Pricing | Majic Chatbot" />
          <meta name="twitter:title" content="Pricing | Majic Chatbot" />
          <meta
            name="description"
            content="Majic Chatbot Pricing page with all our available plans."
          />
          <meta
            property="og:description"
            content="Majic Chatbot Pricing page with all our available plans."
          />
          <meta
            name="twitter:description"
            content="Majic Chatbot Pricing page with all our available plans."
          />
          <meta property="og:url" content="https://majic.ai/pricing" />
          <meta property="twitter:url" content="https://majic.ai/pricing" />
        </Helmet>
      )}

      {/* event trigger for going to pricing page (not plan page) */}
      {!isUpgrade && <EventTrigger triggerType="pricingPage" />}

      <div className={`pricing `}>
        <h1 className="pricing-title">
          {isUpgrade
            ? `${
                userInfo && !userInfo?.plan_selection_made ? "Choose " : ""
              }Your Plan`
            : "Pricing Plans"}
        </h1>

        <PricingToggle />

        {isNotPaid && (
          <div
            className="flex align-center"
            style={{ gap: "11px", marginBottom: "23px" }}
          >
            <img src={guarantee} alt="Guarantee" style={{ height: "43px" }} />
            <span style={{ fontSize: "16px", fontWeight: "400" }}>
              30-Day Full Money-Back Guarantee. For any plan, any reason, no
              questions asked.
            </span>
          </div>
        )}

        <div className="pricing-table-container">
          <div className="pricing-scroll-container">
            <table className="pricing-table">
              {/* <div className="pricing-table-top" /> */}
              <tr>
                <td className="side-regular"></td>
                {pricingConfig.plans.map((plan, index) => (
                  <td key={plan?.stripePlan?.stripe_id ?? index}>
                    <PricingPlan plan={plan} />
                  </td>
                ))}
              </tr>

              {pricingConfig.sections.map((section) => {
                return (
                  <>
                    <tr>
                      <td className="side-title">{section.title}</td>
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>

                    {section.rows.map((row, index) => {
                      return <PricingRow row={row} key={index} />;
                    })}
                  </>
                );
              })}
            </table>
          </div>
        </div>

        {!isUpgrade && (
          <>
            <div className="guarantee-container">
              <img src={guarantee} alt="Guarantee" />
              <h1>30-Day Guarantee</h1>
              <span>
                100% money-back guarantee on any plan you choose. For any
                reason, no questions asked!
              </span>
            </div>

            <FrequentQuestions borderRadius="8px" />
          </>
        )}
      </div>
    </PricingContext.Provider>
  );
}
