import { useEffect, useRef, useState } from "react";
import { ReactComponent as InfoIcon } from "../media/infoFull.svg";
import usePageWidth from "../Tools/usePageWidth";

export const PricingElWithDescription = ({ item, index }) => {
  const [open, setOpen] = useState(false);

  // Position for info
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [yOffset, setYOffset] = useState(3);

  const containerRef = useRef(null);

  const width = usePageWidth();
  useEffect(() => {
    if (width <= 290) setYOffset(20);
    else if (width <= 400) setYOffset(20);
    else if (width <= 620) setYOffset(20);
    else setYOffset(3);
  }, [width]);

  // For moving the description on hover with the cursor
  const moveChild = (event) => {
    const parentRect = event.currentTarget.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

    let mouseX = event.clientX - parentRect.left;
    let mouseY = event.clientY - parentRect.top;

    // For when description is on right
    if (index === 4) {
      mouseX = event.clientX - containerRect.right;
    }

    let translateX = mouseX - 25;
    let translateY = mouseY - yOffset;

    if (translateX < 6.5 && index === 0) translateX = 6.5;

    // For when description is on right and arrow is also on the right
    if (index === 4) {
      translateX = translateX + 85;
      if (translateX > 0) translateX = 0;
    }

    setTranslate({ x: translateX, y: translateY });
  };

  return (
    <td
      className={` ${index !== 0 ? "" : "side-regular"}`}
      key={index}
      style={
        index === 0
          ? {}
          : {
              padding: "0px",
              margin: "0px",
              minHeight: "100%",
              flex: 1,
              width: "auto",
              position: "relative",
              display: "table-cell",
              verticalAlign: "middle",
            }
      }
      ref={containerRef}
    >
      <div
        className={`flex align-center ${index !== 0 ? "justify-center" : ""}`}
        onMouseOver={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onMouseMove={moveChild}
        style={{
          display: "inline-flex",
          cursor: item?.description ? "pointer" : "text",
          maxWidth: item?.description ? "calc(100% - 0px)" : "100%",
        }}
      >
        <span
          style={{
            maxWidth: item?.description ? "calc(100% - 10px)" : "100%",
            overflowWrap: "break-word",
            marginRight: "3px",
          }}
        >
          {item?.text}
        </span>

        {item?.description && <InfoIcon style={{ minWidth: "19px", minHeight: "19px" }} />}

        {item?.description && (
          <div
            className={`pricing-popup ${open ? "open" : ""} ${index === 4 ? "arrow-right" : ""}`}
            style={
              width >= 340
                ? {
                    transform: `translate(${translate.x}px, ${translate.y}px)`,
                  }
                : {
                    transform: `translate(${translate.x}px, ${translate.y}px)`,
                    minWidth: `calc(${width}px - 180px)`,
                  }
            }
          >
            {item.description}
          </div>
        )}
      </div>
    </td>
  );
};
