import { useContext, useEffect, useState } from "react";
import StatusCard from "../StatusCard/StatusCard";
import { WrappedComponentContext } from "../../WrappedComponent";
import debounce from "../debounce";
import { resendConfirmationEmail } from "./resendConfirmationEmail";

const ConfirmEmailWindow = () => {
  const {
    setBannerMessage,
    userInfo,
    emailResent,
    setEmailResent,
    token,
    setHideConfirmEmailBanner,
  } = useContext(WrappedComponentContext);
  const email = userInfo?.email;

  const [sending, setSending] = useState(false);

  // Hides the confirm email banner that pops up on top when this is showing
  useEffect(() => {
    setHideConfirmEmailBanner(true);

    return () => {
      setHideConfirmEmailBanner(false);
    };
  }, []);

  const handleActionClick = debounce(async () => {
    setSending(true);
    const responseObj = await resendConfirmationEmail({
      email,
      authToken: token,
    });
    if (responseObj?.type === "success") setEmailResent(true);
    setBannerMessage(responseObj);
    setSending(false);
  });

  const customBody = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        maxWidth: "100%",
      }}
    >
      <h3 style={{ color: "#5F5968" }}>Please verify your email</h3>

      <p
        style={{
          overflowWrap: "break-word",
          maxWidth: "100%",
          color: "#5F5968",
          fontSize: "14px",
        }}
      >
        You're almost there! We sent an email to{" "}
        <a
          className="link-on-hover"
          href={`mailto:${email}`}
          style={{ fontWeight: "600", color: "#A24DFF" }}
        >
          {email}
        </a>
      </p>

      <p style={{ color: "#5F5968", fontSize: "14px" }}>
        Please click the link in that email to complete your signup. If you
        don't see it, you may need to{" "}
        <span style={{ fontWeight: "600", color: "#5F5968" }}>
          check your spam
        </span>{" "}
        folder.
      </p>

      <p style={{ color: "#5F5968", fontSize: "14px" }}>
        Still can't find the email?
      </p>

      <button
        className={`purple-button small-text ${
          (sending || !email || emailResent) && "muted"
        }`}
        style={{ width: "auto" }}
        onClick={handleActionClick}
      >
        Resend Email
      </button>
    </div>
  );

  return (
    <div className="status-card-page-container">
      <StatusCard success={true} customBody={customBody} width="554px" />
    </div>
  );
};

export default ConfirmEmailWindow;
