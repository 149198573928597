import { useContext } from "react";
import { ViewWebsiteSourcePreviewContext } from "./ViewWebsiteSourcePreview";

export const ExcludedButtons = ({}) => {
  const { excludedHadChange, savingExcluded, saveExcluded, closeExcluded } =
    useContext(ViewWebsiteSourcePreviewContext);

  return (
    <div className="flex align-center" style={{ gap: "12px" }}>
      <button
        className={`purple-button ${
          savingExcluded || !excludedHadChange ? "muted" : ""
        }`}
        onClick={savingExcluded || !excludedHadChange ? () => {} : saveExcluded}
      >
        {savingExcluded ? "Excluding..." : "Exclude"}
      </button>

      {!savingExcluded && (
        <button className="outline-button" onClick={closeExcluded}>
          Cancel
        </button>
      )}
    </div>
  );
};
