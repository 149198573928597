import { useEffect } from "react";
import StatusCard from "../Tools/StatusCard/StatusCard";

export default function SlackFailed({}) {
  useEffect(() => {
    document.title = "Slack Failed | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  return (
    <div className="status-card-page-container">
      <StatusCard
        success={false}
        title="Failed to add chatbot to slack"
        description="Failed to add chatbot to slack"
        to="/slack-integration"
      />
    </div>
  );
}
