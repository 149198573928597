import { useContext, useEffect } from "react";
import StatusCard from "../../Tools/StatusCard/StatusCard";
import { WrappedComponentContext } from "../../WrappedComponent";
import { createNewChatbot } from "./createNewChatbot";
import { addEventTrigger } from "../../Tools/EventTriggers/addOrRemoveEventTrigger";

export const CheckoutSuccess = () => {
  const { token, setBannerMessage, checkForEventTriggers } = useContext(
    WrappedComponentContext
  );

  useEffect(() => {
    // add gtag event trigger for payment success
    addEventTrigger("paymentSuccess");
    checkForEventTriggers();

    // If chatbot was selected before checkout it is created here
    createNewChatbot({ token, setBannerMessage, checkForEventTriggers });

    document.title = "Checkout Success | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  return (
    <div className="status-card-page-container">
      <StatusCard
        success={true}
        title={"Checkout Successful!"}
        description={"Thank you for choosing Majic AI!"}
        to={"/chatbots"}
      />
    </div>
  );
};
