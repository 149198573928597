import { useContext, useState } from "react";
import { addSource } from "./addSource";
import { waitForIndexingToFinish } from "../indexing";
import { InfoDescription } from "../../../ChatbotForm/InfoDescription/InfoDescription";
import Toggle from "../../../Toggle/Toggle";
import { KnowledgeBaseContext } from "../KnowledgeBase";
import "./AddSource.css";
import AdvancedDropdown from "./AdvancedDropdown/AdvancedDropdown";
import { WrappedComponentContext } from "../../../WrappedComponent";

export const AddLinkSource = ({ subType, setModalOpen }) => {
  const [url, setUrl] = useState("");
  // const [title, setTitle] = useState("");
  const [followAnchorLinks, setFollowAnchorLinks] = useState(false);
  const [crawlUpstream, setCrawlUpstream] = useState(false);
  const [levels, setLevels] = useState(3);

  const {
    chatbotId,
    token,
    sourceType,
    webSources,
    setWebSources,
    setOpenIntervals,
  } = useContext(KnowledgeBaseContext);
  const { setBannerMessage, fetchUserInfo } = useContext(
    WrappedComponentContext
  );

  const handleUrlChange = (e) => setUrl(e.target.value);
  // const handleTitleChange = (e) => setTitle(e.target.value);
  const handleLevelsChange = (e) => setLevels(e.target.value);

  const addWebSource = async ({
    url,
    subtype,
    followAnchorLinks,
    crawlUpstream,
  }) => {
    const date = new Date();
    try {
      const exisitingUrls = webSources.map((src) => src?.url);
      if (exisitingUrls.includes(url)) {
        throw {
          title: "Failed to add source",
          message: "The url you selected is already in knowledge base",
        };
      }

      setWebSources([{ url, subtype, loading: true, date }, ...webSources]);

      setModalOpen(false);

      const requestBody = {
        content_item: {
          type:
            subtype === "Single Page" ? "single_page_url" : "crawled_website",
          // title: title,
          title: url,
          trigger_data: {
            site_url: url,
            level: subtype === "Single Page" ? 1 : Number(levels),
            follow_anchors: followAnchorLinks,
            crawl_upstream: crawlUpstream,
          },
          open_ai_model: "gpt-3.5-turbo",
          optional_auto_index: true,
        },
      };

      const newContentItem = await addSource({
        requestBody: requestBody,
        type: "web",
        chatbotId,
        token,
        exisitingUrls: webSources.map((src) => src?.url),
      });
      if (!newContentItem) throw { title: "Failed to create content item." };
      if (newContentItem?.isError) throw newContentItem;

      // Read web source to list - with id
      setWebSources((prevSources) => {
        return [
          { ...newContentItem, url, subtype, date },
          ...prevSources.filter((prevSource) => {
            return !(
              prevSource?.url === url &&
              prevSource?.subtype === subtype &&
              prevSource?.date === date
            );
          }),
        ];
      });

      waitForIndexingToFinish({
        chatbotId,
        token,
        contentItemId: newContentItem.id,
        source: { url, subtype },
        type: "web",
        setOpenIntervals,
        setSources: setWebSources,
        fetchUserInfo,
      });
    } catch (error) {
      console.error(error);

      // Cleanup failed content item
      if (error?.title) {
        setWebSources((prevSources) => {
          return [
            ...prevSources.filter((prevSource) => {
              return !(
                prevSource?.url === url &&
                prevSource?.subtype === subtype &&
                prevSource?.date === date
              );
            }),
          ];
        });

        setBannerMessage({ ...error, type: "error" });
        setModalOpen(false);
      }
    }
  };

  const addNewSource = () => {
    if (!readyToSend) return;

    addWebSource({
      url,
      subtype: subType,
      followAnchorLinks,
      crawlUpstream,
    });
  };

  const readyToSend = !!url;

  return (
    <div className="add-link-source-container">
      <span>Enter URL</span>
      <div className="add-link-source-content">
        <input
          className="inline-input full"
          value={url}
          onChange={handleUrlChange}
          style={{ maxWidth: "calc(100% - 12px)" }}
        />

        {subType !== "Single Page" && (
          <AdvancedDropdown>
            <>
              <div
                className="row-space-between row-align-center"
                style={{ position: "relative" }}
              >
                <span className="chatbot-settings-title row-align-center">
                  Follow anchor links {"(#)"}
                  <InfoDescription customDistance="40px">
                    If you have anchor links (#) which dynamically load
                    different content by your application, then select YES.
                  </InfoDescription>
                </span>
                <Toggle
                  open={followAnchorLinks}
                  setOpen={setFollowAnchorLinks}
                />
              </div>

              <div
                className="row-space-between row-align-center"
                style={{ position: "relative" }}
              >
                <span className="chatbot-settings-title row-align-center">
                  Crawl Upstream
                  <InfoDescription customDistance="40px">
                    By default the crawling is performed only downstream from
                    your URL. Turn this to YES if you’d like to allow crawling
                    upstream as well when such links are encountered (often this
                    is likely to lead to the root of your domain).
                  </InfoDescription>
                </span>
                <Toggle open={crawlUpstream} setOpen={setCrawlUpstream} />
              </div>

              <div
                className="row-space-between row-align-center"
                style={{ position: "relative" }}
              >
                <span className="chatbot-settings-title row-align-center">
                  Max levels to crawl
                  <InfoDescription customDistance="40px">
                    How far deep with subdirectories do you want the crawler to
                    go as it discovers more links in the content. If you think
                    your site is small, there is no harm setting this high. But
                    if you believe your site has a huge amount of data which you
                    may not want in your knowledgebase, then setting a lower
                    value will give you some more granular control. If you
                    enabled Crawl Upstream above, then this value also controls
                    how far the crawler will crawl upstream.
                  </InfoDescription>
                </span>
                <select
                  value={levels}
                  onChange={handleLevelsChange}
                  style={{ width: "82px" }}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div>
            </>
          </AdvancedDropdown>
        )}

        <button
          className={`purple-button ${!readyToSend && "muted"}`}
          onClick={addNewSource}
          style={{ alignSelf: "flex-start" }}
        >
          Add
        </button>
      </div>
    </div>
  );
};
