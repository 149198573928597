import React, { useEffect } from "react";
import StatusCard from "../Tools/StatusCard/StatusCard";

const SubscriptionCancelled = ({}) => {
  useEffect(() => {
    document.title = "Subscription Cancelled | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  return (
    <div className="status-card-page-container">
      <StatusCard
        success={true}
        title="Subscription Cancelled"
        description="Your subscription has been cancelled successfully."
        to="/plan"
      />
    </div>
  );
};

export default SubscriptionCancelled;
