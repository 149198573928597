import { ReactComponent as CloseIconClear } from "../../../../media/close-clear.svg";
import { ReactComponent as DocumentIcon } from "../../../../media/documentIcon.svg";

export const PdfPreview = ({ file, setPdfFiles }) => {
  const removeFile = () => {
    setPdfFiles((prevFiles) =>
      prevFiles.filter((uploadedFile) => uploadedFile !== file)
    );
  };

  return (
    <div className="uploaded-pdf">
      <div
        className="flex align-center"
        style={{
          gap: "4px",
          width: "calc(100% - 20px)",
        }}
      >
        <DocumentIcon />
        <div
          style={{
            maxWidth: "calc(100% - 30px)",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "text",
          }}
        >
          {file?.name}
        </div>
      </div>

      <CloseIconClear
        className="close-icon-clear"
        width="16px"
        height="16px"
        onClick={removeFile}
      />
    </div>
  );
};
