import React, { useEffect } from "react";
import StatusCard from "../Tools/StatusCard/StatusCard";

const SubscriptionUpdated = () => {
  useEffect(() => {
    document.title = "Subscription Updated | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  return (
    <div className="status-card-page-container">
      <StatusCard
        success={true}
        title="Subscription Updated"
        description="Your subscription has been updated successfully."
        to="/plan"
        customUrlText="Your Plan"
      />
    </div>
  );
};

export default SubscriptionUpdated;
