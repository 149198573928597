import { Link } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../../media/successIcon.svg";
import { ReactComponent as FailIcon } from "../../media/failStatus.svg";
import "./StatusCard.css";
import CircularIndeterminate from "../Loading/CircularIndeterminate";

const routesObj = {
  "/": "Back to Home",
  "/login": "Login",
  "/slack-integration": "OK",
  "/plan": "Select Plan",
  "/account": "Your Account",
  "/chatbots": "Go to Chatbots",
};

export default function StatusCard({
  success = true,
  title,
  description,
  to = "/",
  customUrl = null,
  customUrlText = "",
  customBody,
  width = "400px",

  loading = false,
  loadingChildren,
  justContentWithoutBackground = false,
  removeContactLink,

  removeIcon = false,
}) {
  // This component renders a card showing the success or failure of a process

  if (loading)
    return (
      <div className="status-card-container">
        <CircularIndeterminate />
        {loadingChildren}
      </div>
    );

  return (
    <div
      className={`status-card-container ${
        justContentWithoutBackground && "without-background"
      }`}
      style={{ width: width }}
    >
      {removeIcon ? "" : success ? <SuccessIcon /> : <FailIcon />}

      {customBody ? (
        customBody
      ) : (
        <>
          <h3>{title}</h3>

          <p>{description}</p>

          {!customUrl && (
            <Link to={to} className="button-link-container">
              <button className="purple-button small-text">
                {customUrlText && customUrlText !== ""
                  ? customUrlText
                  : routesObj[to]}
              </button>
            </Link>
          )}

          {customUrl && (
            <a href={customUrl} className="button-link-container">
              <button className="purple-button small-text">
                {customUrlText}
              </button>
            </a>
          )}
        </>
      )}

      <span>
        {!removeContactLink && (
          <>
            Need help?{" "}
            <Link to="/contact" className="link link-on-hover">
              Contact Us
            </Link>
          </>
        )}
      </span>
    </div>
  );
}
