export default function CustomChatbotIcon({
  backgroundColor,
  botColor,
  optionalClass = "",
}) {
  return (
    <svg
      className={optionalClass}
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill={backgroundColor} />
      <g clip-path="url(#clip0_221_907)">
        <path
          d="M16.7666 41.4273L20.0621 34.397H30.6515L16.7666 41.4273Z"
          fill={botColor}
        />
        <path
          d="M33.8154 15.2393H15.1851C13.2437 15.2393 11.6699 16.813 11.6699 18.7544V31.2332C11.6699 33.1746 13.2437 34.7483 15.1851 34.7483H33.8154C35.7567 34.7483 37.3305 33.1746 37.3305 31.2332V18.7544C37.3305 16.813 35.7567 15.2393 33.8154 15.2393Z"
          fill={botColor}
        />
        <path
          d="M37.2424 20.9517H11.7576C10.7869 20.9517 10 21.7386 10 22.7092V26.0486C10 27.0193 10.7869 27.8062 11.7576 27.8062H37.2424C38.2131 27.8062 39 27.0193 39 26.0486V22.7092C39 21.7386 38.2131 20.9517 37.2424 20.9517Z"
          fill={botColor}
        />
        <path
          d="M24.5001 21.5668C28.2372 21.5668 31.2667 19.7766 31.2667 17.5683C31.2667 15.36 28.2372 13.5698 24.5001 13.5698C20.7629 13.5698 17.7334 15.36 17.7334 17.5683C17.7334 19.7766 20.7629 21.5668 24.5001 21.5668Z"
          fill={botColor}
        />
        <path
          d="M31.7939 19.7212H17.2061C15.2647 19.7212 13.6909 21.295 13.6909 23.2363V26.0485C13.6909 27.9898 15.2647 29.5636 17.2061 29.5636H31.7939C33.7353 29.5636 35.3091 27.9898 35.3091 26.0485V23.2363C35.3091 21.295 33.7353 19.7212 31.7939 19.7212Z"
          fill={backgroundColor}
        />
        <path
          d="M19.1834 26.3121C20.0813 26.3121 20.8091 25.5842 20.8091 24.6863C20.8091 23.7884 20.0813 23.0605 19.1834 23.0605C18.2855 23.0605 17.5576 23.7884 17.5576 24.6863C17.5576 25.5842 18.2855 26.3121 19.1834 26.3121Z"
          fill={botColor}
        />
        <path
          d="M29.2893 26.3121C30.1872 26.3121 30.9151 25.5842 30.9151 24.6863C30.9151 23.7884 30.1872 23.0605 29.2893 23.0605C28.3915 23.0605 27.6636 23.7884 27.6636 24.6863C27.6636 25.5842 28.3915 26.3121 29.2893 26.3121Z"
          fill={botColor}
        />
        <path
          d="M24.4998 12.5152C25.4704 12.5152 26.2573 11.7283 26.2573 10.7576C26.2573 9.78689 25.4704 9 24.4998 9C23.5291 9 22.7422 9.78689 22.7422 10.7576C22.7422 11.7283 23.5291 12.5152 24.4998 12.5152Z"
          fill={botColor}
        />
        <path
          d="M25.2029 11.7241H23.709V14.5362H25.2029V11.7241Z"
          fill={botColor}
        />
        <path
          d="M21.0728 31.0576H27.9273C27.9273 32.0768 27.1011 32.9031 26.0818 32.9031H22.9182C21.899 32.9031 21.0728 32.0768 21.0728 31.0576Z"
          fill={backgroundColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_221_907">
          <rect
            width="29"
            height="32.4273"
            fill={botColor}
            transform="translate(10 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
