import "./FrequentQuestions.css";
import purpleArrow from "../media/purple-arrow.png";
import { useState } from "react";

export default function FrequentQuestions({ borderRadius = "20px" }) {
  return (
    <div className="frequent-questions-container" style={{ borderRadius }}>
      <h1 className="frequent-questions-title">Frequently Asked Questions</h1>

      <div className="frequent-questions">
        {questions.map((question, index) => (
          <FrequentQuestion question={question} key={question.question} />
        ))}
      </div>
    </div>
  );
}

const FrequentQuestion = ({ question }) => {
  const [open, setOpen] = useState();
  const toggleOpen = () => {
    console.log("running toggle");
    setOpen((prevValue) => !prevValue);
  };

  return (
    <div className="frequent-question">
      <button
        className={`frequent-question-question ${open && "active"}`}
        onClick={toggleOpen}
      >
        <img
          src={purpleArrow}
          className={open ? "active" : ""}
          alt="Open Question"
        />

        <span>{question?.question}</span>
      </button>

      <div className={`frequent-question-answer ${open ? "active" : ""}`}>
        <span dangerouslySetInnerHTML={{ __html: question.answer }}></span>
      </div>
    </div>
  );
};

const questions = [
  {
    question: "What type of content can my chatbot use?",
    answer:
      "Chatbots can incorporate multiple sources at the same time. Presently this includes being able to index from: live URLs, crawling whole websites, PDFs, DOCs, Notion pages, and custom text input. Adding additional support and integrations is our top priority, so watch as this list will expand.",
  },
  {
    question: "Will my chatbot retrain upon changes in my data sources?",
    answer: `You are able to refresh any linked source with just one button click at any time. The chatbot will re-sync and retrain based on new data. If your uploaded files got updated, you can drop and re-upload those at any time.\n\nIn addition, there is also the auto-sync feature: it allows us to check your linked sources and retrain automatically upon detecting a change. Our Pro plan allows for weekly auto-sync, and our Business and Elite plans allow for even daily auto-sync.`,
  },
  {
    question: "Do you use customer data for training?",
    answer:
      "We do not use any of your data for any kind of training. We use OpenAI language models to make Majic Chatbot operational, and like us they do not use any API data, inputs, nor outputs for training their models.",
  },
  {
    question: "Why is Majic Chatbot better to use than OpenAI's custom GPT?",
    answer: `Here are just a few reasons why Majic Chatbot is a better way to serve your chatbot users:
  - Your users don’t need to have a paid subscription at OpenAI.
  - Unlimited data sources
  - Full conversation history
  - Your data is not used for training OpenAI.
  - More language model choices.
  - Embed your chatbot on your websites
  - Customize your chatbot’s branding and colors
  - Ability to connect a bot via Slack app for your team
  - More actions, features, and integrations always being added
  - Dedicated support always here to help and assist`,
  },
  {
    question: "How do I embed a chatbot on my website?",
    answer:
      "Once your chatbot is saved, simply go to the Embed tab in its Settings and the embed code will be available to copy and paste.",
  },
  {
    question: "Can I customize my chatbot?",
    answer:
      "Yes! You can style all the colors, including light mode vs dark mode. You can use a custom avatar. You can display custom welcome messages and preset question recommendations. Paid accounts can also choose to private label the bot completely by removing “powered by Majic Chatbot” link on the bottom.",
  },
  {
    question: "Can my chatbots be private?",
    answer:
      "Yes. There are several ways to accomplish this. First, there’s a chat interface that is available only to the account admin. If you want to embed your chatbot for private use among many individuals, you can do that on any webpage that is behind a login of your own choice. Finally, there’s also an option to use the Majic Slack app that connects with your chatbot, which makes it available only to your Slack team.",
  },
  {
    question: "Can I make a chatbot without my own website?",
    answer:
      "Absolutely. We allow any chatbot to be publicly hosted on our domain. Simply turn this on during your bot creation or in your settings at any time. Paid accounts can choose a specific alias such as https://majic.ai/alias, while free accounts get a random alias code assigned.",
  },
  {
    question: "Do you offer a free trial?",
    answer: `Yes! You can create a chatbot within seconds without any payment information. Then start adding your documents and sources to it.\n\nThe free plan is very limited to only 40 response credits per month, and 20 pages of content for your knowledgebase. It is also limited to one source per type. After a couple of weeks of inactivity, the free bots are auto-deleted.\n\nFor full plan information please see our Pricing page: <a href="https://majic.ai/pricing" target="__blank" class="link">https://majic.ai/pricing</a>`,
  },
  {
    question: "Can I see the conversation history of those who use my chatbot?",
    answer:
      "Yes, you can see the history of all chat conversations between your chatbots and your users. You can search for keywords as well. This is really helpful to detect trends, support issues, or just keep the finger on the pulse of your community. The chat history also offers extra tools to look up the sources which were used in AI’s composition of the answer.",
  },
  {
    question: "What languages can my chatbot support?",
    answer:
      "We support over 70 languages. Your knowledgebase does not have to be in English. Also your customers can ask questions in their own native language. You can also set your chatbot to always reply in a specific language of your choice - this is done through adding to the chatbot’s base prompt in chatbot settings.",
  },
  {
    question: 'What is a "response credit?"',
    answer:
      "When a user asks a question through your chatbot, the chatbot will respond with an answer from your knowledgebase. This pair of question and answer is considered one response, and will use up one response credit. In other words, if your user will ask 5 questions, he or she will receive 5 replies as well, which will altogether consume 5 response credits from your plan.\n\nPlease be aware that switching your language model to be powered from ChatGPT3.5 to ChatGPT4 will consume 15x (times!) more credits. Each ChatGPT4 response is worth 15 response credits. This is due to the advanced and expensive nature of this language model - we mainly just pass on the cost to us down to our users in doing so. These are expected to drop as the technology evolves further.",
  },
  {
    question: 'What is "content base size"?',
    answer:
      "Content base size is how much information can be absorbed and trained across your chatbots in total. While each chatbot gets its own knowledgebase and they don’t crossover, the content base size refers to the total of all your chatbots for the purposes of plan limits.\n\nWe consider 2,000 characters to be 1 page. So for example: 1,000 pages is 2 million characters.\n\nThe reason we meter this is because most AI tools these days rely on metering based on tokens and pricing varies based on character count.",
  },
  {
    question:
      "Do you integrate with support ticketing systems such as Zendesk and Freshdesk?",
    answer:
      "There is no direct integration yet. However Majic Chatbot supports exports of information and data which it is able to absorb straight away. If you require a custom integration sooner, kindly email us at <a href='mailto:support@majic.ai' class='link'>support@majic.ai</a> with your request and we’ll try to take care of your needs with a solution promptly.",
  },
  {
    question:
      "My business needs a custom integration. How can we make this happen?",
    answer:
      "If you require a custom integration, kindly email us at <a href='mailto:support@majic.ai' class='link'>support@majic.ai</a> with your request and we’ll be happy to connect with you and assist further.",
  },
  {
    question: "Do you offer a Slack integration?",
    answer:
      "Yes! We have our very own Slack application which you can connect to your Slack team in mere seconds. This is available for paid accounts only.\n\nYou can select which chatbot in your account you wish to answer through the Slack app. Then you’ll be able to make commands from anywhere in Slack such as: “/majicbot answer question X for me”.\n\nYou can also set your Slack app to be only private, which means all questions and answers in Slack will only show to the people who are doing the asking. Or you can choose to make it public, in which case it will be visible to all those present in your channel where the command is performed.",
  },
  {
    question:
      "We are too busy to set up our data sources. Can you do it for us?",
    answer:
      "Yes! We’d love to help and get you setup. If you upgrade to any paid plan, please reach out to us and we will happily onboard your knowledge sources for you. Assuming you give us access to your material, we’ll collect or otherwise crawl it properly, and setup the whole chatbot in a way that you’ll be satisfied with. Please reach out at <a href='mailto:support@majic.ai' class='link'>support@majic.ai</a>\n\nThough AI tech is at the core of the service, our difference is that we have real people happy to work with you and assist you at any time.",
  },
];
