import { useContext } from "react";
import { InfoDescription } from "../InfoDescription/InfoDescription";
import ChatbotFormInlineToggle from "./ChatbotFormInlineToggle";
import { ChatbotFormContext } from "../ChatbotForm";

const ChatbotFormEnabled = () => {
  const { disabled, setDisabled } = useContext(ChatbotFormContext);

  const setEnabled = (isEnabled) => {
    setDisabled(!isEnabled);
  };

  return (
    <ChatbotFormInlineToggle open={!disabled} setOpen={setEnabled}>
      Chatbot Enabled
      <InfoDescription>
        Set to Yes to make this chatbot live for your users to use. Set this to
        No to disable the chatting functionality. If set to No, your chatbot
        users will see a message that the chatbot is currently disabled.
      </InfoDescription>
    </ChatbotFormInlineToggle>
  );
};

export default ChatbotFormEnabled;
