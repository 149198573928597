import { useContext } from "react";
import { ChatbotFormContext } from "../ChatbotForm";
import ChatbotFormLimitMessage from "./ChatbotFormLimitMessage";
import { InfoDescription } from "../InfoDescription/InfoDescription";

const ChatbotFormRateLimiting = () => {
  const { rateLimiting, setRateLimiting, rateLimitingDetails, setRateLimitingDetails } =
    useContext(ChatbotFormContext);

  const handleRequestsChange = (e) => {
    let value = Number(e.target.value);
    if (value < 1) value = 1;

    setRateLimitingDetails((prevDetails) => {
      return {
        requests: e.target.value,
        perAmountSeconds: prevDetails.perAmountSeconds,
      };
    });
  };
  const handlePerAmountSecondsChange = (e) => {
    let value = Number(e.target.value);
    if (value < 1) value = 1;

    setRateLimitingDetails((prevDetails) => {
      return {
        requests: prevDetails.requests,
        perAmountSeconds: value,
      };
    });
  };

  return (
    <div className="form-item-column" style={{ marginTop: "0px", paddingBottom: "25px" }}>
      <span className="title-20" style={{ margin: "23px 0px" }}>
        Rate limiting
      </span>

      {rateLimiting && (
        <div style={{ marginBottom: "5px", flexWrap: "wrap" }} className="row-align-center">
          <span>Allow no more than</span>&nbsp;&nbsp;
          <input
            className="inline-input tiny"
            type="number"
            value={rateLimitingDetails.requests}
            onChange={handleRequestsChange}
          />
          &nbsp;&nbsp;<span>requests per</span>&nbsp;&nbsp;
          <input
            className="inline-input tiny"
            type="number"
            value={rateLimitingDetails.perAmountSeconds}
            onChange={handlePerAmountSecondsChange}
          />
          &nbsp;&nbsp;<span>seconds</span>
          <InfoDescription>recommended values are 10 requests per 120 seconds.</InfoDescription>
        </div>
      )}

      <ChatbotFormLimitMessage />
    </div>
  );
};

export default ChatbotFormRateLimiting;
