import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as DragIcon } from "../../../media/dragIcon.svg";
import { Link, useParams } from "react-router-dom";
import { ChatbotDemo } from "../Look&Feel/ChatbotPreview/ChatbotDemo/ChatbotDemo";
import CircularIndeterminate from "../../../Tools/Loading/CircularIndeterminate";
import { useContext, useEffect } from "react";
import { ChatbotFormContext } from "../../ChatbotForm";

const FormSettings = ({ form, setForm, saveFunction, cancelLink, isSaving }) => {
  const { setBackLink, manageChatbotLink, setHideTabsMenu } = useContext(ChatbotFormContext);
  const { chatbotId } = useParams();

  useEffect(() => {
    setBackLink({ link: `/chatbot/settings/${chatbotId}/forms`, text: "<  Back to Forms" });
    setHideTabsMenu(true);

    return () => {
      setBackLink({ link: manageChatbotLink, text: "<  Back to Chatbot" });
      setHideTabsMenu(false);
    };
  }, []);

  const onDragEnd = (result) => {
    const fields = form?.form_fields_attributes ?? [];

    const { destination, source, draggableId } = result;
    // Reorder suggested questions

    if (!destination) return;

    // Dropped in same place
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    const currentItem = fields.find((obj) => obj.dragId === draggableId);

    const updatedItems = Array.from(fields);
    updatedItems.splice(source.index, 1);
    updatedItems.splice(destination.index, 0, currentItem);

    setForm((prev) => ({ ...prev, form_fields_attributes: updatedItems }));
  };

  const handleNameChange = (e) => {
    setForm((prev) => ({ ...prev, name: e.target.value }));
  };

  const canSave = form?.name;

  const saveForm = () => {
    try {
      if (!canSave) return;

      saveFunction();
    } catch (error) {}
  };

  return (
    <div className="form-settings-container">
      <section className="form-settings">
        <h1 className="title-20" style={{ marginTop: "0px" }}>
          {form?.id ? "Edit Form" : "New Form"}
        </h1>

        <div className="form-title-container" style={{ marginBottom: "32px" }}>
          <span>Form Title</span>

          <input
            placeholder="enter title here"
            className="inline-input full"
            value={form?.name ?? ""}
            onChange={handleNameChange}
          />
        </div>

        <div className="form-title-container" style={{ marginBottom: "20px" }}>
          <span>Form Fields</span>
        </div>

        <div className="form-fields-titles-container flex align-center">
          <DragIcon style={{ minWidth: "9px", visibility: "hidden" }} />

          <div style={{ minWidth: "53px", textAlign: "center" }}>Use</div>
          <div style={{ flex: 1, boxSizing: "border-box", paddingLeft: "12px" }}>Field Name</div>
          <div style={{ minWidth: "53px", textAlign: "center" }}>Required</div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="form-fields-column">
            {(providedd) => (
              <div
                className="form-fields-container"
                ref={providedd.innerRef}
                {...providedd.droppableProps}
              >
                {form.form_fields_attributes.map((field, index) => (
                  <FormField field={field} setForm={setForm} index={index} key={index} />
                ))}
                {providedd.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div className="flex align-center" style={{ gap: "24px" }}>
          <button className={`purple-button ${canSave ? "" : "muted"}`} onClick={saveForm}>
            {isSaving && <CircularIndeterminate color="white" />}
            {isSaving ? "Saving" : "Save"}
          </button>

          <Link to={`${cancelLink}`} className="text-button link-on-hover">
            Cancel
          </Link>
        </div>
      </section>

      <section className="form-preview">
        <div className="form-title-container" style={{ marginBottom: "12px" }}>
          <span style={{ fontSize: "16px", fontWeight: "500" }}>Chatbot Preview</span>
        </div>

        <div style={{ textAlign: "left" }}>
          <ChatbotDemo
            selectedTheme="light"
            isForm={true}
            formFields={form?.form_fields_attributes.filter((field) => field?.enabled)}
          />
        </div>
      </section>
    </div>
  );
};

const FormField = ({ field, setForm, index }) => {
  const toggleCheckbox = (checkboxKey) => {
    // This will toggle the given object key in the current form field
    setForm((prev) => ({
      ...prev,
      form_fields_attributes: prev.form_fields_attributes.map((currentField) =>
        currentField?.dragId === field?.dragId
          ? {
              ...currentField,
              [checkboxKey]: !currentField[checkboxKey],
            }
          : currentField
      ),
    }));
  };

  const handleFieldLabelChange = (e) => {
    // Change the field name
    setForm((prev) => ({
      ...prev,
      form_fields_attributes: prev.form_fields_attributes.map((currentField) =>
        currentField?.dragId === field?.dragId
          ? { ...currentField, label: e.target.value }
          : currentField
      ),
    }));
  };

  return (
    <Draggable draggableId={field.dragId} index={index} key={field.dragId}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`form-field ${!field?.enabled ? "faded" : ""}`}
          >
            <DragIcon style={{ minWidth: "9px" }} />

            <div className="flex justify-center" style={{ minWidth: "56px" }}>
              <div className="indexed-item-checkbox-container" style={{ gap: "2px" }}>
                <input
                  type="checkbox"
                  className={field?.enabled ? "checked" : ""}
                  checked={field?.enabled}
                  onChange={() => toggleCheckbox("enabled")}
                />
              </div>
            </div>

            <input
              className="inline-input"
              value={field?.label}
              onChange={handleFieldLabelChange}
            />

            <div className="flex justify-center" style={{ minWidth: "56px" }}>
              <div className="indexed-item-checkbox-container" style={{ gap: "2px" }}>
                <input
                  type="checkbox"
                  className={field?.is_required ? "checked" : ""}
                  checked={field?.is_required}
                  onChange={() => toggleCheckbox("is_required")}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default FormSettings;
