import { useParams } from "react-router-dom";
import CreateForm from "./CreateForm";
import ShowForms from "./ShowForms";
import "./forms.css";
import ManageForm from "./ManageForm";

const FormsTab = () => {
  const { tabItemId } = useParams();

  let componentToRender = "";
  if (tabItemId === "new-form") componentToRender = <CreateForm />;
  else if (tabItemId) componentToRender = <ManageForm />;
  else componentToRender = <ShowForms />;

  return componentToRender;
};

export default FormsTab;
