import "./ChatWaiting.css";

// Component for the jumping ... dots for when chatbot is waiting for answer
export default function ChatWaiting({ customHeight, customColor }) {
  let style = {};

  if (customHeight)
    style = { ...style, height: customHeight, width: customHeight };

  if (customColor) style = { ...style, backgroundColor: customColor };

  return (
    <div className="chat-waiting-container">
      <div className="dot" style={style}></div>
      <div className="dot" style={style}></div>
      <div className="dot" style={style}></div>
    </div>
  );
}
