import { PricingElWithDescription } from "./PricingElWithDescription";
import yesIcon from "../media/pricingCheck.svg";

export const PricingRow = ({ row }) => {
  return (
    <tr>
      {row.map((item, index) => {
        return item?.text ? (
          <PricingElWithDescription item={item} index={index} key={index} />
        ) : (
          <td key={index}>
            {/* determines if content should be yes or no picture or just text */}
            {typeof item == "boolean" ? (
              <div className="pricing-icon-container">
                {item ? <img src={yesIcon} alt="Check" /> : <span>-</span>}
              </div>
            ) : (
              item
            )}
          </td>
        );
      })}
    </tr>
  );
};
