import StatusCard from "../../Tools/StatusCard/StatusCard";

export const ResetPasswordLinkSent = () => {
  return (
    <div className="status-card-page-container">
      <StatusCard
        success={true}
        title="Email Sent"
        description="Check your email for a link to reset your password."
        to="/"
      />
    </div>
  );
};
