import React, { useContext, useEffect } from "react";
import ChatbotFormTitle from "../ChatbotFormItems/ChatbotFormTitle";
// import ChatbotFormTemperature from "../ChatbotFormItems/ChatbotFormTemperature";
import ChatbotFormEnablePublicChatbot from "../ChatbotFormItems/ChatbotFormEnablePublicChatbot";
import ChatbotFormButtons from "../ChatbotFormItems/ChatbotFormButtons";
import DeleteChatbotButton from "../ChatbotFormItems/DeleteChatbotButton";
import { ChatbotFormContext } from "../ChatbotForm";
import ChatbotFormModelSelection from "../ChatbotFormItems/ChatbotFormModelSelection";
import ChatbotFormRateLimiting from "../ChatbotFormItems/ChatbotFormRateLimiting";
import ChatbotFormBasePrompt from "../ChatbotFormItems/ChatbotFormBasePrompt";
import DomainsAllowed from "../ChatbotFormItems/DomainsAllowed/DomainsAllowed";
import ChatbotFormEnabled from "../ChatbotFormItems/ChatbotFormEnabled";
import ChatbotFormRemoveMajicBranding from "../ChatbotFormItems/ChatbotFormRemoveMajicBranding";
import ChatbotFormNotifyFinishedSessions from "../ChatbotFormItems/ChatbotFormNotifyFinishedSessions";
import ChatbotFormNoAnswerMessage from "../ChatbotFormItems/ChatbotFormNoAnswerMessage";

const MainSettingsTab = () => {
  const { deleteFunc, isDeleting } = useContext(ChatbotFormContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ChatbotFormEnabled />

      <ChatbotFormTitle />

      {/* setting temperature disabled for now */}
      {/* <ChatbotFormTemperature /> */}

      <ChatbotFormModelSelection />

      <ChatbotFormBasePrompt />

      <ChatbotFormNoAnswerMessage />

      <ChatbotFormRemoveMajicBranding />

      <ChatbotFormNotifyFinishedSessions />

      <DomainsAllowed />

      <ChatbotFormEnablePublicChatbot />

      <ChatbotFormRateLimiting />

      <ChatbotFormButtons page={1} />

      {deleteFunc && <DeleteChatbotButton deleteFunc={deleteFunc} loading={isDeleting} />}
    </div>
  );
};

export default MainSettingsTab;
