import { useContext } from "react";
import { ChatbotFormContext } from "../ChatbotForm";
import { InfoDescription } from "../InfoDescription/InfoDescription";

const ChatbotFormNoAnswerMessage = () => {
  const { noAnswerMessage, setNoAnswerMessage } = useContext(ChatbotFormContext);

  const handleNoAnswerMessageChange = (e) => setNoAnswerMessage(e.target.value);

  return (
    <div className="form-item-column">
      <div className="chatbot-settings-title row-align-center">
        Message displayed when no answer is found:
        <InfoDescription customDistance="30px">
          Sometimes your sources will not have enough information to a specific question. Enter here
          the reply you wish your users to receive when this happens. Some prefer to add information
          on how to contact support by email in this line.
        </InfoDescription>
      </div>
      <br />

      <div className="items-input-items-container">
        <input
          className="items-input"
          value={noAnswerMessage}
          onChange={handleNoAnswerMessageChange}
        />
      </div>
      <br />
    </div>
  );
};

export default ChatbotFormNoAnswerMessage;
