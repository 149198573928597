import { useContext, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../../media/close.svg";
import CustomChatbotIcon from "../../../../CustomIcons/CustomChatbotIcon";
import "./ChatbotPreview.css";
import { ChatbotFormContext } from "../../../ChatbotForm";

export const WelcomeBubble = ({ selectedTheme }) => {
  const {
    theme,
    lightColors,
    darkColors,
    image,
    getImage,
    welcomeBubbleText,
    displayWelcomeBubble,
  } = useContext(ChatbotFormContext);

  const [colors, setColors] = useState(
    selectedTheme === "dark" ? darkColors : lightColors
  );

  useEffect(() => {
    selectedTheme === "dark" ? setColors(darkColors) : setColors(lightColors);
  }, [selectedTheme]);

  useEffect(() => {
    if (theme === "light" || (theme === "system" && selectedTheme === "light"))
      setColors(lightColors);
  }, [lightColors]);

  useEffect(() => {
    if (theme === "dark" || (theme === "system" && selectedTheme === "dark"))
      setColors(darkColors);
  }, [darkColors]);

  if (!displayWelcomeBubble) return "";

  return (
    <div>
      <span className="chatbot-settings-title">Welcome Bubble Preview</span>

      <div>
        <div
          className="welcome-bubble-preview"
          style={{ backgroundColor: colors?.mainBackground }}
        >
          <CloseIcon
            className="close-icon"
            stroke={colors?.mainBackgroundText}
          />

          {image ? (
            <img className="demo-img" src={getImage()} alt="Chatbot Logo" />
          ) : (
            <CustomChatbotIcon
              optionalClass="demo-img"
              backgroundColor="#A24DFF"
              botColor="white"
            />
          )}

          <p style={{ color: colors?.mainBackgroundText }}>
            {welcomeBubbleText}
          </p>
        </div>
      </div>
    </div>
  );
};
