import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../media/chevron-arrow.svg';
import './SelectSlackBot.css';
import { useEffect, useRef, useState } from 'react';

export default function SelectSlackBot({ chatbots, currentSlackBot, setSelectedChatbot }) {
  return (
    <div className="select-slack-bot-container">
      <span>Select a chatbot for slack</span>

      { chatbots?.length ? (
        <CustomDropdown
          items={chatbots}
          currentItem={currentSlackBot}
          selector='id'
          textSelector='name'
          setNewItem={setSelectedChatbot}
        />
      ) : (
        <div>
          <span>No Chatbots</span>
          <br/>
          <Link to="/new-chatbot"><button style={{marginTop: "10px"}} className='purple-button'>Create Chatbot</button></Link>
        </div>
      ) }
    </div>
  );
}

const CustomDropdown = ({ items, currentItem, selector="id", textSelector="name", setNewItem=()=>{} }) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <button
        className={`custom-dropdown-item dropdown-button ${open ? "open" : ""}`}
        onClick={() => setOpen(prev => !prev)}
      >
        <span>{(currentItem && currentItem[textSelector]) ? currentItem[textSelector] : "Select Item"}</span>

        <Arrow className="arrow"/>
      </button>

      <div className={`custom-dropdown-items-container ${open ? "open": ""}`}>
        { items.map((item, index) => (
          <CustomDropdownItem
            item={item}
            textSelector={textSelector}
            selector={selector}
            isLast={index === items.length - 1}
            isSelected={item && currentItem && item[selector] === currentItem[selector]}
            setNewItem={setNewItem}
            setOpen={setOpen}
            key={item[selector] ?? index}
          />
        )) }
      </div>
    </div>
  );
}

const CustomDropdownItem = ({ item, textSelector, selector, isLast=false, isSelected=false, setNewItem, setOpen }) => {

  return (
    <button
      className={`custom-dropdown-item ${isLast ? "last" : ""} ${isSelected ? "selected": ""}`}
      onClick={() => {
        setNewItem(item);
        setOpen(false);
      }}
    >
      <span>{item[textSelector]}</span>
    </button>
  );
}
