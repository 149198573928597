import DocumentTitle from "../Tools/DocumentTitle/DocumentTitle";
import AddConfirmedEmailButton from "./AddConfirmedEmailButton";
import { useContext, useEffect, useState } from "react";
import ConfirmedEmailRow from "./ConfirmedEmailRow";
import { WrappedComponentContext } from "../WrappedComponent";
import { useNavigate } from "react-router-dom";
import WaitingWithText from "../Tools/ChatWaiting/WaitingWithText";
import { getTrustedEmails } from "./getTrustedEmails";

const ConfirmedEmails = () => {
  const [emailList, setEmailList] = useState(null);

  const { token } = useContext(WrappedComponentContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) navigate("/login");

    if (token) {
      // Get trusted emails
      getTrustedEmails(token).then((trustedEmails) => setEmailList(trustedEmails));
    }
  }, []);

  // This is for changing the width of all kb type elements to match the longest one shown on screen
  useEffect(() => {
    if (emailList?.length > 0) {
      const pinkElements = document.getElementsByClassName("type-pink");
      let maxLength = 0;

      // Find the maximum width among the elements
      for (let i = 0; i < pinkElements.length; i++) {
        if (pinkElements[i].innerText.length > maxLength) {
          maxLength = pinkElements[i].innerText.length;
        }
      }

      const charLength = 4.925;
      const maxWidth = 70 + charLength * maxLength;
      const typeElements = document.getElementsByClassName("kb-table-item type");
      // Set the maximum width to all the elements
      for (let i = 0; i < typeElements.length; i++) {
        typeElements[i].style["min-width"] = maxWidth + "px";
        typeElements[i].style["max-width"] = maxWidth + "px";
      }
    }
  }, [emailList]);

  if (!token) return;

  return (
    <div className="page-card-container">
      <div className="page-card min-height-mid">
        <DocumentTitle>Confirmed Emails</DocumentTitle>

        <div
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: "#1E1E1E",
            fontFamily: "Work Sans",
            marginTop: "16px",
          }}
        >
          <span>
            To use emails in notifications, please add them to this list to have them confirmed.
          </span>
          <br />
          <br />
        </div>

        <div className="sources" style={{ marginBottom: "12px" }}>
          <AddConfirmedEmailButton setEmailList={setEmailList} />
        </div>

        {emailList && (
          <div style={{ width: "535px", maxWidth: "100%" }}>
            <table className="scrollable-table knowledge-base-table">
              <tr className="kb-tr">
                <th className="kb-side-table-item first-td" style={{ padding: "9px" }}>
                  Email
                </th>

                <th className="kb-table-item type" style={{ minWidth: "100px" }}>
                  Status
                </th>
                <th className="kb-table-item actions small">Actions</th>
              </tr>

              {emailList?.length > 0 &&
                emailList.map((emailObj, index) => (
                  <ConfirmedEmailRow emailObj={emailObj} setEmailList={setEmailList} key={index} />
                ))}
            </table>
          </div>
        )}
        {!emailList && <WaitingWithText>Loading Emails</WaitingWithText>}
      </div>
    </div>
  );
};

export default ConfirmedEmails;
