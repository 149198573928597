import React, { useContext } from "react";
import { ChatbotFormContext } from "../ChatbotForm";

const ChatbotFormLimitMessage = () => {
  const { limitMessage, setLimitMessage } = useContext(ChatbotFormContext);

  const handleChange = (e) => {
    let value = e.target.value;
    if (!value) value = "Too many requests in a row. Please try again later.";
    setLimitMessage(value);
  };

  return (
    <div className="form-item-column">
      <span className="chatbot-settings-title row-align-center">
        Message displayed when limit reached:
      </span>

      <input className="inline-input fullwidth" value={limitMessage} onChange={handleChange} />
    </div>
  );
};

export default ChatbotFormLimitMessage;
