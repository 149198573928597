import { useEffect } from "react";
import ChatbotFormButtons from "../../ChatbotFormItems/ChatbotFormButtons";
import ChatbotFormLookFeelSettings from "./ChatbotFormLookFeelSettings";
import { ChatbotPreview } from "./ChatbotPreview/ChatbotPreview";

const LookFeelTab = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="row-space-between look-and-feel-container">
        <ChatbotFormLookFeelSettings />

        <ChatbotPreview />
      </div>

      <ChatbotFormButtons page={2} />
    </div>
  );
};

export default LookFeelTab;
