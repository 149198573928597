import ConfirmedEmailActions from "./ConfirmedEmailActions";

const ConfirmedEmailRow = ({ emailObj, setEmailList }) => {
  return (
    <tr className="tr-style kb-tr">
      <td
        className="kb-side-table-item first-td"
        style={{ padding: "12px 9px", wordBreak: "break-word" }}
      >
        {emailObj?.email}
      </td>

      <td className={`kb-table-item type`}>
        <div className="type-pink">{emailObj?.verified ? "Confirmed" : "Confirmation Sent"}</div>
      </td>

      <td className="kb-table-item actions small">
        <ConfirmedEmailActions emailObj={emailObj} setEmailList={setEmailList} />
      </td>
    </tr>
  );
};

export default ConfirmedEmailRow;
