import Toggle from "../../../../Toggle/Toggle";

const ActionTitleSettings = ({ type, scenarioType = "", action, setAction }) => {
  const handleTitleChange = (e) => {
    setAction((prev) => ({ ...prev, title: e.target.value }));
  };

  const setDisabled = (enabledValue) => {
    setAction((prevAction) => ({ ...prevAction, disabled: !enabledValue }));
  };

  return (
    <div>
      <h3 className="actions-tab-settings-title">
        {action?.id ? "" : "New "}
        {type === "notification"
          ? "Action: Event Notification"
          : scenarioType
          ? `Scenerio: ${scenarioType}`
          : "Action: Collect Form"}
      </h3>

      <h4 className="actions-tab-settings-title muted-subtitle">
        {type === "notification"
          ? "Trigger a notification to be sent upon specific events in a chat session."
          : scenarioType
          ? "Present a form to allow users to be contacted by your support agents."
          : "Trigger a form to be shown during chat sessions, prompting your users to submit it."}
      </h4>

      <div
        className="flex align-center"
        style={{ gap: "10px", flexWrap: "wrap", marginTop: "28px" }}
      >
        <h4 className="actions-tab-settings-title subtitle" style={{ marginRight: "30px" }}>
          Action Title:
        </h4>

        <input
          value={action?.title ?? ""}
          onChange={handleTitleChange}
          className="inline-input"
          style={{ width: "334px" }}
        />
      </div>

      {/* Toggle action disabled */}
      <div
        className="flex align-center"
        style={{ gap: "8px", flexWrap: "wrap", marginTop: "28px" }}
      >
        <div style={{ marginRight: "16px" }}>
          <Toggle open={!action?.disabled} setOpen={setDisabled} small={true} />
        </div>

        <span className="actions-tab-settings-title subtitle">Action Enabled</span>
      </div>
    </div>
  );
};

export default ActionTitleSettings;
