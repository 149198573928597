import CircularIndeterminate from "../../../Tools/Loading/CircularIndeterminate";
import CircularProgressWithLabel from "../../../Tools/Loading/CircularProgressWithLabel";
import { ReactComponent as CircleCheck } from "../../../media/circleCheck.svg";
import { BiErrorCircle as ErrorIcon } from "react-icons/bi";
import { ReactComponent as Trash } from "../../../media/trash.svg";

const StatusIcon = ({ source, isDelete }) => {
  const RenderCorrectIcon = () => {
    if (isDelete) return <Trash style={{ width: "20px", height: "20px" }} />;
    else if (
      [
        "indexing_error",
        "gathering_data_error",
        "no_content_to_index",
        "plan_character_limit_exceeded",
      ].includes(source?.state)
    ) {
      // For all errors
      return <ErrorIcon size={20} color="#A24DFF" />;
    } else if (["gathering_data", "created"].includes(source?.state)) {
      // State of gathering data
      return <CircularIndeterminate size="20px" />;
    } else if (source?.state === "validating_character_count") {
      // First half of percentage loading
      return (
        <CircularProgressWithLabel
          value={(source?.processing_progress_value / 2) | 0}
          size="20px"
        />
      );
    } else if (source?.state === "indexing") {
      // Second half of percentage loading
      return (
        <CircularProgressWithLabel
          value={
            50 +
            (Number(source?.processing_progress_value)
              ? Number(source?.processing_progress_value) / 2
              : 0)
          }
          size="20px"
        />
      );
    } else if (source?.state === "indexed") {
      // For when indexed - state = "indexed"
      return <CircleCheck />;
    } else {
      return <CircularIndeterminate size="20px" />;
    }
  };

  return (
    <div className="status-icon-container">
      <RenderCorrectIcon />
    </div>
  );
};

export default StatusIcon;
