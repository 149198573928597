import { formatSuggestedQuestionsString } from "../ManageChatbot/Chat/SuggestedQuestions/formatSuggestedQuestionsString";
import {
  defaultLightColors,
  defaultDarkColors,
} from "./ChatbotFormTabs/Look&Feel/EditThemes/defaultColors";

export const showExistingChatbotData = (existingData, contextValue) => {
  try {
    const {
      setDisabled,
      name,
      setName,
      temperature,
      setTemperature,
      setDefaultModel,
      setBasePrompt,
      botAlias,
      setBotAlias,
      setEnablePublicChatbot,
      setIsExistingImage,
      setImage,
      setSuggestedQuestions,
      setDomainsAllowed,
      setTheme,
      setLightColors,
      setDarkColors,
      setShowTimeStamps,
      setShowImageInReplies,
      setRateLimitingDetails,
      setWelcomeMessage,
      setDisplayWelcomeBubble,
      setWelcomeBubbleText,
      setWelcomeBubbleHangTime,
      setLimitMessage,
      setRemoveBranding,
      setShowSources,
      setMaxSources,
      setNotifyFinishedSessions,
      setNoAnswerMessage,
    } = contextValue;

    if (existingData) {
      if (existingData?.user_disabled) setDisabled(existingData?.user_disabled);
      if (!name && existingData?.name) setName(existingData?.name);
      if (!temperature && existingData?.temperature) setTemperature(existingData?.temperature);

      if (existingData?.open_ai_model)
        setDefaultModel({
          value: existingData?.open_ai_model,
          title: existingData?.open_ai_model,
        });

      if (existingData?.botadmin_base_prompt) setBasePrompt(existingData?.botadmin_base_prompt);

      if (!botAlias && existingData?.alias) setBotAlias(existingData?.alias);
      if (existingData?.public_chat_bot_enabled)
        setEnablePublicChatbot(existingData?.public_chat_bot_enabled);

      if (existingData?.avatar_url) {
        setIsExistingImage(true);
        setImage(existingData?.avatar_url);
      }

      if (existingData?.remove_branding) {
        setRemoveBranding(existingData.remove_branding);
      }

      if (existingData?.suggested_questions) {
        const existingSuggestedQuestions = formatSuggestedQuestionsString(
          existingData?.suggested_questions
        );
        setSuggestedQuestions(existingSuggestedQuestions);
      }

      if (existingData?.domains) setDomainsAllowed(existingData.domains.split(","));

      if (
        existingData?.message_rate_limit_count &&
        existingData?.message_rate_limit_window_seconds
      ) {
        setRateLimitingDetails({
          requests: existingData.message_rate_limit_count,
          perAmountSeconds: existingData.message_rate_limit_window_seconds,
        });
      }

      setShowTimeStamps(existingData?.show_timestamps_in_responses);
      setShowImageInReplies(existingData?.show_chatbot_avatar_in_responses);
      if (existingData?.welcome_message || existingData?.welcome_message === "")
        setWelcomeMessage(existingData?.welcome_message);
      setDisplayWelcomeBubble(existingData?.display_welcome_bubble);
      if (existingData?.welcome_bubble_text_content)
        setWelcomeBubbleText(existingData?.welcome_bubble_text_content);
      setWelcomeBubbleHangTime(existingData?.welcome_bubble_hangtime_seconds);
      if (existingData?.message_limit_reached_message)
        setLimitMessage(existingData.message_limit_reached_message);

      if (["system", "light", "dark"].includes(existingData?.design_colors?.theme))
        setTheme(existingData.design_colors.theme);
      if (existingData?.design_colors?.light) {
        setLightColors({
          ...defaultLightColors,
          ...existingData.design_colors.light,
        });
      }
      if (existingData?.design_colors?.dark) {
        setDarkColors({
          ...defaultDarkColors,
          ...existingData.design_colors.dark,
        });
      }
      setShowSources(existingData?.show_sources);
      setMaxSources(existingData?.show_sources_max);
      setNotifyFinishedSessions(existingData?.notify_finished_sessions);
      if (existingData?.no_answer_response) setNoAnswerMessage(existingData.no_answer_response);
    }
  } catch (error) {
    console.error(error);
  }
};
