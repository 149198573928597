import { Link } from "react-router-dom";
import purpleChatbot from "../media/chatbotPurple.svg";
import DisabledIndication from "../Tools/DisabledIndication/DisabledIndication";

export const ChatbotPreview = ({ chat, index }) => {
  const disabled = chat["disabled?"];

  return (
    <div className="chatbot-preview-container">
      <span
        className="title-20"
        style={{
          textAlign: "center",
          overflow: "hidden",
          overflowWrap: "break-word",
          width: "calc(100% - 30px)",
        }}
      >
        {chat?.name}
      </span>

      {chat?.avatar_url ? (
        <img src={chat?.avatar_url} className="chatbot-img" alt="Chatbot Img" />
      ) : (
        <img src={purpleChatbot} className="chatbot-img" alt="Chatbot Img" />
      )}

      <Link
        to={`/chatbot/${chat.id}/knowledgebase`}
        className="chatbot-manage-button"
      >
        Manage
      </Link>

      {disabled && (
        <div className="chatbot-preview-disabled">
          <DisabledIndication />
        </div>
      )}
    </div>
  );
};
