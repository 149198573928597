import Toggle from "../../Toggle/Toggle";
import { useContext, useEffect } from "react";
import { ChatbotFormContext } from "../ChatbotForm";
import { API_URL, APP_URL } from "../../config";
import { InfoDescription } from "../InfoDescription/InfoDescription";
import { WrappedComponentContext } from "../../WrappedComponent";

export default function ChatbotFormEnablePublicChatbot() {
  const {
    error,
    enablePublicChatbot,
    setEnablePublicChatbot,
    botAlias,
    setBotAlias,
  } = useContext(ChatbotFormContext);

  const { userInfo } = useContext(WrappedComponentContext);

  useEffect(() => {
    if (userInfo && enablePublicChatbot && !botAlias) {
      if (isFreePlan()) generateFreeAlias();
    }
  }, [userInfo, enablePublicChatbot]);

  const isFreePlan = () => {
    return userInfo?.stripe_subscriptions?.length === 0;
  };

  const generateFreeAlias = async () => {
    let found = false;
    while (!found) {
      const randomAlias = generateRandomAlias();
      const endpoint = `${API_URL}/chat_bots/public/alias/${randomAlias}`;
      const response = await fetch(endpoint);
      const data = await response.json();
      // If alias does not exist we will use it
      if (data?.error) {
        found = true;
        setBotAlias(randomAlias);
      }
    }
  };

  const handleBotAliasChange = (e) => {
    if (isFreePlan()) return;
    const cleanedString = encodeURIComponent(e.target.value);
    setBotAlias(cleanedString);
  };

  return enablePublicChatbot ? (
    <div className="form-item-column" id="chatbot-alias" style={{ zIndex: 6 }}>
      <span className="chatbot-settings-title row-align-center">
        Host this chatbot with a public majic.ai alias?
        <InfoDescription customDistance="30px">
          Enable this if you wish us to host a public version of this bot on
          https://majic.ai/alias. This is in addition to hosting the chatbot on
          your own websites. Paid customers can choose their own alias if it is
          still available.
        </InfoDescription>
      </span>

      <div className="form-item-row" style={{ borderBottom: "none" }}>
        <div
          className="bot-alias-input-container flex row align-center"
          style={{ gap: "10px", flexWrap: "wrap" }}
        >
          <span>https://majic.ai/</span>
          <div className="flex col">
            <input
              className={`inline-input small bot-alias ${
                error?.item === "alias" ? "error" : ""
              }`}
              placeholder="choose bot alias"
              value={botAlias}
              onChange={handleBotAliasChange}
            />
            {error?.item === "alias" && (
              <label className="inline-input-error" for="chatbot-alias">
                {error?.message}
              </label>
            )}
          </div>
        </div>

        <div className="chatbot-settings-toggle-container">
          <Toggle open={enablePublicChatbot} setOpen={setEnablePublicChatbot} />
        </div>
      </div>

      {botAlias && (
        <>
          <span style={{ overflowWrap: "break-word" }}>
            URL preview:{" "}
            <a
              target="_blank"
              href={`${APP_URL}/${botAlias}`}
              rel="noreferrer"
              className="link"
            >
              {APP_URL}/{botAlias}
            </a>
          </span>
          <br />
        </>
      )}
    </div>
  ) : (
    <div className="form-item-row" style={{ zIndex: 6 }}>
      <span className="chatbot-settings-title row-align-center">
        Host this chatbot with a public majic.ai alias?
        <InfoDescription customDistance="55px">
          Enable this if you wish us to host a public version of this bot on
          https://majic.ai/alias. This is in addition to hosting the chatbot on
          your own websites. Paid customers can choose their own alias if it is
          still available.
        </InfoDescription>
      </span>

      <div className="chatbot-settings-toggle-container">
        <Toggle open={enablePublicChatbot} setOpen={setEnablePublicChatbot} />
      </div>
    </div>
  );
}

function generateRandomAlias() {
  const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
  const digits = "0123456789";

  // Generate 6 lowercase letters
  const lettersPart = Array.from({ length: 6 }, () =>
    lowercaseLetters.charAt(Math.floor(Math.random() * lowercaseLetters.length))
  ).join("");

  // Generate 2 digits
  const digitsPart = Array.from({ length: 2 }, () =>
    digits.charAt(Math.floor(Math.random() * digits.length))
  ).join("");

  // Combine the two parts and shuffle them
  const combinedString = lettersPart + digitsPart;
  const finalString = combinedString
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");

  return finalString;
}
