import { useEffect } from "react";
import StatusCard from "../Tools/StatusCard/StatusCard";

const SubscriptionSuccessPage = ({ title, description, to, urlText }) => {
  useEffect(() => {
    document.title = `${title} | Majic AI`;
    return () => (document.title = "Majic AI");
  }, []);

  return (
    <div className="status-card-page-container">
      <StatusCard
        success={true}
        title={title}
        description={description}
        to={to}
        customUrlText={urlText}
      />
    </div>
  );
};

export default SubscriptionSuccessPage;
