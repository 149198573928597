import "./SuggestedQuestions.css";

export default function SuggestedQuestions({
  askQuestion,
  colors,
  hasAskedQuestion,
  suggestedQuestions,
  clickedSuggestedQuestions = [],
  setClickedSuggestedQuestions = () => {},
}) {
  const filteredSuggestedQuestions = suggestedQuestions.filter(
    (suggestedQuestion) =>
      !clickedSuggestedQuestions.includes(suggestedQuestion?.question)
  );

  if (!filteredSuggestedQuestions || filteredSuggestedQuestions?.length === 0)
    return "";

  return (
    <div
      className={suggestedQuestions && "suggested-questions"}
      style={
        hasAskedQuestion
          ? {
              marginTop: "0px",
              marginBottom: "20px",
            }
          : {}
      }
    >
      <span
        style={{
          color: colors?.mainBackgroundText
            ? colors?.mainBackgroundText
            : "#5F5968",
          fontFamily: "Work Sans",
          fontSize: "14px",
        }}
      >
        Here are some suggestions:
      </span>

      {filteredSuggestedQuestions.map((suggestedQuestion, index) => (
        <SuggestedQuestion
          suggestedQuestion={suggestedQuestion}
          key={index}
          askQuestion={askQuestion}
          colors={colors}
          setClickedSuggestedQuestions={setClickedSuggestedQuestions}
        />
      ))}
    </div>
  );
}

const SuggestedQuestion = ({
  suggestedQuestion,
  askQuestion,
  colors,
  setClickedSuggestedQuestions = () => {},
}) => {
  if (!suggestedQuestion?.question) return "";

  const handleSuggestedPromptClick = () => {
    askQuestion(suggestedQuestion?.question);
    setClickedSuggestedQuestions((prevItems) => [
      ...prevItems,
      suggestedQuestion?.question,
    ]);
  };

  return (
    <button
      className={`suggested-question ${colors && "with-custom-colors"}`}
      onClick={handleSuggestedPromptClick}
    >
      {/* styles for when given custom colors */}
      <style>{`
        .suggested-question.with-custom-colors {
          color: ${colors?.botText};
          background-color: ${colors?.botBubble};
        }
        .suggested-question.with-custom-colors:hover {
          color: ${colors?.userText};
          background-color: ${colors?.userBubble};
        }
      `}</style>

      <span>{suggestedQuestion?.question}</span>
    </button>
  );
};
