import { useContext, useEffect, useState } from "react";
import { ReactComponent as ChatbotGuy } from "../../../../../media/chatbot_logo_clear.svg";
import { ReactComponent as SendIcon } from "../../../../../media/send_icon.svg";
import chatbotIcon from "../../../../../media/chatbotPurple.svg";
import "./ChatbotDemo.css";
import { ChatbotFormContext } from "../../../../ChatbotForm";
import ChatbotDemoSuggestedQuestions from "./ChatbotDemoSuggestedQuestions";
import { ReactComponent as SourcesDropdownArrow } from "../../../../../media/sourcesDropdownArrow.svg";
import ChatbotDemoForm from "./ChatbotDemoForm";

export const ChatbotDemo = ({
  selectedTheme,
  customDemoData,
  isForm = false,
  formIntroText,
  formFields = [],
  setFormFields = () => {},
}) => {
  const chatbotFormContextData = useContext(ChatbotFormContext);
  const demoData = customDemoData ? customDemoData : chatbotFormContextData;
  const {
    name,
    theme,
    darkColors,
    lightColors,
    image,
    getImage,
    welcomeMessage,
    showImageInReplies,
    showTimeStamps,
    suggestedQuestions,
    showSources,
    maxSources,
  } = demoData;

  const [colors, setColors] = useState(selectedTheme === "dark" ? darkColors : lightColors);
  const [sourceOpen, setSourceOpen] = useState(false);

  useEffect(() => {
    selectedTheme === "dark" ? setColors(darkColors) : setColors(lightColors);
  }, [selectedTheme]);

  useEffect(() => {
    if (theme === "light" || (theme === "system" && selectedTheme === "light"))
      setColors(lightColors);
  }, [lightColors]);

  useEffect(() => {
    if (theme === "dark" || (theme === "system" && selectedTheme === "dark")) setColors(darkColors);
  }, [darkColors]);

  const CloseIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
      >
        <circle cx="25" cy="25" r="25" fill={colors?.headerPlusStartToggle} />
        <path
          d="M17.0057 20.0041C17.1373 20.0033 17.2677 20.0286 17.3896 20.0783C17.5114 20.1281 17.6222 20.2014 17.7157 20.2941L25.0057 27.5941L32.2957 20.2941C32.484 20.1058 32.7394 20 33.0057 20C33.272 20 33.5274 20.1058 33.7157 20.2941C33.904 20.4824 34.0098 20.7378 34.0098 21.0041C34.0098 21.2704 33.904 21.5258 33.7157 21.7141L25.7157 29.7141C25.6227 29.8078 25.5121 29.8822 25.3903 29.933C25.2684 29.9837 25.1377 30.0099 25.0057 30.0099C24.8737 30.0099 24.743 29.9837 24.6211 29.933C24.4992 29.8822 24.3886 29.8078 24.2957 29.7141L16.2957 21.7141C16.2019 21.6211 16.1276 21.5105 16.0768 21.3887C16.026 21.2668 15.9999 21.1361 15.9999 21.0041C15.9999 20.8721 16.026 20.7414 16.0768 20.6195C16.1276 20.4977 16.2019 20.3871 16.2957 20.2941C16.3891 20.2014 16.4999 20.1281 16.6218 20.0783C16.7436 20.0286 16.8741 20.0033 17.0057 20.0041Z"
          fill={colors?.headerTextPlusStartToggleIconColor}
        />
      </svg>
    );
  };

  if (!colors) return "";

  return (
    <div className="chatbot-demo-container">
      <div
        className="chatbot-demo-header"
        style={{
          backgroundColor: colors.headerPlusStartToggle,
          color: colors.headerTextPlusStartToggleIconColor,
        }}
      >
        <img src={image ? getImage() : chatbotIcon} alt="Chatbot Icon" />
        <span>{name}</span>
      </div>

      <div
        className="chatbot-demo-body-container"
        style={{
          backgroundColor: colors?.mainBackground,
        }}
      >
        <style>
          {`
            .chatbot-demo-body::-webkit-scrollbar {
              width: 15px; /* Width of the scrollbar */
            }
            .chatbot-demo-body::-webkit-scrollbar-track {
              background: ${colors?.chatScrollBarBackground};
            }
            .chatbot-demo-body::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: ${colors?.chatScrollBarThumb};
              border: 4px solid ${colors?.chatScrollBarBackground};
              min-height: 40px;
            }
          `}
        </style>
        <div className="chatbot-demo-body">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              position: "relative",
              height: "calc(100% - 0px)",
            }}
          >
            {" "}
            {!isForm ? (
              <div style={{ marginBottom: "10px" }}>
                <div
                  className={`chatbot-demo-message-container first ${
                    !showImageInReplies ? "without-img" : ""
                  }`}
                >
                  {showImageInReplies && (
                    <img
                      src={image ? getImage() : chatbotIcon}
                      alt="Chatbot Icon"
                      style={{
                        border: `3px solid ${colors?.mainBackground}`,
                        backgroundColor: colors?.mainBackground,
                      }}
                    />
                  )}

                  <div>
                    <h4
                      style={{
                        backgroundColor: colors?.botBubble,
                        color: colors?.botText,
                      }}
                    >
                      {welcomeMessage}
                    </h4>
                  </div>

                  {showTimeStamps && (
                    <span style={{ color: colors?.mainBackgroundText }}>3:15 PM</span>
                  )}
                </div>

                <ChatbotDemoSuggestedQuestions
                  suggestedQuestions={suggestedQuestions}
                  colors={colors}
                />

                <div className="chatbot-demo-user-message-container">
                  <h4
                    style={{
                      backgroundColor: colors?.userBubble,
                      color: colors?.userText,
                      display: "inline-block",
                      textAlign: "left",
                      minWidth: "109px",
                    }}
                  >
                    Reply from user
                  </h4>

                  {showTimeStamps && (
                    <span style={{ color: colors?.mainBackgroundText }}>{"3:16 PM"}</span>
                  )}
                </div>

                <div
                  className={`chatbot-demo-message-container ${
                    !showImageInReplies ? "without-img" : ""
                  }`}
                  style={sourceOpen && showSources && maxSources > 0 ? { marginBottom: "0px" } : {}}
                >
                  {showImageInReplies ? (
                    <img
                      src={image ? getImage() : chatbotIcon}
                      alt="Chatbot Icon"
                      style={{
                        backgroundColor: colors?.mainBackground,
                        border: `3px solid ${colors?.mainBackground}`,
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <div>
                    <h4
                      style={{
                        backgroundColor: colors?.botBubble,
                        color: colors?.botText,
                        maxWidth: "151px",
                      }}
                    >
                      This is the bot's reply.
                    </h4>
                  </div>

                  {(showTimeStamps || (showSources && maxSources > 0)) && (
                    <span style={{ color: colors?.mainBackgroundText }}>
                      {showTimeStamps && "3:15 PM"}
                      {showSources && maxSources > 0 && (
                        <ChatbotDemoSource
                          sourceOpen={sourceOpen}
                          setSourceOpen={setSourceOpen}
                          showTimeStamps={showTimeStamps}
                        />
                      )}
                    </span>
                  )}
                </div>

                {/* sources demo */}
                {sourceOpen && showSources && maxSources > 0 && (
                  <div
                    className={`chatbot-demo-message-container ${
                      !showImageInReplies ? "without-img" : ""
                    }`}
                    style={{ marginTop: "4px", maxWidth: "100%" }}
                  >
                    <div style={{ maxWidth: "100%" }}>
                      <h4
                        style={{
                          backgroundColor: colors?.botBubble,
                          color: colors?.botText,
                          maxWidth: "calc(100% - 20px)",
                          boxSizing: "border-box",
                          marginTop: "0px",
                          padding: "12px 18px",
                        }}
                      >
                        {Array.from({ length: maxSources }).map((_, index) => (
                          <div
                            className="chatbot-demo-source-link"
                            style={{ color: colors?.botUrlColor }}
                          >
                            Source link {index + 1}
                          </div>
                        ))}
                      </h4>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <ChatbotDemoForm
                formFields={formFields}
                introText={formIntroText}
                setFormFields={setFormFields}
                colors={colors}
              />
            )}
          </div>
        </div>

        <div
          style={{
            borderTop: `1px solid ${colors?.messageBarBorder}`,
            maxHeight: "110px",
          }}
        >
          <div
            className="chatbot-demo-input-container"
            style={{
              backgroundColor: colors?.messageBarBackground,
              border: `1px solid ${colors?.messageBarBorder}`,
            }}
          >
            <style>
              {`.chatbot-demo-message-bar::-webkit-input-placeholder{
                color: ${colors?.messageBarText};
                font-weight: 400;
              }`}
            </style>
            <input
              placeholder="Type a message"
              className="chatbot-demo-message-bar"
              style={{
                color: colors?.messageBarText,
                fontWeight: "400",
              }}
            />

            <div className="chat-send-icons-container">
              <SendIcon fill={colors?.sendIconColor} style={{ cursor: "pointer" }} />
            </div>
          </div>

          <div
            className="chatbot-demo-powered-by-container"
            style={{ color: colors?.mainBackgroundText }}
          >
            <span>Powered by</span>
            <ChatbotGuy style={{ fill: colors?.mainBackgroundText }} />
            Majic Chatbot
          </div>
        </div>
      </div>

      <div style={{ float: "right", margin: "17px 13px" }}>
        <CloseIcon />
      </div>
      <div className="standard-divider" style={{ marginTop: "85px" }} />
    </div>
  );
};

const ChatbotDemoSource = ({ sourceOpen, setSourceOpen, showTimeStamps }) => {
  return (
    <>
      {" "}
      <div
        className="flex align-center"
        style={{ display: "inline-flex", gap: "3px", cursor: "pointer" }}
        onClick={() => setSourceOpen((prev) => !prev)}
      >
        {showTimeStamps && "- "}Sources{" "}
        <div
          className="flex align-center"
          style={{ transform: `rotate(${sourceOpen ? "180deg" : "0deg"})` }}
        >
          <SourcesDropdownArrow />
        </div>
      </div>
    </>
  );
};
