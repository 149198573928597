import StatusCard from "../../Tools/StatusCard/StatusCard";

export const GoogleSignInFailed = () => {
  return (
    <div className="status-card-page-container">
      <StatusCard
        success={false}
        title={"Google Sign In Failed"}
        description={"Sign in with google has failed. Click below to retry."}
        to="/signup"
        customUrlText="Back to Sign Up"
      />
    </div>
  );
};
