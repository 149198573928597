import { useContext, useState } from "react";
import { WrappedComponentContext } from "../WrappedComponent";
import CircularIndeterminate from "../Tools/Loading/CircularIndeterminate";
import { API_URL } from "../config";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";

export const AddConfirmedEmailComponent = ({ setModalOpen, setEmailList }) => {
  const [email, setEmail] = useState("");
  const [savingEmail, setSavingEmail] = useState(false);

  const { setBannerMessage, token, logout } = useContext(
    WrappedComponentContext
  );

  const handleEmailChange = (e) => setEmail(e.target.value);

  const addNewEmail = async (e) => {
    e.preventDefault();
    if (!email || !readyToSend || savingEmail) return;

    try {
      setSavingEmail(true);

      const endpoint = `${API_URL}/trusted_email_addresses`;
      const method = "POST";
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      const body = JSON.stringify({ trusted_email_address: { email } });

      const res = await fetch(endpoint, { method, headers, body });
      const data = await res.json();

      if (!res.ok) {
        let errMsg = "";
        if (Array.isArray(data?.email) && data?.email?.length > 0) {
          errMsg = "Email " + data.email[0];
        }
        throw new ErrorFormat(errMsg, res.status, logout);
      }

      setEmailList((prev) => [data, ...prev]);

      setBannerMessage({
        type: "success",
        title: "Email Sent",
        message: `Confirmation link sent to ${email}.`,
      });
    } catch (error) {
      setBannerMessage({
        type: "error",
        title: "Failed to add email.",
        message: error?.errorMessage,
      });
    } finally {
      setSavingEmail(false);
      setModalOpen(false);
    }
  };

  const readyToSend = !!email;

  return (
    <div
      className="add-link-source-container"
      style={{ padding: "15px 5px", gap: "10px" }}
    >
      <span>Add a new email</span>

      <form
        onSubmit={addNewEmail}
        className="add-link-source-content"
        style={{ gap: "10px" }}
      >
        <input
          className="inline-input full"
          placeholder="email@yourdomain.com"
          type="email"
          value={email}
          onChange={handleEmailChange}
          style={{
            boxSizing: "border-box",
            maxWidth: "calc(100% - 12px",
            height: "40px",
          }}
        />

        <button
          type="submit"
          className={`purple-button ${
            !readyToSend || savingEmail ? "muted" : ""
          }`}
          style={{ alignSelf: "flex-start" }}
        >
          {savingEmail && <CircularIndeterminate color="white" />}
          {savingEmail ? "Adding Email" : "Add"}
        </button>
      </form>
    </div>
  );
};
