import { useContext, useEffect, useState } from "react";
import FormSettings from "./FormSettings";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../config";
import { WrappedComponentContext } from "../../../WrappedComponent";
import { ErrorFormat } from "../../../Tools/Errors/ErrorFormatting";

const CreateForm = () => {
  const [newForm, setNewForm] = useState({
    name: "",
    form_fields_attributes: [],
  });
  const [isSaving, setIsSaving] = useState(false);

  const { token, logout, setBannerMessage } = useContext(WrappedComponentContext);
  const { chatbotId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getFormElements() {
      try {
        const res = await fetch(`${API_URL}/form_elements`, { headers: { Authorization: token } });
        if (!res.ok) throw new ErrorFormat("", res.status, logout);

        let formFields = await res.json();

        formFields = Object.values(formFields).map((formField, index) => ({
          ...formField,
          order: index + 1,
          dragId: `${index}`,
        }));
        setNewForm((prev) => ({ ...prev, form_fields_attributes: formFields }));
      } catch (error) {
        setBannerMessage({ type: "error", title: "Failed to fetch form elements." });
      }
    }
    getFormElements();
  }, []);

  const createNewForm = async () => {
    try {
      if (isSaving) return;
      setIsSaving(true);

      const endpoint = `${API_URL}/chat_bots/${chatbotId}/forms`;
      const method = "POST";
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      const body = JSON.stringify({
        form: {
          ...newForm,
          form_fields_attributes: formatFormFieldsForSaving(newForm),
        },
      });

      const response = await fetch(endpoint, { method, headers, body });
      if (!response.ok) throw new ErrorFormat("Failed to create form", response.status, logout);

      navigate(`/chatbot/settings/${chatbotId}/forms`);
    } catch (error) {
      setBannerMessage({ type: "error", title: "Failed to create form." });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <FormSettings
      form={newForm}
      setForm={setNewForm}
      saveFunction={createNewForm}
      cancelLink={`/chatbot/settings/${chatbotId}/forms`}
      isSaving={isSaving}
    />
  );
};

export default CreateForm;

export const formatFormFieldsForSaving = (form) => {
  try {
    const formFields = form.form_fields_attributes.map((field, fieldIndex) => ({
      ...field,
      order: fieldIndex + 1,
    }));
    for (let key in formFields) {
      delete formFields[key].dragId;
    }
    return formFields;
  } catch (error) {
    return null;
  }
};
