import { useContext } from "react";
import { ViewWebsiteSourcePreviewContext } from "./ViewWebsiteSourcePreview";
import { IndexedLinkView } from "./IndexedLinkView";

export const ListUrls = ({
  urlObjs,
  title,
  isIndexed,
  hasManageExclusionsButton,
}) => {
  const { manageExclusions, setManageExclusions } = useContext(
    ViewWebsiteSourcePreviewContext
  );

  return (
    <>
      <div
        className="flex align-center"
        style={
          manageExclusions
            ? {}
            : {
                gap: "20px",
                paddingTop: "5px",
                paddingBottom: hasManageExclusionsButton ? "5px" : "10px",
              }
        }
      >
        <div
          style={{
            color: "#1e1e1e",
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: manageExclusions ? "6px" : "0px",
          }}
        >
          {title}
        </div>

        {!manageExclusions && hasManageExclusionsButton && (
          <button
            className="add-source"
            style={{ padding: "6px 16px", margin: "0px" }}
          >
            <span
              style={{ margin: "0px" }}
              onClick={() => setManageExclusions(true)}
            >
              Manage Exclusions
            </span>
          </button>
        )}
      </div>

      <div className="indexed-list">
        {urlObjs.map((urlInfo, index) => (
          <IndexedLinkView
            urlInfo={urlInfo}
            isIndexed={isIndexed}
            index={index}
            key={index}
          />
        ))}
      </div>
    </>
  );
};
