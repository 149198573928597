export const formatSuggestedQuestionsString = (suggestedQuestionsString) => {
  // Gets suggested questions string and returns array of object containing suggested questions data.

  try {
    let suggestedQuestions = suggestedQuestionsString.split("\n");

    // Format suggested questions into an array of objects
    suggestedQuestions = suggestedQuestions.map((suggestedPrompt, index) => {
      suggestedPrompt = suggestedPrompt.split("%ANS%");

      let question = {
        id: `${new Date().getTime()}${suggestedPrompt[0]}${index}`,
        question: suggestedPrompt[0].trim(),
      };

      if (suggestedPrompt.length > 1) {
        question.customAnswer = suggestedPrompt[1].replace(/%NL/g, "\n").trim();
      }

      return question;
    });

    suggestedQuestions = suggestedQuestions.filter((starter) =>
      starter?.question.trim()
    );

    return suggestedQuestions;
  } catch (err) {
    return [];
  }
};
