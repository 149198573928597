import React, { useContext } from "react";
import ChatbotFormInlineToggle from "./ChatbotFormInlineToggle";
import { ChatbotFormContext } from "../ChatbotForm";
import { InfoDescription } from "../InfoDescription/InfoDescription";

const ChatbotFormNotifyFinishedSessions = () => {
  const { notifyFinishedSessions, setNotifyFinishedSessions } =
    useContext(ChatbotFormContext);

  return (
    <ChatbotFormInlineToggle
      open={notifyFinishedSessions}
      setOpen={setNotifyFinishedSessions}
    >
      Notify of every new chat thread by email
      <InfoDescription customDistance="55px">
        If turned on, we’ll email you whenever a new chat thread is started with
        a summary and link to the full thread. Emails go out after 5 minutes of
        idle session time. Please note: chat sessions performed by you in the
        admin panel are not included in notifications.
      </InfoDescription>
    </ChatbotFormInlineToggle>
  );
};

export default ChatbotFormNotifyFinishedSessions;
