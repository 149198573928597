import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API_URL } from "../../../config";
import { WrappedComponentContext } from "../../../WrappedComponent";
import { ErrorFormat } from "../../../Tools/Errors/ErrorFormatting";
import WaitingWithText from "../../../Tools/ChatWaiting/WaitingWithText";
import { throttleWithoutWaitingArgs } from "../../../Tools/throttle";
import { useConfirm } from "../../../Tools/Confirm/Confirm";
import Modal from "../../../Modal/Modal";
import StatusCard from "../../../Tools/StatusCard/StatusCard";
import { ChatbotFormContext } from "../../ChatbotForm";
import { ReactComponent as ViewAction } from "../../../media/viewAction.svg";

const ShowForm = ({ form }) => {
  const formFields = useMemo(
    () => form.form_fields.filter((formField) => formField?.enabled),
    [form]
  );
  const [submissions, setSubmissions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(100000000000);
  const [isLoading, setIsLoading] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const isDeletingRef = useRef(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const { chatbotId } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { token, logout, setBannerMessage } = useContext(WrappedComponentContext);
  const { setBackLink, manageChatbotLink, setHideTabsMenu } = useContext(ChatbotFormContext);

  useEffect(() => {
    getSubmissions(page);

    setBackLink({ link: `/chatbot/settings/${chatbotId}/forms`, text: "<  Back to Forms" });
    setHideTabsMenu(true);

    return () => {
      setBackLink({ link: manageChatbotLink, text: "<  Back to Chatbot" });
      setHideTabsMenu(false);
    };
  }, []);

  useEffect(() => {
    // Add scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, totalPages, isLoading]);

  const getSubmissions = async (page) => {
    try {
      setIsLoading(true);

      const endpoint = `${API_URL}/filled_forms?chat_bot_id=${chatbotId}&form_id=${form?.id}&page=${page}`;
      const headers = { Authorization: token };

      const res = await fetch(endpoint, { headers });
      if (!res.ok) throw new ErrorFormat("Failed to fetch form submissions", res.status, logout);

      const data = await res.json();
      setSubmissions((prevSubs) => [...prevSubs, ...data.filled_forms]);
      setPage((prevPage) => {
        const newPage = prevPage + 1;
        return newPage;
      });
      setTotalPages(data?.meta?.total_pages);
    } catch (error) {
      setBannerMessage({ type: "error", title: "Failed to fetch form submissions" });
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToEdit = () => setSearchParams({ edit: "true" });

  const deleteForm = async () => {
    try {
      if (isDeletingRef.current) return;
      isDeletingRef.current = true;
      const confirmed = await confirm({ title: "Are you sure you want to delete this form?" });
      if (!confirmed) {
        isDeletingRef.current = false;
        return;
      }
      setIsDeleting(true);

      const endpoint = `${API_URL}/chat_bots/${chatbotId}/forms/${form.id}`;
      const method = "DELETE";
      const headers = { Authorization: token };

      const res = await fetch(endpoint, { method, headers });
      if (!res.ok) throw new ErrorFormat("", res.status, logout);

      navigate(`/chatbot/settings/${chatbotId}/forms`);
    } catch (error) {
      setIsDeleting(false);
      isDeletingRef.current = false;
      setBannerMessage({ type: "error", title: "Failed to delete form" });
    }
  };

  const getSubmissionsThrottled = useMemo(() => {
    return throttleWithoutWaitingArgs((page) => {
      getSubmissions(page);
    }, 1000);
  }, [page]);

  const handleScroll = () => {
    var scrollPos =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    var windowHeight =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var bodyHeight = document.body.scrollHeight || document.documentElement.scrollHeight;

    const isAtBottom = scrollPos + windowHeight >= bodyHeight - 300;

    if (isAtBottom && !isLoading && totalPages >= page) {
      getSubmissionsThrottled(page);
    }
  };

  return (
    <>
      <div
        className="flex align-center"
        style={{ justifyContent: "space-between", marginBottom: "12px" }}
      >
        <span className="title-20">
          Manage Form: <span style={{ fontWeight: 400 }}>{form?.name}</span>
        </span>

        <span style={{ fontSize: "14px", color: "#A24DFF" }}>
          <button className="purple-link" onClick={navigateToEdit}>
            design
          </button>{" "}
          |{" "}
          <button className="purple-link" onClick={deleteForm}>
            delete
          </button>
        </span>
      </div>
      <br />

      <div style={{ fontSize: "14px", color: "#1e1e1e", fontWeight: 600 }}>Submissions</div>

      {/* Actions table */}
      {Array.isArray(formFields) && (
        <table className="scrollable-table actions-tab-table" style={{ marginTop: "10px" }}>
          <thead>
            <tr>
              <th>Conversation</th>

              {formFields.map((formField, index) => (
                <th style={tdStyles} className={index === 0 ? "first-td" : ""} key={formField.id}>
                  {formField?.label}
                </th>
              ))}

              <th style={{ ...tdStyles, minWidth: "110px" }} className="last-td">
                Date
              </th>
            </tr>
          </thead>

          <tbody>
            {submissions.map((submission, index) => (
              <tr key={index}>
                <td style={{ padding: "0px" }}>
                  <Link
                    to={`/chatbot/${chatbotId}/conversations/${submission?.chat_session_id}`}
                    className="action-icon-container"
                    style={{ display: "inline-flex" }}
                  >
                    <ViewAction />
                    <span className="action-icon-text" style={{ width: "126px", left: "-10px" }}>
                      View Conversation
                    </span>
                  </Link>
                </td>

                {formFields.map((formField, index) => (
                  <td key={formField.id} style={tdStyles} className={index === 0 ? "first-td" : ""}>
                    {
                      submission?.filled_form_fields.find(
                        (filledFormField) => filledFormField?.form_field?.id === formField?.id
                      )?.value
                    }
                  </td>
                ))}

                <td style={{ ...tdStyles, minWidth: "110px" }} className="last-td">
                  {new Date(submission?.created_at).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {isLoading && <WaitingWithText>Loading Submissions</WaitingWithText>}

      {isDeleting && (
        <Modal
          takeChildrensShape={true}
          closeOnOutsideClick={false}
          removeCloseButton={true}
          setOpen={() => {}}
          component={
            <StatusCard
              loading={true}
              loadingChildren={<WaitingWithText>Deleting</WaitingWithText>}
            />
          }
        />
      )}
    </>
  );
};

const tdStyles = {
  maxWidth: "400px",
  wordBreak: "break-word",
  minWidth: "175px",
};

export default ShowForm;
