import { API_URL } from "../config";

// This method gets the stripe plan link with the plan name and interval
const getStripePlanInfo = async ({ token, planName, interval }) => {
  try {
    planName = planName.toLowerCase();
    if (interval !== "year") interval = "month";

    const allPlansEndpoint = `${API_URL}/stripe_products`;
    const headers = { Authorization: token };

    const response = await fetch(allPlansEndpoint, { headers });
    if (!response.ok) throw "Could not fetch stripe info";

    const stripeInfo = await response.json();

    for (let i = 0; i < stripeInfo?.length; i++) {
      const plan = stripeInfo[i];

      if (plan?.tier === planName) {
        for (let j = 0; j < plan?.stripe_prices?.length; j++) {
          const price = plan?.stripe_prices[j];

          if (price?.interval === interval && price?.active)
            return { priceId: price?.id, planId: plan?.id };
        }
      }
    }

    return { planId: null, priceId: null };
  } catch (error) {
    console.error(error);
    return { planId: null, priceId: null };
  }
};

export default getStripePlanInfo;
