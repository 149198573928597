// Function for formatting a date object
export default function formatDate(dateString = null) {
  const date = new Date(dateString ? dateString : "");

  const options = { year: "numeric", month: "short", day: "numeric" };

  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}
