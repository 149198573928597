import React, { useState } from "react";
import { Link } from "react-router-dom";

const HomepageBigButton = ({ to = "/", children }) => {
  return (
    <>
      <style>
        {`
    .homepage-big-button-absolute-arrow {
      position: absolute;
      right: 16px;
      top: -5px;
    }

    .homepage-big-button {
      border-radius: 10px;
      border: none;
      background: linear-gradient(131deg, #a24dff 0%, #ff5e8e 93.04%);

      color: var(--White, #fff);
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Work Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      padding: 15px 35px;
      position: relative;

      transition: background 0.1s ease;
    }

    .homepage-big-button::before {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      content: attr(data-content);;
      inset: 0; /* same as { top: 0; right: 0; bottom: 0; left: 0; } */
      background-image: linear-gradient(131deg, #ff5e8e 0%, #a24dff 93.04%);
      z-index: 1;
      opacity: 0;
      transition: opacity 0.25s linear;
      border-radius: 10px;
    }
    .homepage-big-button:hover::before {
      opacity: 1;
    }

    @media (max-width: 375px) {
      .homepage-big-button {
        padding: 12px 25px;
        font-size: 16px;
      }

      .homepage-big-button-absolute-arrow {
        top: -11px;
        right: 10px;
      }
    }
  `}
      </style>

      <Link to={to}>
        <button className="homepage-big-button" data-content={children}>
          {children}
        </button>
      </Link>
    </>
  );
};

export default HomepageBigButton;
