import { useEffect } from "react";
import StatusCard from "../Tools/StatusCard/StatusCard";

export default function SlackSuccess({}) {
  useEffect(() => {
    document.title = "Slack Success | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  return (
    <div className="status-card-page-container">
      <StatusCard
        success={true}
        title="Chatbot added to slack"
        description="Your chatbot has been added to slack."
        to="/slack-integration"
      />
    </div>
  );
}
