import { useContext, useEffect, useState } from "react";
import Toggle from "../../../../Toggle/Toggle";
import { ChatbotDemo } from "./ChatbotDemo/ChatbotDemo";
import { WelcomeBubble } from "./WelcomeBubble";
import "./ChatbotPreview.css";
import { ChatbotFormContext } from "../../../ChatbotForm";

export const ChatbotPreview = () => {
  const { theme } = useContext(ChatbotFormContext);

  const [isLight, setIsLight] = useState(true);
  const [colorTheme, setColorTheme] = useState(
    theme === "dark" ? "dark" : "light"
  );

  useEffect(() => {
    if (theme === "system") {
      isLight ? setColorTheme("light") : setColorTheme("dark");
    }
  }, [isLight]);

  useEffect(() => {
    if (theme === "system") {
      isLight ? setColorTheme("light") : setColorTheme("dark");
    } else {
      setColorTheme(theme);
    }
  }, [theme]);

  return (
    <div className="chatbot-preview-container">
      <span className="chatbot-settings-title">Chatbot Preview</span>

      <br />
      <br />
      {theme === "system" && (
        <Toggle open={isLight} setOpen={setIsLight} isThemeToggle={true} />
      )}

      <ChatbotDemo selectedTheme={colorTheme} />

      <br />
      <WelcomeBubble selectedTheme={colorTheme} />
    </div>
  );
};
