import { useState } from "react";
import { formatAMPM } from "../Chat/Chat";
import { ChatMessageSources, ChatMessageSourcesDropdown } from "../Chat/ChatMessageSources";
import { extractChatMessageLinkSources } from "../Chat/extractChatMessageLinkSources";
import { formatResponseText } from "../Chat/formatResponse";
import ChatMessageDocs from "./ChatMessageDocs";
import chatbotIcon from "../../media/chatbotPurple.svg";
import { Link } from "react-router-dom";

const ConversationChatMessage = ({ chatMessage, chatbot }) => {
  const [sourcesOpen, setSourcesOpen] = useState(false);

  const sourceLinks = extractChatMessageLinkSources(chatMessage, chatbot?.show_sources_max);

  const filledForms =
    chatMessage?.triggered_actions?.length > 0
      ? chatMessage.triggered_actions.filter(
          (action) => action?.bot_action?.form?.filled_form?.length > 0
        )
      : [];

  return (
    <div
      className={`chat-message-container ${chatMessage?.customer_message ? "question" : ""} ${
        !chatbot?.show_chatbot_avatar_in_responses ? "without-avatar" : ""
      }`}
    >
      {!chatMessage?.customer_message && chatbot?.show_chatbot_avatar_in_responses && (
        <img
          className="chat-message-logo"
          src={chatbot?.avatar_url ? chatbot?.avatar_url : chatbotIcon}
          alt="Chatbot Icon"
        />
      )}

      {!chatMessage?.customer_message ? (
        <div className="chat-message convos">
          <pre
            className="pre-text"
            dangerouslySetInnerHTML={{
              __html: formatResponseText(chatMessage?.content, true),
            }}
          ></pre>
        </div>
      ) : (
        <div className="chat-message question convos">{chatMessage?.content}</div>
      )}

      {/* Timestamp + Sources Dropdown */}
      {(chatbot?.show_timestamps_in_responses ||
        (chatbot?.show_sources && chatbot?.show_sources_max > 0 && sourceLinks?.length > 0)) && (
        <div className="chat-message-time">
          {chatbot?.show_timestamps_in_responses && formatAMPM(new Date(chatMessage?.created_at))}

          {chatbot?.show_sources && chatbot?.show_sources_max > 0 && sourceLinks?.length > 0 && (
            <ChatMessageSourcesDropdown
              sourcesOpen={sourcesOpen}
              setSourcesOpen={setSourcesOpen}
              showTimeStamps={chatbot?.show_timestamps_in_responses}
            />
          )}
        </div>
      )}
      {/* Sources */}
      {chatbot?.show_sources &&
        chatbot?.show_sources_max > 0 &&
        sourceLinks?.length > 0 &&
        sourcesOpen && <ChatMessageSources sourceLinks={sourceLinks} />}

      {!chatMessage?.customer_message && chatMessage?.utilized_docs?.docs && (
        <ChatMessageDocs
          showAvatar={chatbot?.show_chatbot_avatar_in_responses}
          chatMessage={chatMessage}
        />
      )}

      {filledForms.map((action) => (
        <div
          style={{
            marginTop: "2px",
            color: "#1e1e1e",
            padding: "15px",
            border: "1px solid #1e1e1e",
            display: "inline-flex",
            borderRadius: "10px",
            fontSize: "14px",
          }}
        >
          User submitted form&nbsp;
          <Link
            className="link"
            to={`/chatbot/settings/${chatbot?.id}/forms/${action.bot_action.form?.id}`}
          >
            {action.bot_action.form?.name}
          </Link>
          .
        </div>
      ))}
    </div>
  );
};

export default ConversationChatMessage;
