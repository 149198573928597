import { useContext, useEffect, useState } from "react";
import DocumentTitle from "../Tools/DocumentTitle/DocumentTitle";
import "./Account.css";
import WaitingWithText from "../Tools/ChatWaiting/WaitingWithText";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import { WrappedComponentContext } from "../WrappedComponent";
import { useConfirm } from "../Tools/Confirm/Confirm";
import { findBestPlan } from "../Tools/findBestPlan";

export default function Account() {
  const [plan, setPlan] = useState();

  const { logout, fetchUserInfo, userInfo, token, setToken, setBannerMessage } =
    useContext(WrappedComponentContext);

  const confirm = useConfirm();

  useEffect(() => {
    if (!userInfo) fetchUserInfo();

    document.title = "Account | Majic AI";
    return () => (document.title = "Majic AI");
  }, []);

  useEffect(() => {
    if (userInfo?.subscriptions) setPlan(findBestPlan(userInfo.subscriptions));
  }, [userInfo]);

  const deleteAccount = async () => {
    try {
      const confirmed = await confirm({
        title: "Delete account",
        description: "Are you sure you want to delete your account?",
      });
      if (!confirmed) return;

      const endpoint = `${API_URL}/accounts/current`;
      const method = "DELETE";
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { method, headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to delete account.",
          response.status,
          logout
        );

      // Remove account info from browser
      localStorage.removeItem("accessKey");
      setToken(null);
      window.location.href = "/login";
    } catch (error) {
      console.error(error?.errorMessage ?? error);
      setBannerMessage({ type: "error", title: "Failed to delete account." });
    }
  };

  return (
    <div className="page-card-container">
      <div className="page-card small min-height-mid">
        <DocumentTitle>Your Account</DocumentTitle>

        {userInfo ? (
          <div className="your-account">
            <div>
              <span>
                Your email: {userInfo?.email}&ensp;
                <Link to="/change-email">
                  <button className="purple-link">Edit</button>
                </Link>
                <br />
              </span>

              <span>
                Password:&ensp;
                <Link to="/send-reset-password">
                  <button className="purple-link">Reset</button>
                </Link>
                <br />
              </span>

              {plan?.product_name && (
                <span>
                  Your current plan: {plan?.product_name}&ensp;
                  <Link to="/plan" className="purple-link">
                    Change
                  </Link>
                  <br />
                </span>
              )}

              <span>
                Billing Portal: Payment details, history, and invoices&ensp;
                <Link
                  to="https://billing.stripe.com/p/login/eVa7vSdxG72ocla4gg"
                  className="purple-link"
                >
                  Open
                </Link>
              </span>
              <br />
            </div>

            <span style={{ justifySelf: "flex-end" }}>
              Delete Account:&ensp;
              <button className="purple-link" onClick={deleteAccount}>
                Delete
              </button>
            </span>
          </div>
        ) : (
          <WaitingWithText>Loading</WaitingWithText>
        )}
      </div>
    </div>
  );
}
