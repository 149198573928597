import { createContext, useContext } from "react";
import { EditThemesContext } from "./EditThemes";
import { ChatbotFormContext } from "../../../ChatbotForm";
import { PickColor } from "./PickColor";

export const PickColorsContext = createContext();

export const PickColors = ({ themeId }) => {
  const { setColors, colors } = useContext(EditThemesContext);
  const { showTimeStamps } = useContext(ChatbotFormContext);

  const setColor = (color, colorKey) => {
    setColors({
      ...colors,
      [colorKey]: color.hex,
    });
  };

  const value = {
    setColor,
  };

  return (
    <PickColorsContext.Provider value={value}>
      <div id={themeId}>
        <PickColor
          title="Header & start toggle"
          colorKey="headerPlusStartToggle"
        />
        <PickColor title="Main background" colorKey="mainBackground" />
        <PickColor title="Main background text" colorKey="mainBackgroundText" />
        <div className="new-chatbot-divider secondary" />

        <PickColor title="Bot’s bubble" colorKey="botBubble" />
        <PickColor title="Bot’s text" colorKey="botText" />
        <PickColor title="Bot’s URLs" colorKey="botUrlColor" />
        <div className="new-chatbot-divider secondary" />

        <PickColor title="User’s bubble" colorKey="userBubble" />
        <PickColor title="User’s text" colorKey="userText" />
        <div className="new-chatbot-divider secondary" />

        <PickColor
          title="Message bar background"
          colorKey="messageBarBackground"
        />
        <PickColor title="Message bar text" colorKey="messageBarText" />
        <PickColor title="Message bar icon" colorKey="sendIconColor" />
      </div>
    </PickColorsContext.Provider>
  );
};
