import ChatWaiting from "./ChatWaiting";

export default function WaitingWithText({ optionalClassName="", children }) {
  return (
    <span
      className={optionalClassName}
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      {children}

      <div
        style={{ marginTop: "9px", marginLeft: "5px" }}
      >
        <ChatWaiting customHeight="4px" />
      </div>
    </span>
  );
}
