import StatusCard from "../../Tools/StatusCard/StatusCard";

export const ResetPasswordSuccess = () => {
  return (
    <div className="status-card-page-container">
      <StatusCard
        success={true}
        title="password changed successfully"
        description="Your password has been changed. Please log in again with new password."
        to="/login"
      />
    </div>
  );
};
