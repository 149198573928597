import { useContext, useState } from "react";
import { Item } from "../DomainsAllowed/DomainsAllowed";
import { ChatbotFormContext } from "../../ChatbotForm";
import Modal from "../../../Modal/Modal";
import MakeSuggestedQuestion from "./MakeSuggestedQuestion";
import { formatSuggestedQuestionForSaving } from "../../../ManageChatbot/Chat/SuggestedQuestions/formatSuggestedQuestionForSaving";

const SelectedQuestionItem = ({
  index,
  suggestedQuestion,
  setSuggestedQuestions,
}) => {
  const editIsOpen = suggestedQuestion?.editIsOpen;

  const setEditIsOpen = (value) => {
    if (typeof value === "function") {
      value = value();
    }

    setSuggestedQuestions((prevItems) =>
      prevItems.map((item) => {
        if (item.id === suggestedQuestion.id) {
          return { ...item, editIsOpen: value };
        }
        return item;
      })
    );
  };

  const editSuggestedPrompt = ({ question, customAnswer, id }) => {
    [question, customAnswer] = formatSuggestedQuestionForSaving(
      question,
      customAnswer
    );

    setSuggestedQuestions((prevItems) => {
      const itemsCopy = [...prevItems];
      for (let i = 0; i < itemsCopy.length; i++) {
        if (itemsCopy[i]?.id === id) {
          itemsCopy[i].question = question;
          itemsCopy[i].customAnswer = customAnswer;
        }
      }

      return itemsCopy;
    });

    setEditIsOpen(false);
  };

  if (!suggestedQuestion?.question.trim()) return "";

  return (
    <div style={{ width: "100%", cursor: "pointer" }}>
      <div onClick={() => setEditIsOpen(true)}>
        <Item
          item={suggestedQuestion?.question}
          setItems={setSuggestedQuestions}
          index={index}
          hasIndexText={true}
          hasChatIcon={suggestedQuestion?.customAnswer}
        />
      </div>

      {editIsOpen && (
        <Modal
          extraClassName="mid-size"
          setOpen={setEditIsOpen}
          component={
            <MakeSuggestedQuestion
              question={suggestedQuestion?.question}
              customAnswer={suggestedQuestion?.customAnswer}
              id={suggestedQuestion?.id}
              submit={editSuggestedPrompt}
            />
          }
          closeOnOutsideClick={false}
        />
      )}
    </div>
  );
};

export default SelectedQuestionItem;
