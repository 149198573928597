import { useContext, useEffect, useRef, useState } from "react";
import ChatWaiting from "../../Tools/ChatWaiting/ChatWaiting";
import { ChatContext, formatAMPM } from "./Chat";
import chatbotIcon from "../../media/chatbotPurple.svg";
import { API_URL } from "../../config";
import { formatResponseText } from "./formatResponse";
import { ChatMessageSources, ChatMessageSourcesDropdown } from "./ChatMessageSources";
import { extractChatMessageLinkSources } from "./extractChatMessageLinkSources";
import ChatMessageForm from "./ChatMessageForm";

const ChatMessage = ({ chatMessage, colors, isWelcomeMessage, isLast }) => {
  const {
    chatbotAvatar,
    hasAvatar,
    chatSessionToken,
    scrollToBottom,
    messageLimitReachedMessage,
    hasTimeStamps,
    showSources,
    maxSources,
  } = useContext(ChatContext);

  const [timeContent, setTimeContent] = useState(formatAMPM(chatMessage?.timeStamp));
  const [delayVisible, setDelayVisible] = useState(false);
  const [fetchedResponse, setFetchedResponse] = useState(false);
  const [sourcesOpen, setSourcesOpen] = useState(false);
  const [sourceLinks, setSourceLinks] = useState([]);
  const [triggeredActions, setTriggeredActions] = useState(null);

  const intervalRef = useRef(null);
  const responseContentRef = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (chatMessage?.content === "..." && chatMessage?.type === "answer" && !fetchedResponse) {
      createChatMessage();
    }
  }, [chatMessage?.content]);

  useEffect(() => {
    if (sourcesOpen && isLast) {
      scrollToBottom();
    }
  }, [sourcesOpen]);

  const createChatMessage = async () => {
    try {
      setFetchedResponse(true);

      const endpoint = `${API_URL}/chat_messages?token=${chatSessionToken}`;
      const method = "POST";
      const headers = { "Content-Type": "application/json" };
      const requestData = {
        chat_message: { content: chatMessage?.questionToAnswer },
      };
      const body = JSON.stringify(requestData);

      const response = await fetch(endpoint, { method, headers, body });

      setTimeContent(formatAMPM(new Date()));

      const data = await response.json();
      if (!response.ok) throw data;

      checkIfResponseIsReady(data.id);
    } catch (error) {
      const isRateLimitingErr =
        Array.isArray(error?.chat_session) && error?.chat_session?.length > 0
          ? error?.chat_session[0].startsWith("rate limit of")
          : false;

      errorResponse(isRateLimitingErr ? messageLimitReachedMessage : null);
    }
  };

  const checkIfResponseIsReady = (chatMessageId) => {
    const MAX_ATTEMPTS = 80;
    const ATTEMPTS_FOR_DELAY_MESSAGE = 15;
    let attempts = 0;
    let intervalId;

    async function getQuestionResponseId() {
      try {
        attempts++;
        console.log(`Attempt ${attempts} to ping API...`);
        if (attempts >= ATTEMPTS_FOR_DELAY_MESSAGE) setDelayVisible(true);

        const response = await pingApiForMessageId(chatMessageId);
        if (!response.ok) throw new Error();

        const data = await response.json();

        if (data.response_chat_message_id) {
          clearInterval(intervalId);

          getMessageContent(data.response_chat_message_id);
        } else if (attempts >= MAX_ATTEMPTS) {
          clearInterval(intervalId);

          console.error("Error: Max attempts reached.");
          setDelayVisible(false);
          errorResponse();
        }
      } catch (error) {
        clearInterval(intervalId);
        console.error("Error occurred while fetching answer");
        setDelayVisible(false);
        errorResponse();
      }
    }

    // pings api until we get the chat message id or after 5 times shows error message
    intervalId = setInterval(getQuestionResponseId, 600);
    intervalRef.current = intervalId;
  };

  const pingApiForMessageId = (chatMessageId) => {
    const endpoint = `${API_URL}/chat_messages/${chatMessageId}?token=${chatSessionToken}`;
    const headers = { "Content-Type": "application/json" };
    return fetch(endpoint, { headers });
  };

  const getMessageContent = async (responseMessageId) => {
    try {
      const endpoint = `${API_URL}/chat_messages/${responseMessageId}?token=${chatSessionToken}`;
      const headers = { "Content-Type": "application/json" };

      const messageResponse = await fetch(endpoint, { headers });
      if (!messageResponse.ok) throw new Error();

      const messageData = await messageResponse.json();

      const formattedContent = formatResponseText(messageData.content);
      responseContentRef.current.innerHTML = formattedContent;

      setSourceLinks(extractChatMessageLinkSources(messageData, maxSources));

      if (Array.isArray(messageData?.triggered_actions)) {
        setTriggeredActions(
          messageData.triggered_actions.filter(
            (action) => action?.bot_action?.type === "FormCollectionBotAction"
          )
        );
        console.log(
          messageData.triggered_actions.filter(
            (action) => action?.bot_action?.type === "FormCollectionBotAction"
          )
        );
      }

      scrollToBottom();
    } catch (error) {
      errorResponse();
    }
  };

  const errorResponse = (customMessage = null) => {
    responseContentRef.current.innerHTML = customMessage ?? "Server had issue getting response";

    scrollToBottom();
  };

  return (
    <>
      <div
        className={`chat-message-container ${chatMessage?.type === "question" ? "question" : ""} ${
          !hasAvatar ? "without-avatar" : ""
        } ${isWelcomeMessage ? "welcome-chat-message" : ""}`}
      >
        {/* Chatbot avatar */}
        {chatMessage?.type === "answer" && hasAvatar && (
          <img
            className="chat-message-logo"
            alt="Chat Logo"
            src={chatbotAvatar ? chatbotAvatar : chatbotIcon}
            style={
              colors && {
                borderColor: colors?.mainBackground,
                backgroundColor: colors?.mainBackground,
              }
            }
          />
        )}

        {chatMessage?.type === "answer" ? (
          // Bot answer chat message
          <pre className="pre-text">
            <div
              className="chat-message"
              style={
                colors && {
                  color: colors?.botText,
                  background: colors?.botBubble,
                }
              }
              ref={responseContentRef}
            >
              {/* Delay message */}
              {delayVisible && (
                <div className="chat-delays" style={colors && { color: colors?.botText }}>
                  We are experiencing some delays
                </div>
              )}

              {chatMessage?.content === "..." ? (
                <ChatWaiting customColor={colors?.headerPlusStartToggle ?? "#A24DFF"} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: chatMessage?.content }} />
              )}
            </div>
          </pre>
        ) : (
          // User question chat message
          <div
            className="chat-message question"
            style={
              colors && {
                color: colors?.userText,
                background: colors?.userBubble,
              }
            }
          >
            <span>{chatMessage?.content}</span>
          </div>
        )}

        {/* Timestamp + Sources Dropdown */}
        {(hasTimeStamps || (showSources && maxSources > 0 && sourceLinks?.length > 0)) && (
          <div
            className="chat-message-time"
            style={colors && { color: colors?.mainBackgroundText }}
          >
            {hasTimeStamps && timeContent}
            {showSources && maxSources > 0 && sourceLinks?.length > 0 && (
              <ChatMessageSourcesDropdown
                sourcesOpen={sourcesOpen}
                setSourcesOpen={setSourcesOpen}
                showTimeStamps={hasTimeStamps}
              />
            )}
          </div>
        )}

        {/* Sources */}
        {showSources && maxSources > 0 && sourceLinks?.length > 0 && sourcesOpen && (
          <ChatMessageSources sourceLinks={sourceLinks} colors={colors} />
        )}
      </div>

      {triggeredActions?.length > 0 &&
        triggeredActions.map((action) => (
          <ChatMessageForm
            action={action}
            colors={colors}
            chatSessionToken={chatSessionToken}
            key={action?.id}
          />
        ))}
    </>
  );
};

export default ChatMessage;
