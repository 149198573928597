export function base64StringToFile(base64String, fileName) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/octet-stream" });

  // File constructor is not supported by Internet Explorer
  try {
    return new File([blob], fileName, { type: "application/octet-stream" });
  } catch (e) {
    // If the File constructor is not supported, return null
    return null;
  }
}
