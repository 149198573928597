export function validateEmail(mail) {
  return /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail);
}

export const hasNumber = (str) => {
  return /\d/.test(str);
};

export const hasLowerCase = (str) => {
  return /.*[a-z].*/.test(str);
};

export const hasUpperCase = (str) => {
  return /.*[A-Z].*/.test(str);
};
