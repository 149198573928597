import "./HeaderSettings.css";
import { ReactComponent as UserIcon } from "../media/user.svg";
import { ReactComponent as SlackIcon } from "../media/slack.svg";
import { ReactComponent as PlanIcon } from "../media/plan.svg";
import { ReactComponent as LogoutIcon } from "../media/logout.svg";
import { ReactComponent as EmailIcon } from "../media/email.svg";
import { HeaderContext } from "./Header";
import { useContext } from "react";
import { Link } from "react-router-dom";

export const HeaderSettings = ({ open, setOpen = () => {}, logout }) => {
  const { enableScroll } = useContext(HeaderContext);
  const close = () => {
    enableScroll();
    setOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className={`header-settings-container ${open ? "open" : ""}`}>
      <Link to="/account" className="header-settings-link" onClick={close}>
        <HeaderSetting Icon={UserIcon} extraClass="first" needsStroke={true}>
          Your Account
        </HeaderSetting>
      </Link>

      <Link to="/plan" className="header-settings-link" onClick={close}>
        <HeaderSetting Icon={PlanIcon} needsStroke={true}>
          Your Plan
        </HeaderSetting>
      </Link>

      <Link to="/emails" className="header-settings-link" onClick={close}>
        <HeaderSetting Icon={EmailIcon} needsStroke={true}>
          Confirmed Emails
        </HeaderSetting>
      </Link>

      <Link to="/slack-integration" className="header-settings-link" onClick={close}>
        <HeaderSetting Icon={SlackIcon}>Slack Integration</HeaderSetting>
      </Link>

      <HeaderSetting
        Icon={LogoutIcon}
        extraClass="last"
        onClick={() => {
          logout();
          close();
        }}
        needsStroke={true}
      >
        Log Out
      </HeaderSetting>
    </div>
  );
};

const HeaderSetting = ({ Icon, extraClass = "", children, needsStroke, ...props }) => {
  return (
    <button className={`header-setting ${extraClass}`} {...props}>
      <Icon className={`icon ${needsStroke ? "needs-stroke" : ""}`} />

      <span>{children}</span>
    </button>
  );
};
