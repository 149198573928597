import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../media/closeLight.svg";
import "./ConfirmEmailBanner.css";
import debounce from "../debounce";
import { resendConfirmationEmail } from "./resendConfirmationEmail";
import { WrappedComponentContext } from "../../WrappedComponent";
import { Link, useLocation } from "react-router-dom";

const pagesNotIncluded = ["/", "/contact", "/pricing", "/plan"];

const ConfirmEmailBanner = () => {
  const { setBannerMessage, token, userInfo, emailResent, setEmailResent } =
    useContext(WrappedComponentContext);

  const [open, setOpen] = useState();
  const [hidden, setHidden] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setOpen(!emailResent);
  }, [emailResent]);

  useEffect(() => {
    setHidden(pagesNotIncluded.includes(location.pathname));
  }, [location.pathname]);

  const handleActionClick = debounce(async () => {
    const responseObj = await resendConfirmationEmail({
      email: userInfo?.email,
      authToken: token,
    });
    if (responseObj?.type === "success") {
      setEmailResent(true);
    }
    setBannerMessage(responseObj);
  });

  if (!open || hidden) return;

  return (
    <div className="warning-banner">
      <span
        style={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "25px",
        }}
      >
        Email needs confirming
      </span>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Link
          href="/"
          onClick={handleActionClick}
          className="purple-link"
          style={{ padding: "0px", margin: "0px" }}
        >
          Resend Confirmation Email
        </Link>

        <div
          style={{
            width: "1px",
            height: "16px",
            background: "#1e1e1e",
            marginLeft: "10px",
          }}
        />

        <button
          onClick={() => setOpen(false)}
          style={{
            background: "transparent",
            border: "none",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default ConfirmEmailBanner;
