import { useContext, useEffect, useRef, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism as theme } from "react-syntax-highlighter/dist/esm/styles/prism";
import { MdContentCopy as CopyIcon } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./EmbedChatbotSettings.css";
import { Link, useSearchParams } from "react-router-dom";
import { APP_URL } from "../../../config";
import { ChatbotFormContext } from "../../ChatbotForm";
import AdvancedDropdown from "../../../ManageChatbot/KnowledgeBase/AddSources/AdvancedDropdown/AdvancedDropdown";

export default function EmbedChatbotSettings({ manageChatbotLink = "" }) {
  const { existingData } = useContext(ChatbotFormContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const embedOption = searchParams.get("embed-option");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!embedOption) setSearchParams({ "embed-option": "widget" });
  }, []);

  const embedWidgetCode = `<script
  type="module"
  crossorigin
  id="majic_chatbot_widget"
  data-chatbot-token="${existingData?.token}"
  data-chatbot-customer-info=""
  src="${APP_URL}/chatbot-widget.js"
  async
></script>`;

  const embedInPageCode = `<div
  id="majic-chatbot-embed"
  style="width: 890px; max-width: 100%; height: 80vh; padding: 5px; box-sizing: border-box;"
></div>

<script
  type="module"
  crossorigin
  id="majic_chatbot_widget"
  data-chatbot-token="${existingData?.token}"
  data-embed="true"
  data-chatbot-customer-info=""
  src="${APP_URL}/chatbot-widget.js"
  async
></script>
`;

  const embedInPageContainerCode = `<div
  id="majic-chatbot-embed-container"
  style="width: 890px; max-width: 100%; height: 80vh; padding: 5px; box-sizing: border-box;"
>
  <div id="majic-chatbot-embed" style="width: 100%; height: 100%"></div>
</div>`;

  const embedInPageFunc = `function addScriptTag() {
  const majicContainer = document.getElementById("majic-chatbot-embed-container");
  const majicScript = document.createElement("script");
  majicScript.type = "module";
  majicScript.crossOrigin = "true";
  majicScript.id = "majic_chatbot_widget";
  majicScript.setAttribute("data-chatbot-token","${existingData?.token}");
  majicScript.setAttribute("data-embed","true");
  majicScript.setAttribute("data-chatbot-customer-info", "");
  majicScript.src = \`${APP_URL}/chatbot-widget.js?embed=${"${Date.now()}"}\`;
  majicScript.async = true;
  majicContainer.appendChild(majicScript);
}`;

  const EmbedTabMenu = (
    <div className="new-chatbot-tabs-container" style={{ marginTop: "12px" }}>
      <span
        className={`new-chatbot-tab-title ${
          embedOption === "widget" ? "selected" : ""
        }`}
        style={{ paddingBottom: "6px" }}
        onClick={() => setSearchParams({ "embed-option": "widget" })}
      >
        <span className="manage-chatbot-menu-item-text">Widget</span>
      </span>

      <span
        className={`new-chatbot-tab-title ${
          embedOption === "inpage" ? "selected" : ""
        }`}
        style={{ paddingBottom: "6px" }}
        onClick={() => setSearchParams({ "embed-option": "inpage" })}
      >
        <span className="manage-chatbot-menu-item-text">Embed In Page</span>
      </span>
    </div>
  );

  return (
    <div className="embed-chatbot-settings-container">
      {EmbedTabMenu}

      <div style={{ flex: 1 }}>
        <div></div>
        {embedOption === "widget" && (
          <>
            <p>
              <strong style={{ fontWeight: "500" }}>Widget</strong> is the
              standard chat interface that opens up when you click the button to
              open the chatbot.
            </p>

            <h3>Instructions</h3>

            <ul>
              <li>Copy the code below</li>
              <li>Paste it in the html file on your site</li>
            </ul>

            <ShowCodeBlock>{embedWidgetCode}</ShowCodeBlock>

            <AddCustomerData id={existingData?.id} />

            <div className="embed-advanced-container">
              <AdvancedDropdown openedHeight="auto">
                <>
                  <span style={{ margin: "5px 0px" }}>
                    If your site uses{" "}
                    <strong style={{ fontWeight: "500" }}>
                      Client-side rendering (CSR)
                    </strong>{" "}
                    & you want your site to show your chatbot only on specific
                    pages then you can use this css method to hide your chatbot
                    when you don't need it.
                  </span>
                  <ShowCodeBlock language="css">{cssMethodCode}</ShowCodeBlock>
                </>
              </AdvancedDropdown>
            </div>
          </>
        )}

        {embedOption === "inpage" && (
          <>
            <p>
              <strong style={{ fontWeight: "500" }}>Embed in page</strong> is
              for when you want to embed your chatbot on your site without
              needing the user to open it.
            </p>

            <h3>Instructions</h3>

            <ul>
              <li>
                Your chatbot will be shown within this container.
                <br />
                Place this container exactly where you want your chatbot to
                appear in your page (feel free to change styles as you need).
              </li>
            </ul>
            <ShowCodeBlock>{embedInPageCode}</ShowCodeBlock>

            <AddCustomerData id={existingData?.id} />

            <div className="embed-advanced-container">
              <AdvancedDropdown openedHeight="auto">
                <>
                  <span style={{ margin: "5px 0px" }}>
                    If your site is dynamically loading your html with
                    javascript follow these steps instead:
                  </span>
                  <ol>
                    <li>
                      Paste this in your html code where you want your chatbot
                      to appear
                    </li>
                  </ol>
                  <ShowCodeBlock copyTopOffset="8px">
                    {embedInPageContainerCode}
                  </ShowCodeBlock>

                  <ol>
                    <li value="2">
                      Run this javascript function after the content is loaded
                    </li>
                  </ol>
                  <ShowCodeBlock language="javascript" copyTopOffset="8px">
                    {embedInPageFunc}
                  </ShowCodeBlock>
                </>
              </AdvancedDropdown>
            </div>
          </>
        )}
      </div>

      <Link to={manageChatbotLink}>
        <button
          className="purple-button back-to-chatbot"
          style={{ maxWidth: "160px", marginTop: "20px" }}
        >
          Back to Chatbot
        </button>
      </Link>
    </div>
  );
}

const ShowCodeBlock = ({ language = "html", copyTopOffset, children }) => {
  const backgroundColor = "rgba(239,226,255, 0.4)";

  const customTheme = {
    ...theme,
  };

  return (
    <div className="embed-chatbot-code-container">
      <SyntaxHighlighter
        language={language}
        style={customTheme}
        customStyle={{
          backgroundColor: backgroundColor,
          borderRadius: "12px",
          fontSize: "14px",
          fontFamily: "Work Sans",
        }}
        showLineNumbers={false}
        wrapLines={true}
        lineProps={{ style: { backgroundColor: "transparent" } }}
      >
        {children}
      </SyntaxHighlighter>

      <div className="embed-chatbot-copy-code-button-container">
        <CopyButton text={children} copyTopOffset={copyTopOffset} />
      </div>
    </div>
  );
};

const AddCustomerData = ({ id }) => {
  return (
    <div
      className="embed-advanced-container"
      style={{ marginBottom: "0px", marginTop: "15px" }}
    >
      <AdvancedDropdown
        openedHeight="auto"
        dropdownText="Passing Customer Info"
      >
        <>
          <span style={{ margin: "5px 0px" }}>
            You can pass any customer info to your chatbot, such as a username
            or email address. Set the{" "}
            <span style={{ fontWeight: "600" }}>
              data-chatbot-customer-info
            </span>{" "}
            variable with your custom values like so:
          </span>
          <ShowCodeBlock
            copyTopOffset="4px"
            language="python"
          >{`data-chatbot-customer-info="firstname=Luke&email=skywalker@majic.ai"`}</ShowCodeBlock>

          <span>
            As an admin, you will be able to see the passed variables for every
            chat thread from the{" "}
            <Link
              className="link link-on-hover"
              to={`/chatbot/${id}/conversations`}
              style={{ fontWeight: "500" }}
            >
              Conversations
            </Link>{" "}
            tab.
          </span>
        </>
      </AdvancedDropdown>
    </div>
  );
};

const CopyButton = ({ text, copyTopOffset }) => {
  const [copied, setCopied] = useState(false);
  const copyRef = useRef(null);

  // When clicking outside remove the copied indicator
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (copyRef.current && !copyRef.current.contains(event.target)) {
        setCopied(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <button
        className="flex align-center justify-center copy-button-container"
        style={copyTopOffset ? { top: copyTopOffset } : {}}
        ref={copyRef}
      >
        <div className={`copy-button-copied ${copied ? "open" : ""}`}>
          Copied!
        </div>

        <CopyIcon size={20} />
      </button>
    </CopyToClipboard>
  );
};

const cssMethodCode = `#majic-chatbot-shadow {
  z-index: -2;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
}
body[data-page="/home"] #majic-chatbot-shadow {
  z-index: 2147483647;
  opacity: 1;
  pointer-events: all;
}
`;
