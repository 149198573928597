export function extractChatMessageLinkSources(messageData, maxSources) {
  try {
    let linkSources = [];
    const docs = messageData?.utilized_docs?.docs;

    if (docs?.length > 0) {
      for (let i = 0; i < docs.length; i++) {
        if (docs[i]?.source_url) {
          const isURL = docs[i]?.source_type === "url";
          if (isURL) linkSources.push(docs[i].source_url);
        }
      }
    }

    linkSources = [...new Set(linkSources)]; // Remove duplicates

    linkSources = linkSources.slice(0, maxSources); // Show only the first {maxSources} amount of urls

    return linkSources;
  } catch (error) {
    return [];
  }
}
