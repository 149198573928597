import { useContext, useEffect, useState } from "react";
import { InfoDescription } from "../../InfoDescription/InfoDescription";
import { FiPlus as PlusIcon } from "react-icons/fi";
import {
  AiOutlineCheck as Checkmark,
  AiOutlineClose as Close,
} from "react-icons/ai";
import { MdDragIndicator as DragIcon } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./SelectedQuestionsSelect.css";
import { ChatbotFormContext } from "../../ChatbotForm";
import Modal from "../../../Modal/Modal";
import MakeSuggestedQuestion from "./MakeSuggestedQuestion";
import SelectedQuestionItem from "./SelectedQuestionItem";
import { formatSuggestedQuestionForSaving } from "../../../ManageChatbot/Chat/SuggestedQuestions/formatSuggestedQuestionForSaving";

export const SuggestedQuestionsSelect = ({
  suggestedQuestions = null,
  setSuggestedQuestions = null,
  withoutTitle = false,
  externalButtonOpen,
  setExternalButtonOpen,
  extraStyles = {},
  noDivider = false,
}) => {
  const [newQuestionOpen, setNewQuestionOpen] = useState(false);

  const setModalOpen = (value) => {
    if (setExternalButtonOpen) {
      setExternalButtonOpen(value);
    } else {
      setNewQuestionOpen(value);
    }
  };

  const chatbotFormContext = useContext(ChatbotFormContext);

  if (!suggestedQuestions || !setSuggestedQuestions) {
    suggestedQuestions = chatbotFormContext.suggestedQuestions;
    setSuggestedQuestions = chatbotFormContext.setSuggestedQuestions;
  }

  const addNewQuestion = ({ question, customAnswer }) => {
    if (!question) return;
    const id = `${new Date().getTime()}${question}`;

    [question, customAnswer] = formatSuggestedQuestionForSaving(
      question,
      customAnswer
    );
    const formattedNewQuestion = { id, question, customAnswer };

    setSuggestedQuestions((prevQuestions) => {
      return [...prevQuestions, formattedNewQuestion];
    });
    setModalOpen(false);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    // Reorder suggested questions

    if (!destination) return;

    // Dropped in same place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const currentItem = suggestedQuestions.find(
      (obj) => obj.id === draggableId
    );

    const updatedItems = Array.from(suggestedQuestions);
    updatedItems.splice(source.index, 1);
    updatedItems.splice(destination.index, 0, currentItem);

    setSuggestedQuestions(updatedItems);
  };

  return (
    <>
      <div className="form-item-column" style={extraStyles}>
        {!withoutTitle && (
          <>
            <div className="chatbot-settings-title row-align-center">
              Conversation Starters
              <InfoDescription customDistance="30px">
                Conversation starters show up as clickable queries at the start
                of your chat sessions.
              </InfoDescription>
              <button
                className="purple-button add-suggested-question-button"
                onClick={() => setModalOpen(true)}
              >
                <PlusIcon size={20} />
              </button>
            </div>
            <br />
          </>
        )}

        <div className="set-suggested-questions-container">
          <div className="suggested-questions-content-container">
            {suggestedQuestions.length === 0 &&
              !newQuestionOpen &&
              !externalButtonOpen && (
                <div className="no-suggested-questions">
                  Click the plus icon to add a suggested starter
                </div>
              )}

            <div className="suggested-questions-content">
              {suggestedQuestions.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="selected-questions-column">
                    {(providedd) => (
                      <div
                        ref={providedd.innerRef}
                        {...providedd.droppableProps}
                      >
                        {suggestedQuestions.map((suggestedQuestion, index) => (
                          <Draggable
                            draggableId={suggestedQuestion.id}
                            index={index}
                            key={suggestedQuestion.id}
                            isDragDisabled={suggestedQuestion?.editIsOpen}
                          >
                            {(provided) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="suggested-question-container"
                                >
                                  {/* <span className='suggested-question-index'>{index + 1}.</span> */}
                                  <div className="suggested-question-drag-icon">
                                    <DragIcon size={20} />
                                  </div>
                                  <SelectedQuestionItem
                                    suggestedQuestion={suggestedQuestion}
                                    setSuggestedQuestions={
                                      setSuggestedQuestions
                                    }
                                    index={index}
                                  />
                                </div>
                              );
                            }}
                          </Draggable>
                        ))}
                        {providedd.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                ""
              )}
            </div>
          </div>

          {(newQuestionOpen || externalButtonOpen) && (
            <Modal
              extraClassName="mid-size"
              setOpen={setModalOpen}
              component={<MakeSuggestedQuestion submit={addNewQuestion} />}
              closeOnOutsideClick={false}
            />
          )}
        </div>
      </div>

      {!noDivider && (
        <div className="standard-divider" style={{ marginTop: "20px" }} />
      )}
    </>
  );
};
