import ChatbotFullLogo from "../media/chatbot_fulllogo.svg";
import "./Header.css";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Rotate as Hamburger } from "hamburger-react";
import { ReactComponent as SettingsIcon } from "../media/settings.svg";
import { HeaderSettings } from "./HeaderSettings";
import { Link } from "react-router-dom";

export const HeaderContext = createContext();
const { Provider } = HeaderContext;

export default function Header({
  token,
  logout,
  startingTransparent,
  hideMenu,
}) {
  const [color, setColor] = useState(
    startingTransparent ? "transparent" : "white"
  );
  const [open, setOpen] = useState(false);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const settingsRef = useRef(null);

  const toggleOpen = () => {
    if (open) {
      enableScroll();
    } else {
      disableScroll();
    }
    setOpen(!open);
  };

  const closeCard = () => {
    enableScroll();
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setColor("white");
    } else {
      listenScrollEvent(null);
    }
  }, [open]);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 30) {
      return setColor(startingTransparent ? "transparent" : "white");
    } else if (window.scrollY > 30) {
      return setColor("white");
    }
  };

  // Handles clicking outside of settings - closes the settings dropdown
  useEffect(() => {
    const handleClickOutsideSettings = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target))
        setSettingsOpen(false);
    };
    document.addEventListener("click", handleClickOutsideSettings);

    return () =>
      document.removeEventListener("click", handleClickOutsideSettings);
  }, []);

  // Handles background color changes
  // 1. When scrolling down we change to white
  // 2. When switching between a page that the starting color is transparent and white
  useEffect(() => {
    listenScrollEvent();

    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, [startingTransparent]);

  const providerValue = {
    token,
    logout,
    closeCard,
    enableScroll,
  };

  return (
    <Provider value={providerValue}>
      <div className="header-container" style={{ backgroundColor: color }}>
        <div className="header" style={{ position: "relative" }}>
          <div className="chatbot-fulllogo-container">
            <Link to={token ? "/chatbots" : "/"} onClick={closeCard}>
              <img src={ChatbotFullLogo} style={{ display: "flex" }} />
            </Link>
          </div>

          {!hideMenu && (
            <div className="desktop-links">
              <HeaderLinks />

              {token && (
                <button
                  className="settings-icon-container header-settings"
                  ref={settingsRef}
                >
                  <SettingsIcon
                    onClick={() => setSettingsOpen((prev) => !prev)}
                  />

                  <HeaderSettings
                    open={settingsOpen}
                    setOpen={setSettingsOpen}
                    logout={logout}
                  />
                </button>
              )}
            </div>
          )}

          <div className="chatbot-fulllogo-container hidden">
            <img src={ChatbotFullLogo} />
          </div>

          {!hideMenu && (
            <div className="hamburger-container">
              <Hamburger toggled={open} toggle={toggleOpen} direction="right" />
            </div>
          )}
        </div>

        {!hideMenu && (
          <div style={{ height: "0px", width: "100vw", position: "relative" }}>
            <div className={`mobile-links ${open ? "open" : ""}`}>
              <HeaderLinks />

              {token && (
                <HeaderSettings open={open} setOpen={setOpen} logout={logout} />
              )}
            </div>
          </div>
        )}
      </div>
    </Provider>
  );
}

const HeaderLinks = () => {
  const { token, logout, closeCard } = useContext(HeaderContext);
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    closeCard();
  };

  return (
    <>
      {token ? (
        <>
          <Link
            to="/chatbots"
            className={`header-link ${
              window.location.pathname === "/chatbots" ? "selected" : ""
            }`}
            onClick={handleLinkClick}
          >
            My Chatbots
          </Link>
          <Link
            to="/support"
            className={`header-link ${
              window.location.pathname === "/support" ? "selected" : ""
            }`}
            onClick={handleLinkClick}
          >
            Support
          </Link>
        </>
      ) : (
        <>
          <Link
            to="/"
            className={`header-link ${
              window.location.pathname === "/" ? "selected" : ""
            }`}
            onClick={handleLinkClick}
          >
            Home
          </Link>
          <Link
            to="/pricing"
            className={`header-link ${
              window.location.pathname === "/pricing" ? "selected" : ""
            }`}
            onClick={handleLinkClick}
          >
            Pricing
          </Link>
          <Link
            to="/contact"
            className={`header-link ${
              window.location.pathname === "/contact" ? "selected" : ""
            }`}
            onClick={handleLinkClick}
          >
            Contact
          </Link>
          <Link
            to="/login"
            className={`header-link bottom-middle ${
              window.location.pathname === "/login" ? "selected" : ""
            }`}
            onClick={handleLinkClick}
          >
            Log In
          </Link>
        </>
      )}
    </>
  );
};

function disableScroll() {
  // Get the current page scroll position
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  // if any scroll is attempted,
  // set this to the previous value
  window.onscroll = function () {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

function enableScroll() {
  window.onscroll = function () {};
}
