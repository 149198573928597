import React, { useContext } from "react";
import { AddPdfSourceContext } from "./AddPdfSource";

export const UploadingPdfs = () => {
  const { uploadedPdfs, pdfFiles, uploadAbortedRef } =
    useContext(AddPdfSourceContext);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <section
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <span
          style={{
            color: "#5F5968",
            fontFamily: "Work Sans",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Uploading now
        </span>

        <div className="uploaded-pdf" style={{ position: "relative" }}>
          <span>
            {uploadedPdfs.length}/{pdfFiles.length} files
          </span>

          <div
            style={{
              position: "absolute",
              height: "4px",
              background: "#FF5E8E",
              width: `${(uploadedPdfs.length / pdfFiles.length) * 100}%`,
              bottom: "0px",
              left: "0px",
              borderRadius: "0px 0px 0px 4px",
            }}
          />
        </div>
      </section>

      <button
        className="text-button"
        style={{ alignSelf: "flex-start" }}
        onClick={() => (uploadAbortedRef.current = true)}
      >
        Cancel Upload
      </button>
    </div>
  );
};

export default UploadingPdfs;
