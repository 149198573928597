import { createContext, useContext, useEffect, useRef, useState } from "react";
import { addSource } from "../addSource";
import { waitForIndexingToFinish } from "../../indexing";
import { KnowledgeBaseContext } from "../../KnowledgeBase";
import "./AddPdfSource.css";
import "../AddSource.css";
import { PdfPreview } from "./PdfPreview";
import { PdfFileUpload } from "./FileUpload";
import ChangePdfNames from "./ChangePdfNames";
import UploadingPdfs from "./UploadingPdfs";
import { checkPdfSize } from "./checkPdfSize";
import { WrappedComponentContext } from "../../../../WrappedComponent";

export const AddPdfSourceContext = createContext();

const AddPdfSource = ({ setModalOpen }) => {
  const [useOcr, setUseOcr] = useState(false);
  const [pdfFiles, setPdfFiles] = useState([]);

  // Upload states
  const [uploading, setUploading] = useState(false);
  const uploadAbortedRef = useRef(false);
  const [uploadedPdfs, setUploadedPdfs] = useState([]);

  // Change pdf titles states
  const [isChangingNames, setIsChangingNames] = useState(false);
  const [changedNames, setChangedNames] = useState([]);
  const [successfulyChangedNames, setSuccessfullyChangedNames] = useState([]);
  const changeNamesRef = useRef(false);

  const { chatbotId, token, pdfSources, setPdfSources, setOpenIntervals } =
    useContext(KnowledgeBaseContext);
  const { setBannerMessage, fetchUserInfo } = useContext(
    WrappedComponentContext
  );

  const addPdfSource = async ({ title, file }) => {
    try {
      const pdfTitle = title ? title : file.name;

      const formData = new FormData();
      formData.append("content_item[type]", "document");
      formData.append("content_item[document]", file);
      formData.append("content_item[title]", pdfTitle);

      const newContentItem = await addSource({
        requestBody: formData,
        type: "document",
        chatbotId,
        token,
      });
      if (!newContentItem) throw { title: "Failed to create content item." };
      if (newContentItem?.isError) throw newContentItem;

      const date = new Date();
      setPdfSources((prevPdfSources) => {
        return [{ ...newContentItem, title, date }, ...prevPdfSources];
      });

      waitForIndexingToFinish({
        chatbotId,
        token,
        contentItemId: newContentItem.id,
        setOpenIntervals,
        setSources: setPdfSources,
        fetchUserInfo,
      });

      return newContentItem;
    } catch (error) {
      console.error(error);
      setBannerMessage({ ...error, type: "error" });
      return error;
    }
  };

  const uploadPdfs = async () => {
    try {
      if (pdfFiles.length === 0) return;
      setUploading(true);

      for (let i = 0; i < pdfFiles.length; i++) {
        if (uploadAbortedRef.current) throw "PDF upload aborted";

        if (checkPdfSize(pdfFiles[i])) {
          const uploadedFile = await addPdfSource({ file: pdfFiles[i] });
          if (!uploadedFile) throw "An error occurred.";
          if (uploadedFile?.isError) throw uploadedFile;

          setUploadedPdfs((prevUploaded) => [...prevUploaded, uploadedFile]);
        } else {
          setBannerMessage({
            type: "error",
            title: "File size too large",
            message: `${pdfFiles[i].name}. Must be under 15MB`,
          });
        }
      }

      if (uploadedPdfs?.length > 0) {
        setBannerMessage({
          type: "success",
          title: "Success!",
          message: "Your files were uploaded",
        });
        setIsChangingNames(true);
        setUploading(false);
      } else {
        setUploading(false);
        setModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      if (error?.isError) setBannerMessage({ ...error, type: "error" });
      else if (error === "PDF upload aborted") {
        setBannerMessage({ type: "error", title: "PDF upload aborted" });
      } else {
        setBannerMessage({ type: "error", title: "Failed to add pdfs" });
      }
      setModalOpen(false);
    }
  };

  const value = {
    pdfFiles,
    setPdfFiles,
    setModalOpen,
    uploading,
    setUploading,
    uploadAbortedRef,
    uploadedPdfs,
    setUploadedPdfs,
    isChangingNames,
    setIsChangingNames,
    changedNames,
    setChangedNames,
    successfulyChangedNames,
    setSuccessfullyChangedNames,
    changeNamesRef,
  };

  if (isChangingNames) {
    return (
      <AddPdfSourceContext.Provider value={value}>
        <ChangePdfNames />
      </AddPdfSourceContext.Provider>
    );
  }

  if (uploading) {
    return (
      <AddPdfSourceContext.Provider value={value}>
        <UploadingPdfs />
      </AddPdfSourceContext.Provider>
    );
  }

  return (
    <AddPdfSourceContext.Provider value={value}>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <PdfFileUpload setPdfFiles={setPdfFiles} />

        {pdfFiles?.length > 0 && (
          <section>
            <span
              style={{
                color: "#5F5968",
                fontFamily: "Work Sans",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Your file queue
            </span>
            <div
              className={`uploaded-pdfs-container ${
                pdfFiles?.length > 4 && "with-scroll"
              }`}
            >
              {pdfFiles.map((file, index) => (
                <PdfPreview file={file} key={index} setPdfFiles={setPdfFiles} />
              ))}
            </div>
          </section>
        )}

        <button
          className={`purple-button ${pdfFiles?.length === 0 && "muted"}`}
          style={{ alignSelf: "flex-start" }}
          onClick={uploadPdfs}
        >
          Upload
        </button>
      </div>
    </AddPdfSourceContext.Provider>
  );
};

export default AddPdfSource;
