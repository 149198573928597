import { useState, createContext, useContext } from "react";
import "./EditThemes.css";
import { ChatbotFormContext } from "../../../ChatbotForm";
import { PickColors } from "./PickColors";
import { defaultDarkColors, defaultLightColors } from "./defaultColors";

export const EditThemesContext = createContext();

export default function EditThemes() {
  const {
    theme,
    setTheme,
    setLightColors,
    setDarkColors,
    lightColors,
    darkColors,
  } = useContext(ChatbotFormContext);

  const [selectedTheme, setSelectedTheme] = useState(
    theme === "system" ? "light" : theme
  );

  const handleThemeChange = (e) => {
    setTheme(e.target.value);
    if (e.target.value !== "system") setSelectedTheme(e.target.value);
  };

  const reloadDefault = () => {
    selectedTheme === "light"
      ? setLightColors(defaultLightColors)
      : setDarkColors(defaultDarkColors);
  };

  const value = {
    hasBothThemes: theme === "system",
    selectedTheme: selectedTheme,
    setColors: selectedTheme === "light" ? setLightColors : setDarkColors,
    colors: selectedTheme === "light" ? lightColors : darkColors,
  };

  return (
    <EditThemesContext.Provider value={value}>
      <div>
        <h4>Interface Colors</h4>
        <div style={{ height: "1px", background: "#A6A6A6" }} />

        <div className="form-item-row">
          <span className="chatbot-settings-title">Theme</span>
          <select
            name="theme"
            id="theme"
            className="small-select"
            value={theme}
            onChange={handleThemeChange}
          >
            <option value="system">System</option>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </select>
        </div>

        {theme === "system" && (
          <div>
            <br />
            <div className="manage-chatbot-menu-container">
              <span
                className={`new-chatbot-tab-title ${
                  selectedTheme === "light" ? "selected" : ""
                }`}
                onClick={() => setSelectedTheme("light")}
              >
                <span className="manage-chatbot-menu-item-text">
                  Light Mode Colors
                </span>
              </span>

              <span
                className={`new-chatbot-tab-title ${
                  selectedTheme === "dark" ? "selected" : ""
                }`}
                onClick={() => setSelectedTheme("dark")}
              >
                <span className="manage-chatbot-menu-item-text">
                  Dark Mode Colors
                </span>
              </span>
            </div>
          </div>
        )}

        <div className="new-chatbot-divider secondary nomargin" />

        <button
          className="text-button"
          onClick={reloadDefault}
          style={{ marginTop: "14px", marginBottom: "7px" }}
        >
          Reload Default
        </button>

        <div className="new-chatbot-divider secondary" />

        {selectedTheme === "light" ? (
          <PickColors themeId="light-picker" />
        ) : (
          <PickColors themeId="dark-picker" />
        )}
      </div>
    </EditThemesContext.Provider>
  );
}
