import getStripePlanInfo from "../Pricing/getStripePlanInfo";
import { createNewChatbot } from "../SubscriptionStates/Checkout/createNewChatbot";
import { API_URL } from "../config";

const getStripeUrl = async ({
  authKey,
  plan,
  interval,
  setBannerMessage,
  checkForEventTriggers,
}) => {
  try {
    if (!plan) return null;

    if (plan.toLowerCase() === "free") {
      const selectedFree = await selectFreePlan(authKey, setBannerMessage);

      if (selectedFree) {
        // If chatbot was made before checkout it is created here
        await createNewChatbot({ token: authKey, checkForEventTriggers });

        return "/chatbots";
      } else {
        return null;
      }
    }

    const { priceId, planId } = await getStripePlanInfo({
      token: authKey,
      planName: plan,
      interval,
    });
    const checkoutEndpoint = `${API_URL}/stripe/checkout_sessions?price_id=${priceId}`;
    const headers = {
      Authorization: authKey,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ product_id: planId });
    const method = "POST";

    const checkoutResponse = await fetch(checkoutEndpoint, {
      method,
      headers,
      body,
    });
    if (!checkoutResponse.ok)
      throw new Error("Failed to fetch slack plan link.");

    const checkoutData = await checkoutResponse.json();

    return checkoutData?.stripe_checkout_session_url;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const selectFreePlan = async (authKey, setBannerMessage) => {
  try {
    if (!authKey) return;

    const endpoint = `${API_URL}/accounts/plan_selection_made`;
    const headers = { Authorization: authKey };
    const method = "POST";

    const response = await fetch(endpoint, { method, headers });
    if (!response.ok) {
      throw new Error("Failed to select free plan");
    }

    const data = await response.json();

    return data?.success;
  } catch (error) {
    console.error(error);
    setBannerMessage({
      title: "Failed to select plan",
      message: "Free plan selection failed",
    });
    return false;
  }
};

export default getStripeUrl;
