import { ReactComponent as ReloadAction } from "../media/reloadAction.svg";
import { ReactComponent as CloseAction } from "../media/closeAction.svg";
import { useContext, useState } from "react";
import { API_URL } from "../config";
import { WrappedComponentContext } from "../WrappedComponent";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import { useConfirm } from "../Tools/Confirm/Confirm";

const ConfirmedEmailActions = ({ emailObj, setEmailList }) => {
  const [state, setState] = useState({
    isRemoving: false,
    isResending: false,
  });

  const { token, logout, setBannerMessage } = useContext(WrappedComponentContext);
  const confirm = useConfirm();

  const isVerified = emailObj?.verified;
  const resendActionDisabled = state?.isResending || isVerified;
  const deleteActionDisabled = state?.isRemoving;

  const resend = async () => {
    try {
      if (resendActionDisabled) return;
      setState((prev) => ({ ...prev, isResending: true }));

      const endpoint = `${API_URL}/trusted_email_addresses/${emailObj?.id}/resend_confirmation_email`;
      const method = "PUT";
      const headers = { Authorization: token };
      const res = await fetch(endpoint, { method, headers });
      if (!res.ok) throw new ErrorFormat("", res.status, logout);

      setBannerMessage({
        type: "success",
        title: "Email Sent",
        message: `Confirmation link resent to ${emailObj?.email}.`,
      });
    } catch (error) {
      setBannerMessage({
        type: "error",
        title: "Could not resend confirmation email.",
      });
    } finally {
      setState((prev) => ({ ...prev, isResending: false }));
    }
  };

  const remove = async () => {
    try {
      if (deleteActionDisabled) return;

      const confirmed = await confirm({
        title: "Delete Email",
        description: "Are you sure you want to delete this email?",
      });
      if (!confirmed) return;

      setState((prev) => ({ ...prev, isRemoving: true }));

      const endpoint = `${API_URL}/trusted_email_addresses/${emailObj?.id}`;
      const method = "DELETE";
      const headers = { Authorization: token };
      const res = await fetch(endpoint, { method, headers });
      if (!res.ok) throw new ErrorFormat("", res.status, logout);

      // Remove deleted email from list
      setEmailList((prevList) =>
        prevList.filter((currentEmail) => currentEmail?.id !== emailObj?.id)
      );
    } catch (error) {
      setBannerMessage({ type: "error", message: "Could not remove email." });
    } finally {
      setState((prev) => ({ ...prev, isRemoving: false }));
    }
  };

  return (
    <div className="row-space-between" style={{ gap: "5px" }}>
      <div className={`action-icon-container ${resendActionDisabled ? "disabled" : ""}`}>
        <ReloadAction onClick={resend} />
        <span
          className="action-icon-text"
          style={{ width: isVerified ? "148px" : "185px", right: "-10px" }}
        >
          {isVerified ? "Email already verified" : "Resend confirmation email"}
        </span>
      </div>

      <div className={`action-icon-container ${deleteActionDisabled ? "disabled" : ""}`}>
        <CloseAction onClick={remove} />
        <span className="action-icon-text" style={{ width: "97px", right: "-10px" }}>
          Remove email
        </span>
      </div>
    </div>
  );
};

export default ConfirmedEmailActions;
