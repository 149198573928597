import { useContext, useEffect, useState } from "react";
import { ChatbotFormContext } from "../ChatbotForm";
import Dropdown from "../../Tools/Dropdown/Dropdown";
import { findBestPlan } from "../../Tools/findBestPlan";
import { WrappedComponentContext } from "../../WrappedComponent";
import { API_URL } from "../../config";

export default function ChatbotFormModelSelection() {
  const [models, setModels] = useState();
  const [plan, setPlan] = useState();

  const { defaultModel, setDefaultModel, userInfo } =
    useContext(ChatbotFormContext);
  const { setBannerMessage } = useContext(WrappedComponentContext);

  useEffect(() => {
    async function fetchModels(setModels) {
      try {
        const response = await fetch(`${API_URL}/models`);
        const modelsData = await response.json();

        const modelsArray = Object.entries(modelsData).map(([key, value]) => ({
          value: key,
          ...value,
        }));

        const modelsArrayFiltered = modelsArray.filter(
          (model) => model?.active
        );

        setModels(modelsArrayFiltered);
      } catch (error) {
        console.error(error);
      }
    }
    fetchModels(setModels);
  }, []);

  useEffect(() => {
    // Sets the current model
    if (models) {
      if (!defaultModel?.value) {
        setDefaultModel(models[0]);
      }
    }
  }, [models]);

  useEffect(() => {
    if (userInfo?.subscriptions) setPlan(findBestPlan(userInfo.subscriptions));
  }, [userInfo]);

  // Gives full value with description when there is none
  useEffect(() => {
    if (defaultModel && !defaultModel?.description && models) {
      const fullModelData =
        models.find((model) => model?.value === defaultModel?.value) ??
        models[0];
      setDefaultModel(fullModelData);
    }
  }, [defaultModel, models]);

  const setSelectedItem = (value) => {
    const isGpt4 = value?.title === "GPT-4 Turbo";
    if (!isGpt4) {
      setDefaultModel(value);
      return;
    }

    if (["pro", "business", "elite"].includes(plan?.tier)) {
      setDefaultModel(value);
    } else {
      setBannerMessage({
        type: "error",
        title: "Paid plan required for GPT-4.",
        message:
          "This model is only available once your account is upgraded to a paid plan.",
      });
    }
  };

  return (
    <div
      className="form-item-row"
      id="chatbot-model-selection"
      style={{ justifyContent: "flex-start" }}
    >
      <span className="chatbot-settings-title select-model-title">
        AI Language Model
      </span>

      <Dropdown
        items={models}
        selectedItem={defaultModel}
        setSelectedItem={setSelectedItem}
        customWidth="323px"
      />
    </div>
  );
}
