import { useContext, useEffect, useRef, useState } from "react";
import { EditThemesContext } from "./EditThemes";
import { SketchPicker } from "react-color";
import { PickColorsContext } from "./PickColors";

export const PickColor = ({ title, colorKey }) => {
  const [open, setOpen] = useState(false);

  const { hasBothThemes, colors, selectedTheme } =
    useContext(EditThemesContext);
  const { setColor } = useContext(PickColorsContext);

  const colorPickerRef = useRef(null);

  // Closes the color picker when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="form-item-row noborder auto">
      <span className="chatbot-settings-title edit-theme-title">
        {title}
        {hasBothThemes && (
          <span style={{ opacity: 0.4, fontSize: "12px" }}>
            {selectedTheme === "light" ? " - Light" : " - Dark"} mode
          </span>
        )}
      </span>

      <div className="color-picker-container" ref={colorPickerRef}>
        <div
          className="color-example"
          style={{ backgroundColor: colors[colorKey] }}
          onClick={() => setOpen(!open)}
        />
        <span className="color-input" onClick={() => setOpen(!open)}>
          {colors[colorKey]}
        </span>

        {open && (
          <SketchPicker
            className="color-picker"
            color={colors[colorKey]}
            onChangeComplete={(newColor) => setColor(newColor, colorKey)}
          />
        )}
      </div>
    </div>
  );
};
