import { useContext, useEffect, useState } from "react";
import DocumentTitle from "../../Tools/DocumentTitle/DocumentTitle";
import "./ResetPasswordPage.css";
import { API_URL } from "../../config";
import { ErrorFormat } from "../../Tools/Errors/ErrorFormatting";
import StatusCard from "../../Tools/StatusCard/StatusCard";
import { WrappedComponentContext } from "../../WrappedComponent";
import { useNavigate } from "react-router-dom";
import debounce from "../../Tools/debounce";
import CircularIndeterminate from "../../Tools/Loading/CircularIndeterminate";

export default function ResetPasswordPage({}) {
  const [email, setEmail] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { logout, userInfo, fetchUserInfo, token, setBannerMessage } =
    useContext(WrappedComponentContext);

  useEffect(() => {
    if (!token) navigate("/login");
  }, [token]);

  useEffect(() => {
    if (!userInfo) {
      fetchUserInfo();
    } else {
      setEmail(userInfo?.email);
    }
  }, [userInfo]);

  useEffect(() => {
    if (successStatus) {
      navigate("/password/reset/sent");
    }
  }, [successStatus]);

  // const handleEmailChange = (e) => setEmail(e.target.value);

  const sendResetPassword = async () => {
    setLoading(true);
    try {
      if (!email) return;

      const requestBody = { user: { email } };
      const response = await fetch(API_URL + "/users/password", {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(requestBody),
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to send reset password link.",
          response.status,
          logout
        );

      setLoading(false);
      setSuccessStatus(true);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setBannerMessage({ type: "error", title: "Failed to reset password" });
    }
  };

  const sendResetPasswordDebounce = debounce(() => sendResetPassword());

  return (
    <div className="page-card-container">
      <div className="page-card small min-height-mid">
        <DocumentTitle>RESET PASSWORD</DocumentTitle>

        {successStatus ? (
          <div style={{ marginTop: "41px" }}>
            <StatusCard
              success={true}
              title="Email Sent"
              description="Check your email for a link to reset your password."
              to="/"
            />
          </div>
        ) : (
          <div className="send-password-reset-container">
            <p>You can reset the password for your account below:</p>

            <div className="send-password-reset">
              <p style={{ overflowWrap: "break-word" }}>
                Sending to:{" "}
                <i>
                  <b>{email}</b>
                </i>
              </p>

              <button
                className={`purple-button small-text ${!email && "muted"}`}
                onClick={email ? sendResetPasswordDebounce : () => {}}
              >
                {loading && <CircularIndeterminate color="white" />}
                Send Reset Link
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
