import { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { API_URL, DEPLOYED_APP_URL } from "../config";
import { WrappedComponentContext } from "../WrappedComponent";

const MiniHostedChatbot = () => {
  const { chatbotAlias } = useParams();
  const { setBannerMessage, setHideMenu } = useContext(WrappedComponentContext);

  const scriptRef = useRef(null);

  useEffect(() => {
    setHideMenu(true);

    addScriptTag().then((scriptTag) => {
      if (scriptTag) {
        removeCurrentWidgetData();

        scriptRef.current = scriptTag;
        document.body.appendChild(scriptRef.current);
      }
    });

    return () => {
      if (scriptRef.current) {
        removeCurrentWidgetData();

        // This gets the script tag to put back our chatbot widget that we show on our site
        const ourMajicScript = getMajicScriptTag(
          "812b9adeb7c669639f52bc7b5b9837c7bc157e4f",
          true
        );
        document.body.appendChild(ourMajicScript);

        setHideMenu(false);
      }
    };
  }, []);

  const removeCurrentWidgetData = () => {
    const shadowToRemove = document.getElementById("majic-chatbot-shadow");
    document.body.removeChild(shadowToRemove);
    const oldScript = document.getElementById("majic_chatbot_widget");
    document.body.removeChild(oldScript);
  };

  const addScriptTag = async () => {
    try {
      const chatbotData = await getChatbotData();
      if (!chatbotData?.token) return null;

      const majicScript = getMajicScriptTag(chatbotData.token);
      return majicScript;
    } catch (error) {
      return null;
    }
  };

  const getChatbotData = async () => {
    try {
      const endpoint = `${API_URL}/chat_bots/public/alias/${chatbotAlias}`;
      const response = await fetch(endpoint);
      const data = await response.json();

      if (!response.ok) throw new Error("Error");

      if (!data?.public_chat_bot_enabled) {
        throw new Error("Public chatbot disabled.");
      }

      return data;
    } catch (error) {
      setBannerMessage({ type: "error", title: error?.message });
      return null;
    }
  };

  const getMajicScriptTag = (chatbotToken, isProd = false) => {
    try {
      const majicScript = document.createElement("script");
      majicScript.type = "module";
      majicScript.crossOrigin = "true";
      majicScript.id = "majic_chatbot_widget";
      majicScript.setAttribute("data-chatbot-token", chatbotToken);
      majicScript.src = `${
        isProd ? "https://majic.ai" : DEPLOYED_APP_URL
      }/chatbot-widget.js?embed=${Date.now()}`;
      majicScript.async = true;

      return majicScript;
    } catch (error) {
      return null;
    }
  };

  return <div id="mini-chatbot-container"></div>;
};

export default MiniHostedChatbot;
