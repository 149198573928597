// This component is reausable for any time a toggle is needed inline with text in a form

import Toggle from "../../Toggle/Toggle";

const ChatbotFormInlineToggle = ({
  open,
  setOpen,
  extraClass,
  hasToggleContainer = true, // Toggle container has some styles that we don't always want so we let this be toggled
  children,
}) => {
  return (
    <div className={`form-item-row ${extraClass}`}>
      <span className="chatbot-settings-title row-align-center">{children}</span>

      <div className={`${hasToggleContainer && "chatbot-settings-toggle-container"}`}>
        <Toggle open={open} setOpen={setOpen} />
      </div>
    </div>
  );
};

export default ChatbotFormInlineToggle;
