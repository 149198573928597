import "./BannerMessage.css";
import { ReactComponent as CloseClear } from "../../media/close-clear.svg";
import { ReactComponent as ErrorIcon } from "../../media/errorBannerIcon.svg";
import { ReactComponent as SuccessIcon } from "../../media/successBannerIcon.svg";
import { ReactComponent as InfoBannerIcon } from "../../media/infoBannerIcon.svg";
import { useEffect, useState } from "react";

const BANNER_CLOSE_TIME = 3000; // 3s

const BannerMessage = ({ message, setMessage = () => {} }) => {
  const [timeoutId, setTimeoutId] = useState();
  const [forceClosed, setForceClosed] = useState(false);

  const type = message?.type;

  let BannerIcon =
    type === "info"
      ? InfoBannerIcon
      : type === "error"
      ? ErrorIcon
      : SuccessIcon;

  useEffect(() => {
    if (message) {
      const timeout = setTimeout(() => {
        handleCloseBanner();
      }, BANNER_CLOSE_TIME);

      setTimeoutId(timeout);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [message]);

  const handleCloseClick = () => {
    clearTimeout(timeoutId);

    handleCloseBanner();
  };

  const handleCloseBanner = () => {
    setForceClosed(true);
    setTimeout(() => {
      setMessage(null);
      setForceClosed(false);
    }, 200);
  };

  return (
    <div
      className={`banner-top-right ${message && !forceClosed ? "open" : ""}`}
    >
      <div className="banner-container" style={bannerStyles[type]}>
        <div className="banner-content-container">
          <BannerIcon style={{ minHeight: "30px", minWidth: "30px" }} />

          <div className="banner-content">
            <h3>{message?.title}</h3>

            <span>{message?.message}</span>
          </div>
        </div>

        <button>
          <CloseClear
            style={{
              height: "20px",
              width: "20px",
              stroke: bannerStyles[type]?.stroke,
            }}
            onClick={handleCloseClick}
          />
        </button>
      </div>
    </div>
  );
};

const bannerStyles = {
  info: {
    border: "1px solid #7E00E2",
    stroke: "#7E00E2",
    background: "#EFE2FF",
  },

  error: {
    border: "1px solid #ED4545",
    background: "#FBE3E2",
    stroke: "#ED4545",
  },

  success: {
    border: "1px solid #28A853",
    background: "#DFF2E6",
    stroke: "#28A853",
  },
};

export default BannerMessage;
