import { useContext, useEffect, useState } from "react";
import addPurple from "../../media/addPurple.svg";
import { formatSuggestedQuestionsString } from "../Chat/SuggestedQuestions/formatSuggestedQuestionsString";
import { SuggestedQuestionsSelect } from "../../ChatbotForm/ChatbotFormItems/ChatbotFormSelectedQuestions/SelectedQuestionsSelect";
import { ChatbotDemo } from "../../ChatbotForm/ChatbotFormTabs/Look&Feel/ChatbotPreview/ChatbotDemo/ChatbotDemo";
import { WrappedComponentContext } from "../../WrappedComponent";
import { API_URL } from "../../config";
import { ErrorFormat } from "../../Tools/Errors/ErrorFormatting";
import { InfoDescription } from "../../ChatbotForm/InfoDescription/InfoDescription";

const KBStarters = ({ chatbot, setChatbot }) => {
  const { token, logout, setBannerMessage } = useContext(
    WrappedComponentContext
  );

  const [newStarterOpen, setNewStarterOpen] = useState(false);
  const [starters, setStarters] = useState([]);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    const existingSuggestedQuestions = formatSuggestedQuestionsString(
      chatbot?.suggested_questions
    );
    setStarters([...existingSuggestedQuestions]);
  }, []);

  const changeStarters = (value) => {
    setChanged(true);
    setStarters(value);
  };

  const saveStarters = async () => {
    if (!changed || saving || saved) return;
    setSaving(true);

    try {
      const formData = new FormData();
      let formattedStarters = starters.map((question) => {
        return `${question?.question}${
          question?.customAnswer
            ? `%ANS%${question?.customAnswer.replace(/\n/g, "%NL")}`
            : ""
        }`;
      });
      formattedStarters = formattedStarters.filter(
        (question) => !["", " "].includes(question)
      );
      const startersString = formattedStarters.join("\n");
      formData.append("chat_bot[suggested_questions]", startersString);

      const endpoint = `${API_URL}/chat_bots/${chatbot?.id}`;
      const method = "PUT";
      const body = formData;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { method, body, headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to update chatbot starters",
          response.status,
          logout
        );

      const updatedChatbot = await response.json();

      setSaving(false);
      setSaved(true);
      setBannerMessage({
        type: "success",
        title: "Conversation Starters Saved!",
      });
      setChatbot(updatedChatbot);
      setChanged(false);

      setTimeout(() => {
        setSaved(false);
      }, 3000);
    } catch (error) {
      setSaving(false);
      setBannerMessage({
        type: "error",
        title: "Failed to update chatbot starters",
      });
    }
  };

  return (
    <div>
      <button
        className="add-source"
        onClick={() => setNewStarterOpen(true)}
        style={{ marginTop: "6px" }}
      >
        <img src={addPurple} alt="Add Starter" />
        <span>Conversation Starter</span>
      </button>

      <div
        className="kb-title flex align-center"
        style={{
          minWidth: "100%",
          marginTop: "30px",
          position: "relative",
          lineHeight: "18px",
          marginBottom: "15px",
        }}
      >
        Current Conversation Starters
        <InfoDescription customDistance="30px">
          Conversation starters show up as clickable queries at the start of
          your chat sessions.
        </InfoDescription>
      </div>

      <SuggestedQuestionsSelect
        suggestedQuestions={starters}
        setSuggestedQuestions={changeStarters}
        externalButtonOpen={newStarterOpen}
        setExternalButtonOpen={setNewStarterOpen}
        withoutTitle={true}
        extraStyles={{ marginTop: "3px" }}
        noDivider={true}
      />

      {(changed || saved) && (
        <button
          className={`
          purple-button 
          ${saving || saved ? "muted" : ""}
        `}
          onClick={saveStarters}
          style={{ marginTop: "20px" }}
        >
          {saving ? "Saving..." : saved ? "Saved" : "Save"}
        </button>
      )}

      <div
        className="kb-title"
        style={{
          marginTop: "10px",
          transform: "translateY(16px)",
        }}
      >
        Chatbot Preview
      </div>

      <div style={{ width: "418px", maxWidth: "100%" }}>
        <ChatbotDemo
          selectedTheme={chatbot?.design_colors?.theme ?? "light"}
          customDemoData={{
            name: chatbot?.name,
            theme: chatbot?.design_colors?.theme ?? "light",
            darkColors: chatbot?.design_colors?.dark,
            lightColors: chatbot?.design_colors?.light,
            image: chatbot?.avatar_url,
            getImage: () => {
              return chatbot?.avatar_url;
            },
            welcomeMessage: chatbot?.welcome_message,
            showImageInReplies: chatbot?.show_chatbot_avatar_in_responses,
            showTimeStamps: chatbot?.show_timestamps_in_responses,
            suggestedQuestions: starters,
          }}
        />
      </div>
    </div>
  );
};

export default KBStarters;
