import { useContext, useEffect, useState } from "react";
import WaitingWithText from "../../../Tools/ChatWaiting/WaitingWithText";
import StatusCard from "../../../Tools/StatusCard/StatusCard";
import { API_URL } from "../../../config";
import { useParams, useSearchParams } from "react-router-dom";
import { WrappedComponentContext } from "../../../WrappedComponent";
import { ErrorFormat } from "../../../Tools/Errors/ErrorFormatting";
import EditForm from "./EditForm";
import ShowForm from "./ShowForm";

const ManageForm = () => {
  const [form, setForm] = useState();
  const [errorLoadingForm, setErrorLoadingForm] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const isEdit = searchParams.get("edit") === "true";

  const { chatbotId, tabItemId } = useParams();
  const { token, logout } = useContext(WrappedComponentContext);

  useEffect(() => {
    async function getForm() {
      try {
        const endpoint = `${API_URL}/chat_bots/${chatbotId}/forms/${tabItemId}`;
        const headers = { Authorization: token };

        const res = await fetch(endpoint, { headers });
        if (!res.ok) throw new ErrorFormat("Failed to save form", res.status, logout);

        let data = await res.json();
        data.form_fields_attributes = data.form_fields
          .sort((a, b) => a.order - b.order)
          .map((field, index) => ({
            ...field,
            dragId: `${index}`,
          }));
        setForm(data);
      } catch (error) {
        setErrorLoadingForm(true);
      }
    }
    getForm();
  }, []);

  if (!form) {
    return errorLoadingForm ? (
      <div className="status-card-page-container">
        <StatusCard
          success={false}
          title="Form not found"
          description="We were unable to find this form."
          to={`/chatbot/settings/${chatbotId}/forms`}
          customUrlText="Back to Forms"
        />
      </div>
    ) : (
      <div style={{ margin: "10px 0px" }}>
        <WaitingWithText>Loading Form</WaitingWithText>
      </div>
    );
  }

  return isEdit ? <EditForm formData={form} /> : <ShowForm form={form} />;
};

export default ManageForm;
