import { useEffect, useRef, useState } from "react";
import { removeEventTrigger } from "./addOrRemoveEventTrigger";

// Component for adding all event triggers that were saved in localstroage
const EventTriggers = ({ recheckEventTriggers, setRecheckEventTriggers }) => {
  const [eventTriggers, setEventTriggers] = useState([]);

  useEffect(() => getEventTriggers(), []);
  useEffect(() => {
    if (recheckEventTriggers) {
      getEventTriggers();
      setRecheckEventTriggers(false);
    }
  }, [recheckEventTriggers]);

  const getEventTriggers = () => {
    try {
      let fetchedTriggers = JSON.parse(localStorage.getItem("event-triggers"));
      fetchedTriggers = fetchedTriggers.filter(
        (trigger) => typeof trigger === "string"
      );
      fetchedTriggers = [...new Set(fetchedTriggers)];

      if (Array.isArray(fetchedTriggers) && fetchedTriggers?.length > 0) {
        setEventTriggers(fetchedTriggers);
      }
    } catch (error) {}
  };

  return eventTriggers?.length > 0 ? (
    <>
      {eventTriggers.map((triggerType) => (
        <EventTrigger triggerType={triggerType} key={triggerType} />
      ))}
    </>
  ) : (
    ""
  );
};

// component for adding a single event trigger script tag
export const EventTrigger = ({ triggerType, customFunc = () => {} }) => {
  const scriptRef = useRef(null);

  useEffect(() => {
    const eventTrigger = eventTriggers[triggerType];

    removeEventTrigger(triggerType);
    customFunc();

    if (eventTrigger) {
      scriptRef.current = document.createElement("script");
      scriptRef.current.innerHTML = eventTrigger;
      document.head.appendChild(scriptRef.current);
    }

    return () => {
      if (scriptRef.current) {
        document.head.removeChild(scriptRef.current);
      }
    };
  }, []);

  return "";
};

// gtag format is: "<script> gtag('event', 'conversion', {'send_to': 'place_to_send_to'}); </script>"
const eventTriggers = {
  registration:
    "gtag('event', 'conversion', {'send_to': 'AW-11453435908/08mMCLbh6IoZEISgttUq'});",
  chatbotCreation:
    "gtag('event', 'conversion', {'send_to': 'AW-11453435908/z78dCO-q6YoZEISgttUq'});",
  paymentSuccess:
    "gtag('event', 'conversion', { 'send_to': 'AW-11453435908/hTgnCNWK9IoZEISgttUq', 'transaction_id': '' });",
  pricingPage: "gtag('config', 'AW-11453435908');",
  firstSource:
    "gtag('event', 'conversion', {'send_to': 'AW-11453435908/z-UICPOdzY0ZEISgttUq'});",
};

export default EventTriggers;
