import { useContext, useEffect, useState } from "react";
import {
  AiOutlineEye as EyeIcon,
  AiOutlineEyeInvisible as EyeCloseIcon,
} from "react-icons/ai";
import useQuery from "../../Tools/useQuery";
import { useNavigate } from "react-router-dom";
import { hasLowerCase, hasNumber, hasUpperCase } from "../../Tools/regex";
import { API_URL } from "../../config";
import DocumentTitle from "../../Tools/DocumentTitle/DocumentTitle";
import "./ResetPassword.css";
import { WrappedComponentContext } from "../../WrappedComponent";
import { AppContext } from "../../App";

export default function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const [inputError, setInputError] = useState("");

  const query = useQuery();
  const navigate = useNavigate();

  const { setBannerMessage } = useContext(WrappedComponentContext);
  const { logout } = useContext(AppContext);

  useEffect(() => {
    if (success) {
      logout(() => {});
      navigate("/password/reset/success");
    }
  }, [success]);

  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  const resetPassword = async () => {
    try {
      const resetPasswordToken = query.get("reset_password_token");
      if (!resetPasswordToken) return;

      const requestBody = {
        user: {
          reset_password_token: resetPasswordToken,
          password: password,
          password_confirmation: confirmPassword,
        },
      };

      const response = await fetch(API_URL + "/users/password", {
        method: "PUT",
        mode: "cors",
        body: JSON.stringify(requestBody),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        setSuccess(true);
        return;
      }

      const data = await response.json();
      throw data;
    } catch (error) {
      console.error(error);
      if (error?.errors) {
        let errorMessage = "";
        for (const [key, value] of Object.entries(error?.errors)) {
          errorMessage += `${key} ${value}. `;
        }
        setInputError(errorMessage);
      } else {
        setBannerMessage({ type: "error", title: "Failed to reset password." });
      }
    }
  };

  return (
    <div className="page-card-container">
      <div className="page-card small min-height-mid set-new-password">
        <DocumentTitle>SET NEW PASSWORD</DocumentTitle>

        <>
          <div className="send-password-reset-container set-new-pass-content">
            <span>New Password</span>

            <div
              className={`password-input-container ${
                inputError &&
                inputError !==
                  "The repeat password did not match the first entry."
                  ? "error"
                  : ""
              }`}
              style={{ marginBottom: "8px" }}
            >
              <input
                className="password-input"
                placeholder="New Password"
                type={passwordVisible ? "text" : "password"}
                onChange={handlePasswordChange}
              />

              <button
                className="login-icon-container password-visibility-container"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? (
                  <EyeCloseIcon size={20} />
                ) : (
                  <EyeIcon size={20} />
                )}
              </button>
            </div>

            <div
              className={`password-input-container ${
                inputError ? "error" : ""
              }`}
            >
              <input
                className="password-input"
                placeholder="Confirm New Password"
                type={confirmPasswordVisible ? "text" : "password"}
                onChange={handleConfirmPasswordChange}
              />

              <button
                className="login-icon-container password-visibility-container"
                onClick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
              >
                {confirmPasswordVisible ? (
                  <EyeCloseIcon size={20} />
                ) : (
                  <EyeIcon size={20} />
                )}
              </button>
            </div>

            {inputError && (
              <span className="error-message">
                <i>{inputError}</i>
              </span>
            )}
          </div>

          <div style={{ marginTop: "32px" }}>
            <button onClick={resetPassword}>Set Password</button>
          </div>
        </>
      </div>
    </div>
  );
}
