import { createContext, useEffect, useRef, useState } from "react";
import "./Chat.css";
import { API_URL } from "../../config";
import { ReactComponent as SendIcon } from "../../media/send_icon.svg";
import SuggestedQuestions from "./SuggestedQuestions/SuggestedQuestions";
import { formatResponseText } from "./formatResponse";
import { formatSuggestedQuestionsString } from "./SuggestedQuestions/formatSuggestedQuestionsString";
import ChatMessage from "./ChatMessage";

export const ChatContext = createContext();

export default function Chat({ chatbot, colors = null, isAdmin = false }) {
  const chatbotDisabled =
    chatbot?.admin_disabled || chatbot?.user_disabled || chatbot?.["disabled?"];

  const [chatMessages, setChatMessages] = useState([]);
  const [welcomeMessage, setWelcomeMessage] = useState();
  const [questionInput, setQuestionInput] = useState("");
  const [chatSessionToken, setChatSessionToken] = useState();
  const [hasAskedQuestion, setHasAskedQuestion] = useState(false);

  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [clickedSuggestedQuestions, setClickedSuggestedQuestions] = useState([]);

  const scrollableChatRef = useRef(null);

  const scrollToBottom = () => {
    scrollableChatRef.current.scrollTop = scrollableChatRef.current.scrollHeight;
  };

  // Scrolls to bottom after adding a new chat message
  useEffect(() => {
    if (chatMessages?.length > 1) {
      scrollToBottom();
    }
  }, [chatMessages]);

  useEffect(() => {
    // Set the welcome message as the first bot reply when chatbot data is given
    if (chatbot && !welcomeMessage) {
      setWelcomeMessage({
        type: "answer",
        content: chatbotDisabled
          ? "Chatbot Disabled"
          : formatResponseText(chatbot?.welcome_message),
        timeStamp: new Date(),
      });
    }

    // Format suggested questions string
    if (chatbot?.suggested_questions && chatbot?.suggested_questions !== suggestedQuestions) {
      setSuggestedQuestions(formatSuggestedQuestionsString(chatbot?.suggested_questions));
    }
  }, [chatbot]);

  const handleQuestionInputChange = (e) => setQuestionInput(e.target.value);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") askQuestion();
  };

  const startChatSession = () => {
    if (chatSessionToken) return chatSessionToken;
    const requestBody = {
      chat_session: { token: chatbot?.token, admin_chat_session: isAdmin },
    };

    return fetch(`${API_URL}/chat_sessions`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Could not start chat session");
        return response.json();
      })
      .then((data) => {
        setChatSessionToken(data.token);
        return data.token;
      })
      .catch((error) => {
        console.error(error?.message);
        return null;
      });
  };

  const askQuestion = async (suggestedQuestion = null) => {
    if (!questionInput && !suggestedQuestion) return;
    const currentChatSessionToken = await startChatSession();
    if (!currentChatSessionToken) return;

    const questionToAsk = suggestedQuestion ? suggestedQuestion : questionInput;

    const exactAnswer = suggestedQuestions.find(
      (suggestedPrompt) => suggestedPrompt?.question === questionToAsk
    )?.customAnswer;

    setHasAskedQuestion(true);

    const now = new Date();

    const question = {
      type: "question",
      content: questionToAsk,
      timeStamp: now,
    };

    const answer = {
      type: "answer",
      content: exactAnswer ? formatResponseText(exactAnswer) : "...",
      questionToAnswer: questionToAsk,
      timeStamp: now,
    };

    setChatMessages([...chatMessages, question, answer]);
    setQuestionInput("");

    if (exactAnswer) createExactReplyChatMessage(question, exactAnswer, currentChatSessionToken);

    scrollToBottom();
  };

  const createExactReplyChatMessage = (question, exactReply, chatSessionToken) => {
    const endpoint = `${API_URL}/chat_messages`;
    const method = "POST";
    const body = JSON.stringify({
      token: chatSessionToken,
      chat_message: {
        content: question?.content,
        is_exact_reply: true,
        exact_reply_content: exactReply,
      },
    });
    console.log(body);
    const headers = { "Content-Type": "application/json" };
    fetch(endpoint, { method, body, headers });
  };

  const value = {
    chatbotAvatar: chatbot?.avatar_url,
    hasAvatar: (chatbot?.show_chatbot_avatar_in_responses && colors) || !colors,
    chatSessionToken: chatSessionToken,
    scrollToBottom: scrollToBottom,
    messageLimitReachedMessage: chatbot?.message_limit_reached_message,
    hasTimeStamps: chatbot?.show_timestamps_in_responses,
    showSources: chatbot?.show_sources,
    maxSources: chatbot?.show_sources_max,
  };

  return (
    <ChatContext.Provider value={value}>
      <div className="chat-container" style={colors && { height: "100%" }}>
        <style>{`
          .chat::-webkit-scrollbar-track {
            background: ${colors?.chatScrollBarBackground};
          }
          .chat::-webkit-scrollbar-thumb {
            background: ${colors?.chatScrollBarThumb};
            border-color: ${colors?.chatScrollBarBackground};
          }

          /* This is for links on hosted chatbot */
          .chat-message-link {
            color: ${colors?.botUrlColor ?? "#A24DFF"} !important;
          }
        `}</style>
        <div
          className="chat"
          id="chat"
          ref={scrollableChatRef}
          style={
            colors && {
              display: "flex",
              flexDirection: "column",
              justifyContent: hasAskedQuestion ? "flex-start" : "space-between",
              maxHeight: "calc(100% - 85px)",
              height: "calc(100% - 85px)",
              overflowY: "auto",
            }
          }
        >
          {welcomeMessage && (
            <ChatMessage chatMessage={welcomeMessage} colors={colors} isWelcomeMessage={true} />
          )}

          {!chatbotDisabled && (
            <SuggestedQuestions
              hasAskedQuestion={hasAskedQuestion}
              askQuestion={askQuestion}
              chatbot={chatbot}
              colors={colors}
              suggestedQuestions={suggestedQuestions}
              clickedSuggestedQuestions={clickedSuggestedQuestions}
              setClickedSuggestedQuestions={setClickedSuggestedQuestions}
            />
          )}

          {/* All questions and answers after the first one */}
          {chatMessages?.length > 0 &&
            chatMessages.map((chatMessage, index) => (
              <ChatMessage
                chatMessage={chatMessage}
                colors={colors}
                key={index}
                isLast={index === chatMessages.length - 1}
              />
            ))}
        </div>

        <div
          className="chat-send-container"
          style={colors && { borderTop: `1px solid ${colors?.messageBarBorder}` }}
        >
          <div
            className="chat-send"
            style={
              colors && {
                border: `1px solid ${colors?.messageBarBorder}`,
                background: colors?.messageBarBackground,
              }
            }
          >
            <input
              className={`chat-send-input ${colors && "with-custom-colors"}`}
              placeholder={chatbotDisabled ? "Chatbot Disabled" : "Type a message"}
              value={questionInput}
              onChange={!chatbotDisabled ? handleQuestionInputChange : () => {}}
              onKeyDown={handleKeyDown}
              maxLength={500}
            />
            {/* this style is for when chat has custom styles on the input */}
            <style>{`
              .chat-send-input.with-custom-colors, .chat-send-input.with-custom-colors::placeholder {
                color: ${colors?.messageBarText};
                font-weight: 400;
              }
            `}</style>

            <div className="chat-send-icons-container">
              <SendIcon
                fill={colors?.sendIconColor ?? "#A24DFF"}
                onClick={() => askQuestion()}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      </div>
    </ChatContext.Provider>
  );
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
