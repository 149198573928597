import { ReactComponent as SourcesDropdownArrow } from "../../media/sourcesDropdownArrow.svg";

export const ChatMessageSources = ({ colors, sourceLinks = [] }) => {
  return (
    <div
      className="chat-message"
      style={
        colors
          ? {
              color: colors?.botText,
              background: colors?.botBubble,
              padding: "12px 18px",
            }
          : { padding: "12px 18px" }
      }
    >
      <style>{`
          /* This is for links on hosted chatbot */
          .chat-message-link {
            color: ${colors?.botUrlColor ?? "#A24DFF"} !important;
          }
        `}</style>
      {sourceLinks.map((link, index) => (
        <a
          key={index}
          className="chat-message-link"
          target="__blank"
          href={`${!link.startsWith("http") ? "https://" : ""}${link}`}
          style={{
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            textOverflow: "ellipsis",
          }}
        >
          {link}
        </a>
      ))}
    </div>
  );
};

export const ChatMessageSourcesDropdown = ({
  sourcesOpen,
  setSourcesOpen,
  showTimeStamps,
}) => {
  const toggleSourcesOpen = () => setSourcesOpen((prev) => !prev);

  return (
    <>
      {" "}
      <div
        className="flex align-center"
        style={{ display: "inline-flex", gap: "1px", cursor: "pointer" }}
        onClick={toggleSourcesOpen}
      >
        {showTimeStamps && "- "}Sources{" "}
        <div
          className="flex align-center"
          style={{ transform: `rotate(${sourcesOpen ? "180deg" : "0deg"})` }}
        >
          <SourcesDropdownArrow />
        </div>
      </div>
    </>
  );
};
