import { useContext, useEffect, useState } from "react";
import "./Chatbots.css";
import { Link } from "react-router-dom";
import { API_URL } from "../config";
import { FiPlus as PlusIcon } from "react-icons/fi";
import WaitingWithText from "../Tools/ChatWaiting/WaitingWithText";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import { WrappedComponentContext } from "../WrappedComponent";
import { ChatbotPreview } from "./ChatbotPreview";
import { Helmet } from "react-helmet";
import ConfirmEmailWindow from "../Tools/ConfirmEmail/ConfirmEmailWindow";

export default function MyChatbots({ token }) {
  const [chatbots, setChatbots] = useState();

  const { logout, userInfo, fetchUserInfo } = useContext(
    WrappedComponentContext
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!userInfo && token) fetchUserInfo();
    fetchChatbots();
  }, []);

  const fetchChatbots = async () => {
    try {
      if (!token) return;
      const endpoint = `${API_URL}/chat_bots`;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { headers });
      if (!response.ok)
        throw new ErrorFormat(
          "Failed to fetch chatbots.",
          response.status,
          logout
        );

      const data = await response.json();
      setChatbots(data);
    } catch (error) {
      console.error(error?.errorMessage ?? error);
    }
  };

  if (!token) {
    window.location.replace("/login");
    return;
  }

  // Confirm email window shows up when email is not confirmed and user is on free plan
  if (
    userInfo &&
    !userInfo?.email_confirmed &&
    userInfo?.stripe_subscriptions?.length === 0
  ) {
    return <ConfirmEmailWindow email={userInfo?.email} />;
  }

  return (
    <div className="page-card-container">
      <Helmet>
        <title>My Chatbots | Majic Chatbot</title>
        <meta name="description" content="List showing all your chatbots." />
      </Helmet>

      <div className="page-card small min-height-large">
        <div className="page-card-title-container">
          <h1 className="title-20" style={{ margin: "0px" }}>
            My Chatbots
          </h1>

          <Link to="/new-chatbot" style={{ textDecoration: "none" }}>
            <button className="purple-button" id="new-chatbot-button">
              <span id="new-chatbot-button-full-content">New Chatbot</span>
              <PlusIcon size={20} id="new-chatbot-button-short-content" />
            </button>
          </Link>
        </div>

        <div className="page-card-divider" style={{ marginBottom: "25px" }} />

        {/* Monthly response credits */}
        {userInfo && (
          <span className="chatbots-light-grey-text">
            Monthly response credits left:{" "}
            {(
              Number(
                userInfo?.plan_information?.plan_supported_features?.usage
                  ?.message_monthly_limit
              ) - Number(userInfo?.plan_information?.month_message_count)
            ).toLocaleString()}{" "}
            (
            {Number(
              userInfo?.plan_information?.plan_supported_features?.usage
                ?.message_monthly_limit
            ).toLocaleString()}
            )
          </span>
        )}

        {chatbots?.length > 0 && (
          <div className="my-chatbots-items">
            {chatbots.map((chat, index) => {
              return <ChatbotPreview chat={chat} key={index} index={index} />;
            })}
          </div>
        )}

        {chatbots && chatbots?.length === 0 && (
          <span className="chatbots-light-grey-text">
            Your chatbots will appear here.
          </span>
        )}

        {!chatbots && <WaitingWithText>Loading</WaitingWithText>}
      </div>
    </div>
  );
}
