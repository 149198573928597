import "./Footer.css";
import ChatbotFullLogo from "../media/chatbot_fulllogo.svg";
import { Link } from "react-router-dom";
import fb from "../media/fb.png";
import tw from "../media/tw.png";
import linkedin from "../media/in.svg";
import { DEPLOYED_APP_URL } from "../config";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-cols">
          <div className="footer-col">
            <div className="chatbot-fulllogo-container">
              <img src={ChatbotFullLogo} />
            </div>

            <span style={{ lineHeight: "18px", marginTop: "19px" }}>
              501 S. Cherry St, Suite 1100 Denver, Colorado, USA
            </span>
          </div>

          <div className="footer-col" style={{ width: "160px" }}>
            <h4 className="footer-title">Quick Links</h4>
            <Link to="/" className="no-line">
              <span>Home</span>
            </Link>
            <Link to="/pricing" className="no-line">
              <span>Pricing</span>
            </Link>
            <Link to="/contact" className="no-line">
              <span>Contact</span>
            </Link>
          </div>

          <div className="footer-col">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h4 className="footer-title">Privacy</h4>
              <a
                href={`${DEPLOYED_APP_URL}/privacypolicy`}
                target="__blank"
                className="no-line"
              >
                <span>Privacy Policy</span>
              </a>
              <a
                href={`${DEPLOYED_APP_URL}/tos`}
                target="__blank"
                className="no-line"
              >
                <span>Terms and Conditions</span>
              </a>
            </div>

            <div
              className="flex align-center"
              style={{ gap: "20px", marginTop: "8px" }}
            >
              <a
                target="__blank"
                href="https://www.linkedin.com/company/majic-ai/"
              >
                <img src={linkedin} alt="LinkedIn" />
              </a>
              <a target="__blank" href="https://twitter.com/majic_chatbot">
                <img src={tw} alt="Twitter" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-year-container">
          <span>© {currentYear} Majic Chatbot</span>
        </div>
      </div>
    </div>
  );
}

const currentYear = new Date().getFullYear();
