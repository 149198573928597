import { API_URL } from "../../config";

export const updatePendingDeletionStatus = ({
  chatbotId,
  token,
  setDeletingSources,
  pendingDeletionIsPingingRef,
  setOpenIntervals,
  fetchUserInfo,
}) => {
  // This method pings api to see if the given content item has been indexed
  // The interval will run every 8 seconds and each time will make the number of times to skip
  // pinging the api double each time essentially doubling the time between pings

  let timeoutId;
  let time = 4000;

  function getDeletionStatus() {
    console.log("Deleting");
    pendingDeletionIsPingingRef.current = true;

    getItemsPendingDeletion({ chatbotId, token })
      .then((itemsPendingDeletion) => {
        if (itemsPendingDeletion?.length === 0) {
          pendingDeletionIsPingingRef.current = false;
          setDeletingSources(itemsPendingDeletion);
          fetchUserInfo(); // Fetch updated user info after finish deleting
        } else {
          setDeletingSources(itemsPendingDeletion);
          timeoutId = setTimeout(getDeletionStatus, time);
          setOpenIntervals((prev) => [...prev, timeoutId]);
        }
      })
      .catch((error) => {
        pendingDeletionIsPingingRef.current = false;
      });
  }

  getDeletionStatus();
};

function getItemsPendingDeletion({ chatbotId, token, deletingSources }) {
  return new Promise((resolve, reject) => {
    const endpoint = `${API_URL}/chat_bots/${chatbotId}/pending_content_item_deletions`;
    const headers = { Authorization: token };

    fetch(endpoint, { headers })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch deleting sources");
        return response.json();
      })
      .then((data) => {
        data = data.map((item) => ({ ...item, pendingDeletion: true }));
        resolve(data);
      })
      .catch((error) => {
        reject(new Error("Could not get items pending deletion"));
      });
  });
}
