import { Link, useNavigate } from "react-router-dom";
import "./Home.css";
import purpleChatbot from "../media/chatbotPurple.svg";
import UseCases from "./UseCases";
import Recommendations from "./Recommendations";
import { useContext, useEffect } from "react";
import { WrappedComponentContext } from "../WrappedComponent";
import HomepageBigButton from "./HomepageBigButton";
import { ReactComponent as CurvyArrow } from "../media/homepageCurvyArrow.svg";
import { Helmet } from "react-helmet";
import FrequentQuestions from "../FrequentQuestions/FrequentQuestions";
import { ReactComponent as PurpleCheckmark } from "../media/purpleCheckmark.svg";
import chatbotChart from "../media/home_chatbot_chart@2x.jpg";
import chatInterface from "../media/home_chat_interface@2x.jpg";
import { ReactComponent as ArrowGrey } from "../media/arrow_grey.svg";

export default function Home({ version = "default" }) {
  const navigate = useNavigate();

  const { token } = useContext(WrappedComponentContext);

  useEffect(() => {
    if (token) {
      navigate("/chatbots");
    }
  }, [token]);

  return (
    <div className="home">
      <Helmet>
        <title>
          Majic Chatbot - AI Chatbots powered by your knowledge & data
        </title>
        <meta
          property="og:title"
          content="Majic Chatbot - AI Chatbots powered by your knowledge & data"
        />
        <meta
          name="twitter:title"
          content="Majic Chatbot - AI Chatbots powered by your knowledge & data"
        />
        <meta
          name="description"
          content="Majic Chatbot lets you create powerful AI Chatbots with your knowledge & data. Embed your chatbot on any website, and answer 90% of customer questions automatically."
        />
        <meta
          property="og:description"
          content="Majic Chatbot lets you create powerful AI Chatbots with your knowledge & data. Embed your chatbot on any website, and answer 90% of customer questions automatically."
        />
        <meta
          name="twitter:description"
          content="Majic Chatbot lets you create powerful AI Chatbots with your knowledge & data. Embed your chatbot on any website, and answer 90% of customer questions automatically."
        />
        <meta property="og:url" content="https://majic.ai" />
        <meta property="twitter:url" content="https://majic.ai" />
      </Helmet>

      <section className="home-top-container">
        <div className="home-top">
          <h6>GPT Chatbot for your users</h6>

          {titleVersions[version]}

          <h3>
            Majic Chatbot analyzes your{" "}
            <span className="purple-text" style={{ fontWeight: "500" }}>
              content & sources
            </span>
            , smartly answers{" "}
            <span className="purple-text" style={{ fontWeight: "500" }}>
              90% of questions
            </span>
            , and integrates with your website{" "}
            <span className="purple-text" style={{ fontWeight: "500" }}>
              in minutes
            </span>
            .
          </h3>

          <div style={{ display: "inline-flex" }}>
            <div style={{ height: "0px", width: "0px", position: "relative" }}>
              <div className="homepage-big-button-absolute-arrow">
                <CurvyArrow />
              </div>
            </div>
            <HomepageBigButton to="/new-chatbot">
              Create Chatbot
            </HomepageBigButton>
          </div>

          <div
            className="flex justify-center"
            style={{
              gap: "10px",
              marginTop: "35px",
              marginBottom: "41px",
              flexWrap: "wrap",
            }}
          >
            <div className="flex align-center purple-check-text">
              <PurpleCheckmark />
              Fast Setup
            </div>
            <div className="flex align-center purple-check-text">
              <PurpleCheckmark />
              Zero Coding
            </div>
            <div className="flex align-center purple-check-text">
              <PurpleCheckmark />
              No Credit Card
            </div>
          </div>
        </div>
      </section>

      <div className="homepage-sections-container">
        <section className="home-blue-section-container">
          <div className="home-blue-section">
            <div className="home-blue-section-text-container">
              <h2>How It Works</h2>

              <span>
                Add your URLs, PDFs, documents, or text as your sources...{" "}
                <span
                  style={{ fontWeight: "700" }}
                  className="purple-pink-gradient-text"
                >
                  Done!
                </span>
              </span>

              <img
                src={chatbotChart}
                alt="Chatbot Chart"
                style={{ width: "100%", margin: "40px 0px" }}
              />

              <span style={{ marginTop: "0px", marginBottom: "30px" }}>
                Your chatbot quickly learns all new content and is ready to
                chat.
              </span>
            </div>

            <ArrowGrey className="home-blue-arrow-grey" />

            <img
              src={chatInterface}
              alt="Chat Interface"
              className="home-blue-chat-interface"
            />
          </div>
        </section>

        <UseCases />

        <Recommendations />

        <FrequentQuestions />
      </div>
    </div>
  );
}

const titleVersions = {
  default: (
    <h1>
      Create <span className="purple-text">AI chatbots</span> powered by your
      sources & data
    </h1>
  ),
  ai_customer_service: (
    <h1>
      Offer seamless customer service with{" "}
      <span className="purple-text">AI chatbots</span>
    </h1>
  ),
  ai_chatbots: (
    <h1>
      Custom <span className="purple-text">AI chatbot</span> that delights your
      customers
    </h1>
  ),
};
