import { useEffect, useState } from "react";

export const useDarkModeDetector = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    const handleChange = (event) => {
      setIsDarkMode(event.matches);
    };

    darkModeMediaQuery.addListener(handleChange);

    // Clean up the listener when the component unmounts
    return () => {
      darkModeMediaQuery.removeListener(handleChange);
    };
  }, []);

  return isDarkMode;
};
