import { useState } from "react";
import { ReactComponent as DropdownArrow } from "../../../../media/dropdown_arrow.svg";
import "./AdvancedDropdown.css";

export default function AdvancedDropdown({
  openedHeight = "150px",
  dropdownText = "Advanced",
  initialOpenState = false,
  arrowAfterText = false,
  indented = true,
  children,
}) {
  const [open, setOpen] = useState(initialOpenState);

  return (
    <div className={`advanced-dropdown-container ${open ? "open" : ""}`}>
      <div
        className="advanced-dropdown"
        style={arrowAfterText ? { flexDirection: "row-reverse" } : {}}
        onClick={() => setOpen((prev) => !prev)}
      >
        <span>{dropdownText}</span>

        <DropdownArrow
          className={`advanced-dropdown-icon ${open ? "open" : ""}`}
        />
      </div>

      <div
        className={`
          advanced-dropdown-children-container 
          ${open ? "open" : ""} 
          ${indented && "indented"} 
          ${openedHeight === "auto" ? "no-transition" : ""}
        `}
        style={{ height: open ? openedHeight : "0px" }}
      >
        {children}
      </div>
    </div>
  );
}
