const ChatbotDemoForm = ({ colors, formFields, introText }) => {
  return (
    <div className="chatbot-demo-form-container">
      <div
        className="chatbot-demo-form"
        style={{
          background: colors?.mainBackground,
          color: colors?.mainBackgroundText,
          border: `1px solid ${colors?.messageBarBorder}`,
          boxShadow: "1px 1px 6px 0px rgba(0, 0, 0, 0.20)",
        }}
      >
        {introText && <span style={{ fontSize: "15px", fontWeight: "400" }}>{introText}</span>}

        <style>
          {`
      .chatbot-demo-form-input::-webkit-input-placeholder{
        color: ${colors?.messageBarText};
        font-weight: 400;
        font-style: italic;
      }
      .chatbot-demo-form-input:focus {
        outline: 2px solid ${colors?.userBubble}
      }
      `}
        </style>
        {formFields.map((formField, index) => (
          <div
            className={`chatbot-demo-form-input-container ${formField?.required ? "required" : ""}`}
            key={index}
          >
            <span style={{ color: colors?.mainBackgroundText }}>
              {formField?.label}
              {formField?.is_required ? "*" : ""}
            </span>

            {(function () {
              const TagName = formField?.type === "TextAreaFormField" ? "textarea" : "input";
              return (
                <TagName
                  className={`inline-input chatbot-demo-form-input ${
                    formField?.type === "TextAreaFormField" ? "is-textarea" : ""
                  }`}
                  style={{
                    background: colors?.messageBarBackground,
                    border: `1px solid ${colors?.messageBarBorder}`,
                    color: colors?.messageBarText,
                  }}
                />
              );
            })()}
          </div>
        ))}

        <button
          className={`chatbot-demo-form-button`}
          style={{
            background: colors?.userBubble,
            color: colors?.userText,
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ChatbotDemoForm;
