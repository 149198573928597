import { Link } from "react-router-dom";
import "./NoMatch.css";
import { BiError as ErrorIcon } from "react-icons/bi";

export default function NoMatch({
  linkTo = "/",
  linkText = "Take me back home",
}) {
  return (
    <div className="login-container">
      <div className="no-match-container">
        <div className="no-match-error-message-container">
          <ErrorIcon size={35} />
          <h3>
            Oops!
            <br />
            Looks like this page doesn't exist!
          </h3>
        </div>

        <p style={{ overflowWrap: "break-word" }}>
          We could not find a match for:{" "}
          <span style={{ color: "#016dd0", fontWeight: "500" }}>
            {window.location.href}
          </span>
        </p>

        <Link
          to={linkTo}
          className="no-match-home-link-text-container purple-pink-gradient-text"
        >
          {linkText}&nbsp;→
        </Link>
      </div>

      <div className="login-left-triangle" />
      <div className="login-right-triangle" />
    </div>
  );
}
