import { useContext } from "react";
import { ConversationsContext } from "./Conversations";
import { getTimeSinceString } from "./getTimeSinceString";

const ChatSessionPrev = ({ chatSession }) => {
  const { selectedConversation, setSelectedConversation } =
    useContext(ConversationsContext);

  const date = chatSession?.last_interaction_timestamp
    ? chatSession?.last_interaction_timestamp
    : chatSession?.created_at;

  const dayString = getTimeSinceString(date);

  let contentString = `${chatSession?.last_message?.content}\n${chatSession?.last_message?.response_chat_message?.content}`;
  if (contentString.length > 143) {
    contentString = contentString.slice(0, 140) + "...";
  }

  return (
    <div
      className={`chat-session-container ${
        selectedConversation?.id === chatSession?.id ? "selected" : ""
      }`}
      onClick={() => setSelectedConversation(chatSession)}
    >
      <div className="chat-session">
        <div className="chat-session-text-container">
          <span className="time-since-session">{dayString}</span>

          <p className="chat-session-text" style={{ whiteSpace: "pre-line" }}>
            {contentString}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChatSessionPrev;
