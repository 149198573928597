import { useContext } from "react";
import ChatbotFormInlineToggle from "./ChatbotFormInlineToggle";
import { ChatbotFormContext } from "../ChatbotForm";

const ChatbotFormWelcomeBubble = () => {
  const {
    displayWelcomeBubble,
    setDisplayWelcomeBubble,
    welcomeBubbleText,
    setWelcomeBubbleText,
    welcomeBubbleHangTime,
    setWelcomeBubbleHangTime,
    error,
  } = useContext(ChatbotFormContext);

  const handleWelcomeBubbleTextChange = (e) => {
    setWelcomeBubbleText(e.target.value);
  };

  const handleHangTimeChange = (e) => {
    if (["", " "].includes(e.target.value)) {
      setWelcomeBubbleHangTime("");
      return;
    }

    let value = Number(e.target.value);
    if (value < 0) value = "";

    setWelcomeBubbleHangTime(value);
  };

  return (
    <div className="form-item-column without-margin">
      <ChatbotFormInlineToggle
        open={displayWelcomeBubble}
        setOpen={setDisplayWelcomeBubble}
        hasToggleContainer={false}
        extraClass="noborder"
      >
        Display welcome bubble
      </ChatbotFormInlineToggle>

      {displayWelcomeBubble && (
        <>
          <input
            className={`inline-input fullwidth ${
              error?.item === "welcome bubble text content" ? "error" : ""
            }`}
            value={welcomeBubbleText}
            onChange={handleWelcomeBubbleTextChange}
            id="welcome-bubble-text-content"
          />

          {error?.item === "welcome bubble text content" && (
            <label
              className="inline-input-error"
              for="welcome-bubble-text-content"
            >
              {error?.message}
            </label>
          )}
          <br />

          <div>
            <span>Appears</span>&nbsp;&nbsp;
            <input
              className="inline-input tiny"
              style={{}}
              value={welcomeBubbleHangTime}
              type="number"
              onChange={handleHangTimeChange}
            />
            &nbsp;&nbsp;<span>seconds after page load</span>
          </div>
          <br />
        </>
      )}
    </div>
  );
};

export default ChatbotFormWelcomeBubble;
