import { getTrustedEmails } from "../../../../ConfirmedEmails/getTrustedEmails";
import { getChatbotForms } from "../../FormsTab/getChatbotForms";

export function prepopulateActionFormSettings({ chatbotId, token, action, setState }) {
  prepopulateFormsData({ chatbotId, token, action, setState });
  prepopulateEmailsData({ token, action, setState });

  prepopulateTriggers({ action, setState });
}

export function prepopulateActionNotificationsSettings({ token, action, setState }) {
  prepopulateEmailsData({ token, action, setState });

  prepopulateTriggers({ action, setState });
}

function prepopulateTriggers({ action, setState }) {
  // prepopulate triggers values

  if (!action?.id || !Array.isArray(action?.triggers)) return;

  // set all default trigger values to false - we will turn on any that are used - this overrides the default settings
  setState((prev) => ({
    ...prev,
    triggerOnNoAnswer: false,
    triggerAfterEveryNumQuestions: false,
    triggerOnKeywords: false,
    triggerOnChatSessionStart: false,
  }));

  action.triggers.forEach((trigger) => {
    switch (trigger?.type) {
      case "NoAnswerTrigger":
        setState((prev) => ({ ...prev, triggerOnNoAnswer: true }));
        break;

      case "AfterNQuestionsTrigger":
        setState((prev) => ({
          ...prev,
          triggerAfterEveryNumQuestions: true,
          numQuestionsToTriggerAfter: {
            title: trigger?.trigger_data?.num_questions,
            value: trigger?.trigger_data?.num_questions,
          },
        }));
        break;

      case "KeywordMatchTrigger":
        setState((prev) => ({
          ...prev,
          triggerOnKeywords: true,
          keywordTriggers: trigger?.trigger_data?.keywords.split(","),
        }));
        break;

      case "ChatSessionStartedTrigger":
        setState((prev) => ({ ...prev, triggerOnChatSessionStart: true }));
        break;

      default:
        break;
    }
  });
}

async function prepopulateFormsData({ chatbotId, token, action, setState }) {
  // Get chatbot forms

  let selectedForm = null;
  let forms = await getChatbotForms(chatbotId, token);
  if (!forms) return;

  // format form data for dropdown
  forms = forms.map((form) => {
    form = {
      ...form,
      value: form?.id,
      title: form?.name,
    };

    // set selected form when action has one selected already
    if (form?.id === action?.form_id) selectedForm = form;

    return form;
  });

  setState((prev) => ({ ...prev, forms, selectedForm }));
}

async function prepopulateEmailsData({ token, action, setState }) {
  // Set toggle for forwarding submissions via email on edit
  if (action?.id) {
    setState((prev) => ({
      ...prev,
      forwardSubmissionsViaEmail: !!action?.forward_form_submissions,
    }));
  }

  // Get trusted emails
  let selectedEmail = null;
  let emails = await getTrustedEmails(token);
  if (!Array.isArray(emails)) return;

  // Filter out unverfified emails
  emails = emails.filter((email) => email?.verified);

  const selectedEmailValue =
    action?.bot_action_emails?.length > 0 ? action?.bot_action_emails[0]?.email : null;

  // format trusted emails for dropdown
  emails = emails.map((email) => {
    email = {
      ...email,
      value: email?.email,
      title: email?.email,
    };

    // set selected email when action has one selected already
    if (email?.email === selectedEmailValue) selectedEmail = email;

    return email;
  });

  setState((prev) => ({ ...prev, emails, selectedEmail }));
}
