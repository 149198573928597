import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { API_URL } from "../config";
import "./Login.css";
import {
  AiOutlineEye as EyeIcon,
  AiOutlineEyeInvisible as EyeCloseIcon,
  AiOutlineMail as MailIcon,
} from "react-icons/ai";
import loginImage from "../media/loginImage.png";
import { GoKey as PasswordIcon } from "react-icons/go";
import { SignInWithGoogleButton } from "./SignInWithGoogle/SignInWithGoogle";
import CircularIndeterminate from "../Tools/Loading/CircularIndeterminate";
import { WrappedComponentContext } from "../WrappedComponent";
import ForgotPasswordPopup from "./ForgotPasswordPopup";
import { Helmet } from "react-helmet";
import getStripeUrl from "./getStripeUrl";
import { createNewChatbot } from "../SubscriptionStates/Checkout/createNewChatbot";
import { AppContext } from "../App";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);

  const tokenHasJustBeenSetRef = useRef(false);

  const navigate = useNavigate();
  const { setBannerMessage, token, setToken, checkForEventTriggers } =
    useContext(WrappedComponentContext);
  const { logout } = useContext(AppContext);

  // This code is for admin accessing account with special token
  const [searchParams, setSearchParams] = useSearchParams();
  const adminEmail = searchParams.get("email");
  const adminToken = searchParams.get("token");
  const [adminRequestSent, setAdminRequestSent] = useState(false);
  useEffect(() => {
    if (adminEmail && adminToken && !adminRequestSent) {
      setAdminRequestSent(true);
      login(null, `${adminEmail}_majic`, adminToken);
    }
  }, [adminEmail, adminToken]);

  useEffect(() => {
    document.body.dataset.page = "/login";
    return () => (document.body.dataset.page = "");
  }, []);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") login();
  };

  const validateData = (email, password) => {
    if (!email || !password) return false;
    return true;
  };

  const login = async (e = null, adminEmail = null, adminPassword = null) => {
    try {
      const formEmail = adminEmail ? adminEmail : email;
      const formPassword = adminPassword ? adminPassword : password;

      if (!validateData(formEmail, formPassword)) return;
      setLoading(true);

      const endpoint = `${API_URL}/users/sign_in`;
      const method = "POST";
      const body = JSON.stringify({
        user: { email: formEmail, password: formPassword },
      });
      const headers = { "Content-Type": "application/json" };

      const response = await fetch(endpoint, { method, body, headers });
      const data = await response.json();

      if (!response.ok) throw data;

      const accessKey = response.headers.get("Authorization");
      if (!accessKey) throw new Error({ error: "No Access Key" });

      localStorage.setItem("accessKey", accessKey);

      const plan = window.sessionStorage.getItem("plan");
      const interval = window.sessionStorage.getItem("interval");
      window.sessionStorage.removeItem("plan");
      window.sessionStorage.removeItem("interval");

      if (data?.plan_selection_made) {
        // If chatbot was selected before checkout it is created here
        await createNewChatbot({ token: accessKey, checkForEventTriggers });

        setLoading(false);
        tokenHasJustBeenSetRef.current = true;
        setToken(accessKey);
        navigate("/chatbots");
        return;
      }

      // If plan was selected prior to signing up we redirect them to checkout here
      if (plan) {
        const stripeURL = await getStripeUrl({
          accessKey,
          plan,
          interval,
          setBannerMessage,
          checkForEventTriggers,
        });
        window.location.replace(stripeURL ? stripeURL : "/plan");
      } else {
        window.location.replace("/plan");
      }
    } catch (error) {
      setLoading(false);

      let errorMessage =
        error?.error && error?.error !== true ? error?.error : error?.message;
      setBannerMessage({
        type: "error",
        title: "Failed to Log In",
        message: errorMessage,
      });
    }
  };

  if (token) {
    if (adminEmail && adminToken && !tokenHasJustBeenSetRef.current) {
      logout();
    } else {
      window.location.replace("/chatbots");
    }
  }

  return (
    <div className="login-container">
      <Helmet>
        <title>Login | Majic Chatbot</title>
        <meta property="og:title" content="Login | Majic Chatbot" />
        <meta name="twitter:title" content="Login | Majic Chatbot" />
        <meta name="description" content="Login page for Majic Chatbot" />
        <meta
          property="og:description"
          content="Login page for Majic Chatbot"
        />
        <meta
          name="twitter:description"
          content="Login page for Majic Chatbot"
        />
        <meta property="og:url" content="https://majic.ai/login" />
        <meta property="twitter:url" content="https://majic.ai/login" />
      </Helmet>

      <div className="login">
        <div className="login-form-container">
          <div className="login-form" onKeyPress={handleKeyPress}>
            <h1>Log in</h1>

            <>
              <SignInWithGoogleButton />

              <span className="or-divider">OR</span>

              <div className="login-input-container">
                <MailIcon className="login-icon" size={18} />
                <input
                  value={email}
                  placeholder="Email"
                  type="email"
                  onChange={handleEmailChange}
                />
              </div>

              <div className="login-input-container with-second-icon small-margin-top">
                <PasswordIcon className="login-icon" size={18} />
                <input
                  value={password}
                  placeholder="Password"
                  type={passwordVisible ? "text" : "password"}
                  onChange={handlePasswordChange}
                />

                <button className="login-icon eye">
                  {passwordVisible ? (
                    <EyeCloseIcon
                      size={18}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    />
                  ) : (
                    <EyeIcon
                      size={18}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    />
                  )}
                </button>
              </div>

              <span
                className="small-margin-top link link-on-hover"
                style={{
                  alignSelf: "end",
                  cursor: "pointer",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
                onClick={() => setIsResetPassword(true)}
              >
                Forgot Password?
              </span>

              <button
                onClick={login}
                className={`purple-button small-margin-top login-submit ${
                  (!email || !password) && "muted"
                }`}
              >
                {loading && <CircularIndeterminate color="white" />}
                Log in
              </button>
            </>
          </div>

          <span className="switch-to-signup-container">
            Don’t have an account?&nbsp;
            <Link to={`/signup`} className="link link-on-hover">
              Sign up now
            </Link>
          </span>
        </div>

        <div className="login-img-container">
          <img className="login-img" src={loginImage} alt="login" />
        </div>
      </div>

      <ForgotPasswordPopup
        isResetPassword={isResetPassword}
        setIsResetPassword={setIsResetPassword}
      />
    </div>
  );
}
