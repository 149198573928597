import React, { useContext } from "react";
import { API_URL } from "../../../../config";
import { useNavigate, useParams } from "react-router-dom";
import { WrappedComponentContext } from "../../../../WrappedComponent";
import { ErrorFormat } from "../../../../Tools/Errors/ErrorFormatting";

const SaveAction = ({ action, setAction, settingsState, keywordsInputRef, type }) => {
  const { chatbotId } = useParams();
  const { token, logout, setBannerMessage } = useContext(WrappedComponentContext);
  const navigate = useNavigate();

  const getTriggers = () => {
    let triggers = action?.triggers ?? [];

    const addTrigger = (type, trigger_data) => {
      let isNewTrigger = true;
      triggers = triggers.map((trigger) => {
        if (trigger?.type === type) {
          isNewTrigger = false;
          return { id: trigger?.id, type, trigger_data };
        }
        return trigger;
      });
      if (isNewTrigger) triggers.push({ type, trigger_data });
    };

    const removeTrigger = (type) => {
      triggers = triggers.map((trigger) =>
        trigger?.type === type ? { id: trigger?.id, type, _destroy: true } : trigger
      );
    };

    settingsState?.triggerOnNoAnswer
      ? addTrigger("NoAnswerTrigger")
      : removeTrigger("NoAnswerTrigger");

    settingsState?.triggerAfterEveryNumQuestions
      ? addTrigger("AfterNQuestionsTrigger", {
          num_questions: settingsState?.numQuestionsToTriggerAfter?.value,
        })
      : removeTrigger("AfterNQuestionsTrigger");

    const keywords = keywordsInputRef?.current?.value.trim()
      ? [...settingsState?.keywordTriggers, keywordsInputRef?.current?.value.trim()].join(",")
      : settingsState?.keywordTriggers.join(",");

    settingsState?.triggerOnKeywords && keywords
      ? addTrigger("KeywordMatchTrigger", { keywords: keywords })
      : removeTrigger("KeywordMatchTrigger");

    settingsState?.triggerOnChatSessionStart
      ? addTrigger("ChatSessionStartedTrigger")
      : removeTrigger("ChatSessionStartedTrigger");

    return triggers;
  };

  const getEmailAttributes = () => {
    let emailsAttributes = action?.bot_action_emails ?? [];
    const selectedEmail = settingsState?.selectedEmail?.email;
    let isNewEmailAttribute = true; // this is to tell if the selected email is already in the list or not

    // If toggle is off return null
    const doNotForwardToEmail =
      !settingsState?.forwardSubmissionsViaEmail && !settingsState?.sendNotificationViaEmail;
    if (doNotForwardToEmail) return null;

    // we want to remove any emails in the list that are not the selected email
    emailsAttributes = emailsAttributes.map((email) => {
      if (email?.email === selectedEmail) {
        isNewEmailAttribute = false;
        return email;
      }
      return { ...email, _destroy: true };
    });

    if (isNewEmailAttribute) emailsAttributes.push({ email: selectedEmail });

    return emailsAttributes;
  };

  const getFormActionBody = () => {
    let botAction = {
      title: action?.title,
      intro_text: action?.intro_text,
      success_text: action?.success_text,
      form_id: settingsState?.selectedForm?.id,
      disabled: action?.disabled,
      type: "form_collection",
      triggers_attributes: getTriggers(),
      forward_form_submissions: !!settingsState?.forwardSubmissionsViaEmail,
    };

    if (settingsState?.forwardSubmissionsViaEmail)
      botAction.bot_action_emails_attributes = getEmailAttributes();

    return { bot_action: botAction };
  };

  const getNotificationActionBody = () => {
    let botAction = {
      title: action?.title,
      disabled: action?.disabled,
      type: "event_notification",
      triggers_attributes: getTriggers(),
      forward_form_submissions: !!settingsState?.forwardSubmissionsViaEmail,
    };

    if (settingsState?.forwardSubmissionsViaEmail)
      botAction.bot_action_emails_attributes = getEmailAttributes();

    return { bot_action: botAction };
  };

  const saveAction = async () => {
    if (!canSave) return;

    try {
      const endpoint = action?.id
        ? `${API_URL}/chat_bots/${chatbotId}/bot_actions/${action?.id}` // For edit
        : `${API_URL}/chat_bots/${chatbotId}/bot_actions`; // For create
      const method = action?.id ? "PUT" : "POST";
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      let body = type === "notification" ? getNotificationActionBody() : getFormActionBody();
      body = JSON.stringify(body);

      const res = await fetch(endpoint, { method, headers, body });
      const data = await res.json();
      if (!res.ok) throw new ErrorFormat(data, res.status, logout);

      navigate(`/chatbot/settings/${chatbotId}/actions`);
    } catch (error) {
      let errMsg = "";
      if (typeof error?.errorMessage === "object") {
        for (const [key, value] of Object.entries(error?.errorMessage)) {
          if (errMsg) errMsg += ", ";
          errMsg = errMsg + key + " " + value;
        }
        if (errMsg) errMsg = errMsg.charAt(0).toUpperCase() + errMsg.substring(1);
      }
      setBannerMessage({
        type: "error",
        title: "Failed to save action",
        message: errMsg,
      });
      console.error(error);
    }
  };

  const canSave =
    action?.title &&
    (type === "notification" ? true : settingsState?.selectedForm?.id) &&
    (settingsState?.forwardSubmissionsViaEmail ? settingsState?.selectedEmail?.email : true);

  return (
    <section style={{ marginTop: "47px" }}>
      <button className={`purple-button ${!canSave ? "muted" : ""}`} onClick={saveAction}>
        Save Action
      </button>
    </section>
  );
};

export default SaveAction;
