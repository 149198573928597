import { useContext } from "react";
import { ReactComponent as CircleClose } from "../../media/circleClose.svg";
import { ChatbotFormContext } from "../ChatbotForm";
import { WrappedComponentContext } from "../../WrappedComponent";

const ChatbotFormAvatar = () => {
  const { image, setImage, setIsExistingImage } =
    useContext(ChatbotFormContext);
  const { setBannerMessage } = useContext(WrappedComponentContext);

  const handleImageChange = async (e) => {
    const selectedImage = e.target.files[0];

    // Check if an image is selected
    if (selectedImage) {
      try {
        const isNotTooNarrow = await hasValidAspectRatio(selectedImage);
        if (!isNotTooNarrow)
          throw {
            type: "error",
            title: "Image is too narrow",
            message: "Aspect ratio must not be more narrow than 2/1",
          };

        if (selectedImage.type === "image/svg+xml") {
          // Check if the SVG file size is within the allowed limit (1 MB)
          if (selectedImage.size <= 1024 * 1024) {
            setIsExistingImage(false);
            setImage(selectedImage);
          } else {
            throw {
              type: "error",
              title: "Svg is too big",
              message: "Svgs must be less than 1mb",
            };
          }
        } else {
          // For non-SVG files, use FileReader to convert to a data URL and then to a File object
          const reader = new FileReader();

          reader.onload = function (event) {
            const img = new Image();

            img.onload = function () {
              if (isBigEnough(img, 150)) {
                const resizedImageDataUrl = resizeImage(img, 150);
                const file = dataURLtoFile(
                  resizedImageDataUrl,
                  selectedImage.name
                );

                setIsExistingImage(false);
                setImage(file);
              } else {
                e.target.value = null;
                setBannerMessage({
                  type: "error",
                  title: "Image too small",
                  message: "Image must be at least 150/150",
                });
              }
            };

            // Set the source of the Image object to the resized image data URL
            img.src = event.target.result;
          };

          // Read the selected image as a data URL
          reader.readAsDataURL(selectedImage);
        }
      } catch (error) {
        e.target.value = null;
        setBannerMessage(error);
      }
    }
  };

  function hasValidAspectRatio(selectedFile) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = function () {
        const meetsCondition =
          img.width / img.height < 2 && img.height / img.width < 2;
        resolve(meetsCondition);
      };

      // Set the source of the Image object to the selected file
      img.src = URL.createObjectURL(selectedFile);

      img.onerror = function (error) {
        reject(error);
      };
    });
  }

  const isBigEnough = (img, minSize = 150) => {
    return img.width >= minSize || img.height >= minSize;
  };

  const resizeImage = (img, targetSize = 150) => {
    let newWidth, newHeight;

    if (img.width < img.height) {
      newWidth = targetSize;
      newHeight = (targetSize * img.height) / img.width;
    } else {
      newWidth = (targetSize * img.width) / img.height;
      newHeight = targetSize;
    }

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = newWidth;
    canvas.height = newHeight;

    ctx.drawImage(img, 0, 0, newWidth, newHeight);

    return canvas.toDataURL();
  };

  if (!image) {
    return (
      <input
        type="file"
        accept="image/*"
        id="avatar-upload"
        onChange={handleImageChange}
        style={{ width: "calc(100% - 20px)" }}
      />
    );
  }

  return (
    <div
      className="row-space-between"
      style={{
        borderRadius: "6px",
        border: "1px solid #E1E1E1",
        padding: "6px 9px",
        color: "#616161",
        fontSize: "14px",
      }}
    >
      <span
        style={{
          marginTop: "3px",
          maxWidth: "calc(100% - 20px)",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {image?.name ? image?.name : "Selected"}
      </span>

      <CircleClose
        style={{ cursor: "pointer" }}
        onClick={() => {
          setImage(null);
        }}
      />
    </div>
  );
};

export default ChatbotFormAvatar;

// Helper function to convert data URL to File object
function dataURLtoFile(dataURL, fileName) {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // Create a new File object
  return new File([u8arr], fileName, { type: mime });
}
