export const formatResponseText = (text, isConvos = false) => {
  text = formatCodeIfExists(text);
  text = quirck1(text);
  text = wrapLinksWithHtml(text, isConvos);
  text = wrapEmailsWithHtml(text);
  text = formatLists(text);
  return text;
};

function formatLists(str) {
  // Turns sentences that start with "- " into lists
  // Makes start of text until first ":" bold (ignores a tag semi-colons and is limited to 90 chars)

  let newStr = str.replace(
    /^- ((?:<a[^>]*>[^<]*<\/a>|[^:\n])+)(:.+)?$/gm,
    function (_, p1, p2) {
      let replacementContent = p1;
      if (p2)
        replacementContent =
          "<strong style='font-weight: 600;'>" + p1 + "</strong>" + p2;

      const p1WithoutTags = p1.replace(/<[^>]*>/g, "");
      if (p1WithoutTags.length > 90) replacementContent = p1 + p2;

      return "<li class='chat-response-li'>" + replacementContent + "</li>";
    }
  );

  return newStr;
}

// Formats code parts of answer text
export const formatCodeIfExists = (inputText) => {
  let str = `${inputText}`;
  let pattern = /```(.*?)```/gs; // 'g' for global search, 's' for allowing . to match newline characters
  str = str.replace(/</g, "&lt;").replace(/>/g, "&gt;"); //replaces < and > so that the browser doesn't think it is actual html
  str = str.replace(pattern, '<div class="code-container">$1</div>');

  return str;
};

function wrapLinksWithHtml(text, isConvos = false) {
  var urlPattern = /(https?:\/\/\S+|www\.\S+)/g;

  // Replace each match with the HTML link format
  var htmlText = text.replace(urlPattern, function (match) {
    // removes any of these special chars from the end of the link
    let cleanMatch = match.replace(/[\.,\(\)\[\]\{\}<>\'\"\`]$/, "");
    const endSpecialChar =
      match.length > cleanMatch.length ? match.charAt(match.length - 1) : "";
    if (!cleanMatch.startsWith("http")) cleanMatch = "https://" + cleanMatch;
    return (
      `<a class="${
        isConvos ? "link" : "chat-message-link"
      }" target="__blank" href="` +
      cleanMatch +
      '">' +
      cleanMatch +
      "</a>" +
      endSpecialChar
    );
  });

  return htmlText;
}

function wrapEmailsWithHtml(text) {
  var emailPattern = /[\w.-]+[\+]*[\w.-]*@[\w.-]+\.\w+/g;

  // Replace each match with the HTML link format
  var htmlText = text.replace(emailPattern, function (match) {
    // removes any of these special chars from the end of the link
    const cleanMatch = match.replace(/[\.,\(\)\[\]\{\}<>\'\"\`]$/, "");
    const endSpecialChar =
      match.length > cleanMatch.length ? match.charAt(match.length - 1) : "";
    return (
      '<a class="link chat-message-link" target="__blank" href="mailto:' +
      cleanMatch +
      '">' +
      cleanMatch +
      "</a>" +
      endSpecialChar
    );
  });

  return htmlText;
}

function quirck1(text) {
  // Weird issue that sometimes chatgpt will give like so: [https://www.di.fm/member/subscription](https://www.di.fm/member/subscription)
  // This fixes this quirck

  let regex =
    /\[(http:\/\/.*?|https:\/\/.*?)\]\((http:\/\/.*?|https:\/\/.*?)\)/g;
  let matches = text.matchAll(regex);

  for (let match of matches) {
    text = text.replace(match[0], match[2]);
  }

  return text;
}
