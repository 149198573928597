import { useEffect, useRef } from "react";
import "./Modal.css";
import { ReactComponent as CircleClose } from "../media/circleClose.svg";

export default function Modal({
  setOpen,
  component,
  type = "",
  subtype = "",
  extraClassName = "",
  closeOnOutsideClick = true,
  withoutBackgroundBlur = false,
  takeChildrensShape = false,
  removeCloseButton = false,
}) {
  let extraClass = "";
  if (type === "text") extraClass = type;
  else if (subtype === "upload") extraClass = subtype;
  else extraClass = extraClassName;

  const modalRef = useRef(null);
  const modalContainerRef = useRef(null);
  const closeButtonRef = useRef(null);

  // Closes the modal when clicking outside of it
  useEffect(() => {
    if (closeOnOutsideClick) {
      const handleClickOutside = (event) => {
        if (
          (modalRef.current &&
            !modalRef.current.contains(event.target) &&
            modalContainerRef.current &&
            modalContainerRef.current.contains(event.target)) ||
          (closeButtonRef.current && closeButtonRef.current.contains(event.target))
        ) {
          setOpen(false);
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => document.removeEventListener("click", handleClickOutside);
    }
  }, []);

  return (
    <div
      className={`modal-container ${withoutBackgroundBlur && "without-background"}`}
      ref={modalContainerRef}
    >
      <div
        className={`modal ${extraClass} ${takeChildrensShape && "takes-child-shape"}`}
        ref={modalRef}
      >
        <div style={{ position: "relative", height: "1px" }}>
          {!removeCloseButton && (
            <div className="modal-circle-close-container" ref={closeButtonRef}>
              <CircleClose onClick={() => setOpen(false)} />
            </div>
          )}
        </div>

        {component}
      </div>
    </div>
  );
}
