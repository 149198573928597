export async function downloadContentItemDocument(documentUrl, title, token) {
  try {
    const downloadLink = documentUrl;
    const headers = new Headers({ Authorization: token });

    const response = await fetch(downloadLink, { headers });
    if (!response.ok) throw new Error("Failed to fetch file");

    const blob = await response.blob();

    // Creates url out of blob, clicks on it and then removes it
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = title; // Set the desired filename
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  }
}
