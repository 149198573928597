// The methods in this file handle:
// Checking if item is indexed,
// Pinging api to see if items are indexed

import { API_URL } from "../../config";

export const waitForIndexingToFinish = ({
  chatbotId,
  token,
  contentItemId,
  setOpenIntervals,
  setSources,
  fetchUserInfo,
}) => {
  // This method pings api to see if the given content item has been indexed
  // The interval will run every 8 seconds and each time will make the number of times to skip
  // pinging the api double each time essentially doubling the time between pings

  let intervalId;
  let time = 4000;

  function getContentItemById() {
    checkIfNeedsIndex(chatbotId, contentItemId, token)
      .then((response) => {
        if (!response.ok) throw "Error";
        return response.json();
      })
      .then((data) => {
        console.log(data);
        updateSource({ newData: data, setSources }).then((previousSource) => {
          if (checkIfSourceNeedsUpdates(data)) {
            const statusTextEq =
              previousSource?.processing_status_text ===
              data?.processing_status_text;
            const prevAndCurrentStatusTextDontExist =
              !previousSource?.processing_status_text &&
              !data?.processing_status_text;
            const stateTextEq = previousSource?.state === data?.state;
            const prevAndCurrentStateTextDontExist =
              !previousSource?.state && !data?.state;
            if (
              (statusTextEq || prevAndCurrentStatusTextDontExist) &&
              (stateTextEq || prevAndCurrentStateTextDontExist)
            )
              time *= 2;
            else time = 4000;

            intervalId = setTimeout(getContentItemById, time);
            setOpenIntervals((prevOpenIntervals) => {
              return [...prevOpenIntervals, intervalId];
            });
            console.log(intervalId, time);
          } else {
            fetchUserInfo(); // Fetch updated user info after indexing finish
          }
        });
      })
      .catch((error) => {
        console.error("Error occurred while fetching API:", error);
      });
  }

  getContentItemById();
};

const checkIfNeedsIndex = (chatbotId, contentItemId, token) => {
  return fetch(
    `${API_URL}/chat_bots/${chatbotId}/content_items/${contentItemId}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  );
};

const updateSource = ({ newData, setSources }) => {
  // Updates the sources list to show that the given source indexing has been completed
  // Returns previous source details

  return new Promise((resolve) => {
    setSources((prevSources) => {
      const updatedSources = prevSources.map((currentSource) => {
        const condition = currentSource.id === newData.id;

        if (condition) {
          resolve(currentSource);
          return {
            ...currentSource,
            ...newData,
            url: currentSource?.url,
          };
        }
        return currentSource;
      });

      return updatedSources;
    });
  });
};

export const waitForIndexingForLoadingSources = ({
  type,
  sources,
  pingSourcesLoaded,
  setPingSourcesLoaded,
  chatbotId,
  token,
  setOpenIntervals,
  setSources,
  fetchUserInfo,
}) => {
  // This method waits for indexing (pings the api until the source is indexed) for all the loading sources
  // Is called after extracting the initial sources

  if (pingSourcesLoaded) {
    sources.forEach((source) => {
      if (checkIfSourceNeedsUpdates(source))
        waitForIndexingToFinish({
          chatbotId,
          token,
          contentItemId: source?.id,
          source,
          setOpenIntervals,
          sources,
          setSources,
          fetchUserInfo,
        });
    });

    setPingSourcesLoaded(false);
  }
};

const checkIfSourceNeedsUpdates = (source) => {
  if (
    source?.state === "indexing_error" ||
    source?.state === "gathering_data_error" ||
    source?.state === "no_content_to_index" ||
    source?.state === "plan_character_limit_exceeded" ||
    source?.state === "indexed"
  )
    return false;

  return true;
};
