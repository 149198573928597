import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_URL, DEPLOYED_APP_URL } from "../config";
import "./Login.css";
import {
  AiOutlineEye as EyeIcon,
  AiOutlineEyeInvisible as EyeCloseIcon,
  AiOutlineMail as MailIcon,
} from "react-icons/ai";
import loginImage from "../media/loginImage.png";
import { GoKey as PasswordIcon } from "react-icons/go";
import { SignInWithGoogleButton } from "./SignInWithGoogle/SignInWithGoogle";
import CircularIndeterminate from "../Tools/Loading/CircularIndeterminate";
import { WrappedComponentContext } from "../WrappedComponent";
import { Helmet } from "react-helmet";
import getStripeUrl from "./getStripeUrl";
import { validateEmail } from "../Tools/regex";
import { addEventTrigger } from "../Tools/EventTriggers/addOrRemoveEventTrigger";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { setBannerMessage, token, checkForEventTriggers } = useContext(
    WrappedComponentContext
  );

  useEffect(() => {
    document.body.dataset.page = "/signup";
    return () => {
      document.body.dataset.page = "";
    };
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") signup();
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const validateData = () => {
    try {
      if (!email || !password) return false;

      const emailValidated = validateEmail(email);
      if (!emailValidated) throw new Error("Invalid email.");

      return true;
    } catch (error) {
      setBannerMessage({
        type: "error",
        title: "Failed to sign up.",
        message: error?.message,
      });

      return false;
    }
  };

  const signup = async () => {
    try {
      if (!validateData()) return;
      setLoading(true);

      const body = JSON.stringify({ user: { email, password } });
      const endpoint = `${API_URL}/users`;
      const method = "POST";
      const headers = { "Content-Type": "application/json" };

      const response = await fetch(endpoint, { method, body, headers });

      const data = await response.json();

      if (!response.ok) throw data;

      const accessKey = response.headers.get("Authorization");
      if (!accessKey) throw { error: "No Access Key" };

      localStorage.setItem("accessKey", accessKey);

      const plan = window.sessionStorage.getItem("plan");
      const interval = window.sessionStorage.getItem("interval");
      window.sessionStorage.removeItem("plan");
      window.sessionStorage.removeItem("interval");

      // add gtag event trigger for registration
      addEventTrigger("registration");

      // If plan was selected prior to signing up we redirect them to checkout here
      if (plan) {
        const stripeURL = await getStripeUrl({
          accessKey,
          plan,
          interval,
          setBannerMessage,
          checkForEventTriggers,
        });

        window.location.replace(stripeURL ? stripeURL : "/plan");
      } else {
        window.location.replace("/plan");
      }
    } catch (err) {
      setLoading(false);

      let errorMessage = err?.error ?? err?.message;
      if (err?.errors) {
        errorMessage = "";
        for (const [key, value] of Object.entries(err?.errors)) {
          errorMessage += `${key} ${value}. `;
        }
      }
      setBannerMessage({
        type: "error",
        title: "Failed to sign up.",
        message: errorMessage,
      });
    }
  };

  if (token) {
    window.location.replace("/chatbots");
  }

  return (
    <div className="login-container">
      <Helmet>
        <title>Signup | Majic Chatbot</title>
        <meta property="og:title" content="Signup | Majic Chatbot" />
        <meta name="twitter:title" content="Signup | Majic Chatbot" />
        <meta name="description" content="Signup page for Majic Chatbot" />
        <meta
          property="og:description"
          content="Signup page for Majic Chatbot"
        />
        <meta
          name="twitter:description"
          content="Signup page for Majic Chatbot"
        />
        <meta property="og:url" content="https://majic.ai/signup" />
        <meta property="twitter:url" content="https://majic.ai/signup" />
      </Helmet>

      <div className="login">
        <div className="login-form-container">
          <div className="login-form" onKeyPress={handleKeyPress}>
            <h1>Create your free account</h1>

            <SignInWithGoogleButton page="signup" />

            <span className="or-divider">OR</span>

            <div className="login-input-container">
              <MailIcon className="login-icon" size={18} />
              <input
                placeholder="Email"
                type="email"
                onChange={handleEmailChange}
              />
            </div>

            <div className="login-input-container with-second-icon small-margin-top">
              <PasswordIcon className="login-icon" size={18} />
              <input
                placeholder="Password"
                type={passwordVisible ? "text" : "password"}
                onChange={handlePasswordChange}
              />

              <button className="login-icon eye">
                {passwordVisible ? (
                  <EyeCloseIcon
                    size={18}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                ) : (
                  <EyeIcon
                    size={18}
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                )}
              </button>
            </div>

            <button
              onClick={signup}
              className={`purple-button small-margin-top login-submit ${
                (!email || !password) && "muted"
              }`}
            >
              {loading && <CircularIndeterminate color="white" />}
              Create account
            </button>

            <span className="small-margin-top muted-text">
              By registering, you agree to our{" "}
              <a
                href={`${DEPLOYED_APP_URL}/tos`}
                className="link link-on-hover"
                target="__blank"
              >
                Terms of Use
              </a>{" "}
              &{" "}
              <a
                className="link link-on-hover"
                href={`${DEPLOYED_APP_URL}/privacypolicy`}
                target="__blank"
              >
                Privacy Policy
              </a>
            </span>
          </div>

          <span className="switch-to-signup-container">
            Already have an account?&nbsp;
            <Link to={`/login`} className="link link-on-hover">
              Log in
            </Link>
          </span>
        </div>

        <div className="login-img-container">
          <img className="login-img" src={loginImage} alt="login" />
        </div>
      </div>
    </div>
  );
}
