import "./Recommendations.css";
import heart from "../media/heart.png";
import difmLogo from "../media/difmLogo.png";
import jazzradioLogo from "../media/jazzradioLogo.png";

export default function Recommendations() {
  return (
    <div className="recommendations-container">
      <h2>
        Customer Love &nbsp;
        <img src={heart} alt="Heart" />
      </h2>

      <div className="recommendations">
        {recommendations &&
          recommendations.map((recommendation, index) => (
            <Recommendation recommendation={recommendation} key={index} />
          ))}
      </div>
    </div>
  );
}

const Recommendation = ({ recommendation }) => {
  return (
    <div className="recommendation">
      <div className="recommendation-header">
        <img
          src={recommendation.img}
          alt={`profile - ${recommendation.name}`}
          className="recommendation-img"
        />

        <div className="recommendation-header-content">
          <h4>{recommendation.name}</h4>

          <span>{recommendation.job}</span>
        </div>
      </div>

      <p>{recommendation.comment}</p>
    </div>
  );
};

const recommendations = [
  {
    img: difmLogo,
    name: "Duy Do",
    job: "VP of Operations - DI.FM",
    comment:
      "“At DI.FM, we were able to vastly improve our approach to customer service by using Majic Chatbot.\n\nBy automating responses to a wide array of customer questions, it ensures prompt and accurate assistance around the clock. This has enhanced our customer satisfaction and cut down on the need for additional support staff.”",
  },
  {
    img: jazzradioLogo,
    name: "Charlotte Nilsson",
    job: "Senior Director - JazzRadio.com",
    comment:
      "“Majic Chatbot has become an indispensable tool for delivering fast and reliable information about our Premium service to our paid and prospective customers.\n\nIts ability to automatically handle a wide range of questions specifically related to our offering is awesome. For more custom inquiries, the chatbot smartly redirects to our skilled human team, ensuring every customer query is thoroughly addressed.”",
  },
];
