import { API_URL } from "../../config";
import { ErrorFormat } from "../Errors/ErrorFormatting";

export async function resendConfirmationEmail({ email, authToken }) {
  try {
    const endpoint = `${API_URL}/accounts/resend_confirmation_email`;
    const method = "PUT";
    const body = JSON.stringify({ email });
    const headers = { Authorization: authToken };

    const response = await fetch(endpoint, { method, body, headers });
    if (!response.ok) {
      throw new ErrorFormat(
        "Failed to send email confirmation email.",
        response.status
      );
    }

    return {
      type: "success",
      title: "Email Sent!",
      message: "Check your inbox for an email to confirm your email with us.",
    };
  } catch (error) {
    return { type: "error", title: error?.errorMessage ?? error?.message };
  }
}
