import React, { useContext } from "react";
import { ChatbotFormContext } from "../ChatbotForm";

const ChatbotFormWelcomeMessage = () => {
  const { welcomeMessage, setWelcomeMessage } = useContext(ChatbotFormContext);

  const handleWelcomeMessageChange = (e) => {
    setWelcomeMessage(e.target.value);
  };

  return (
    <>
      <div className="standard-divider" style={{ marginTop: "30px" }} />
      <div className="form-item-column">
        <span className="chatbot-settings-title row-align-center">
          Welcome message
        </span>
        <br />
        <input
          value={welcomeMessage}
          onChange={handleWelcomeMessageChange}
          className="inline-input fullwidth"
        />
      </div>
      <div className="standard-divider" style={{ marginTop: "20px" }} />
    </>
  );
};

export default ChatbotFormWelcomeMessage;
