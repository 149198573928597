import "./InfoDescription.css";
import { ReactComponent as InfoIcon } from "../../media/infoFull.svg";
import { useEffect, useRef } from "react";
import polygonWithBorder from "../../media/PolygonWithBorder.svg";
import polygon from "../../media/Polygon.svg";

export const InfoDescription = ({
  children,
  tipOffset = { left: "1px" },

  customComponent = null,
  customTooltipContainerStyle = { marginLeft: "4px" },
}) => {
  const boxRef = useRef(null);

  // fixes position if overflows to thr right
  const fixRightOverflow = () => {
    const boxElement = boxRef.current;
    // Handle description overflowing to the right
    const { right } = boxElement.getBoundingClientRect();
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    const { translateX } = getTranslateXY(boxElement);
    let overflowAmount = Math.max(0, right - viewportWidth - translateX);
    if (overflowAmount) overflowAmount += 16;

    if (overflowAmount)
      boxRef.current.style.transform = `translateX(${overflowAmount * -1 - 10}px)`;
  };

  const fixLeftOverflow = () => {
    const boxElement = boxRef.current;
    const { left } = boxElement.getBoundingClientRect();

    if (left < 15) {
      boxElement.style.width = "calc(100vw - 36px)";
      boxElement.style["white-space"] = "normal";
      boxRef.current.style.transform = `translateX(0px)`;
      fixRightOverflow();
    } else {
      boxElement.style.width = "auto";
      boxElement.style["white-space"] = "nowrap";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const boxElement = boxRef.current;

      if (boxElement) {
        fixRightOverflow();

        // Screen is too small - let element expand vertically
        const { width } = boxElement.getBoundingClientRect();
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

        if (viewportWidth > 560) {
          // makes max width of description 550px
          if (width > 550) {
            boxElement.style.width = "550px";
            boxElement.style["white-space"] = "normal";
            boxRef.current.style.transform = `translateX(0px)`;
            fixRightOverflow();
          }
        } else {
          fixLeftOverflow();
        }
      }
    };

    handleResize(); // Initial calculation

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tooltip-container" style={customComponent ? {} : customTooltipContainerStyle}>
      {customComponent ? (
        customComponent
      ) : (
        <div className={`info-popup-tooltip-container`}>
          <InfoIcon />
        </div>
      )}

      <div className="tooltip-animation">
        <div className="tooltip-description" ref={boxRef}>
          {children}
        </div>

        <div className="tooltip-description-tip" style={{ ...tipOffset }}>
          {/* <TooltipPoint style={{ height: "7px", width: "14px", display: "flex" }} /> */}
          <div className="tooltip-polygon">
            <img src={polygon} />
          </div>
          <div className="tooltip-polygon-with-border">
            <img src={polygonWithBorder} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Get translateX and translateY values of an element
function getTranslateXY(element) {
  const style = window.getComputedStyle(element);
  const matrix = new DOMMatrixReadOnly(style.transform);
  return {
    translateX: matrix.m41,
    translateY: matrix.m42,
  };
}
