import { useContext, useEffect, useState } from "react";
import ChatbotFormInlineToggle from "./ChatbotFormInlineToggle";
import { ChatbotFormContext } from "../ChatbotForm";
import { findBestPlan } from "../../Tools/findBestPlan";
import { WrappedComponentContext } from "../../WrappedComponent";

const ChatbotFormRemoveMajicBranding = () => {
  const [plan, setPlan] = useState();

  const { userInfo, removeBranding, setRemoveBranding } =
    useContext(ChatbotFormContext);
  const { setBannerMessage } = useContext(WrappedComponentContext);

  useEffect(() => {
    if (userInfo?.subscriptions) setPlan(findBestPlan(userInfo.subscriptions));
  }, [userInfo]);

  const setOpen = (value) => {
    if (["business", "elite"].includes(plan?.tier)) {
      setRemoveBranding(value);
    } else {
      setBannerMessage({
        type: "error",
        title: "Upgrade to complete this action",
        message: "Upgrade to Business or Elite to remove majic branding",
      });
    }
  };

  return (
    <ChatbotFormInlineToggle open={removeBranding} setOpen={setOpen}>
      Remove Majic.AI branding from chatbot
    </ChatbotFormInlineToggle>
  );
};

export default ChatbotFormRemoveMajicBranding;
