import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ActionSettings from "./ActionSettings";
import { scenarios } from "../Actions";

const NewAction = ({ type }) => {
  const [action, setAction] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const scenario = scenarios.find(
    (scenario) => scenario?.queryParamValue === searchParams.get("type")
  );

  useEffect(() => {
    if (scenario) {
      setAction((prev) => ({
        ...prev,
        title: scenario.value,
        intro_text: scenario.defaultValues.introText,
        success_text: scenario.defaultValues.successText,
      }));
    }
  }, []);

  return (
    <ActionSettings
      action={action}
      setAction={setAction}
      type={type}
      scenarioType={scenario?.value}
    />
  );
};

export default NewAction;
