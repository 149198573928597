import { useContext, useState } from "react";
import DocumentTitle from "../../Tools/DocumentTitle/DocumentTitle";
import { API_URL } from "../../config";
import { WrappedComponentContext } from "../../WrappedComponent";
import { ErrorFormat } from "../../Tools/Errors/ErrorFormatting";
import CircularIndeterminate from "../../Tools/Loading/CircularIndeterminate";
import StatusCard from "../../Tools/StatusCard/StatusCard";

export default function ChangeEmail() {
  const { token, setBannerMessage } = useContext(WrappedComponentContext);

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailError, setEmailError] = useState();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const changeEmail = async (e) => {
    e.preventDefault();
    if (!email) return;
    setLoading(true);

    try {
      const endpoint = `${API_URL}/accounts/update_email`;
      const method = "PUT";
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      const body = JSON.stringify({ email: email });

      const response = await fetch(endpoint, { method, headers, body });
      if (response.status === 401) {
        throw new ErrorFormat("Failed to update email.", response.status);
      }

      const data = await response.json();
      if (data?.email) {
        setEmailError(`email ${data?.email[0]}`);
      } else if (!response.ok) {
        setBannerMessage({
          type: "error",
          title: "Failed to update email.",
          message: data?.message,
        });
      } else {
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  if (success) {
    return (
      <div className="status-card-page-container">
        <StatusCard
          success={true}
          title="Email Changed"
          description="Email provisionally changed, please check your email for a confirmation link"
          to="/account"
          customUrlText="OK"
        />
      </div>
    );
  }

  return (
    <div className="page-card-container">
      <div className="page-card small min-height-mid">
        <DocumentTitle>CHANGE EMAIL</DocumentTitle>

        <div className="send-password-reset-container">
          <p>You can update your email by providing a new one below:</p>

          <form className="send-password-reset" onSubmit={changeEmail}>
            <div>
              <input
                placeholder="Enter New Email Address"
                value={email}
                onChange={handleEmailChange}
                required
                type="email"
                className={emailError && "error"}
              />

              {emailError && (
                <label className="inline-input-error">{emailError}</label>
              )}
            </div>

            <button
              className={`purple-button small-text ${!email && "muted"}`}
              type="submit"
            >
              {loading && <CircularIndeterminate color="white" />}
              Send Reset Instructions
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
