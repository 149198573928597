import { useContext, useEffect, useState } from "react";
import { API_URL } from "../config";
import ChatbotForm from "./ChatbotForm";
import { Form, Link, useNavigate } from "react-router-dom";
import { ErrorFormat } from "../Tools/Errors/ErrorFormatting";
import { WrappedComponentContext } from "../WrappedComponent";
import { saveFileToSessionStorage } from "../Tools/fileStuff/saveFileToSessionStorage";
import SubscriptionErrorPage from "../SubscriptionStates/SubscriptionErrorPage";
import StatusCard from "../Tools/StatusCard/StatusCard";
import ConfirmEmailWindow from "../Tools/ConfirmEmail/ConfirmEmailWindow";
import { addEventTrigger } from "../Tools/EventTriggers/addOrRemoveEventTrigger";

export default function NewChatbot() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { token, logout, userInfo, fetchUserInfo, checkForEventTriggers } =
    useContext(WrappedComponentContext);

  useEffect(() => {
    if (!userInfo) fetchUserInfo();
  }, []);

  const validateData = (requestData) => {
    const name = requestData.get("chat_bot[name]");
    const enablePublicChatbot = requestData.get(
      "chat_bot[public_chat_bot_enabled]"
    );
    const alias = requestData.get("chat_bot[alias]");
    const model = requestData.get("chat_bot[open_ai_model]");

    if (!name) {
      setError({ item: "name", message: "Chatbot name can't be blank" });
      return false;
    } else if (enablePublicChatbot === "true" && !alias) {
      setError({
        item: "alias",
        message: "Public chatbot must have a bot alias.",
      });
      return;
    } else if (!model) {
      setError({ item: "open_ai_model", message: "Model must not be blank" });
    }
    return true;
  };

  // This function stashes chatbot data in localstorage in a case where user is not logged in
  const stashChatbotData = (newChatbotRequestData) => {
    window.sessionStorage.removeItem("newChatbotJsonData");
    window.sessionStorage.removeItem("avatarJsonData");

    const avatar = newChatbotRequestData.get("chat_bot[avatar]");
    if (avatar) saveFileToSessionStorage(avatar, "avatarJsonData");

    const formDataJSON = JSON.stringify(
      Array.from(newChatbotRequestData.entries())
    );
    window.sessionStorage.setItem("newChatbotJsonData", formDataJSON);

    window.location.href = "/signup";
  };

  const createNewChatbot = async (requestData) => {
    try {
      const dataValidated = await validateData(requestData);
      if (!dataValidated) return;
      setLoading(true);

      if (!token) return stashChatbotData(requestData);

      const endpoint = `${API_URL}/chat_bots`;
      const method = "POST";
      const body = requestData;
      const headers = { Authorization: token };

      const response = await fetch(endpoint, { method, body, headers });
      const newChatbot = await response.json();

      if (response.status === 401)
        throw new ErrorFormat(
          "Couldn't create chatbot",
          response.status,
          logout
        );
      else if (!response.ok)
        throw newChatbot ? newChatbot : { httpStatusCode: response.status };

      // add gtag event trigger for chatbot creation
      addEventTrigger("chatbotCreation");
      checkForEventTriggers();

      setLoading(false);
      navigate(`/chatbot/settings/${newChatbot.id}/embed`);
    } catch (error) {
      setLoading(false);
      console.error(error);

      if (error?.name) setError({ item: "name", message: error?.name[0] });
      else if (error?.alias)
        setError({ item: "alias", message: error?.alias[0] });
      else if (error?.base) setError({ item: "base", message: error?.base[0] });
      else if (error?.user) setError({ item: "user", message: error?.user[0] });
      else if (error?.welcome_bubble_text_content)
        setError({
          item: "welcome bubble text content",
          message: `Welcome bubble text ${error?.welcome_bubble_text_content[0]}`,
        });
      else if (error?.open_ai_model)
        setError({
          item: "open_ai_model",
          message: `open ai model ${error?.open_ai_model[0]}`,
        });
      else
        setError({
          title: "Failed to create chatbot.",
          message: `Server responded with ${error?.httpStatusCode}`,
        });
    }
  };

  // Confirm email window shows up when email is not confirmed and user is on free plan
  if (
    userInfo &&
    !userInfo?.email_confirmed &&
    userInfo?.stripe_subscriptions?.length === 0
  ) {
    return <ConfirmEmailWindow email={userInfo?.email} />;
  }

  if (userInfo?.plan_information?.plan_chat_bot_limit_at_max) {
    return (
      <div className="status-card-page-container">
        <div style={{ maxWidth: "100%" }}>
          <Link
            to="/chatbots"
            className="manage-chatbot-back-to-chatbots"
            style={{ top: "100px", left: "auto", background: "inherit" }}
          >
            {"<  Back to Chatbots"}
          </Link>

          <StatusCard
            success={false}
            title="Can't add another chatbot."
            description="In order to complete this action please upgrade here:"
            to="/plan"
            customUrlText="Upgrade Here"
          />
        </div>
      </div>
    );
  }

  return (
    <ChatbotForm
      saveFunc={createNewChatbot}
      error={error}
      setError={setError}
      loading={loading}
    />
  );
}
