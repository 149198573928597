import { useContext } from "react";
import { ViewWebsiteSourcePreviewContext } from "./ViewWebsiteSourcePreview";

export const IndexedLinkView = ({ urlInfo, isIndexed, index }) => {
  const { manageExclusions, excluded, setExcluded, setExcludedHadChange } =
    useContext(ViewWebsiteSourcePreviewContext);

  const toggleChecked = () => {
    setExcluded((prevItems) => {
      const checked = excluded.includes(urlInfo?.url);

      setExcludedHadChange(true);

      if (checked) {
        const filteredItems = prevItems.filter((item) => item !== urlInfo?.url);
        return [...filteredItems];
      } else {
        return [...prevItems, urlInfo?.url];
      }
    });
  };

  return (
    <div className="flex align-center">
      {manageExclusions && (
        <div className="indexed-item-checkbox-container">
          <input
            type="checkbox"
            checked={excluded.includes(urlInfo?.url)}
            onClick={toggleChecked}
          />
        </div>
      )}

      <div className="indexed-list-item flex align-center">
        {index + 1}.&nbsp;
        {isIndexed ? (
          urlInfo?.blacklisted ? (
            <span>
              {urlInfo.url} <span style={{ color: "#FF5E8E" }}>(excluded)</span>
            </span>
          ) : (
            <a href={urlInfo.url} target="_blank" className="link">
              {urlInfo.url}
            </a>
          )
        ) : (
          <span>
            {urlInfo.url}{" "}
            {urlInfo?.blacklisted && (
              <span style={{ color: "#FF5E8E" }}>(excluded)</span>
            )}
          </span>
        )}
        <br />
        <br />
      </div>
    </div>
  );
};
