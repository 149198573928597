import { createContext, useContext, useEffect, useState } from "react";
import "./ChatbotForm.css";
import { Link, useNavigate } from "react-router-dom";
import EmbedChatbotSettings from "./ChatbotFormTabs/EmbedChatbotSettings/EmbedChatbotSettings";
import { WrappedComponentContext } from "../WrappedComponent";
import LookFeelTab from "./ChatbotFormTabs/Look&Feel/LookFeelTab";
import MainSettingsTab from "./ChatbotFormTabs/MainSettingsTab";
import ChatbotFormTabs from "./ChatbotFormTabs/ChatbotFormTabs";
import { saveChatbotForm } from "./saveChatbotForm";
import { showExistingChatbotData } from "./showExistingChatbotData";
import { showDefaultChatbotValues } from "./showDefaultChatbotValues";
import { AppContext } from "../App";
import WaitingWithText from "../Tools/ChatWaiting/WaitingWithText";
import FormsTab from "./ChatbotFormTabs/FormsTab/FormsTab";
import {
  defaultLightColors,
  defaultDarkColors,
} from "./ChatbotFormTabs/Look&Feel/EditThemes/defaultColors";
import ActionsTab from "./ChatbotFormTabs/ActionsTab/ActionsTab";

export const ChatbotFormContext = createContext();

export default function ChatbotForm({
  saveFunc,
  existingData,
  isEdit,
  deleteFunc,
  manageChatbotLink,
  defaultTab,
  error,
  setError,
  loading,
  isDeleting,
}) {
  const { token, userInfo, fetchUserInfo, setBannerMessage } = useContext(WrappedComponentContext);
  const { globalSettings } = useContext(AppContext);
  const navigate = useNavigate();

  const [tab, setTab] = useState(defaultTab ? defaultTab : "main-settings");
  const [redirectToErrorSpot, setRedirectToErrorSpot] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [defaultModel, setDefaultModel] = useState();
  const [enablePublicChatbot, setEnablePublicChatbot] = useState(false);
  const [botAlias, setBotAlias] = useState("");
  const [rateLimiting, setRateLimiting] = useState(true);
  const [rateLimitingDetails, setRateLimitingDetails] = useState({
    requests: 10,
    perAmountSeconds: 120,
  });
  const [limitMessage, setLimitMessage] = useState(
    "Too many requests in a row. Please try again later."
  );
  const [domainsAllowed, setDomainsAllowed] = useState([]);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [showImageInReplies, setShowImageInReplies] = useState(true);
  const [showTimeStamps, setShowTimeStamps] = useState(true);
  const [welcomeMessage, setWelcomeMessage] = useState(
    "Hello and welcome! How can I help you today?"
  );
  const [displayWelcomeBubble, setDisplayWelcomeBubble] = useState(false);
  const [welcomeBubbleText, setWelcomeBubbleText] = useState(
    "Welcome! If you have any questions, click the chat button below and we’ll do our best to help."
  );
  const [welcomeBubbleHangTime, setWelcomeBubbleHangTime] = useState(2);
  const [temperature, setTemperature] = useState(0);
  const [basePrompt, setBasePrompt] = useState();
  const [noAnswerMessage, setNoAnswerMessage] = useState(
    "Sorry, I don’t have an exact answer for that."
  );
  const [removeBranding, setRemoveBranding] = useState(false);
  const [showSources, setShowSources] = useState(false);
  const [maxSources, setMaxSources] = useState(2);
  const [image, setImage] = useState();
  const [isExistingImage, setIsExistingImage] = useState();
  const [notifyFinishedSessions, setNotifyFinishedSessions] = useState(false);

  // States for setting theme and colors
  const [theme, setTheme] = useState("light");
  const [lightColors, setLightColors] = useState(defaultLightColors);
  const [darkColors, setDarkColors] = useState(defaultDarkColors);

  const [backLink, setBackLink] = useState({ link: manageChatbotLink, text: "<  Back to Chatbot" });
  const [hideTabsMenu, setHideTabsMenu] = useState(false);

  useEffect(() => {
    if (defaultTab) setTab(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (!userInfo) fetchUserInfo();
  }, []);

  useEffect(() => {
    if (!isEdit && globalSettings) showDefaultChatbotValues(globalSettings, value);
  }, [globalSettings]);

  useEffect(() => showExistingChatbotData(existingData, value), [existingData]);

  useEffect(() => handleGivenError(), [error]);
  useEffect(() => goToPlaceOfError(), [tab, redirectToErrorSpot]);

  const getImage = () => {
    if (isExistingImage) return image;
    return URL.createObjectURL(image);
  };

  const canGoToNext = (showError = false) => {
    // Checks if user is able to go to next page (look and feel)

    try {
      if (isEdit) return true;

      if (!name) throw { item: "name", message: "Chatbot name can't be blank" };
      if (!defaultModel?.value) throw { item: "open_ai_model", message: "Model must not be blank" };
      if (enablePublicChatbot && !botAlias) {
        throw { item: "alias", message: "Public chatbot must have a bot alias." };
      }

      return true;
    } catch (error) {
      if (showError) {
        setError(error);
      }

      return false;
    }
  };

  const handleGivenError = () => {
    // Goes to correct tab of error and sets the redirect to error spot value + handles other errors

    if (!error) return;

    // If no plan show message with link to /plan
    if (error?.item === "user" && error?.message === "has not chosen a plan yet") {
      navigate("/needs-plan");
    }

    const mainSettingsErrors = ["name", "alias", "open_ai_model"];
    const lookAndFeelErrors = ["welcome bubble text content"];

    if (mainSettingsErrors.includes(error?.item)) {
      setTab("main-settings");
      setRedirectToErrorSpot(true);
    } else if (lookAndFeelErrors.includes(error?.item)) {
      setTab("look-&-feel");
      setRedirectToErrorSpot(true);
    } else {
      // Other errors
      if (error?.message || error?.title) {
        setBannerMessage({
          type: "error",
          title: error?.title ? error?.title : "Failed to complete this action.",
          message: error?.message,
        });
      }
    }
  };

  const goToPlaceOfError = () => {
    if (tab && redirectToErrorSpot) {
      try {
        if (error?.item === "name") document.getElementById("chatbot-title").scrollIntoView();
        else if (error?.item === "alias") document.getElementById("chatbot-alias").scrollIntoView();
        else if (error?.item === "open_ai_model") {
          document.getElementById("chatbot-model-selection").scrollIntoView();

          setBannerMessage({
            type: "error",
            title: "Failed to complete this action.",
            message: error?.message,
          });
        } else if (error?.item === "welcome bubble text content") {
          document.getElementById("welcome-bubble-text-content").scrollIntoView();
        } else {
          alert(error?.message ?? error);
        }

        window.scrollBy(0, -141);
        setRedirectToErrorSpot(false);
      } catch (error) {
        setRedirectToErrorSpot(false);
      }
    }
  };

  const requiredItemsAreFilled = canGoToNext(false);

  const value = {
    error,
    setError,
    tab,
    setTab,
    isEdit,
    requiredItemsAreFilled,
    userInfo,
    deleteFunc,
    isDeleting,
    loading,
    canGoToNext,

    saveChatbotForm,
    saveFunc,

    defaultModel,
    setDefaultModel,
    domainsAllowed,
    setDomainsAllowed,
    showImageInReplies,
    setShowImageInReplies,
    showTimeStamps,
    setShowTimeStamps,
    image,
    setImage,
    isExistingImage,
    setIsExistingImage,
    getImage,
    suggestedQuestions,
    setSuggestedQuestions,
    welcomeBubbleText,
    setWelcomeBubbleText,
    displayWelcomeBubble,
    setDisplayWelcomeBubble,
    welcomeBubbleHangTime,
    setWelcomeBubbleHangTime,
    welcomeMessage,
    setWelcomeMessage,
    name,
    setName,
    temperature,
    setTemperature,
    enablePublicChatbot,
    setEnablePublicChatbot,
    botAlias,
    setBotAlias,
    basePrompt,
    setBasePrompt,
    rateLimiting,
    setRateLimiting,
    rateLimitingDetails,
    setRateLimitingDetails,
    limitMessage,
    setLimitMessage,
    theme,
    setTheme,
    lightColors,
    darkColors,
    setLightColors,
    setDarkColors,
    disabled,
    setDisabled,
    removeBranding,
    setRemoveBranding,
    showSources,
    setShowSources,
    maxSources,
    setMaxSources,
    notifyFinishedSessions,
    setNotifyFinishedSessions,
    noAnswerMessage,
    setNoAnswerMessage,

    existingData,

    setBackLink,
    manageChatbotLink,
    setHideTabsMenu,
  };

  return (
    <ChatbotFormContext.Provider value={value}>
      <div className={`page-card-container ${isEdit ? "with-back-button" : ""}`}>
        <div className="page-card" style={{ position: "relative" }}>
          {/* back link on edit */}
          {backLink?.link && (
            <Link to={backLink?.link} className="manage-chatbot-back-to-chatbots">
              {backLink?.text}
            </Link>
          )}

          {!isEdit || existingData ? (
            <>
              {!hideTabsMenu && (
                <>
                  <div className="page-card-title-container">
                    <h1 className="title-20">
                      {isEdit ? `${existingData?.name ?? "Chatbot"} Settings` : "New Chatbot"}
                    </h1>
                  </div>

                  <ChatbotFormTabs />

                  <div className="new-chatbot-divider nomargin" />
                </>
              )}

              <div style={{ height: "100%" }}>
                {tab === "main-settings" && <MainSettingsTab />}

                {tab === "look-&-feel" && <LookFeelTab />}

                {tab === "embed-chatbot" && isEdit && (
                  <EmbedChatbotSettings manageChatbotLink={manageChatbotLink} />
                )}

                {tab === "forms" && isEdit && <FormsTab />}

                {tab === "actions" && isEdit && <ActionsTab />}
              </div>
            </>
          ) : (
            <WaitingWithText>Loading Chatbot</WaitingWithText>
          )}
        </div>
      </div>
    </ChatbotFormContext.Provider>
  );
}
