import { addEventTrigger } from "../../Tools/EventTriggers/addOrRemoveEventTrigger";
import { base64StringToFile } from "../../Tools/fileStuff/base64StringToFile";
import { API_URL } from "../../config";

// This function checks if chatbot data has been saved in session storage and if so created the chatbot
// (This function is called after login and checkout if needed)
export const createNewChatbot = async ({
  token,
  setBannerMessage = null,
  checkForEventTriggers,
}) => {
  try {
    const newChatbotJsonData =
      window.sessionStorage.getItem("newChatbotJsonData");
    window.sessionStorage.removeItem("newChatbotJsonData");

    const avatarData = window.sessionStorage.getItem("avatarJsonData");
    window.sessionStorage.removeItem("avatarJsonData");

    if (!newChatbotJsonData || !token) {
      return null;
    }

    let avatar;
    if (avatarData) {
      const { base64, fileName } = JSON.parse(avatarData);
      avatar = base64StringToFile(base64, fileName);
    }

    // Parse the saved json data into formData
    const formData = new FormData();
    const formDataArray = JSON.parse(newChatbotJsonData);
    formDataArray.forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.delete("chat_bot[avatar]");
    if (avatar) formData.append("chat_bot[avatar]", avatar);

    const endpoint = `${API_URL}/chat_bots`;
    const method = "POST";
    const body = formData;
    const headers = { Authorization: token };

    const response = await fetch(endpoint, { method, body, headers });
    const newChatbot = await response.json();

    if (!response.ok || !newChatbot?.id)
      throw new Error("Failed to create chatbot");

    // add gtag event trigger for chatbot creation
    addEventTrigger("chatbotCreation");
    checkForEventTriggers();

    if (setBannerMessage) {
      setBannerMessage({
        type: "success",
        title: "Chatbot Ready!",
        message: "Your new chatbot is now ready!",
      });
    }

    return true;
  } catch (error) {
    console.error(error);

    if (setBannerMessage) {
      setBannerMessage({
        type: "error",
        title: "Failed to create chatbot.",
      });
    }

    return false;
  }
};
